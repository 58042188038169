<script setup>
import Faqs from "../components/version/VersionComponent.vue";
</script>

<script>
export default {
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ruta: "",
    };
  },
  created() {
    if (window.location.pathname == "/version") {
      this.ruta = 1;
    }
  },
};
</script>

<template>
  <Faqs ref="faqs" :lang="lang" :ruta="ruta" />
</template>

<style>
@import "../../src/assets/css/faqs.css";
</style>
