<template>
  <div>
    <div
      v-if="$parent.errors && $parent.errors.length > 0"
      style="z-index: 10051"
      :class="[
        { succesful: succesful },
        { purple: purple },
        'newProject-ErrorWindowContainer',
      ]"
    >
      <img
        v-if="!succesful && !purple"
        :src="require('@/assets/img/projects/close_errors.png')"
        class="newProject-ErrorClose"
        @click="$parent.closeErrorsWindow()"
      />
      <img
        v-if="succesful || purple"
        style="width: 8px"
        :src="require('@/assets/img/projects/close_errors_green.png')"
        class="newProject-ErrorClose"
        @click="$parent.closeErrorsWindow()"
      />
      <p>
        <strong>{{ langFilter(title) }}</strong
        ><br />{{ langFilter(text) }} <strong>{{ variable1 }}</strong>
        {{ text2 !== "" ? langFilter(text2) : "" }}
        <strong>{{ variable2 }}</strong>
      </p>
      <div class="newProject-ErrorTimeBar" :style="$parent.errorTime"></div>
    </div>
    <div
      v-if="$parent.succesful && $parent.succesful.length > 0"
      class="newProject-ErrorWindowContainer"
    >
      <img
        src="/img/projects/close_errors.png"
        class="newProject-ErrorClose"
        @click="$parent.closeErrorsWindow()"
      />
      <p>
        <strong>{{ langFilter(title) }}</strong
        ><br />{{ langFilter(text) }} <strong>{{ variable1 }}</strong>
        {{ text2 !== "" ? langFilter(text2) : "" }}
        <strong>{{ variable2 }}</strong>
      </p>
      <div class="newProject-ErrorTimeBar" :style="$parent.errorTime"></div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      default: "en",
    },
  },
  data() {
    return {
      succesful: false,
      purple: false,
      title: "¡Incompleted fields!",
      text: "To continue you must fill all the fields.",
      variable1: "",
      text2: "",
      variable2: "",
      traducciones: [
        {
          name: "To continue you must fill all the fields.",
          es: "Para continuar debes rellenar todos los campos.",
          en: "To continue you must fill all the fields.",
        },
        {
          name: "To continue there must be at least one Task in the Part.",
          es: "To continue there must be at least one Task in the Part.",
          en: "To continue there must be at least one Task in the Part.",
        },
        {
          name: "¡Incompleted fields!",
          es: "¡Campos incompletos!",
          en: "¡Incompleted fields!",
        },
        {
          name: "Incorrect data entered!",
          es: "¡Datos introducidos incorrectos!",
          en: "Incorrect data entered!",
        },
        {
          name: "the email and/or password do not match.",
          es: "Cuidado, el usuario y/o la contraseña no coinciden.",
          en: "The email and/or password do not match.",
        },

        {
          name: "Access denied!",
          es: "Acceso denegado!",
          en: "Access denied!",
        },
        {
          name: "your trial period has ended.",
          es: "Su periodo de prueba ha finalizado. Contacte con ventas@acceleralia.com si desea extender su periodo de prueba.",
          en: "Your trial period has ended. Contact ventas@acceleralia.com if you wish to extend your trial period.",
        },

        {
          name: "The password is to weak.",
          es: "La contraseña es demasiado débil.",
          en: "The password is to weak.",
        },
        {
          name: "The password is to weak.",
          es: "La contraseña es demasiado débil.",
          en: "The password is to weak.",
        },
        {
          name: "Fill the password",
          es: "Rellena la contraseña.",
          en: "Fill the password.",
        },
        {
          name: "Field required",
          es: "Campo requerido.",
          en: "Field required.",
        },
        {
          name: "Use at least 5 characters for your username",
          es: "Utiliza un mínimo de 5 caracteres para tu usuario.",
          en: "Use at least 5 characters for your username.",
        },
        {
          name: "Username too short",
          es: "Usuario demasiado corto.",
          en: "Username too short",
        },
        {
          name: "Please read and accept terms and conditions and privacy policy.",
          es: "Por favor lee y acepta los terminos y condiciones y la politica de privacidad.",
          en: "Please read and accept terms and conditions and privacy policy.",
        },
        {
          name: "Ups... Something went wrong!",
          es: "¡Ups... Algo ha salido mal!",
          en: "Ups... Something went wrong!",
        },
        {
          name: "The passwords you entered do not match.",
          es: "Las contraseñas introducidas no coinciden.",
          en: "The passwords you entered do not match.",
        },
        {
          name: "The password you entered do not match.",
          es: "La contraseña introducida no coincide.",
          en: "The password you entered do not match.",
        },
        {
          name: "¡Updated profile!",
          es: "¡Perfil actualizado!",
          en: "¡Updated profile!",
        },
        {
          name: "The profile has been updated succesfully",
          es: "El perfil ha sido actualizado con éxito.",
          en: "The profile has been updated succesfully",
        },
        {
          name: "The email field must be an email.",
          es: "El campo email debe ser un email.",
          en: "The email field must be an email.",
        },
        {
          name: "Be careful, the email introduced does not exist.",
          es: "Cuidado, el email introducido no existe.",
          en: "Be careful, the email introduced does not exist.",
        },
        {
          name: "To INVITE a new user you have to insert an email.",
          es: "Para INVITAR un nuevo usuario debes introducir un email.",
          en: "To INVITE a new user you have to insert an email.",
        },
        {
          name: "Incompleted field!",
          es: "¡Campo incompleto!",
          en: "Incompleted field!",
        },
        {
          name: "Incorrect field!",
          es: "¡Campo incorrecto!",
          en: "Incorrect field!",
        },
        {
          name: "Email sent!",
          es: "¡Email enviado!",
          en: "Email sent!",
        },
        {
          name: "An invitation email has been sent",
          es: "Se ha enviado un email de invitación al espacio",
          en: "An invitation email to the space has been sent",
        },
        {
          name: "Please insert a valid email",
          es: "Por favor inserta un email válido",
          en: "Please insert a valid email",
        },
        {
          name: "The user is already in the space",
          es: "El usuario ya esta en el espacio",
          en: "The user is already in the space",
        },
        {
          name: "This Username already exists. Please try with another one.",
          es: "El Nombre de usuario ya existe. Por favor inténtalo con otro.",
          en: "This Username already exists. Please try with another one.",
        },
        {
          name: "Please try again later",
          es: "Por favor vuelva a intentar más tarde.",
          en: "Please try again later.",
        },
        //Solutions screen
        {
          name: "warning",
          es: "Aviso",
          en: "Warning",
        },
        {
          name: "The status has been updated from",
          es: "Se ha actualizado el estado de",
          en: "The status has been updated from",
        },
        {
          name: "to",
          es: "a",
          en: "to",
        },
        {
          name: "The file can't be more than 5MB",
          es: "El archivo no puede ser mayor de 5MB",
          en: "The file can't be more than 5MB",
        },
        {
          name: "Error uploading the file",
          es: "Error subiendo el archivo",
          en: "Error uploading the file",
        },
        {
          name: "Error evaluating project",
          es: "Error al evaluar el proyecto",
          en: "Error evaluating project",
        },
        {
          name: "Try with an image with a size lower than 5MB and a minimum of 160x160 pixels.",
          es: "Prueba con una imagen que pese menos de 5MB y con una dimensión mínima de 160x160 pixeles.",
          en: "Try with an image with a size lower than 5MB and a minimum of 160x160 pixels.",
        },
        {
          name: "¡Incorrect dates!",
          es: "¡Fechas incorrectas!",
          en: "Incorrect dates!",
        },
        {
          name: "The end date must be greater than the start date.",
          es: "La fecha de finalización debe ser mayor que la fecha de inicio.",
          en: "The finish date must be greater than the start date.",
        },
        {
          name: "textCopiedSuccessfully",
          es: "El texto se ha copiado exitosamente",
          en: "The text has been copied successfully",
        },
        {
          name: "textCopiedWithError",
          es: "El texto no se ha copiado. Hubo un error",
          en: "The text has not been copied. There was an error",
        },
        {
          name: "Incompleted project fields",
          es: "Tienes campos del proyecto sin completar.",
          en: "You have incomplete project fields.",
        },
        {
          name: "Error uploading file",
          es: "Error al subir archivo",
          en: "Error uploading file.",
        },
        {
          name: "downloadErrorTitle",
          es: "Error al descargar el archivo",
          en: "Error downloading file"
        },
        {
          name: "programCreated",
          es: "El programa se ha creado con exito",
          en: "The program has been created successfully",
        },
        {
          name: "templateSavedAlert",
          es: "La plantilla se ha guardado correctamente, la puedes visualizar en su apartado correspondiente",
          en: "The template has been saved correctly, you can view it in its corresponding section",
        },
        {
          name: "succesfulTemplateCopy",
          es: "La nueva copia ha sido creada",
          en: "The new copy has been created",
        },
        {
          name: "errorUpdatingUser",
          es: "Hubo un error actualizando los usuarios",
          en: "There was an error updating users"
        },
        {
          name: "usersSuccessfullyUpdated",
          es: "Usuarios actualizados correctamente",
          en: "Users successfully updated..."
        },
        {
          name: "usersUpdatedInProject",
          es: "La lista de usuarios asignados al proyecto ha sido actualizada.",
          en: "The list of users assigned to the project has been updated."
        },
        {
          name: "ErrorSendingMessage",
          es: "Error enviando mensaje al asistente",
          en: "Error sending message to the assistant",
        },
        {
          name: "PowerPointNotAllowed",
          es: "Archivos de PowerPoint no estan permitidos",
          en: "PowerPoint files are not allowed",
        },
        {
          name: "ErrorDeleteChat",
          es: "Error eliminando el chat del asistente",
          en: "Error deleting assistant's chat",
        },
        {
          name: "Files",
          es: "Archivos",
          en: "Files",
        },
        {
          name: "ErrorEvaluatingProject",
          es: "Error evaluando el proyecto",
          en: "Error evaluating project",
        },
        {
          name: "ErrorChat",
          es: "Error obteniendo el chat del Asistente",
          en: "Error getting Assistant's chat",
        },
        {
          name: "ErrorQuestionPrompt",
          es: "Error obteniendo el mensaje de la pregunta",
          en: "Error getting the question prompt",
        },
      ],
    };
  },
};
</script>

<style>
@import "../../src/assets/css/projects/newProject.css";
</style>
