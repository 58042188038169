<template>
  <div>
    <header-general
      :title="langFilter('title_my_itineraries_screen')"
      src="/img/icons/itinerary.svg"
    />
    <track-catalog
      v-show="view === 0"
      :role="role"
      :lang="lang"
      :view="view"
      :spaces="spaces"
      :isAdmin="isAdmin"
    />
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import trackCatalog from "./TrackCatalogComponent.vue";
// import newTrack from "./newTrack/NewTrackComponent.vue";

export default {
  components: {
    trackCatalog,
    // newTrack,
  },
  mixins: [translationMixin],
  props: {
    role: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    spaces: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      view: 0,
      isAdmin: false,
      // traduccion
      traducciones: [
        {
          name: "error",
          es: "Error, por favor contacta con support@acceleralia.com.",
          en: "Error, please contact support@acceleralia.com.",
        },
        {
          name: "no_tracks",
          es: "No hay tracks disponibles.",
          en: "No tracks available.",
        },
        {
          name: "title_my_itineraries_screen",
          es: "Plantillas",
          en: "Templates",
        },
      ],
    };
  },
  created() {
    this.isAdmin = this.role == 1;
    const pathname = window.location.pathname;
    pathname.split("/");
    if (pathname[4] === "-") {
      if (this.isAdmin) {
        this.view = 1;
      } else {
        history.replaceState(null, null, `/tracks`);
      }
    }
  },
};
</script>
