<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="row task">
    <div class="col-2" />
    <div class="col-9 new-template-data-question new-template-padding-question">
      <div>
        <hover :content="langFilter('addQuestion')">
          <button class="new-btn-purple-add" @click="addQuestion" />
        </hover>
      </div>
      <span class="new-template-nowrap"
        >{{ langFilter("question") }} {{ questionIndex + 1 }}.</span
      >
      <input
        class="new-template-input-part new-template-input-part-question"
        :placeholder="langFilter('questionName') + '...'"
        type="text"
        :value="question.title"
        @input="updateQuestion($event, 1)"
      />
    </div>
    <new-template-creation-options
      class="new-template-padding-question"
      :edit="true"
      :lang="lang"
      :apartIndex="apartIndex"
      :themeIndex="themeIndex"
      :questionIndex="questionIndex"
      :type="3"
      @openModalConfirmation="openModalConfirmation($event)"
    />
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../../mixins/translationMixin.js";
export default {
  props: [
    "question",
    "themeIndex",
    "apartIndex",
    "questionIndex",
    "totalQuestions",
    "lang",
  ],
  data() {
    return {
      traducciones: [
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
        {
          name: "questionName",
          es: "Escribir nombre de la pregunta...",
          en: "Write the name of the question...",
        },
        {
          name: "addQuestion",
          es: "Añadir Pregunta",
          en: "Add Question",
        },
      ],
    };
  },
  methods: {
    updateQuestion(event, type) {
      this.$emit("updateQuestion", [
        event.target.value,
        type,
        this.questionIndex,
      ]);
    },
    openModalConfirmation(data) {
      data.push(this.questionIndex);
      this.$emit("openModalConfirmation", data);
    },
    addQuestion() {
      this.$emit("addQuestion", [
        this.themeIndex,
        this.apartIndex,
        this.questionIndex,
      ]);
    },
  },
  mixins: [translationMixin],
};
</script>
