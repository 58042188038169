<template>
  <div
    v-show="showModal"
    id="notifications-modal"
    class="notifications-modal-container"
    :class="{
      'fixed-banner': banner,
    }"
  >
    <div class="not-modal-body">
      <div v-if="$parent.selectedNotificationId === 0">
        <div class="div-notifications-modal" @click="markAllAsRead">
          <img
            :src="require('@/assets/img/notifications/mark_as_read.svg')"
            alt="mark as read"
          />
          <span class="notification-txt-modal">{{
            langFilter("mark_all_read")
          }}</span>
        </div>
        <div
          v-if="banner"
          class="div-notifications-modal"
          @click="openNotifications()"
        >
          <img
            :src="require('@/assets/img/notifications/open_notifications.svg')"
            alt="open notifications"
          />
          <span class="notification-txt-modal">{{
            langFilter("open_notifications")
          }}</span>
        </div>
      </div>
      <div v-else>
        <div class="div-notifications-modal" @click="markAsRead">
          <img
            :src="require('@/assets/img/notifications/mark_as_read.svg')"
            alt="mark as read"
          />
          <span v-if="!locatedModal[2]" class="notification-txt-modal">{{
            langFilter("no_read")
          }}</span>
          <span v-else class="notification-txt-modal">{{
            langFilter("mark_read")
          }}</span>
        </div>
        <div class="div-notifications-modal" @click="deleteNotification">
          <img
            :src="require('@/assets/img/notifications/config.svg')"
            alt="config"
          />
          <span class="notification-txt-modal">{{
            langFilter("delete_not")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import notificationsMixin from "../../mixins/notificationsMixin.js";
import axios from "axios";
export default {
  mixins: [translationMixin, notificationsMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    locatedModal: {
      type: Array,
      required: true,
    },
    banner: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true
    }
  },
  emits: ["deleteNotification", "markAllAsRead", "markAsRead"],
  data() {
    return {
      top: 0,
      left: 0,
      traducciones: [
        {
          name: "mark_read",
          es: "Marcar como leída",
          en: "Mark as read",
        },
        {
          name: "mark_all_read",
          es: "Marcar todas como leídas",
          en: "Mark all as read",
        },
        {
          name: "no_read",
          es: "Marcar como no leída",
          en: "Mark as unread",
        },
        {
          name: "delete_not",
          es: "Eliminar esta notificación",
          en: "Delete this notification",
        },
        {
          name: "open_notifications",
          es: "Abrir notificaciones",
          en: "Open notifications",
        },
      ],
    };
  },
  watch: {
    locatedModal: function (val) {
      if (!this.banner) {
        this.top = val[0] - 33 + "px";
        this.left = val[1] - 225 + "px";
      } else {
        this.top = val[0] + "px";
        this.left = val[1] + "px";
      }
    },
  },
  methods: {
    openNotifications() {
      let page = window.location.pathname;
      let pagename = page.split("/");
      window.location.href = pagename[0] + "/notifications";
    },
    deleteNotification() {
      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/notifications/deleteNotification`,
          {
            data: {
              notification_id: this.$parent.selectedNotificationId,
            },
          }
        )
        .then(() => {
          this.$emit("deleteNotification", this.$parent.selectedNotificationId);
          this.$parent.notificationCount();
        });
    },
    markAllAsRead() {
      axios
        .patch(`${process.env.VUE_APP_API_URL}/notifications/markAllAsRead`)
        .then(() => {
          this.$emit("markAllAsRead", 1);
          this.$parent.notificationCount();
        });
    },
    markAsRead() {
      axios
        .patch(`${process.env.VUE_APP_API_URL}/notifications/markAsRead`, {
          notification_id: this.$parent.selectedNotificationId,
        })
        .then(() => {
          this.$emit("markAsRead", this.$parent.selectedNotificationId);
          this.$parent.notificationCount();
        });
    },
  },
};
</script>

<style>
#notifications-modal {
  top: v-bind("top");
  left: v-bind("left");
}
</style>
