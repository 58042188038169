<!-- eslint-disable vue/prop-name-casing -->
<!-- eslint-disable vue/prop-name-casing -->
<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="members-title">
    <span>{{ langFilter("projMembers") }}</span>
  </div>
  <itinerary-users-modal
    v-if="addingUsers !== -1"
    :lang="lang"
    :space="space"
    :projectId="projectId"
    :addingUsers="addingUsers"
    :users-not-in-project="usersToAdd"
    @restore-list="restoreUsersNotInProject($event)"
    @close-modal="addingUsers = -1"
    @add-users="getUsersInProject"
    @add-project-users="addProjectUsers($event)"
    @handle-unselect="hanldeUnselect($event)"
    @select-user-to-invite="selectUserToInvite($event)"
  />
  <template v-for="(userData, userIndex) in users" :key="userIndex">
    <div class="container itinerary-users-title">
      {{ langFilter(userIndex) }}
      <div class="row">
        <div class="col-12">
          <button
            v-if="editing"
            class="itinerary-add-btn itinerary-add-users-btn"
            @click="addingUsers = userIndex"
          >
            +
          </button>
        </div>
      </div>
    </div>
    <itinerary-users-table
      v-for="(user, i) in userData"
      :key="i"
      :i="i"
      :lang="lang"
      :editing="editing"
      :type="userIndex"
      :user="user"
      @remove-user="removeUser($event)"
    />
  </template>

  <alertMessage ref="alerts" :lang="lang"></alertMessage>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    editing: Boolean,
    space: {
      type: Object,
      default: () => ({}),
    },
    projectId: [String, Number],
    usersInProject: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["handleUsers"],
  data() {
    return {
      addingUsers: -1,
      users: {
        members: [],
        tutors: [],
      },
      usersToAdd: [],
      usersToRemove: {
        members: [],
        tutors: [],
      },
      errors: [],
      traducciones: [
        {
          name: "projMembers",
          es: "MIEMBROS DEL PROYECTO",
          en: "PROJECT MEMBERS",
        },
        {
          name: "members",
          es: "Miembros",
          en: "Members",
        },
        {
          name: "tutors",
          es: "Tutor/es",
          en: "Tutor/s",
        },
      ],
    };
  },
  computed: {
    isNewProject() {
      return !this.projectId;
    },
  },
  async created() {
    try {
      await this.getUsersInProject();
      await this.getUsersNotInProject();
    } catch (error) {
      this.openErrorsAlert();
      console.error("Error creating users component", error);
    }
  },
  methods: {
    restoreUsersNotInProject(data) {
      this.usersToAdd.push(...data);
    },
    removeUser({ type, index }) {
      const [user] = this.users[type].splice(index, 1);
      if (!user) return;

      this.usersToRemove[type] = [...(this.usersToRemove[type] || []), user];
      this.usersToAdd = [...this.usersToAdd, user];
    },
    addProjectUsers({ type, data }) {
      this.users[type] = [...this.users[type], ...data];

      const dataIds = new Set(data.map(({ id }) => id));
      this.usersToRemove[type] =
        this.usersToRemove[type]?.filter(({ id }) => !dataIds.has(id)) || [];

      this.$emit("handleUsers", {
        data: this.users,
      });
    },
    selectUserToInvite({ id: userId }) {
      this.usersToAdd = this.usersToAdd.filter(({ id }) => id !== userId);
    },
    hanldeUnselect(user) {
      this.usersToAdd = [...this.usersToAdd, user];
    },
    openErrorsAlert() {
      this.$refs.alerts.title = "Ups... Something went wrong!";
      this.$refs.alerts.text = "Please try again later";

      if (this.errors.length == 0) {
        this.errors.push("error");
        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.errors = [];
    },
    async getUsersInProject() {
      const { members = [], tutors = [] } = this.usersInProject || {};
      if (members.length || tutors.length || this.isNewProject) {
        this.users = this.usersInProject;
        return;
      }

      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/getUsersBySpaceInProject/${this.space.id}/${this.projectId}`
        );

        const { members, tutors } = data;
        this.users = {
          members,
          tutors,
        };

        this.$emit("handleUsers", { data: this.users });
      } catch (error) {
        throw new Error("Error getting users in project", { cause: error });
      }
    },
    async getUsersNotInProject() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/getUsersBySpaceNotInProject/${this.space.id}/${this.projectId}`
        );

        this.usersToAdd = data.users;
      } catch (error) {
        throw new Error("Error getting users not in project", { cause: error });
      }
    },
  },
};
</script>