<template>
  <div>
    <!-- <atras :lang="lang"></atras> -->
    <div class="barraContacto">
      <div class="contacto-subtext">
        <h2 class="centro-ayuda faqs-h-bold">
          {{ langFilter("centroayuda") }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // traduccion
      traducciones: [
        {
          name: "contacto",
          es: "Contacto",
          en: "Contact",
        },
        {
          name: "centroayuda",
          es: "Centro de ayuda.",
          en: "Help center.",
        },
      ],
    };
  },
  created() {
    $(".myProjects-SecondHeaderContainer").hide();
    $(".carga").hide();
    $(".myProjects-MainContainer").show();
    this.cambiarIdioma(window.location.href);
  },
  methods: {
    cambiarIdioma(url) {
      if (url.slice(-2) == "es") {
        // this.lang = "es";
      }
    },
  },
};
</script>
