<template>
  <div>
    <header-general
      :title="langFilter('title_my_itineraries_screen')"
      src="/img/sidebar/icon–projects.svg"
    />
    <!-- <span @click="goBackTrack()" class="atras back-button-header">
            <img src="/img/general/new_back.svg" alt="Back" />
        </span> -->
    <!-- questions modal -->

    <div
      v-show="questionsModal === 1"
      id="myProjects-ModalQuestions"
      class="myProjects-ModalContainer"
      style="display: block"
    >
      <div class="myProjects-Modal-questions">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="newProject-close-modals"
          @click="closeModalClickingX"
        >
          <path
            d="M16.3248 16.6752C15.9342 17.0658 15.3011 17.0658 14.9105 16.6752L9.05882 10.8235L3.14828 16.7341C2.75776 17.1246 2.12459 17.1246 1.73407 16.7341L1.23652 16.2365C0.845994 15.846 0.845995 15.2128 1.23652 14.8223L7.14706 8.91177L1.32476 3.08946C0.934231 2.69894 0.93423 2.06577 1.32475 1.67525L1.67525 1.32475C2.06577 0.934229 2.69894 0.934229 3.08946 1.32475L8.91177 7.14706L14.8223 1.23652C15.2128 0.845995 15.846 0.845995 16.2365 1.23652L16.7341 1.73407C17.1246 2.12459 17.1246 2.75776 16.7341 3.14828L10.8235 9.05882L16.6752 14.9105C17.0658 15.3011 17.0658 15.9342 16.6752 16.3248L16.3248 16.6752Z"
            fill="#B7AED6"
          />
        </svg>
        <!-- <img id="myProjects-ModalClose" class="myProjects-ModalClose" :src="require('@/assets/img/projects/modal_X.png')" @click="questionsModal=0"> -->
        <h1 class="newProjects-ModalQuestionsTitle">
          {{ langFilter("new question") }}
        </h1>
        <div class="newProject-HalfContainer" style="padding-right: 10.5px">
          <label class="newProject-NumberInput">1</label
          ><label class="newProject-TextInput">{{
            langFilter("task title")
          }}</label>
          <input
            id="newProject-QuestionTitle"
            v-model="question.task"
            class="newProject-Input"
            style="text-align: left"
            :placeholder="langFilter('task title placeholder')"
            :disabled="lockedTrack == 1"
          />
        </div>
        <div
          class="newProject-HalfContainer"
          style="padding-left: 10.5px; padding-right: 0px"
        >
          <label class="newProject-NumberInput">2</label
          ><label class="newProject-TextInput">{{
            langFilter("task type")
          }}</label>
          <select
            v-model="question.type"
            class="newProject-Input"
            style="text-align: left"
            :disabled="lockedTrack == 1"
          >
            <option value="1" selected>
              {{ langFilter("taskType1") }}
            </option>
            <option value="2" selected>
              {{ langFilter("taskType2") }}
            </option>
            <option value="3" selected>
              {{ langFilter("taskType3") }}
            </option>
            <option value="4" selected>
              {{ langFilter("taskType4") }}
            </option>
            <option value="5" selected>
              {{ langFilter("taskType5") }}
            </option>
          </select>
        </div>
        <!-- <label class="newProject-NumberInput questions-label"
                        >3</label
                    ><label class="newProject-TextInput questions-label">{{
                        langFilter("question")
                    }}</label>
                    <textarea
                        class="newProject-Input questions-inputs"
                        :placeholder="langFilter('question placeholder')"
                        id="newProject-QuestionQuestion"
                        v-model="question.question"
                        :disabled="lockedTrack == 1"
                    ></textarea> -->
        <label class="newProject-NumberInput questions-label">3</label
        ><label class="newProject-TextInput questions-label">{{
          langFilter("instructions")
        }}</label
        ><label class="newProject-TextInput questions-label2"
          >({{ langFilter("optional") }})</label
        >
        <textarea
          v-model="question.instructions"
          class="newProject-Input questions-inputs"
          :placeholder="langFilter('instructions placeholder')"
          :disabled="lockedTrack == 1"
        ></textarea>
        <!-- <label class="newProject-NumberInput questions-label"
                        >5</label
                    ><label class="newProject-TextInput questions-label">{{
                        langFilter("help")
                    }}</label
                    ><label class="newProject-TextInput questions-label2"
                        >({{ langFilter("optional") }}) -
                        {{ langFilter("hidden information") }}</label
                    >
                    <textarea
                        class="newProject-Input questions-inputs"
                        :placeholder="langFilter('help placeholder')"
                        v-model="question.help"
                        :disabled="lockedTrack == 1"
                    ></textarea> -->
        <div v-show="question.type == 2 || question.type == 3">
          <label class="newProject-NumberInput questions-label">4</label
          ><label
            v-if="question.type == 2"
            class="newProject-TextInput questions-label"
            >{{ langFilter("taskType2") }}</label
          ><label
            v-if="question.type == 3"
            class="newProject-TextInput questions-label"
            >{{ langFilter("taskType3") }}</label
          ><label class="newProject-TextInput questions-label2">{{
            langFilter("correct answers")
          }}</label>
          <div id="newProject-QuestionChecks" class="newProject-InputChecks">
            <div v-if="question.type == 3">
              <draggable
                v-model="question.newQuestionCheck"
                draggable=".question"
                handle=".dragg-me-question"
                animation="500"
                ghostClass="ghost"
                chosenClass="chosen"
                :options="{
                  animation: 500,
                  handle: '.dragg-me-question',
                  ghostClass: 'ghost',
                  chosenClass: 'chosen',
                  group: 'question',
                  itemKey: 'id',
                }"
              >
                <div
                  v-for="(check, indexCheck) in question.newQuestionChecks"
                  :key="indexCheck"
                  class="newProject-CheckContainer drag-icon-container question"
                >
                  <label>
                    <input
                      v-model="check.correct"
                      type="checkbox"
                      name="newQuestionCheck"
                      :value="indexCheck"
                      :disabled="lockedTrack == 1"
                    />
                  </label>
                  <input
                    v-model="check.name"
                    class="newProject-InvisibleInput"
                    style="overflow: hidden"
                    type="text"
                    :placeholder="langFilter('enter response')"
                    :disabled="lockedTrack == 1"
                  />
                  <span
                    v-if="lockedTrack !== 1"
                    class="newProject-CloseIconContainer"
                    ><img
                      :src="require('@/assets/img/projects/modal_X.png')"
                      class="newProject-DragIcon-hide newProject-drag-x-center"
                      @click="question.newQuestionChecks.splice(indexCheck, 1)"
                  /></span>
                  <span
                    v-if="lockedTrack !== 1"
                    class="newProject-DragIconContainer"
                    ><img
                      :src="require('@/assets/img/projects/drag_icon.png')"
                      class="newProject-DragIcon-hide newProject-drag-x-center dragg-me-question"
                  /></span>
                </div>
              </draggable>
            </div>
            <div v-if="question.type == 2">
              <draggable
                v-model="question.newQuestionCheck"
                draggable=".question"
                handle=".dragg-me-question"
                animation="500"
                ghostClass="ghost"
                chosenClass="chosen"
                :options="{
                  animation: 500,
                  handle: '.dragg-me-question',
                  ghostClass: 'ghost',
                  chosenClass: 'chosen',
                  group: 'question',
                  itemKey: 'id',
                }"
              >
                <div
                  v-for="(check, indexCheck) in question.newQuestionChecks"
                  :key="indexCheck"
                  class="newProject-CheckContainer drag-icon-container question"
                >
                  <label>
                    <input
                      v-model="question.radio"
                      type="radio"
                      name="newQuestionCheck"
                      :value="indexCheck"
                      :disabled="lockedTrack == 1"
                    />
                  </label>
                  <input
                    v-model="check.name"
                    class="newProject-InvisibleInput"
                    style="overflow: hidden"
                    type="text"
                    :placeholder="langFilter('enter response')"
                    :disabled="lockedTrack == 1"
                  />
                  <span
                    v-if="lockedTrack !== 1"
                    class="newProject-CloseIconContainer"
                    ><img
                      :src="require('@/assets/img/projects/modal_X.png')"
                      class="newProject-DragIcon-hide newProject-drag-x-center"
                      @click="question.newQuestionChecks.splice(indexCheck, 1)"
                  /></span>
                  <span
                    v-if="lockedTrack !== 1"
                    class="newProject-DragIconContainer"
                    ><img
                      :src="require('@/assets/img/projects/drag_icon.png')"
                      class="newProject-DragIcon-hide newProject-drag-x-center dragg-me-question"
                  /></span>
                </div>
              </draggable>
            </div>
            <label class="newProject-AddCheckbox" @click="addQuestionCheck">{{
              langFilter("+ Add")
            }}</label>
          </div>
        </div>
        <div style="height: 20px"></div>
        <div
          v-if="lockedTrack == 0"
          class="newProject-NextButton"
          style="margin: 10px 0px 20px 0px"
        >
          <p class="newProject-ButtonText" @click="sendQuestionToValidate">
            {{ langFilter("save") }}
          </p>
        </div>
      </div>
    </div>

    <!-- resources modal -->
    <div
      v-show="resourcesModal === 1"
      id="myProjects-ModalResources"
      class="myProjects-ModalContainer"
      style="display: block"
    >
      <div
        class="myProjects-Modal"
        style="
          width: 935px;
          height: 606px;
          padding-left: 33px;
          padding-right: 33px;
          background-color: #ffffff;
          overflow-y: auto;
        "
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="newProject-close-modals"
          @click="openModalConfirmationClose()"
        >
          <path
            d="M16.3248 16.6752C15.9342 17.0658 15.3011 17.0658 14.9105 16.6752L9.05882 10.8235L3.14828 16.7341C2.75776 17.1246 2.12459 17.1246 1.73407 16.7341L1.23652 16.2365C0.845994 15.846 0.845995 15.2128 1.23652 14.8223L7.14706 8.91177L1.32476 3.08946C0.934231 2.69894 0.93423 2.06577 1.32475 1.67525L1.67525 1.32475C2.06577 0.934229 2.69894 0.934229 3.08946 1.32475L8.91177 7.14706L14.8223 1.23652C15.2128 0.845995 15.846 0.845995 16.2365 1.23652L16.7341 1.73407C17.1246 2.12459 17.1246 2.75776 16.7341 3.14828L10.8235 9.05882L16.6752 14.9105C17.0658 15.3011 17.0658 15.9342 16.6752 16.3248L16.3248 16.6752Z"
            fill="#B7AED6"
          />
        </svg>

        <div class="newProject-ResourcesTitle">
          <h1>{{ langFilter("add resource") }}</h1>
        </div>
        <div class="newProject-SelectContent">
          <div style="width: 600px; margin: auto; text-align: center">
            <p
              :class="
                (modalResourcesTab == 2 ? 'newProject-ResourceTabButton' : '') +
                (modalResourcesTab == 1
                  ? 'newProject-ResourceTabButtonActive'
                  : '')
              "
              style="border-right: 2px solid #5a3de2"
              @click="modalResourcesTab = 1"
            >
              {{ langFilter("upload file") }}
            </p>
            <p
              :class="
                (modalResourcesTab == 1 ? 'newProject-ResourceTabButton' : '') +
                (modalResourcesTab == 2
                  ? 'newProject-ResourceTabButtonActive'
                  : '')
              "
              @click="modalResourcesTab = 2"
            >
              {{ langFilter("recurso de archivo") }}
            </p>
          </div>
        </div>
        <div v-if="modalResourcesTab == 1">
          <div class="newProject-HalfContainer" style="padding-right: 9.5px">
            <label class="newProject-NumberInput">1</label
            ><label class="newProject-TextInput">{{
              langFilter("resource title")
            }}</label>
            <input
              id="newProject-ResourceTitle"
              v-model="resource.title"
              class="newProject-Input"
              :placeholder="langFilter('resource title placeholder')"
              style="margin-bottom: 16px"
            />
          </div>
          <div
            class="newProject-HalfContainer"
            style="padding-right: 0px; padding-left: 9.5px"
          >
            <label class="newProject-NumberInput">2</label
            ><label class="newProject-TextInput">{{
              langFilter("category")
            }}</label>
            <select
              v-if="resource.chapter !== ''"
              v-model="resource.category"
              class="newProject-Input"
              style="text-align: left; margin-bottom: 16px"
            >
              <option selected :value="0">
                {{ langFilter("noCategory") }}
              </option>
              <option
                v-for="(category, indexCategory) in listCategories"
                :key="indexCategory"
                :value="indexCategory + 1"
              >
                {{ category.category }}
              </option>
            </select>
          </div>

          <label class="newProject-NumberInput">3</label
          ><label class="newProject-TextInput">{{ langFilter("url") }}</label>
          <input
            id="newProject-ResourceURL"
            v-model="resource.url"
            :style="resource.file == true ? 'background:#F7F6FA;' : ''"
            class="newProject-Input"
            :placeholder="langFilter('url placeholder')"
            style="margin-bottom: 16px"
            :disabled="resource.file"
          />
          <label class="newProject-NumberInput">4</label
          ><label class="newProject-TextInput">{{
            langFilter("upload file")
          }}</label>
          <label class="newProject-ResourcesMaxSize">{{
            langFilter("max size")
          }}</label>

          <div
            id="newProject-ResourceFileContainer"
            class="newProject-ResourceInputDiv"
            :style="
              (resource.url !== '' ? 'background:#F7F6FA;' : '') +
              (resource.file ? 'height: 102px;' : '')
            "
          >
            <div v-show="resource.file">
              <img
                :src="require('@/assets/img/projects/Tick.png')"
                class="newProject-ResourceSuccesImg"
              />
              <p class="newProject-ResourceSuccesP">
                {{ langFilter("succesful file") }}
              </p>
              <label class="newProject-ResourceSavedFile"
                >{{ resource.userFileName
                }}<img
                  :src="require('@/assets/img/projects/modal_X.png')"
                  class="newProject-ResourceClose"
                  @click="resource.file = false"
              /></label>
            </div>
            <div
              v-show="!resource.file"
              class="newProject-ButtonResourcesActive"
            >
              <label
                class="newProject-ButtonResources"
                :style="resource.url !== '' ? 'cursor:default;' : ''"
              >
                <input
                  id="newProject-ResourceFile"
                  type="file"
                  style="display: none"
                  :disabled="resource.url !== ''"
                  @change="uploadFileToModal()"
                />
                {{ langFilter("select file") }}
              </label>
            </div>
          </div>
          <div class="newProject-HalfContainer" style="padding-right: 9.5px">
            <label class="newProject-NumberInput">5</label
            ><label class="newProject-TextInput">{{
              langFilter("chapter")
            }}</label>
            <select
              v-model="resource.chapter"
              class="newProject-Input"
              style="text-align: left"
              @change="updatePreResources()"
            >
              <option
                v-for="(chapter, indexChapter) in chapters"
                :key="indexChapter"
                :value="indexChapter"
              >
                {{ indexChapter + 1 }}.{{ chapter.chapter }}
              </option>
            </select>
          </div>
          <div
            class="newProject-HalfContainer"
            style="padding-right: 0px; padding-left: 9.5px"
          >
            <label class="newProject-NumberInput">6</label
            ><label class="newProject-TextInput">{{
              langFilter("part")
            }}</label>
            <select
              v-if="resource.chapter !== ''"
              v-model="resource.part"
              class="newProject-Input"
              style="text-align: left"
              @change="updatePreResources()"
            >
              <option value="-1">No {{ langFilter("part") }}</option>
              <option
                v-for="(part, indexPart) in chapters[resource.chapter].parts"
                :key="indexPart"
                :value="indexPart"
              >
                {{ indexPart + 1 }}.{{ part.part }}
              </option>
            </select>
          </div>
          <div class="newProject-resource-template">
            <input
              id="cbox2"
              v-model="resource.template"
              type="checkbox"
              value="second_checkbox"
              style="display: inline-block"
            /><label>{{ langFilter("template document") }}</label>
          </div>
          <div class="newProject-NextButton" style="margin-top: 30px">
            <p class="newProject-ButtonText" @click="newFileChapterPart">
              {{ langFilter("save") }}
            </p>
          </div>
        </div>
        <div v-if="modalResourcesTab == 2">
          <div
            class="newProject-SearchUserContainer"
            style="
              width: 307px !important;
              float: right;
              margin-top: 22px;
              margin-bottom: 17px;
              margin-right: 8px;
            "
          >
            <img :src="require('@/assets/img/projects/lupa.png')" />
            <input
              v-model="searchResources"
              type="text"
              class="newProject-SearchUser"
              :placeholder="langFilter('search...')"
            />
          </div>
          <div class="newProject-Pre-Resources-Container">
            <div
              v-for="(preResource, indexPreResource) in filteredPreResources"
              :key="indexPreResource"
              class="newProject-Pre-SetTrackCard"
            >
              <input
                v-model="listResourcesChecked"
                type="checkbox"
                :value="preResource"
                class="newProject-Pre-SetResourceCheckbox"
              />
              <img
                :src="require('@/assets/img/projects/pre-set-track-image.png')"
                class="newProject-Pre-SetTrackCardImage"
              />
              <div class="newProject-Pre-SetTrackCardContainer">
                <h1>{{ preResource.name }}</h1>
                <a :href="preResource.link" target="_blank">
                  <p>{{ langFilter("view more") }}</p>
                </a>
              </div>
            </div>
            <p v-if="filteredPreResources.length === 0" class="no-busqueda">
              {{ langFilter("no_busqueda") }}
            </p>
          </div>
          <div
            id="select-plant-btn"
            class="newProject-NoSelectedButton"
            style="margin-top: 30px"
          >
            <p class="newProject-ButtonText" @click="selectPreResources()">
              {{ langFilter("select") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="newProject-SelectContent">
      <div style="width: auto; margin: auto; text-align: center">
        <p class="newTrack-SelectButtonActive">
          {{ langFilter("New track") }}
        </p>
      </div>
    </div>
    <div class="newProject-TabSelectorContainer">
      <div
        style="
          margin-left: 50%;
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
        "
      >
        <label
          v-if="tabs == 2"
          :class="(steps > 1 ? 'success' : '') + (steps == 1 ? 'step' : '')"
          @click="selectedTemplateStep()"
          >{{ langFilter("customization") }}</label
        >

        <label
          v-if="tabs !== 2"
          :class="(steps > 1 ? 'success' : '') + (steps == 1 ? 'step' : '')"
          @click="stepsFunction(1)"
          >{{ langFilter("customization") }}</label
        >
        <label
          :class="(steps > 4 ? 'success' : '') + (steps == 4 ? 'step' : '')"
          style="margin-right: 0px"
          @click="stepsFunction(4)"
          >{{ langFilter("start") }}</label
        >
      </div>
    </div>

    <!-- First tab -->
    <div v-show="steps == 0" class="newProject-TabContainer">
      <div
        class="newProject-SearchUserContainer"
        style="
          width: 307px !important;
          float: right;
          margin-top: 30px;
          margin-bottom: 21px;
        "
      >
        <img :src="require('@/assets/img/projects/lupa.png')" />
        <input
          v-model="searchPreSet"
          type="text"
          class="newProject-SearchUser"
          :placeholder="langFilter('search...')"
        />
      </div>
      <div class="newProject-Pre-Set-Container" style="overflow: hidden">
        <div
          v-for="(preSet, indexPreSet) in filteredPreSetTracks"
          :key="indexPreSet"
          class="newProject-Pre-SetTrackCard"
          @click="selectTemplateStyles($event)"
        >
          <div class="newProject-track-options-container">
            <p
              class="newProject-track-options"
              @click="openDeleteTrack($event)"
            >
              ⋅⋅⋅
            </p>
            <div class="newProject-track-options-elements">
              <p @click="deleteTrack(preSet, indexPreSet)">
                {{ langFilter("Delete Track") }}
              </p>
            </div>
          </div>
          <img
            :src="getImageEnv() + '/img/track_headers/' + preSet.img"
            class="newProject-Pre-SetTrackCardImage"
            @click="selectTrackTemplate(preSet.id)"
          />
          <div
            class="newProject-Pre-SetTrackCardContainer"
            @click="selectTrackTemplate(preSet.id)"
          >
            <h1>{{ preSet.name }}</h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Second tab -->
    <div v-show="steps == 1" class="myProjects-MainContainer">
      <p
        v-if="tabs == 2 && lockedTrack == 1"
        class="newProject-EditTrack"
        @click="openModalConfirmationEdit"
      >
        {{ langFilter("Edit Track") }}
        <img :src="require('@/assets/img/projects/edit_track.png')" />
      </p>
      <p v-if="tabs == 2 && lockedTrack == 0" class="newProject-EditTrack">
        {{ langFilter("save new track") }}
        <img :src="require('@/assets/img/projects/save_track.png')" />
      </p>

      <div class="newProject-CompleteContainer">
        <div class="newProject-HalfContainer">
          <label id="newProject-TrackNameNumber" class="newProject-NumberInput"
            >1</label
          ><label id="newProject-TrackNameTitle" class="newProject-TextInput">{{
            langFilter("TrackName")
          }}</label>
          <div class="newProject-InfoIconContainer" style="margin-top: -20px">
            <img
              :src="require('@/assets/img/projects/InfoIcon.png')"
              class="littleImage"
            />
            <div class="newProject-InfoIconHover">
              <p>
                {{ langFilter("track info1") }}
                <strong>Track</strong>
                {{ langFilter("track info2") }}
                <span id="myProjects-ModalInfo1" @click="openModalTrack()">{{
                  langFilter("show more")
                }}</span>
              </p>
            </div>
          </div>
          <input
            id="newProject-TrackName"
            v-model="trackName"
            class="newProject-Input"
            :placeholder="langFilter('Insert the Track name...')"
            style="margin-bottom: 16px"
            :disabled="lockedTrack == 1"
            :style="lockedTrack == 1 ? 'background-color:#F7F6FA;' : ''"
            @blur="checkIfTrackNameExist"
          />
        </div>
        <div class="newProject-CompleteContainer newTrack-borderBottom-addImg">
          <label id="newProject-HeaderNameNumber" class="newProject-NumberInput"
            >2</label
          ><label
            id="newProject-HeaderNameTitle"
            class="newProject-TextInput"
            >{{ langFilter("header image") }}</label
          >
          <div id="newProject-PreviewDiv" class="newProject-CompleteInputDiv">
            <img
              v-show="showImage"
              id="newProject-Preview"
              class="newProject-BackgroundImg"
              src=""
            />
            <div
              id="newProject-HeaderButton"
              class="newProject-ButtonHeaderActive"
            >
              <label v-show="lockedTrack == 0" class="newProject-ButtonHeader">
                <input
                  id="newProject-Header"
                  type="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="headerImagePreview"
                />
                {{ langFilter("Select file") }}
              </label>
              <label
                v-show="lockedTrack == 0"
                class="newProject-RecomendedSize"
                >{{ langFilter("Recommended size") }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="newProject-CompleteContainer">
                <label class="newProject-NumberInput">3</label
                ><label class="newProject-TextInput">Syllabus</label>
                <div id="newProject-Syllabus" class="newProject-Syllabus"></div>
            </div> -->

      <div class="newProject-CompleteContainer">
        <label class="newProject-NumberInput">3</label>
        <label class="newProject-TextInput">{{ langFilter("index") }}</label>
        <label
          class="newProject-StationTextHeader"
          style="margin-right: 88px"
          >{{ langFilter("optional") }}</label
        >
        <label
          class="newProject-StationTextHeader"
          style="margin-right: 40px"
          >{{ langFilter("resources") }}</label
        >
        <draggable
          v-model="chapters"
          draggable=".chapter"
          :options="{
            animation: 500,
            handle: '.dragg-me-chapter',
            ghostClass: 'ghost',
            chosenClass: 'chosen',
            itemKey: 'id',
            group: 'chapter',
          }"
        >
          <div
            v-for="(chapter, indexChapter) in chapters"
            :id="'newProject-ChapterError' + indexChapter"
            :key="indexChapter"
            class="newProject-SolutionsContainer chapter"
            :style="lockedTrack == 1 ? 'background-color:#F7F6FA;' : ''"
          >
            <img
              :src="require('@/assets/img/projects/Polygon_21.png')"
              class="newProject-Minimize"
              @click="minimizeExpand($event, chapter)"
            />
            <label class="newProject-NumberStation"
              >{{ langFilter("chapter") }} {{ indexChapter + 1 }}.</label
            >
            <input
              :id="'newProject-Chapter' + indexChapter + 'Name'"
              v-model="chapter.chapter"
              type="text"
              class="newProject-InputInvisible"
              :placeholder="langFilter('Insert the Chapter name...')"
              :disabled="lockedTrack == 1"
              :style="lockedTrack == 1 ? 'background-color:#F7F6FA;' : ''"
            />

            <span class="newProject-CloseIconContainer"
              ><img
                v-if="chapters.length > 1 && lockedTrack !== 1"
                :src="require('@/assets/img/projects/modal_X.png')"
                class="newProject-DragIcon"
                @click="openModalConfirmationChapter(indexChapter)"
            /></span>
            <span class="newProject-DragIconContainer"
              ><img
                v-if="lockedTrack !== 1"
                :src="require('@/assets/img/projects/drag_icon.png')"
                class="newProject-DragIcon dragg-me-chapter"
            /></span>

            <label class="switch newProject-StationTextHeader">
              <!-- <input type="checkbox" name="notification" id="notificationsEditUser" v-model="chapter.optional">
                                                <span class="slider round"></span> -->
            </label>
            <label
              v-if="
                chapter.files.length == 0 &&
                chapter.preResources.length == 0 &&
                lockedTrack == 0
              "
              class="newProject-StationTextHeader"
              style="margin-right: 40px; cursor: pointer"
              @click="modalResourcesOpen(indexChapter, -1)"
              >{{ langFilter("+ Add") }}</label
            >

            <div class="myProjects-list-programs-dropdown">
              <label
                v-if="
                  chapter.files.length > 0 || chapter.preResources.length > 0
                "
                class="newProject-StationTextHeader"
                style="margin-right: 26px; width: 108px"
                data-toggle="modal"
                data-target="#recursosModal"
                @click="openPopUpResources($event, indexChapter, -1)"
                >{{ chapter.files.length + chapter.preResources.length }}
                {{ langFilter("resources") }}</label
              >

              <div class="newProject-list-files-dropdown-content">
                <p
                  class="newProject-addMoreResourcesChapter"
                  @click="modalResourcesOpen(indexChapter, -1)"
                >
                  {{ langFilter("+ Add") }}
                  {{ langFilter("resource") }}
                </p>
              </div>
              <div
                class="newProject-list-files-dropdown-content-container"
                @click="clickOutsideDiv($event)"
              ></div>
            </div>

            <textarea
              :id="'newProject-Chapter' + indexChapter + 'Description'"
              v-model="chapter.description"
              class="newProject-DescriptionInput"
              :placeholder="langFilter('Add a description...')"
              :disabled="lockedTrack == 1"
              @keydown="autosize($event)"
            ></textarea>

            <draggable
              v-show="chapter.hide == false"
              v-model="chapters[indexChapter].parts"
              draggable=".part"
              :options="{
                animation: 500,
                handle: '.dragg-me-part',
                ghostClass: 'ghost',
                chosenClass: 'chosen',
                itemKey: 'id',
                group: 'part',
              }"
            >
              <div
                v-for="(part, indexPart) in chapters[indexChapter].parts"
                :key="indexPart"
                class="newProject-PartContainer part"
              >
                <img
                  :src="require('@/assets/img/projects/Polygon_21.png')"
                  class="newProject-Minimize"
                  @click="minimizeExpand($event, part)"
                />
                <label class="newProject-NumberPart"
                  >{{ langFilter("part") }} {{ indexPart + 1 }}.</label
                >
                <input
                  :id="
                    'newProject-Part' + indexChapter + '-' + indexPart + 'Name'
                  "
                  v-model="part.part"
                  type="text"
                  class="newProject-InputInvisiblePart"
                  :placeholder="langFilter('Insert the Part name...')"
                  :disabled="lockedTrack == 1"
                />

                <span class="newProject-CloseIconContainer"
                  ><img
                    v-if="lockedTrack !== 1"
                    :src="require('@/assets/img/projects/modal_X.png')"
                    class="newProject-DragIcon"
                    @click="
                      openModalConfirmationSection(indexChapter, indexPart)
                    "
                /></span>

                <span class="newProject-DragIconContainer"
                  ><img
                    v-if="lockedTrack !== 1"
                    :src="require('@/assets/img/projects/drag_icon.png')"
                    class="newProject-DragIcon dragg-me-part"
                /></span>

                <label class="switch newProject-StationTextHeader">
                  <input
                    id="notificationsEditUser"
                    v-model="part.optional"
                    type="checkbox"
                    name="notification"
                    :disabled="lockedTrack == 1"
                  />
                  <span class="slider round"></span>
                </label>
                <label
                  v-if="
                    part.files.length == 0 &&
                    part.preResources.length == 0 &&
                    lockedTrack == 0
                  "
                  class="newProject-StationTextHeader"
                  style="margin-right: 40px; cursor: pointer"
                  @click="modalResourcesOpen(indexChapter, indexPart)"
                  >{{ langFilter("+ Add") }}</label
                >

                <div class="myProjects-list-programs-dropdown">
                  <label
                    v-if="part.files.length > 0 || part.preResources.length > 0"
                    class="newProject-StationTextHeader"
                    style="margin-right: 26px; width: 108px"
                    data-toggle="modal"
                    data-target="#recursosModal"
                    @click="openPopUpResources($event, indexChapter, indexPart)"
                    >{{ part.files.length + part.preResources.length }}
                    {{ langFilter("resources") }}</label
                  >

                  <div class="newProject-list-files-dropdown-content">
                    <p
                      class="newProject-addMoreResourcesChapter"
                      @click="modalResourcesOpen(indexChapter, indexPart)"
                    >
                      {{ langFilter("+ Add") }}
                      {{ langFilter("resource") }}
                    </p>
                  </div>
                  <div
                    class="newProject-list-files-dropdown-content-container"
                    @click="clickOutsideDiv($event)"
                  ></div>
                </div>
                <draggable
                  v-show="part.hide == false"
                  v-model="chapters[indexChapter].parts[indexPart].tasks"
                  draggable=".task"
                  :options="{
                    animation: 500,
                    handle: '.dragg-me-task',
                    ghostClass: 'ghost',
                    chosenClass: 'chosen',
                    itemKey: 'id',
                    group: 'task',
                  }"
                >
                  <div
                    v-for="(task, indexTask) in chapters[indexChapter].parts[
                      indexPart
                    ].tasks"
                    :key="indexTask"
                    class="newProject-TaskContainer drag-icon-container task"
                  >
                    <label class="newProject-NumberTask"
                      >{{ langFilter("task") }} {{ indexTask + 1 }}.</label
                    >
                    <div
                      style="
                        display: inline-block;
                        overflow: hidden;
                        width: calc(100% - 340px);
                        height: auto;
                        margin-top: 6px;
                      "
                    >
                      <span
                        type="text"
                        class="newProject-InputInvisibleTask"
                        style="margin: 0px; cursor: pointer"
                        @click="editTask(indexTask, indexPart, indexChapter)"
                        >{{ task.task }}</span
                      >
                    </div>

                    <span class="newProject-CloseIconContainer"
                      ><img
                        v-if="
                          chapters[indexChapter].parts[indexPart].tasks.length >
                            1 && lockedTrack !== 1
                        "
                        :src="require('@/assets/img/projects/modal_X.png')"
                        class="newProject-DragIcon-hide"
                        style="margin-top: 13px"
                        @click="
                          openModalConfirmationQuestion(
                            indexChapter,
                            indexPart,
                            indexTask
                          )
                        "
                    /></span>
                    <span
                      class="newProject-DragIconContainer"
                      style="margin-top: 7px"
                      ><img
                        :src="require('@/assets/img/projects/drag_icon.png')"
                        class="newProject-DragIcon-hide dragg-me-task"
                      />
                    </span>

                    <label class="switch newProject-StationTextHeader">
                      <!-- <input type="checkbox" name="notification" id="notificationsEditUser" v-model="task.optional">
                                                        <span class="slider round"></span> -->
                    </label>

                    <label
                      class="newProject-StationTextHeader"
                      style="margin-right: 40px"
                    >
                      <img
                        class="newProject-QuestionTypeImg"
                        :src="
                          require(
                            '@/assets/img/projects/question_type' +
                              task.type +
                              '.png'
                          )
                        "
                        @mouseover="showTipoTask($event)"
                        @mouseout="hideTipoTask($event)"
                      />
                      <div class="tipo-task">
                        {{ langFilter("taskType" + task.type) }}
                      </div>
                    </label>
                  </div>
                </draggable>

                <label
                  v-show="part.hide == false"
                  v-if="lockedTrack !== 1"
                  :id="'newProject-Tasks' + indexChapter + '-' + indexPart"
                  class="newProject-AddTask"
                  @click="openQuestionsModal(indexPart, indexChapter)"
                  >{{ langFilter("+ Add") }} {{ langFilter("task") }}...</label
                >
              </div>
            </draggable>
            <div
              v-show="chapter.hide == false"
              v-if="lockedTrack !== 1"
              class="newProject-PartContainer"
            >
              <label class="newProject-AddPart" @click="MoreParts(indexChapter)"
                >{{ langFilter("+ Add") }} {{ langFilter("part") }}...</label
              >
            </div>
          </div>
        </draggable>
        <div
          v-if="lockedTrack !== 1"
          class="newProject-SolutionsContainer chapter"
          style="padding-bottom: 20px"
        >
          <label class="newProject-AddChapter" @click="MoreChapters()"
            >{{ langFilter("+ Add") }} {{ langFilter("chapter") }}...</label
          >
        </div>
      </div>
    </div>
    <div
      v-show="steps == 2"
      class="newProject-TabContainer"
      style="padding: 125px 78px 0px 78px"
    ></div>

    <div v-show="steps == 4" class="newProject-TabContainer">
      <label class="newProject-NumberInput">4</label
      ><label class="newProject-TextInput">{{ langFilter("start2") }}</label>
      <div class="newProject-UsersContainer">
        <img
          :src="require('@/assets/img/projects/Tierra_Luna.png')"
          class="newProject-StartImage"
        />
        <h2 class="newProject-StartTitle">
          {{ langFilter("departing!") }}
        </h2>
        <p class="newProject-StartText">
          {{ langFilter("trackCreated") }}
        </p>

        <div
          v-if="steps == 4"
          class="newProject-FinalButton"
          @click="submitTrack()"
        >
          <p class="newProject-ButtonText">
            {{ langFilter("lets go!") }}
          </p>
        </div>
      </div>
    </div>

    <div style="width: 100%; display: block; overflow: hidden">
      <div
        v-if="steps != 4"
        class="newProject-NextButton"
        @click="stepsFunction(4)"
      >
        <p class="newProject-ButtonText">{{ langFilter("next") }}</p>
      </div>
    </div>

    <newProjectResources
      ref="modalResources"
      :lang="lang"
      :categories="listCategories"
    ></newProjectResources>
    <confirmation ref="modalConfirmation" :lang="lang"></confirmation>
    <alertMessage
      :text="'To continue you must fill all the fields'"
      :lang="lang"
    ></alertMessage>
  </div>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";
import { Modal } from "bootstrap";
import translationMixin from "../../../mixins/translationMixin.js";
import "../../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditor from "../../../../node_modules/froala-editor/js/froala_editor.pkgd.min.js";
import axios from "axios";
import $ from "jquery";

export default {
  components: {
    draggable: VueDraggableNext,
  },
  mixins: [translationMixin],
  props: {
    categories: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      default: "en",
    },
    space: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ["resource", "newTrack"],
  data() {
    return {
      showImage: false,
      chapterQuest: "",
      partQuest: "",
      taskQuest: "",
      customer: [],
      programs: [],
      confirmModal: 0,
      lockedTrack: 0,
      preSetTracks: [],
      selectedTemplate: "",
      searchPreSet: "",

      // Form Inputs
      trackName: "",
      trackNameValidation: false,
      projectName: "",
      cascadeProject: false,
      chapters: [
        {
          chapter: "",
          description: "",
          hide: false,
          files: [],
          preResources: [],
          parts: [
            {
              part: "",
              files: [],
              optional: false,
              hide: false,
              preResources: [],
              tasks: [],
            },
          ],
        },
      ],
      company: this.space.id,
      time: "",
      duration: "",
      program: "",
      selectedUsers: [],
      selectedTutors: [],
      selectedMentors: [],

      //question form
      questionEdit: 0,
      question: {
        task: "",
        type: 1,
        question: "",
        instructions: "",
        help: "",
        newQuestionChecks: [],
        radio: 0,
        optional: false,
        chapter: 0,
        part: 0,
      },
      //resources form
      resource: {
        title: "",
        category: 0,
        url: "",
        chapter: 0,
        part: null,
        file: false,
        template: false,
        userFileName: "",
        userFile: {},
      },

      //Utility variables
      newEmail: "",
      addEmail: 0,
      steps: 1,
      tabs: 1,
      modalResourcesTab: 1,
      questionsModal: 0,
      errors: [],
      succesful: [],

      errorTime: {},
      setTimeouts: [],

      resourcesModal: 0,

      listUsersCustomer: [],

      listCategories: [],
      listUsers: [],
      listTutors: [],
      listMentors: [],
      totalUsers: [],

      searchUsers: "",
      searchMentors: "",
      searchTutors: "",
      newResources: [],

      preSetResources: [],
      searchResources: "",
      listResourcesChecked: [],

      //Traducción
      traducciones: [
        {
          name: "title_my_itineraries_screen",
          es: "Plantillas",
          en: "Templates",
        },
        {
          name: "New track",
          es: "Nuevo itinerario",
          en: "New itinerary",
        },
        {
          name: "noCategory",
          es: "Sin categoría",
          en: "No category",
        },
        {
          name: "syllabus",
          es: "Escribe el syllabus...",
          en: "Write the syllabus...",
        },
        {
          name: "trackCreated",
          es: "Encontrarás tu nuevo itinerario en el catálogo.",
          en: "Your new itinerary will be available in the catalog.",
        },
        {
          name: "Track template",
          es: "Plantilla Track",
          en: "Track template",
        },
        {
          name: "CSV File",
          es: "Archivos CSV",
          en: "CSV File",
        },
        {
          name: "Back",
          es: "Atrás",
          en: "Back",
        },
        {
          name: "taskType1",
          es: "Texto",
          en: "Text",
        },
        {
          name: "taskType2",
          es: "Una opción",
          en: "Single choice",
        },
        {
          name: "taskType3",
          es: "Multiples opciones",
          en: "Multiple choice",
        },
        {
          name: "taskType4",
          es: "Subir imagen",
          en: "Upload image",
        },
        {
          name: "taskType5",
          es: "Subir archivo",
          en: "Upload file",
        },
        {
          name: "projects",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "template",
          es: "Plantilla",
          en: "Template",
        },
        {
          name: "customization",
          es: "Customización",
          en: "Customization",
        },
        {
          name: "program",
          es: "Programa",
          en: "Program",
        },
        {
          name: "no_busqueda",
          es: "No se han encontrado resultados con tu búsqueda.",
          en: "No results were found with your search.",
        },
        {
          name: "add users",
          es: "Añadir usuarios",
          en: "Add users",
        },
        {
          name: "start",
          es: "¡Comenzar!",
          en: "Start!",
        },
        {
          name: "search...",
          es: "Buscar...",
          en: "Search...",
        },
        {
          name: "Delete Track",
          es: "Eliminar Itinerario",
          en: "Delete Itinerary",
        },
        {
          name: "Edit Track",
          es: "Editar Itinerario",
          en: "Edit Itinerary",
        },
        {
          name: "save new track",
          es: "Guardar Nuevo Itinerario",
          en: "Save New Itinerary",
        },
        {
          name: "track info1",
          es: "Un",
          en: "The",
        },
        {
          name: "track info2",
          es: "es el indice de tu proyecto.",
          en: "is your project’s index.",
        },
        {
          name: "show more",
          es: "Ver más",
          en: "Show more",
        },
        {
          name: "Insert the Track name...",
          es: "Insertar el nombre del Itinerario...",
          en: "Insert the Itinerary name...",
        },
        {
          name: "Insert the Project name...",
          es: "Insertar el nombre del Proyecto...",
          en: "Insert the Project name...",
        },
        {
          name: "project",
          es: "Proyecto",
          en: "Project",
        },
        {
          name: "Cascade project",
          es: "Proyecto en cascada",
          en: "Cascade project",
        },
        {
          name: "cascade info1",
          es: "El usuario tiene un",
          en: "The user has a",
        },
        {
          name: "cascade info2",
          es: "Progreso escalonado",
          en: "Staggered progress",
        },
        {
          name: "header image",
          es: "Imagen de cabecera",
          en: "Header image",
        },
        {
          name: "Select file",
          es: "Subir archivo",
          en: "Select file",
        },
        {
          name: "Recommended size",
          es: "[Tamaño recomendado 290x80px]",
          en: "[Recomended size 290x80px]",
        },
        {
          name: "index",
          es: "Índice",
          en: "Index",
        },
        {
          name: "TrackName",
          es: "Nombre del Itinerario",
          en: "Itinerario Name",
        },
        {
          name: "optional",
          es: "Opcional",
          en: "Optional",
        },
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "resource",
          es: "Recurso",
          en: "Resource",
        },
        {
          name: "chapter",
          es: "Tema",
          en: "Chapter",
        },
        {
          name: "Insert the Chapter name...",
          es: "Escribe el nombre del tema...",
          en: "Insert the Chapter name...",
        },
        {
          name: "Add a description...",
          es: "Añade una descripción...",
          en: "Add a description...",
        },
        {
          name: "+ Add",
          es: "+ Añadir",
          en: "+ Add",
        },
        {
          name: "part",
          es: "Apartado",
          en: "Part",
        },
        {
          name: "Insert the Part name...",
          es: "Escribe el nombre del apartado...",
          en: "Insert the Part name...",
        },
        {
          name: "task",
          es: "Pregunta",
          en: "Task",
        },
        {
          name: "program",
          es: "Programa",
          en: "Program",
        },
        {
          name: "program placeholder",
          es: "Selecciona el programa asociado...",
          en: "Select the Program...",
        },
        {
          name: "life time",
          es: "Tiempo de vida del proyecto",
          en: "Project life time",
        },
        {
          name: "months duration",
          es: "Meses de duración del proyecto",
          en: "Duration time of the project",
        },
        {
          name: "company",
          es: "Empresa/Universidad",
          en: "Company",
        },

        {
          name: "time to spend",
          es: "Tiempo dedicado",
          en: "Time to spend",
        },
        {
          name: "hours/week",
          es: "Horas/semana de dedicación aprox...",
          en: "Hours per week needed...",
        },
        {
          name: "assigne users",
          es: "Asignar usuarios a este proyecto",
          en: "Assigne users to this Project",
        },
        {
          name: "no users",
          es: "No hay usuarios",
          en: "No users",
        },
        {
          name: "USERS",
          es: "USUARIOS",
          en: "USERS",
        },
        {
          name: "tap email...",
          es: "Escribir email...",
          en: "Tap email...",
        },
        {
          name: "start2",
          es: "Comenzar",
          en: "Start",
        },
        {
          name: "departing!",
          es: "¡Despegamos!",
          en: "Departing!",
        },
        {
          name: "email sent",
          es: "(Se ha enviado un email a todos los miembros seleccionados en el paso anterior)",
          en: "(An email has been sent to all the members selected in the previous step)",
        },
        {
          name: "lets go!",
          es: "¡Vamos!",
          en: "Let's go!",
        },
        {
          name: "next",
          es: "Siguiente",
          en: "Next",
        },
        {
          name: "new question",
          es: "Nueva pregunta",
          en: "New Question",
        },
        {
          name: "task title",
          es: "Título de la pregunta",
          en: "Question title",
        },
        {
          name: "task title placeholder",
          es: "Escribe un titulo para esta pregunta...",
          en: "Write a title for this question...",
        },
        {
          name: "task type",
          es: "Tipo de pregunta",
          en: "Type of question",
        },
        {
          name: "task title",
          es: "Título de la pregunta",
          en: "Question title",
        },
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
        {
          name: "question placeholder",
          es: "Escribe aqui la pregunta...",
          en: "Write the question here...",
        },
        {
          name: "instructions",
          es: "Instrucciones",
          en: "Instructions",
        },
        {
          name: "instructions placeholder",
          es: "Añade instrucciones para el desarrollo de la pregunta...",
          en: "Add instructions for the development of the question...",
        },
        {
          name: "help",
          es: "Ayuda",
          en: "Help",
        },

        {
          name: "help placeholder",
          es: "Añadir palabras clave o cualquier tipo de ayuda extra...",
          en: "Add keywords or any kind of extra help...",
        },
        {
          name: "correct answers",
          es: "Marca las respuestas correctas",
          en: "Mark the correct answers",
        },
        {
          name: "enter response",
          es: "Introduce una respuesta...",
          en: "Enter an answer...",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "optional",
          es: "Opcional",
          en: "Optional",
        },
        {
          name: "hidden information",
          es: "Esta información permanecerá oculta a no ser que el alumno la solicite.",
          en: "This information will remain hidden unless the student requests it.",
        },
        {
          name: "add resource",
          es: "Añadir Recurso",
          en: "Add Resource",
        },
        {
          name: "upload file",
          es: "Subir archivo",
          en: "Upload file",
        },
        {
          name: "recurso de archivo",
          es: "Recurso de archivo",
          en: "Resource files",
        },
        {
          name: "resource title",
          es: "Título del recurso",
          en: "Resource title",
        },
        {
          name: "resource title placeholder",
          es: "Escribir un título para este recurso...",
          en: "Write a title for this resource...",
        },
        {
          name: "category",
          es: "Categoría",
          en: "Category",
        },
        {
          name: "url",
          es: "Enlace URL del recurso",
          en: "Resource URL link",
        },
        {
          name: "url placeholder",
          es: "Añadir el enlace URL del recurso...",
          en: "Add resource URL link...",
        },
        {
          name: "max size",
          es: "Maximo tamaño 8MB, para mas contactanos.",
          en: "Max size 8mb, for more contact us.",
        },
        {
          name: "succesful file",
          es: "Archivo subido con éxito!",
          en: "File uploaded succesfully",
        },
        {
          name: "select file",
          es: "Subir archivo",
          en: "Select file",
        },
        {
          name: "view more",
          es: "Ver más",
          en: "View more",
        },
        {
          name: "select",
          es: "Seleccionar",
          en: "Select",
        },
        {
          name: "template document",
          es: "Este documento es una plantilla",
          en: "This document is a template",
        },
      ],
    };
  },
  computed: {
    filteredPreResources() {
      if (this.preSetResources.length == 0) return [];
      return this.preSetResources.filter((post) => {
        return post.name
          .toLowerCase()
          .includes(this.searchResources.toLowerCase());
      });
    },
    filteredUserList() {
      return this.listUsersCustomer.filter((post) => {
        return post.name.toLowerCase().includes(this.searchUsers.toLowerCase());
      });
    },
    filteredMentorList() {
      return this.listUsersCustomer.filter((post) => {
        return post.name
          .toLowerCase()
          .includes(this.searchMentors.toLowerCase());
      });
    },
    filteredTutorList() {
      return this.listUsersCustomer.filter((post) => {
        return post.name
          .toLowerCase()
          .includes(this.searchTutors.toLowerCase());
      });
    },

    filteredPreSetTracks() {
      return this.preSetTracks.filter((post) => {
        return post.name
          .toLowerCase()
          .includes(this.searchPreSet.toLowerCase());
      });
    },
  },
  watch: {
    listResourcesChecked: function (val) {
      if (val.length === 0) {
        $("#select-plant-btn")
          .removeClass("newProject-NextButton")
          .addClass("newProject-NoSelectedButton");
      } else {
        $("#select-plant-btn")
          .removeClass("newProject-NoSelectedButton")
          .addClass("newProject-NextButton");
      }
    },
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/getCategories`)
      .then((response) => {
        this.listCategories = response.data;
      });
    axios
      .post(`${process.env.VUE_APP_API_URL}/getPreResources`, {
        params: {
          customer: this.space.id,
        },
      })
      .then((response) => {
        this.preSetResources = response.data;
      });
  },
  methods: {
    initFroala() {
      new FroalaEditor("#newProject-Syllabus", {
        placeholderText: this.langFilter("syllabus"),
        fontFamilyDefaultSelection: "Poppins",
        height: 139,
        attribution: false,
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting",
            ],
          },

          moreParagraph: {
            buttons: ["outdent", "indent"],
          },

          moreRich: {
            buttons: ["insertLink", "insertImage", "insertFile"],
          },

          moreMisc: {
            buttons: ["undo", "redo"],

            align: "right",

            buttonsVisible: 2,
          },
        },
        pluginsEnabled: [
          "link",
          "linkText",
          "colors",
          "fontSize",
          "lineHeight",
          "specialCharactersSets",
          "quickInsert",
          "linkAlwaysBlank",
          "wordPaste",
        ],
        linkAlwaysBlank: true,
        wordPasteModal: false,
        quickInsertButtons: ["image", "link", "file"],
        key: process.env.VUE_APP_FROALA_KEY,
      });
    },
    openModalTrack() {
      $("#myProjects-Modal").toggle();
    },
    addEmailFunction(index) {
      this.addEmail = index;
      setTimeout(function () {
        document.getElementById("addEmail" + index).focus();
      }, 10);
    },
    closeAddEmail() {
      this.addEmail = 0;
      this.newEmail = "";
    },
    emailUser() {
      var re = /\S+@\S+\.\S+/;

      var regexEmail = re.test(this.newEmail);

      if (!regexEmail) {
        $("#addEmail3").addClass("newProject-InputValidation");
      } else {
        $("#addEmail3").removeClass("newProject-InputValidation");
        var newEmail = {
          id: 9999999999999,
          name: this.newEmail,
          type: -2,
        };

        this.selectedUsers.push(newEmail);

        this.newEmail = "";
      }
    },
    emailMentor() {
      var re = /\S+@\S+\.\S+/;

      var regexEmail = re.test(this.newEmail);

      if (!regexEmail) {
        $("#addEmail2").addClass("newProject-InputValidation");
      } else {
        $("#addEmail2").removeClass("newProject-InputValidation");
        var newEmail = {
          id: 9999999999999,
          name: this.newEmail,
          type: -3,
        };

        this.selectedMentors.push(newEmail);
        this.newEmail = "";
      }
    },
    emailTutor() {
      var re = /\S+@\S+\.\S+/;

      var regexEmail = re.test(this.newEmail);

      if (!regexEmail) {
        $("#addEmail1").addClass("newProject-InputValidation");
      } else {
        $("#addEmail1").removeClass("newProject-InputValidation");
        var newEmail = {
          id: 9999999999999,
          name: this.newEmail,
          type: -1,
        };

        this.selectedTutors.push(newEmail);
        this.newEmail = "";
      }
    },
    addUser(filter, indexFilter) {
      filter.type = 2;
      this.selectedUsers.push(filter);
      this.listUsersCustomer.splice(indexFilter, 1);
      this.searchUsers = "";
    },
    addMentor(filter, indexFilter) {
      filter.type = 3;
      this.selectedMentors.push(filter);
      this.listUsersCustomer.splice(indexFilter, 1);
      this.searchMentors = "";
    },
    addTutor(filter, indexFilter) {
      filter.type = 1;
      this.selectedTutors.push(filter);
      this.listUsersCustomer.splice(indexFilter, 1);
      this.searchTutors = "";
    },
    removeUser(index) {
      this.listUsersCustomer.push(this.selectedUsers[index]);
      this.selectedUsers.splice(index, 1);
    },
    removeMentor(index) {
      this.listUsersCustomer.push(this.selectedMentors[index]);
      this.selectedMentors.splice(index, 1);
    },
    removeTutor(index) {
      this.listUsersCustomer.push(this.selectedTutors[index]);
      this.selectedTutors.splice(index, 1);
    },

    addQuestionCheck() {
      this.question.newQuestionChecks.push({ name: "", correct: false });
    },

    openPopUpFilter(el) {
      $(el.currentTarget).next().show();
    },
    openDeleteTrack(e) {
      $(".newProject-track-options-elements").hide("fast");
      $(e.currentTarget).next().show("fast");
    },
    closePopUpFilter(el) {
      var blur = el.currentTarget;

      $(blur).next().hide();
    },
    noLoseFocusOnClickUser(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    headerImagePreview() {
      $("#newProject-PreviewDiv").removeClass("newProject-InputValidation");
      $("#newProject-HeaderNameNumber").removeClass(
        "newProject-InputValidationNumber"
      );
      $("#newProject-HeaderNameTitle").removeClass(
        "newProject-InputValidationTitle"
      );
      // previewDiv=document.getElementById("newProject-PreviewDiv");
      var button = document.getElementById("newProject-HeaderButton");
      var preview = document.getElementById("newProject-Preview");
      var imgInp = document.getElementById("newProject-Header");

      const [file] = imgInp.files;
      if (file) {
        this.showImage = true;
        preview.src = URL.createObjectURL(file);
        // previewDiv.style.height = "315px";
        button.classList.add("newProject-ButtonHeaderActive");
      }
    },
    closeModalClickingX() {
      this.resourcesModal = 0;
      this.questionsModal = 0;
    },
    MoreChapters() {
      this.$emit("resource");

      this.chapters.push({
        chapter: "",
        description: "",
        hide: false,
        files: [],
        preResources: [],
        parts: [
          {
            part: "",
            files: [],
            optional: false,
            hide: false,
            preResources: [],
            tasks: [],
          },
        ],
      });
    },
    MinusChapters(index) {
      this.chapters.splice(index, 1);
    },
    MoreParts(index) {
      this.chapters[index].parts.push({
        part: "",
        files: [],
        optional: false,
        hide: false,
        preResources: [],
        tasks: [],
      });
    },
    MinusParts(index, part) {
      this.chapters[index].parts.splice(part, 1);
    },
    MoreTasks() {
      this.chapters[this.question.chapter].parts[this.question.part].tasks.push(
        this.question
      );
      this.questionsModal = 0;
      this.question = {
        task: "",
        type: 1,
        question: "",
        instructions: "",
        help: "",
        newQuestionChecks: [],
        radio: 0,
        chapter: 0,
        part: 0,
      };
    },
    MinusTasks(index, part, task) {
      this.chapters[index].parts[part].tasks.splice(task, 1);
    },
    submitTrack() {
      $(".carga").show();
      $("#app").hide();
      // const syllabusmessage = $("#newProject-Syllabus")
      //     .children()
      //     .eq(2)
      //     .children()
      //     .children()
      //     .text();

      var header = "";
      if (this.lockedTrack == 0) {
        header = $("#newProject-Header").prop("files")[0];
      }
      var formData = new FormData();
      var arrayInfoOfFiles = [];
      var i = 0;
      this.chapters.forEach((chapter) => {
        var j = 0;
        chapter.files.forEach((file) => {
          file.category = this.listCategories[file.category].id;
          if (file.file) {
            var infoFile = {
              chapter: i,
              part: 999999,
              category: file.category,
              name: file.title,
              template: file.template,
            };
            arrayInfoOfFiles.push(infoFile);
            formData.append("arrayOfFiles[]", file.userFile);
          }
        });
        chapter.parts.forEach((part) => {
          part.files.forEach((file) => {
            file.category = this.listCategories[file.category].id;

            if (file.file) {
              var infoFile = {
                chapter: i,
                part: j,
                category: file.category,
                name: file.title,
                template: file.template,
              };
              arrayInfoOfFiles.push(infoFile);
              formData.append("arrayOfFiles[]", file.userFile);
            }
          });
          j = j + 1;
        });
        i = i + 1;
      });
      var fakeChapter = JSON.stringify(this.chapters);

      formData.append("indexOfFiles", JSON.stringify(arrayInfoOfFiles));

      var fakeSelectedTemplate = JSON.stringify(this.selectedTemplate);
      formData.append("company", this.space.id);
      formData.append("track", this.trackName);
      formData.append("chapters", fakeChapter);
      formData.append("file", header);
      formData.append("lockedTrack", this.lockedTrack);
      formData.append("selectedTemplate", fakeSelectedTemplate);
      formData.append("lang", this.lang);
      // formData.append("syllabus", syllabusmessage);

      axios
        .post(`${process.env.VUE_APP_API_URL}/track/insertNewTrack`, formData, {
          params: {
            headers: { "Content-Type": "multipart/form-data" },
          },
        })
        .then(() => {
          window.location.href = "/tracks";
        });
    },
    newFileChapterPart() {
      var errors = false;
      if (this.resource.title == "") {
        $("#newProject-ResourceTitle").addClass("newProject-InputValidation");
        errors = true;
      } else {
        $("#newProject-ResourceTitle").removeClass(
          "newProject-InputValidation"
        );
      }
      if (this.resource.url == "") {
        if (!this.resource.file) {
          $("#newProject-ResourceURL").addClass("newProject-InputValidation");
          $("#newProject-ResourceFileContainer").addClass(
            "newProject-InputValidation"
          );
          errors = true;
        } else {
          $("#newProject-ResourceURL").removeClass(
            "newProject-InputValidation"
          );
          if (
            document.getElementById("newProject-ResourceFile").files.length == 0
          ) {
            $("#newProject-ResourceFileContainer").addClass(
              "newProject-InputValidation"
            );
            errors = true;
          } else {
            $("#newProject-ResourceFileContainer").removeClass(
              "newProject-InputValidation"
            );
          }
        }
      } else {
        this.resource.userFile = {};
        this.resource.userFileName = "";
        $("#newProject-ResourceURL").removeClass("newProject-InputValidation");
        $("#newProject-ResourceFileContainer").removeClass(
          "newProject-InputValidation"
        );
      }
      if (errors) {
        this.$refs.alerts.title = "¡Incompleted fields!";
        this.$refs.alerts.text = "To continue you must fill all the fields.";
        this.openErrorsAlert();
      } else {
        this.resourcesModal = false;
        this.resource.category = this.listCategories[this.resource.category].id;
        if (this.resource.part == -1) {
          this.chapters[this.resource.chapter].files.push(this.resource);
        } else {
          this.chapters[this.resource.chapter].parts[
            this.resource.part
          ].files.push(this.resource);
        }
        this.resource = {
          title: "",
          category: 0,
          url: "",
          chapter: 0,
          part: -1,
          file: false,
          template: false,
          userFileName: "",
          userFile: {},
        };
        document.getElementById("newProject-ResourceFile").value = "";
      }
    },
    sendQuestionToValidate() {
      var valid = true;
      var characters = false;

      if (this.question.task == "") {
        $("#newProject-QuestionTitle").addClass("newProject-InputValidation");
        valid = false;
      } else {
        $("#newProject-QuestionTitle").removeClass(
          "newProject-InputValidation"
        );
      }
      // if (this.question.question == "") {
      //   $("#newProject-QuestionQuestion").addClass(
      //     "newProject-InputValidation"
      //   );
      //   valid = false;
      // } else {
      //   $("#newProject-QuestionQuestion").removeClass(
      //     "newProject-InputValidation"
      //   );
      // }
      if (this.question.type == 3) {
        var paso = false;
        this.question.newQuestionChecks.forEach(function (element) {
          if (element.correct == true) {
            paso = true;
          }
          if (element.name.length == 0) {
            characters = true;
          }
        });
        if (this.question.newQuestionChecks.length < 2) {
          paso = false;
        }
        if (paso == false || characters == true) {
          $("#newProject-QuestionChecks").addClass(
            "newProject-InputValidation"
          );
          valid = false;
        } else {
          $("#newProject-QuestionChecks").removeClass(
            "newProject-InputValidation"
          );
        }
      }

      if (this.question.type == 2) {
        if (this.question.newQuestionChecks.length < 2) {
          $("#newProject-QuestionChecks").addClass(
            "newProject-InputValidation"
          );
          valid = false;
        } else {
          this.question.newQuestionChecks.forEach(function (element) {
            if (element.name.length == 0) {
              characters = true;
            }
          });
          if (characters == true) {
            $("#newProject-QuestionChecks").addClass(
              "newProject-InputValidation"
            );
          } else {
            $("#newProject-QuestionChecks").removeClass(
              "newProject-InputValidation"
            );
          }
        }
      }
      if (valid == true && characters == false) {
        if (this.questionEdit !== 1) {
          this.MoreTasks();
        } else {
          this.chapters[this.savedTask[0]].parts[this.savedTask[1]].tasks[
            this.savedTask[2]
          ] = this.question;
          this.question = {
            task: "",
            type: 1,
            // question: "",
            instructions: "",
            // help: "",
            newQuestionChecks: [],
            radio: 0,
            chapter: 0,
            part: 0,
          };
        }
        this.questionsModal = 0;
      }
    },
    selectedTemplateStep() {
      if (this.selectedTemplate !== "") {
        this.steps = 1;
      } else {
        this.openErrorsAlert();
      }
    },
    stepsFunction(step) {
      switch (step) {
        case 0:
          this.steps = 0;

          break;
        case 1:
          this.steps = 1;

          break;
        case 2:
          if (this.validationStep1()) {
            this.steps = 2;
          }

          break;
        case 3:
          if (this.validationStep1() && this.validationStep2()) {
            this.steps = 3;
          }

          break;
        case 4:
          if (this.validationStep1()) {
            this.steps = 4;
          }

          break;
      }
    },
    validationSomethingChanged() {
      let somethingChanged = false;
      if (this.trackName.length != 0) {
        somethingChanged = true;
      }
      if (this.lockedTrack == 0) {
        var header = document.getElementById("newProject-Header").files.length;

        if (header != 0) {
          somethingChanged = true;
        }
      }

      var i = 0;
      var j = 0;

      this.chapters.forEach((chapter) => {
        j = 0;
        var chapterError = false;
        if (chapter.chapter != "") {
          somethingChanged = true;
        }
        if (chapter.description != "") {
          somethingChanged = true;
        }

        chapter.parts.forEach((part) => {
          if (part.part != "") {
            somethingChanged = true;
          }
          j = j + 1;
        });
        if (chapterError) {
          somethingChanged = true;
        }
        i = i + 1;
      });
      return somethingChanged;
    },
    validationStep1() {
      var canContinue = true;
      if (this.trackName.length == 0 || this.trackNameValidation) {
        canContinue = false;

        $("#newProject-TrackName").addClass("newProject-InputValidation");
      } else {
        $("#newProject-TrackName").removeClass("newProject-InputValidation");
      }
      if (this.lockedTrack == 0) {
        var header = document.getElementById("newProject-Header").files.length;

        if (header == 0) {
          canContinue = false;
          $("#newProject-PreviewDiv").addClass("newProject-InputValidation");
        } else {
          $("#newProject-PreviewDiv").removeClass("newProject-InputValidation");
        }
      }

      var i = 0;
      var j = 0;

      this.chapters.forEach((chapter) => {
        j = 0;
        var chapterError = false;
        if (chapter.chapter == "") {
          canContinue = false;

          $("#newProject-Chapter" + i + "Name").addClass(
            "newProject-InputValidationWithoutBorder"
          );
          chapterError = true;
        } else {
          $("#newProject-Chapter" + i + "Name").removeClass(
            "newProject-InputValidationWithoutBorder"
          );
        }
        if (chapter.description == "") {
          canContinue = false;

          $("#newProject-Chapter" + i + "Description").addClass(
            "newProject-InputValidation"
          );
          chapterError = true;
        } else {
          $("#newProject-Chapter" + i + "Description").removeClass(
            "newProject-InputValidation"
          );
        }

        chapter.parts.forEach((part) => {
          if (part.part == "") {
            canContinue = false;

            $("#newProject-Part" + i + "-" + j + "Name").addClass(
              "newProject-InputValidationWithoutBorder"
            );
            chapterError = true;
          } else {
            $("#newProject-Part" + i + "-" + j + "Name").removeClass(
              "newProject-InputValidationWithoutBorder"
            );
          }
          j = j + 1;
        });
        if (chapterError) {
          $("#newProject-ChapterError" + i).addClass(
            "newProject-ContainerValidation"
          );
        } else {
          $("#newProject-ChapterError" + i).removeClass(
            "newProject-ContainerValidation"
          );
        }
        i = i + 1;
      });
      if (canContinue) {
        return true;
      } else {
        this.openErrorsAlert();
        return false;
      }
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    modalResourcesOpen(chapter, part) {
      if (part == -1) {
        this.listResourcesChecked = this.chapters[chapter].preResources;
      } else {
        this.listResourcesChecked =
          this.chapters[chapter].parts[part].preResources;
      }

      this.resourcesModal = 1;
      this.resource.chapter = chapter;
      this.resource.part = part;
    },
    updatePreResources() {
      this.listResourcesChecked = [];
      if (this.resource.part == -1) {
        this.listResourcesChecked =
          this.chapters[this.resource.chapter].preResources;
      } else {
        this.listResourcesChecked =
          this.chapters[this.resource.chapter].parts[
            this.resource.part
          ].preResources;
      }
    },
    closeModalClickingBackgroundQuestions(el) {
      var modal = document.getElementById("myProjects-ModalQuestions");

      if (el.target == modal) {
        this.question = {
          task: "",
          type: 1,
          question: "",
          instructions: "",
          help: "",
          newQuestionChecks: [],
          radio: 0,
          optional: false,
          chapter: 0,
          part: 0,
        };
        this.resourcesModal = 0;
        this.questionsModal = 0;
      }
    },
    openPopUpResources(el, chapter, part) {
      this.$refs.modalResources.selectedCategories = [];
      this.$refs.modalResources.parentChapter = chapter;
      this.$refs.modalResources.parentPart = part;
      if (part !== -1) {
        var resourcesMap = this.chapters[chapter].parts[part].preResources.map(
          (item) => ({
            category: item.resource_category,
            template: item.template,
            url: item.link,
            title: item.name,
            file: item.file,
            selection: 1,
            preResource: true,
            userFileName: item.link,
          })
        );
        this.$refs.modalResources.resources_list = this.chapters[chapter].parts[
          part
        ].files
          .concat(resourcesMap)
          .map((item, index) => ({
            category: item.category,
            template: item.template,
            url: item.url,
            title: item.title,
            file: item.file,
            selection: item.selection,
            preResource: item.preResource,
            index: index,
            userFileName: item.userFileName,
          }));
      } else {
        resourcesMap = this.chapters[chapter].preResources.map((item) => ({
          category: item.resource_category,
          template: item.template,
          url: item.link,
          title: item.name,
          file: item.file,
          selection: 1,
          preResource: true,
          userFileName: item.link,
        }));
        this.$refs.modalResources.resources_list = this.chapters[chapter].files
          .concat(resourcesMap)
          .map((item, index) => ({
            category: item.category,
            template: item.template,
            url: item.url,
            title: item.title,
            file: item.file,
            selection: item.selection,
            preResource: item.preResource,
            index: index,
            userFileName: item.userFileName,
          }));
      }
    },
    clickOutsideDiv(el) {
      $(el.currentTarget).hide();

      $(el.currentTarget).prev().hide();
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);

      this.errors = [];
    },
    minimizeExpand(el, chapter, part) {
      if (chapter) {
        if (chapter.hide) {
          $(el.target).css("transform", "rotate(0deg)");
        } else {
          $(el.target).css("transform", "rotate(270deg)");
        }
        chapter.hide = !chapter.hide;
      } else if (part) {
        if (part.hide) {
          $(el.target).css("transform", "rotate(0deg)");
        } else {
          $(el.target).css("transform", "rotate(270deg)");
        }
        part.hide = !part.hide;
      }
    },
    uploadFileToModal() {
      if (
        document.getElementById("newProject-ResourceFile").files[0].size >
        1024 * 1024 * 8
      ) {
        alert("Limit size exceeded");
      } else {
        this.resource.file = !this.resource.file;
        this.resource.userFile = document.getElementById(
          "newProject-ResourceFile"
        ).files[0];

        this.resource.userFileName = this.resource.userFile.name;
      }
    },
    selectPreResources() {
      if (this.listResourcesChecked.length != 0) {
        if (this.resource.part == -1) {
          this.chapters[this.resource.chapter].preResources =
            this.listResourcesChecked;
        } else {
          this.chapters[this.resource.chapter].parts[
            this.resource.part
          ].preResources = this.listResourcesChecked;
        }
        this.resourcesModal = 0;
      }
    },
    minusResource(chapter, part, resourcesindex, index, selection, type) {
      if (part == -1) {
        if (selection == 0) {
          this.chapters[chapter].files.splice(index, 1);
        } else {
          this.chapters[chapter].preResources.splice(index, 1);
        }
      } else {
        if (selection == 0) {
          this.chapters[chapter].parts[part].files.splice(index, 1);
        } else {
          this.chapters[chapter].parts[part].preResources.splice(index, 1);
        }
      }
      if (type == 0) {
        this.$refs.modalResources.resourcesCat.splice(resourcesindex, 1);
      } else {
        this.$refs.modalResources.resourcesPlant.splice(resourcesindex, 1);
      }
      this.$refs.modalResources.resourcesCat.forEach((element) => {
        if (element.index > index && element.selection == selection) {
          element.index = element.index - 1;
        }
      });
      this.$refs.modalResources.resourcesPlant.forEach((element) => {
        if (element.index > index && element.selection == selection) {
          element.index = element.index - 1;
        }
      });
    },
    deleteTrack(track, index) {
      // console.log(track);

      axios
        .post(`${process.env.VUE_APP_API_URL}/new-project-delete-track`, {
          track: track,
          customer: this.space.id,
        })
        .then(() => {
          this.preSetTracks.splice(index, 1);
        });
    },

    checkIfTrackNameExist() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/checkTrackName`, {
          track: this.trackName,
          customer: this.space.id,
        })
        .then((response) => {
          if (response.data.length !== 0) {
            this.trackNameValidation = true;
            $("#newProject-TrackName").addClass("newProject-InputValidation");
            this.openModalConfirmation();
          } else {
            this.trackNameValidation = false;
            $("#newProject-TrackName").removeClass(
              "newProject-InputValidation"
            );
          }
        });
    },
    openModalConfirmation() {
      this.$refs.modalConfirmation.confirmationMessage =
        "El nombre del Track ya existe, prueba a insertar otro nombre.";
      this.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$refs.modalConfirmation.confirmationButtonText = "Texto";
      this.$refs.modalConfirmation.confirmationButtonText2 = "";
      $("#modalConfirmation").modal("toggle");
    },
    openModalConfirmationBack() {
      if (this.steps === 4) {
        this.steps = 1;
      } else {
        if (this.validationSomethingChanged()) {
          this.confirmModal = 8;
          this.$refs.modalConfirmation.confirmationMessage = "Progress";
          this.$refs.modalConfirmation.confirmationMessage2 = "Sure";
          this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
          this.$refs.modalConfirmation.confirmationButtonText2 = "Si, salir";
          $("#modalConfirmation").modal("toggle");
        } else {
          //Cambiar Url
          history.replaceState(null, null, `/tracks`);
          this.$emit("newTrack", 0);
          this.removeRedBorder();
        }
      }
    },
    goBackTrack() {
      history.replaceState(null, null, `/tracks`);
      this.$emit("newTrack", 0);
      this.removeRedBorder();
    },
    removeRedBorder() {
      $("input, div, textarea")
        .removeClass("newProject-ContainerValidation")
        .removeClass("newProject-InputValidation")
        .removeClass("newProject-InputValidationWithoutBorder");
      this.errors = [];
    },
    openModalConfirmationClose() {
      this.confirmModal = 8;
      this.$refs.modalConfirmation.confirmationMessage = "Progress";
      this.$refs.modalConfirmation.confirmationMessage2 = "Sure";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Si, salir";
      this.resourcesModal = 0;
      this.questionsModal = 0;
    },
    openModalConfirmationQuestion(indexChapter, indexPart, indexTask) {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.chapterQuest = indexChapter;
      this.partQuest = indexPart;
      this.taskQuest = indexTask;
      this.confirmModal = 5;
      this.$refs.modalConfirmation.confirmationMessage = "question";
      this.$refs.modalConfirmation.confirmationMessage2 =
        "¿Está seguro de querer eliminar la pregunta?";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Si, Eliminar";
      myModal.toggle();
    },

    openModalConfirmationSection(indexChapter, indexPart) {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.chapterQuest = indexChapter;
      this.partQuest = indexPart;
      this.confirmModal = 6;
      this.$refs.modalConfirmation.confirmationMessage = "question";
      this.$refs.modalConfirmation.confirmationMessage2 =
        "¿Está seguro de querer eliminar el apartado?";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Si, Eliminar";
      myModal.toggle();
    },
    openModalConfirmationChapter(indexChapter) {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.chapterQuest = indexChapter;
      this.confirmModal = 7;
      this.$refs.modalConfirmation.confirmationMessage = "question";
      this.$refs.modalConfirmation.confirmationMessage2 =
        "¿Está seguro de querer eliminar el tema?";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Si, Eliminar";
      myModal.toggle();
    },
    openModalConfirmationEdit() {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.confirmModal = 4;
      this.$refs.modalConfirmation.confirmationMessage =
        "Al editar esta plantilla se creara un nuevo Track";
      this.$refs.modalConfirmation.confirmationMessage2 =
        "¿Esta seguro de querer continuar?";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Continuar";
      myModal.toggle();
    },
    confirmTrackContinue() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );

      var header = document.getElementById("newProject-Header").files.length;

      switch (this.confirmModal) {
        case 0:
          window.location.href = window.location.origin + "/tracks/";
          break;
        case 1:
          if (
            !(this.trackName == "") ||
            !(this.projectName == "") ||
            !(header == 0)
          ) {
            myModal.toggle();
          }
          this.clearAll();

          this.tabs = 1;
          this.steps = 1;
          var preview = document.getElementById("newProject-Preview");
          preview.src = "";
          this.showImage = false;

          break;
        case 2:
          if (
            !(this.trackName == "") ||
            !(this.projectName == "") ||
            !(header == 0)
          ) {
            myModal.toggle();
          }
          this.clearAll();

          this.tabs = 2;
          this.steps = 0;

          break;
        case 3:
          if (
            !(this.trackName == "") &&
            !(this.projectName == "") &&
            !(header == 0)
          ) {
            myModal.toggle();
          }
          this.clearAll();

          this.tabs = 3;
          this.steps = 1;

          break;
        case 4:
          this.lockedTrack = 0;
          preview = document.getElementById("newProject-Preview");
          preview.src = "";
          this.showImage = false;
          myModal.toggle();
          break;
        case 5:
          this.MinusTasks(this.chapterQuest, this.partQuest, this.taskQuest);
          this.chapterQuest == "";
          this.partQuest == "";
          this.taskQuest == "";
          myModal.toggle();
          break;
        case 6:
          this.MinusParts(this.chapterQuest, this.partQuest);
          this.chapterQuest == "";
          this.partQuest == "";
          myModal.toggle();
          break;
        case 7:
          this.MinusChapters(this.chapterQuest);
          this.chapterQuest == "";
          myModal.toggle();
          break;
        case 8:
          myModal.toggle();
          $(
            "#newProject-QuestionTitle, #newProject-QuestionQuestion, #newProject-ResourceTitle, #newProject-ResourceFileContainer, #newProject-ResourceURL"
          ).removeClass("newProject-InputValidation");
          this.resourcesModal = 0;
          this.questionsModal = 0;
          this.chapterQuest == "";
          this.partQuest == "";
          this.taskQuest == "";
          //Cambiar Url
          history.replaceState(null, null, `/tracks`);
          this.$emit("newTrack", 0);
          this.removeRedBorder();
          break;
      }
    },
    confirmTrackCancel() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.toggle();
    },
    confirmTrackNoOptions() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.toggle();
    },
    changeTabs(index) {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );

      var header = document.getElementById("newProject-Header").files.length;

      if (this.tabs !== index) {
        if (this.trackName == "" && this.projectName == "" && header == 0) {
          this.confirmModal = index;
          this.confirmTrackContinue(0);
        } else {
          this.confirmModal = index;
          this.$refs.modalConfirmation.confirmationMessage =
            "Perderá todos los datos rellenados.";
          this.$refs.modalConfirmation.confirmationMessage2 =
            "¿Esta seguro de querer continuar?";
          this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
          this.$refs.modalConfirmation.confirmationButtonText2 = "Si, cambiar";
          myModal.toggle();
        }
      }
    },
    selectTrackTemplate(preTrack) {
      axios
        .post(`${process.env.VUE_APP_API_URL}/getTrack`, {
          track: preTrack,
        })
        .then((response) => {
          var preview = document.getElementById("newProject-Preview");
          preview.src =
            process.env.VUE_APP_API_STORAGE +
            "/img/track_headers/" +
            response.data.track.img;
          this.showImage = true;

          this.selectedTemplate = response.data.track;
          response.data.chapters.forEach((chapter) => {
            if (chapter.parts.length === 0) {
              var ids = [chapter.id, 0, 0];
              this.chapters_ids.push(ids);
            } else {
              chapter.parts.forEach((part) => {
                if (part.tasks.length === 0) {
                  var ids = [chapter.id, part.id, 0];
                  this.chapters_ids.push(ids);
                } else {
                  part.tasks.forEach((task) => {
                    var ids = [chapter.id, part.id, task.question_id];
                    this.chapters_ids.push(ids);
                  });
                }
              });
              chapter.parts.sort((a, b) =>
                a.solution_order > b.solution_order
                  ? 1
                  : b.solution_order > a.solution_order
                    ? -1
                    : 0
              );
            }
          });
          response.data.default_chapters.forEach((chapter) => {
            chapter.parts.sort((a, b) =>
              a.solution_order > b.solution_order
                ? 1
                : b.solution_order > a.solution_order
                  ? -1
                  : 0
            );
          });
          this.chapters = response.data.chapters;
          this.defaultTemplateChapters = response.data.default_chapters;

          this.trackName = response.data.track.name;

          this.syllabus = response.data.track.syllabus;

          $("#newProject-Syllabus").find("button").addClass("fr-disabled");
          $(".fr-wrapper").children().html(this.syllabus);
          $(".fr-wrapper").removeClass("show-placeholder");
          $(".fr-wrapper").children().prop("contenteditable", false);
          $(".fr-wrapper").css("background-color", "#F7F6FA");
          $(".fr-second-toolbar").css("background-color", "#F7F6FA");
        });

      this.steps = 1;
      this.lockedTrack = 1;
    },
    openQuestionsModal(part, chapter) {
      this.questionsModal = 1;
      this.questionEdit = 0;
      this.question = {
        task: "",
        type: 1,
        question: "",
        instructions: "",
        help: "",
        newQuestionChecks: [],
        radio: 0,
        optional: false,
        chapter: 0,
        part: 0,
      };
      this.question.part = part;
      this.question.chapter = chapter;
    },
    editTask(task, part, chapter) {
      this.questionEdit = 1;
      this.questionsModal = 1;
      this.savedTask = [chapter, part, task];

      this.question = JSON.parse(
        JSON.stringify(this.chapters[chapter].parts[part].tasks[task])
      );
    },
    showTipoTask(el) {
      $(el.target).next().show();
    },
    hideTipoTask(el) {
      $(el.target).next().hide();
    },
    selectTemplateStyles(el) {
      if (el.target.className !== "newProject-track-options") {
        $(".newProject-Pre-SetTrackCard.selected").removeClass("selected");
        $(el.currentTarget).addClass("selected");
      }
    },
    clearAll() {
      $("#newProject-Header").val(null);
      var preview = document.getElementById("newProject-Preview");
      preview.src = "";

      this.confirmModal = 0;

      this.lockedTrack = 0;

      this.searchPreSet = "";
      this.selectedTemplate = "";

      // Form Inputs
      this.trackName = "";
      this.trackNameValidation = false;
      this.projectName = "";
      this.cascadeProject = false;
      this.chapters = [
        {
          chapter: "",
          description: "",
          hide: false,
          files: [],
          preResources: [],
          parts: [
            {
              part: "",
              files: [],
              optional: false,
              hide: false,
              preResources: [],
              tasks: [],
            },
          ],
        },
      ];
      this.company = 0;
      this.time = "";
      this.duration = "";
      this.program = "";
      this.selectedUsers = [];
      this.selectedTutors = [];
      this.selectedMentors = [];

      //question form
      this.questionEdit = 0;
      this.question = {
        task: "",
        type: 1,
        question: "",
        instructions: "",
        help: "",
        newQuestionChecks: [],
        radio: 0,
        optional: false,
        chapter: 0,
        part: 0,
      };
      //resources form
      this.resource = {
        title: "",
        category: 0,
        url: "",
        chapter: 0,
        part: null,
        file: false,
        template: false,
        userFileName: "",
        userFile: {},
      };

      //Utility variables
      this.newEmail = "";
      this.addEmail = 0;
      this.steps = 1;
      this.tabs = 1;
      this.modalResourcesTab = 1;
      this.questionsModal = 0;
      this.errors = [];
      this.succesful = [];
      this.errorTime = {};
      this.setTimeouts = [];

      this.resourcesModal = 0;

      this.listUsers = [
        {
          id: 1,
          name: "jaime",
        },
        {
          id: 2,
          name: "jose A",
        },
      ];

      this.totalUsers = [];

      this.searchUsers = "";
      this.searchMentors = "";
      this.searchTutors = "";
      this.newResources = [];

      this.searchResources = "";
      this.listResourcesChecked = [];
    },
    autosize(event) {
      const textarea = document.getElementById(event.target.id);
      if (event.key == "Enter") {
        textarea.value += "\n";
      }
      setTimeout(function () {
        textarea.style = "height:auto;";
        textarea.style = "height:" + textarea.scrollHeight + "px";
      }, 0);
    },
    getImageEnv() {
      return process.env.VUE_APP_API_STORAGE;
    },
  },
};
</script>
