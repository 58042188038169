export default {
  methods: {
    langFilter(filter) {
      let result = this.traducciones.filter((traduccion) => {
        if (filter == traduccion.name) {
          return traduccion;
        }
      });

      if (result.length > 0) {
        if (this.lang == "es" || this.lang == "test") {
          return result[0].es;
        } else {
          return result[0].en;
        }
      } else {
        return filter;
      }
    },
  },
};
