<template>
  <div>
    <div class="login-sidebar">
      <img
        class="login-sidebar-logo"
        :src="require('@/assets/img/sidebar/acc_logo.png')"
      />
      <p class="login-sidebar-text">
        {{ langFilter("Tell us your") }} <span>email</span>
      </p>
    </div>
    <div v-if="steps == 1" class="login-main-container">
      <h1 class="login-header">{{ langFilter("Forgot your password?") }}</h1>
      <h1 class="login-second-header">
        {{ langFilter("We will send you an") }}
        <span>{{ langFilter("email to retrieve it.") }}</span>
      </h1>
      <div class="register-input-row" style="margin-top: 110px">
        <div
          class="register-input-col"
          style="margin-left: 75px; margin-right: 75px"
        >
          <label>Email</label>
          <input
            id="input-email"
            v-model="email"
            type="email"
            placeholder="Email"
          />
        </div>
        <div class="login-checkbox">
          <!-- <label class="forgot-password-account-msg">{{langFilter('Don’t have an account?')}} <span>{{langFilter('Sign up')}}</span></label> -->
        </div>
      </div>

      <div class="register-input-row" style="margin-top: 45px">
        <div class="register-input-col" style="width: 325px"></div>
        <div class="register-input-col" style="width: 325px"></div>
        <div class="register-input-col" style="width: 325px">
          <div class="register-input-col"></div>
          <div class="register-input-col">
            <div class="login-button" @click="submit">
              <p class="login-button-text">{{ langFilter("Sent") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="steps == 2" class="login-main-container">
      <h1 class="login-header">{{ langFilter("Forgot your password?") }}</h1>
      <h1 class="login-second-header">
        {{ langFilter("We will send you an") }}
        <span>{{ langFilter("email to retrieve it.") }}</span>
      </h1>
      <div class="register-input-row" style="margin-top: 128px">
        <div class="register-input-col">
          <p class="forgot-password-final-text">
            Se ha enviado un email a tu bandeja de entrada desde el cuál podrás
            reestablecer tu contraseña.
          </p>
        </div>
      </div>
    </div>

    <alertMessage ref="alerts" :lang="lang"></alertMessage>
  </div>
</template>
<script>
import translationMixin from "../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";

export default {
  mixins: [translationMixin],
  data() {
    return {
      // alerts
      errors: [],
      succesful: [],
      //data
      steps: 1,
      email: "",
      lang: "es",
      traducciones: [
        {
          name: "Forgot your password?",
          es: "¿Contraseña olvidada?",
          en: "Forgot your password?",
        },
        {
          name: "We will send you an",
          es: "Te enviaremos un",
          en: "We will send you an",
        },
        {
          name: "email to retrieve it.",
          es: "email para recuperarla.",
          en: "email to retrieve it.",
        },
        {
          name: "Don’t have an account?",
          es: "¿No tienes cuenta aún?",
          en: "Don’t have an account?",
        },
        {
          name: "Sign up",
          es: "Registrarme",
          en: "Sign up",
        },
        {
          name: "Sent",
          es: "Enviar",
          en: "Sent",
        },
        {
          name: "Tell us your",
          es: "Inserta tu",
          en: "Tell us your",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.lang = navigator.language.substring(0, 2);
  },
  methods: {
    submit() {
      if (this.email == "") {
        $("#input-email").addClass("input-error");

        this.$refs.alerts.title = "¡Incompleted fields!";
        this.$refs.alerts.text = "To continue you must fill all the fields.";

        this.openErrorsAlert();
        return false;
      }
      let email_exist = false;
      axios
        .post(`${process.env.VUE_APP_API_URL}/forgot-password/email-exist`, {
          email: this.email,
          lang: this.lang,
        })
        .then((response) => {
          if (response.data.length != 0) {
            email_exist = true;
          }

          if (email_exist) {
            $("#input-email").removeClass("input-error");
            this.steps = 2;
            axios
              .post(
                `${process.env.VUE_APP_API_URL}/forgot-password/send-email`,
                {
                  email: this.email,
                  lang: this.lang,
                }
              )
              .catch(function () {});
          } else {
            this.$refs.alerts.title = "Incorrect data entered!";
            this.$refs.alerts.text =
              "Be careful, the email introduced does not exist.";

            this.openErrorsAlert();
            $("#input-email").addClass("input-error");
          }
        })
        .catch(function () {});
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);

      this.errors = [];
    },
  },
};
</script>
<style>
.forgot-password-account-msg {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;

  text-align: center;

  color: #ffffff;
}
.forgot-password-account-msg span {
  color: #815bf0;
  cursor: pointer;
}
.forgot-password-final-text {
  width: 607px;
  font-family: "Poppins-Regular";
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #e5e6fa;
}
</style>
