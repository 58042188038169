<script setup>
import Home from "../components/home/HomeComponent.vue";
</script>

<template>
  <Home
    :spaces="spaces"
    :role="role"
    :lang="lang"
    :homeNotifications="homeNotifications"
  />
</template>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    homeNotifications: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
@import "../../src/assets/css/home/home.css";
</style>
