<template>
  <div class="modal-input-row" style="padding-bottom: 0px">
    <div class="modal-input-col">
      <div
        class="modal-button-confirm"
        @click="$parent.$parent.submit_modal(buttonId)"
      >
        <p>{{ button }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
    buttonId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      //Traducción
      traducciones: [],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
