<template>
  <div>
    <span
      :class="[
        { 'percentage-solution-number': map },
        { 'percentage-header-bold': header },
        { 'solution-progressbar-percentage-left': index === 0 },
        { 'solution-progressbar-percentage-center': index === 1 },
        { 'solution-progressbar-percentage-right': index === 2 },
      ]"
    >
      {{ percentage }}%
    </span>
    <div
      :class="[
        { 'percentage-solution-closed': !mapOpened },
        { 'solution-progressbar-left': index === 0 },
        { 'solution-progressbar-header': index === 1 },
        { 'solution-progressbar-right': index === 2 },
        'progress',
      ]"
    >
      <div
        :class="[{ 'progress-completed': percentage == 100 }, 'progress-bar']"
        role="progressbar"
        :style="`width: ${percentage}%`"
        :aria-valuenow="percentage"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
    },
    map: {
      type: Boolean,
      required: true,
    },
    mapOpened: {
      type: Boolean,
      required: true,
    },
    header: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
