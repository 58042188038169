<template>
  <div class="relative" @mouseenter="hoverIn()" @mouseleave="hoverOut()">
    <slot />
    <div
      v-if="hover"
      class="hoverGeneral-box"
      :class="(solution && 'hoverSolutions') || (bs && 'hoverGeneral2-box')"
    >
      <span>{{ content }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import "../../assets/css/hover.css";

export default defineComponent({
  name: "HoverGeneral",
  props: {
    content: {
      type: String,
      required: true,
    },
    bs: {
      type: Boolean,
      required: false,
    },
    solution: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    hoverIn() {
      this.hover = true;
    },
    hoverOut() {
      this.hover = false;
    },
  },
});
</script>
