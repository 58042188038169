<template>
  <div class="itinerary-users-to-select">
    <div class="itinerary-select-user" @click="handleUser(user)">
      <div v-if="isSelected" />
    </div>
    <span v-if="user.name !== ''" @click="handleUser(user)">{{
      user.name
    }}</span>
    <span
      :class="error ? 'itinerary-user-error' : ''"
      @click="handleUser(user)"
      >{{ user.email }}</span
    >
    <span v-if="error" class="itinerary-user-error">{{
      langFilter("notValidEmail")
    }}</span>
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: ["lang", "user", "isSelected"],
  emits: ["selectUser"],

  data() {
    return {
      error: false,
      traducciones: [
        {
          name: "notValidEmail",
          es: "El email no es válido",
          en: "The email is not valid",
        },
      ],
    };
  },
  watch: {
    user: {
      handler() {
        this.error = false;
      },
      deep: true,
    },
  },

  methods: {
    handleUser(user) {
      if (user.id === 0) {
        const emailRegex =
          /^[a-zA-Z0-9._-]+([+][a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailRegex.test(user.email)) {
          this.error = true;
          return;
        } else this.error = false;
      }
      this.$emit("selectUser", user);
    },
  },
};
</script>