<script setup>
import Notification from "../components/notifications/NotificationsComponent.vue";
</script>

<template>
  <Notification :lang="lang" :space="spaces[0]" />
</template>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
};
</script>
