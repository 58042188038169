<template>
  <div class="d-flex flex-wrap mt-4 assistant-list-hr">
    <template v-for="assistant in assistList" :key="assistant.id">
      <AssistantCard :assistant="assistant" :lang="lang" />
    </template>
  </div>
</template>

<script>
import AssistantCard from "./AssistantCard.vue";

export default {
  name: "AssistantsList",
  components: {
    AssistantCard,
  },
  props: {
    lang: {
      type: String,
      default: "en",
    },
    assistList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: this.assistList ?? [],
    };
  },
};
</script>

<style scoped>
@import "../../assets/css/assistants/assistants.css";
</style>
