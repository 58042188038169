<template>
  <div>
    <div
      class="itinerary-eachRow row-itinerary no-selection-itinerary"
      style="margin-right: 43px; padding-top: 24px"
    >
      <div
        class="title-table-theme"
        style="
          display: flex;
          padding: 0 0 5px 0;
          text-align: left;
          white-space: normal;
        "
      >
        <span v-if="cascade" style="margin-top: 10px">{{
          langFilter("cascadeProject")
        }}</span>
        <img
          id="image_info"
          class="myDIV"
          style="padding-left: 5px; padding-bottom: 0; width: 22px"
          :src="show_info_icon()"
          @mouseover="showhover()"
          @mouseout="hidehover()"
        />
        <span
          id="hover_header"
          :class="setclasshover()"
          style="margin-top: 3px"
          @mouseover="showhover()"
          @mouseout="hidehover()"
        >
          <div
            class="text-description-itinerary"
            style="width: 150px; height: 44px"
          >
            <p
              class="text-hover-itinerary"
              style="
                text-align: left;
                width: 150px;
                height: 44px;
                padding: 7px 0 0 7px;
              "
            >
              {{ langFilter("hover1") }}
              <strong
                >{{ langFilter("hover2") }}<br />{{ langFilter("hover3") }}
              </strong>
              <span
                data-bs-toggle="modal"
                data-bs-target="#myProjects-Modal"
                style="cursor: pointer"
                >{{ langFilter("vermas") }}</span
              >
            </p>
          </div>
        </span>
      </div>
      <div
        class="text-header-table-itinerary info-title-theme-table"
        style="text-align: center"
      >
        <p class="">{{ langFilter("recursos") }}</p>
      </div>
      <!-- <div class="text-header-table-itinerary info-title-theme-table" style="text-align: center">
                <p class="">{{ langFilter("estado") }}</p>
            </div> -->
      <div
        class="text-header-table-itinerary info-title-theme-table"
        style="text-align: center"
      >
        <p class="">{{ langFilter("avance") }}</p>
      </div>
    </div>
    <div id="myProjects-Modal" class="myProjects-ModalContainer">
      <div class="modal-dialog modal-bs5-dialog">
        <div class="myProjects-Modal">
          <img
            id="myProjects-ModalClose"
            class="myProjects-ModalClose"
            :src="require('@/assets/img/login_register/Chat_X.png')"
          />
          <img
            :src="require('@/assets/img/projects/InfoIcon.png')"
            class="myProjects-ModalInfoIcon"
          />
          <p id="myProjects-ModalTitle" class="myProjects-ModalTitle">
            {{ langFilter("textHoverel") }}
            <strong>{{ langFilter("cascade") }}</strong>
            {{ langFilter("textHover") }}
          </p>
          <p
            id="myProjects-ModalInfo"
            class="myProjects-ModalInfo"
            style="width: 66%; margin-left: 18%; white-space: normal"
          >
            {{ langFilter("textModal") }}
          </p>
          <div class="myProjects-ModalImage" style="margin-top: 10px">
            <img id="myProjects-ModalImage" :src="langImage()" />
          </div>
          <p id="myProjects-ModalExtraInfo" class="myProjects-ModalExtraInfo">
            {{ langFilter("pregunta") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
export default {
  name: "ItineraryHeaderTableComponent",
  mixins: [translationMixin],
  props: {
    cascade: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      traducciones: [
        {
          name: "recursos",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "estado",
          es: "Estado",
          en: "State",
        },
        {
          name: "avance",
          es: "Avance por temas",
          en: "Advance by topics",
        },
        {
          name: "pregunta",
          es: "P*=Pregunta",
          en: "Q*=Question",
        },
        {
          name: "textModal",
          es: "(El usuario no podrá seguir adelante en su progreso hasta completar la tarea que tenga pendiente.)",
          en: "(The user won't be able to continue its progress until complete the pending task)",
        },
        {
          name: "textHoverel",
          es: "El",
          en: "A",
        },
        {
          name: "cascade",
          es: "proyecto en cascada",
          en: "cascade project",
        },
        {
          name: "textHover",
          es: "se trata de un progreso escalonado.",
          en: "is a stepwise progress.",
        },
        {
          name: "hover1",
          es: "El usuario tiene un",
          en: "The user has a",
        },
        {
          name: "hover2",
          es: "progreso",
          en: "stepwise",
        },
        {
          name: "hover3",
          es: "escalonado",
          en: "progress",
        },
        {
          name: "vermas",
          es: "Ver más",
          en: "See more",
        },
        {
          name: "cascadeProject",
          es: "Proyecto en cascada",
          en: "Stepwise project",
        },
      ],
    };
  },
  created() {
    if (this.cascade) {
      $("#image_info").addClass("image-info-width");
    }
  },
  methods: {
    setclasshover() {
      if (this.lang == "es") {
        return "hide-modal-itinerary";
      } else {
        return "hide-modal-itinerary-2";
      }
    },
    langImage() {
      if (this.lang == "es") {
        return "../img/itinerario/track_info_es.png";
      } else {
        return "../img/itinerario/track_info_en.png";
      }
    },
    showhover() {
      if (this.cascade) {
        $("#hover_header").show();
      }
    },
    hidehover() {
      if (this.cascade) {
        $("#hover_header").hide();
      }
    },
    showInfo() {
      if (this.cascade) {
        $("#myProjects-Modal").modal("toggle");
      }
    },
    cascade_project() {
      if (this.cascade) {
        return "Proyecto en cascada";
      }
    },
    show_info_icon() {
      if (this.cascade) {
        return "/img/itinerario/info.svg";
      }
    },
  },
};
</script>
