<template>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <h5>Assign Users</h5>
      </div>
      <div class="col-6">
        <h5>Users Asigned</h5>
      </div>
      <br /><br />
      <div class="col-6">
        <input
          type="text"
          placeholder="Search Users"
          @input="searchUsers($event)"
        />
      </div>
      <div class="col-6" />
      <br /><br />
      <div class="col space-div-height">
        <table>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th></th>
          </tr>
          <tr v-for="(user, userIndex) in users" :key="user.id">
            <td>{{ user.name }}</td>
            <td>
              <select @change="changeSelectedRole($event, userIndex, false)">
                <option :value="1">Admin</option>
                <option :value="2">Member</option>
              </select>
            </td>
            <td>
              <button class="btn btn-success" @click="addUser(user)">+</button>
            </td>
          </tr>
        </table>
      </div>
      <div class="col space-div-height">
        <table>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th></th>
          </tr>
          <tr v-for="(user, userIndex) in usersAsigned" :key="user.id">
            <td>{{ user.name }}</td>
            <td>
              <select @change="changeSelectedRole($event, userIndex, true)">
                <option :value="1">Admin</option>
                <option :value="2" :selected="user.role == 2">Member</option>
              </select>
            </td>
            <td>
              <button class="btn btn-danger" @click="removeUser(user.id)">
                -
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br /><br />
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    spaceId: {
      type: Number,
      required: true,
    },
  },
  emits: ["addUser", "removeUser"],
  data() {
    return {
      users: [],
      usersAsigned: [],
      selectedRole: 1,
      search: "",
      allUsers: [],
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    getAllUsers() {
      this.addingUsers = !this.addingUsers;
      if (this.users.length == 0) {
        axios
          .get(`${process.env.VUE_APP_API_URL}/general/getAllUsers`)
          .then((response) => {
            this.users = response.data;
            this.allUsers = response.data;
            if (this.spaceId != null) {
              axios
                .get(
                  `${process.env.VUE_APP_API_URL}/manageSpaces-acc/getUsersBySpace`,
                  {
                    params: {
                      id: this.spaceId,
                    },
                  },
                )
                .then((response) => {
                  this.usersAsigned = response.data;
                  //filter users by users asigned
                  this.users = this.users.filter((user) => {
                    let found = false;
                    this.usersAsigned.forEach((userAsigned) => {
                      if (user.id == userAsigned.id) {
                        found = true;
                      }
                    });
                    return !found;
                  });
                  this.$emit("addUser", this.usersAsigned);
                });
            }
          });
      }
    },
    searchUsers(event) {
      const search = event.target.value;
      this.search = search.toLowerCase();

      //filter users by search
      this.users = this.allUsers.filter((user) => {
        if (user.name != null) {
          return user.name.toLowerCase().includes(this.search);
        }
      });
    },
    addUser(user) {
      this.usersAsigned.push(user);
      this.users = this.users.filter((u) => u.id != user.id);
      this.allUsers = this.allUsers.filter((u) => u.id != user.id);
      this.$emit("addUser", this.usersAsigned);
    },
    removeUser(user_id) {
      this.users.push(this.usersAsigned.find((u) => u.id == user_id));
      this.allUsers.push(this.usersAsigned.find((u) => u.id == user_id));
      this.usersAsigned = this.usersAsigned.filter((u) => u.id != user_id);
      this.$emit("removeUser", user_id);
    },
    changeSelectedRole(event, index, selected) {
      if (!selected) {
        this.users[index].role = event.target.value;
      } else {
        this.usersAsigned[index].role = event.target.value;
      }
    },
  },
};
</script>
