<script setup>
import NewProjectV2 from "../components/projects/NewProjectsV2Component.vue";
</script>

<template>
  <NewProjectV2 :space="spaces[0]" :role="role" :lang="lang" :userId="userId" />
</template>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
@import "../../src/assets/css/projects/newProject.css";
@import "../../src/assets/css/projects/newProjectV2.css";
@import "../../src/assets/css/home/home.css";
</style>
