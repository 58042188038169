<template>
  <div class="edit-profile-MainContainer">
    <header-general
      :title="langFilter('profile')"
      src="/img/users/icons/Profile-icon.png"
    />

    <div
      id="EditUser-PasswordModal"
      class="modal fade modal-sidebar-open"
      tabindex="-1"
      role="dialog"
      aria-labelledby="EditUser-PasswordModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <span
              id="closeModal"
              class="EditUser-CloseModal"
              data-bs-toggle="modal"
              data-bs-target="#EditUser-PasswordModal"
              >&#10005;</span
            >
            <form method="post" enctype="multipart/form-data">
              <h5 class="EditUser-password-SubTitle">
                {{ langFilter("change password") }}
              </h5>

              <input
                id="lastPasswordEditUser"
                class="EditUser-password-InputBox"
                style="margin-top: 24px"
                type="password"
                name="lastPassword"
                :placeholder="langFilter('current password')"
              />

              <input
                id="passwordEditUser"
                class="EditUser-password-InputBox"
                style="margin-top: 32px"
                type="password"
                name="password"
                :placeholder="langFilter('new password')"
              />

              <input
                id="passwordConfirmEditUser"
                class="EditUser-password-InputBox"
                style="margin-top: 16px"
                type="password"
                name="confirmPassword"
                :placeholder="langFilter('repeat password')"
              />

              <div
                class="EditUser-ChangePasswordButton"
                @click="validatePasswords"
              >
                <p class="EditUser-ButtonText">
                  {{ langFilter("change password") }}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      id="EditUser-SuccesfulPasswordModal"
      class="modal fade modal-sidebar-open"
      tabindex="-1"
      role="dialog"
      aria-labelledby="EditUser-SuccesfulPasswordModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <span
              id="closeModal"
              class="EditUser-CloseModal"
              data-bs-toggle="modal"
              data-bs-target="#EditUser-SuccesfulPasswordModal"
              >&#10005;</span
            >
            <p class="EditUser-SuccesfulPasswordP1">
              {{ langFilter("change password") }}
            </p>
            <img
              src="/img/users/icons/changed-password.png"
              style="margin-bottom: 22px"
            />
            <h1 class="EditUser-SuccesfulPasswordH1">
              {{ langFilter("Changed!") }}
            </h1>
            <p class="EditUser-SuccesfulPasswordP2">
              {{ langFilter("The new password has been changed succesfully") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      id="EditUser-ImageModal"
      class="modal fade modal-sidebar-open"
      tabindex="-1"
      role="dialog"
      aria-labelledby="EditUser-ImageModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="width: 275px; height: 342px">
          <div class="modal-body">
            <span
              id="closeModal"
              class="EditUser-CloseModal"
              data-bs-toggle="modal"
              data-bs-target="#EditUser-ImageModal"
              >&#10005;</span
            >
            <div style="position: relative; margin-top: 20px">
              <img
                id="UserEdit-UserImagePreview"
                class="UserEdit-UserImage"
                :src="getAvatar()"
              />
            </div>
            <img id="dataImage" src="" style="display: none" />
            <div
              class="EditUser-ChangePasswordButton-blue"
              style="margin-top: 20px; margin-bottom: 10px"
            >
              <label style="display: inline-block; width: 100%">
                <input
                  id="ImageEditUser"
                  type="file"
                  name="image"
                  style="display: none"
                  accept="image/*"
                  @change="imgPreview()"
                />
                <p class="EditUser-ButtonText-blue">
                  {{ langFilter("select file") }}
                </p>
              </label>
            </div>

            <p class="UserEdit-ImageRules">
              [{{ langFilter("min size") }}: 160x160px
            </p>
            <p class="UserEdit-ImageRules">
              {{ langFilter("min weight") }}: 5Mb]
            </p>
            <div
              class="EditUser-ChangePasswordButton"
              style="margin-top: 40"
              @click="saveImgPreview()"
            >
              <p class="EditUser-ButtonText">
                {{ langFilter("save") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="EditUser-Top">
      <h2 class="UserEdit-Title">
        {{ langFilter("hi") }} {{ user_info.first_name }}
      </h2>
      <div style="position: relative">
        <img
          id="UserEdit-UserImage"
          class="UserEdit-UserImage"
          :src="getAvatar()"
        />
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 5px;
        "
      >
        <label
          style="display: inline-block"
          data-bs-toggle="modal"
          data-bs-target="#EditUser-ImageModal"
        >
          <p class="UserEdit-UserImageP">
            {{ langFilter("change image") }}
          </p>
        </label>
      </div>
      <p class="UserEdit-ImageRules">{{ langFilter("min size") }}: 160x160px</p>
      <p class="UserEdit-ImageRules">{{ langFilter("min weight") }}: 5Mb</p>
    </div>
    <div class="container row justify-content-md-center" style="margin: auto">
      <div class="col-lg-6 col-xl-4">
        <h5 class="EditUser-SubTitle">{{ langFilter("acces data") }}</h5>
        <p class="EditUser-InputTitle">{{ langFilter("user") }}*</p>
        <input
          id="Input-user_login"
          v-model="user_info.username"
          class="EditUser-InputBox"
          type="text"
          disabled="disabled"
        />
        <p class="EditUser-InputTitle">Email*</p>
        <input
          id="Input-user_email"
          v-model="user_info.email"
          class="EditUser-InputBox"
          type="email"
          disabled="disabled"
        />
        <p class="EditUser-InputTitle" name="password">
          {{ langFilter("password") }}*
        </p>
        <input
          id="Input-user_password"
          class="EditUser-InputBox"
          type="password"
          placeholder="******"
          disabled="disabled"
        />

        <div style="width: 100%; text-align: left">
          <label
            id="myBtn"
            class="EditUser-ModifyPassword"
            style=""
            @click="openModalPassword()"
            ><i>{{ langFilter("change password") }}</i></label
          >
        </div>

        <div v-if="user_info.deactivated_at">
          <p class="EditUser-InputTitle">
            {{ langFilter("deactivationDate") }}
          </p>
          <input
            class="EditUser-InputBox"
            :value="formattedDeactivationDate"
            disabled="disabled"
          />
        </div>

        <h5 class="EditUser-SubTitle" style="margin-top: 22px">
          {{ langFilter("notifications") }}
        </h5>
        <div style="display: flex; justify-content: center; margin-top: 10px">
          <label class="switch" style="margin-top: 4px">
            <input
              id="notificationsEditUser"
              v-model="user_info.notifications"
              type="checkbox"
              name="notification"
              true-value="0"
              false-value="1"
            />
            <span class="slider round"></span>
          </label>

          <p class="UserEdit-SwitchP">
            {{ langFilter("allow to get notifications") }}.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-xl-4" style="margin-bottom: 20px">
        <h5 class="EditUser-SubTitle">{{ langFilter("about you") }}</h5>
        <p class="EditUser-InputTitle">{{ langFilter("name") }}*</p>
        <input
          id="Input-user_firstname"
          v-model="user_info.first_name"
          :placeholder="langFilter('name')"
          class="EditUser-InputBox"
          name="firstname"
          type="text"
        />
        <p class="EditUser-InputTitle">{{ langFilter("surname") }}*</p>
        <input
          id="Input-user_lastname"
          v-model="user_info.last_name"
          :placeholder="langFilter('surname')"
          class="EditUser-InputBox"
          name="lastname"
          type="text"
        />
        <p class="EditUser-InputTitle">{{ langFilter("birth date") }}</p>
        <input
          id="Input-user_lastname"
          v-model="user_info.birth_date"
          :placeholder="langFilter('birth date')"
          class="EditUser-InputBox"
          name="lastname"
          type="date"
        />
        <p class="EditUser-InputTitle">{{ langFilter("phone number") }}</p>
        <input
          id="Input-user_lastname"
          v-model="user_info.phone"
          :placeholder="langFilter('phone number')"
          class="EditUser-InputBox"
          name="lastname"
          type="text"
        />
        <p class="EditUser-InputTitle">{{ langFilter("country") }}</p>
        <select
          id="Input-user_lastname"
          v-model="user_info.nationality"
          class="EditUser-InputBox"
          name="lastname"
          type="text"
        >
          <option
            v-for="(country, indexCountries) in countries"
            :key="indexCountries"
            :value="country.text"
          >
            {{ country.text }}
          </option>
        </select>
        <p class="EditUser-InputTitle">{{ langFilter("language") }}</p>
        <select
          id="Input-user_lastname"
          v-model="user_info.language"
          class="EditUser-InputBox"
          name="lastname"
          type="text"
        >
          <option
            v-for="(language, indexLanguages) in languages"
            :key="indexLanguages"
            :value="language.value"
          >
            {{ langFilter(language.name) }}
          </option>
        </select>
      </div>

      <div class="col-lg-6 col-xl-4" style="margin-bottom: 20px">
        <h5 class="EditUser-SubTitle">
          {{ langFilter("professional development") }}
        </h5>
        <p class="EditUser-InputTitle">{{ langFilter("company") }}</p>
        <input
          id="Input-user_company"
          v-model="user_info.company"
          :placeholder="langFilter('company')"
          class="EditUser-InputBox"
          name="company"
          type="text"
        />
        <p class="EditUser-InputTitle">
          {{ langFilter("knowledge area") }}
        </p>
        <div class="edit-profile-InfoIconContainer" style="margin-top: -20px">
          <img
            :src="require('@/assets/img/projects/InfoIcon.png')"
            class="littleImageProfile"
          />
          <div class="edit-profile-InfoIconHover">
            <p>
              Ex:
              <strong>
                {{ langFilter("food") }}
              </strong>
            </p>
          </div>
        </div>
        <input
          id="Input-user_knowledge_area"
          v-model="user_info.area"
          :placeholder="langFilter('knowledge area')"
          class="EditUser-InputBox"
          name="knowledge"
          type="text"
        />
        <p class="EditUser-InputTitle">{{ langFilter("expertise") }}</p>
        <div class="edit-profile-InfoIconContainer" style="margin-top: -20px">
          <img
            :src="require('@/assets/img/projects/InfoIcon.png')"
            class="littleImageProfile"
          />
          <div class="edit-profile-InfoIconHover">
            <p>
              Ex:
              <strong>
                {{ langFilter("vegan foods") }}
              </strong>
            </p>
          </div>
        </div>
        <input
          id="Input-user_expertise"
          v-model="user_info.speciality"
          :placeholder="langFilter('expertise')"
          class="EditUser-InputBox"
          name="expertise"
          type="text"
        />
        <p class="EditUser-InputTitle">{{ langFilter("calendar url") }}</p>
        <input
          id="Input-user_calendar"
          v-model="user_info.calendar"
          :placeholder="langFilter('calendar url')"
          class="EditUser-InputBox"
          name="calendar"
          type="text"
        />
      </div>
      <div class="col-12">
        <div class="EditUser-NextButton2" @click="saveUserInfo()">
          <p class="EditUser-ButtonText2">{{ langFilter("save") }}</p>
        </div>
      </div>
    </div>

    <alertMessage ref="alerts" :lang="newLang"></alertMessage>
  </div>
</template>

<script>
import moment from "moment";
import translationMixin from "../../mixins/translationMixin.js";
import { Modal } from "bootstrap";
import $ from "jquery";
import axios from "axios";
export default {
  components: {},
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newLang: this.lang,
      user_info: { avatar: "/img/general/loading.gif" },
      avatar: "",
      errors: [],
      succesful: [],

      languages: [
        {
          name: "spanish",
          value: "es",
        },
        {
          name: "english",
          value: "en",
        },
      ],
      countries: [
        { text: "Afghanistan", value: "AF" },
        { text: "Åland Islands", value: "AX" },
        { text: "Albania", value: "AL" },
        { text: "Algeria", value: "DZ" },
        { text: "American Samoa", value: "AS" },
        { text: "Andorra", value: "AD" },
        { text: "Angola", value: "AO" },
        { text: "Anguilla", value: "AI" },
        { text: "Antarctica", value: "AQ" },
        { text: "Antigua and Barbuda", value: "AG" },
        { text: "Argentina", value: "AR" },
        { text: "Armenia", value: "AM" },
        { text: "Aruba", value: "AW" },
        { text: "Australia", value: "AU" },
        { text: "Austria", value: "AT" },
        { text: "Azerbaijan", value: "AZ" },
        { text: "Bahamas", value: "BS" },
        { text: "Bahrain", value: "BH" },
        { text: "Bangladesh", value: "BD" },
        { text: "Barbados", value: "BB" },
        { text: "Belarus", value: "BY" },
        { text: "Belgium", value: "BE" },
        { text: "Belize", value: "BZ" },
        { text: "Benin", value: "BJ" },
        { text: "Bermuda", value: "BM" },
        { text: "Bhutan", value: "BT" },
        { text: "Bolivia", value: "BO" },
        { text: "Bosnia and Herzegovina", value: "BA" },
        { text: "Botswana", value: "BW" },
        { text: "Bouvet Island", value: "BV" },
        { text: "Brazil", value: "BR" },
        { text: "British Indian Ocean Territory", value: "IO" },
        { text: "Brunei Darussalam", value: "BN" },
        { text: "Bulgaria", value: "BG" },
        { text: "Burkina Faso", value: "BF" },
        { text: "Burundi", value: "BI" },
        { text: "Cambodia", value: "KH" },
        { text: "Cameroon", value: "CM" },
        { text: "Canada", value: "CA" },
        { text: "Cape Verde", value: "CV" },
        { text: "Cayman Islands", value: "KY" },
        { text: "Central African Republic", value: "CF" },
        { text: "Chad", value: "TD" },
        { text: "Chile", value: "CL" },
        { text: "China", value: "CN" },
        { text: "Christmas Island", value: "CX" },
        { text: "Cocos (Keeling) Islands", value: "CC" },
        { text: "Colombia", value: "CO" },
        { text: "Comoros", value: "KM" },
        { text: "Congo", value: "CG" },
        { text: "Congo, The Democratic Republic of the", value: "CD" },
        { text: "Cook Islands", value: "CK" },
        { text: "Costa Rica", value: "CR" },
        { text: "Cote D'Ivoire", value: "CI" },
        { text: "Croatia", value: "HR" },
        { text: "Cuba", value: "CU" },
        { text: "Cyprus", value: "CY" },
        { text: "Czech Republic", value: "CZ" },
        { text: "Denmark", value: "DK" },
        { text: "Djibouti", value: "DJ" },
        { text: "Dominica", value: "DM" },
        { text: "Dominican Republic", value: "DO" },
        { text: "Ecuador", value: "EC" },
        { text: "Egypt", value: "EG" },
        { text: "El Salvador", value: "SV" },
        { text: "Equatorial Guinea", value: "GQ" },
        { text: "Eritrea", value: "ER" },
        { text: "Estonia", value: "EE" },
        { text: "Ethiopia", value: "ET" },
        { text: "Falkland Islands (Malvinas)", value: "FK" },
        { text: "Faroe Islands", value: "FO" },
        { text: "Fiji", value: "FJ" },
        { text: "Finland", value: "FI" },
        { text: "France", value: "FR" },
        { text: "French Guiana", value: "GF" },
        { text: "French Polynesia", value: "PF" },
        { text: "French Southern Territories", value: "TF" },
        { text: "Gabon", value: "GA" },
        { text: "Gambia", value: "GM" },
        { text: "Georgia", value: "GE" },
        { text: "Germany", value: "DE" },
        { text: "Ghana", value: "GH" },
        { text: "Gibraltar", value: "GI" },
        { text: "Greece", value: "GR" },
        { text: "Greenland", value: "GL" },
        { text: "Grenada", value: "GD" },
        { text: "Guadeloupe", value: "GP" },
        { text: "Guam", value: "GU" },
        { text: "Guatemala", value: "GT" },
        { text: "Guernsey", value: "GG" },
        { text: "Guinea", value: "GN" },
        { text: "Guinea-Bissau", value: "GW" },
        { text: "Guyana", value: "GY" },
        { text: "Haiti", value: "HT" },
        { text: "Heard Island and Mcdonald Islands", value: "HM" },
        { text: "Holy See (Vatican City State)", value: "VA" },
        { text: "Honduras", value: "HN" },
        { text: "Hong Kong", value: "HK" },
        { text: "Hungary", value: "HU" },
        { text: "Iceland", value: "IS" },
        { text: "India", value: "IN" },
        { text: "Indonesia", value: "ID" },
        { text: "Iran, Islamic Republic Of", value: "IR" },
        { text: "Iraq", value: "IQ" },
        { text: "Ireland", value: "IE" },
        { text: "Isle of Man", value: "IM" },
        { text: "Israel", value: "IL" },
        { text: "Italy", value: "IT" },
        { text: "Jamaica", value: "JM" },
        { text: "Japan", value: "JP" },
        { text: "Jersey", value: "JE" },
        { text: "Jordan", value: "JO" },
        { text: "Kazakhstan", value: "KZ" },
        { text: "Kenya", value: "KE" },
        { text: "Kiribati", value: "KI" },
        { text: "Korea, Democratic People'S Republic of", value: "KP" },
        { text: "Korea, Republic of", value: "KR" },
        { text: "Kuwait", value: "KW" },
        { text: "Kyrgyzstan", value: "KG" },
        { text: "Lao People'S Democratic Republic", value: "LA" },
        { text: "Latvia", value: "LV" },
        { text: "Lebanon", value: "LB" },
        { text: "Lesotho", value: "LS" },
        { text: "Liberia", value: "LR" },
        { text: "Libyan Arab Jamahiriya", value: "LY" },
        { text: "Liechtenstein", value: "LI" },
        { text: "Lithuania", value: "LT" },
        { text: "Luxembourg", value: "LU" },
        { text: "Macao", value: "MO" },
        { text: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
        { text: "Madagascar", value: "MG" },
        { text: "Malawi", value: "MW" },
        { text: "Malaysia", value: "MY" },
        { text: "Maldives", value: "MV" },
        { text: "Mali", value: "ML" },
        { text: "Malta", value: "MT" },
        { text: "Marshall Islands", value: "MH" },
        { text: "Martinique", value: "MQ" },
        { text: "Mauritania", value: "MR" },
        { text: "Mauritius", value: "MU" },
        { text: "Mayotte", value: "YT" },
        { text: "Mexico", value: "MX" },
        { text: "Micronesia, Federated States of", value: "FM" },
        { text: "Moldova, Republic of", value: "MD" },
        { text: "Monaco", value: "MC" },
        { text: "Mongolia", value: "MN" },
        { text: "Montserrat", value: "MS" },
        { text: "Morocco", value: "MA" },
        { text: "Mozambique", value: "MZ" },
        { text: "Myanmar", value: "MM" },
        { text: "Namibia", value: "NA" },
        { text: "Nauru", value: "NR" },
        { text: "Nepal", value: "NP" },
        { text: "Netherlands", value: "NL" },
        { text: "Netherlands Antilles", value: "AN" },
        { text: "New Caledonia", value: "NC" },
        { text: "New Zealand", value: "NZ" },
        { text: "Nicaragua", value: "NI" },
        { text: "Niger", value: "NE" },
        { text: "Nigeria", value: "NG" },
        { text: "Niue", value: "NU" },
        { text: "Norfolk Island", value: "NF" },
        { text: "Northern Mariana Islands", value: "MP" },
        { text: "Norway", value: "NO" },
        { text: "Oman", value: "OM" },
        { text: "Pakistan", value: "PK" },
        { text: "Palau", value: "PW" },
        { text: "Palestinian Territory, Occupied", value: "PS" },
        { text: "Panama", value: "PA" },
        { text: "Papua New Guinea", value: "PG" },
        { text: "Paraguay", value: "PY" },
        { text: "Peru", value: "PE" },
        { text: "Philippines", value: "PH" },
        { text: "Pitcairn", value: "PN" },
        { text: "Poland", value: "PL" },
        { text: "Portugal", value: "PT" },
        { text: "Puerto Rico", value: "PR" },
        { text: "Qatar", value: "QA" },
        { text: "Reunion", value: "RE" },
        { text: "Romania", value: "RO" },
        { text: "Russian Federation", value: "RU" },
        { text: "RWANDA", value: "RW" },
        { text: "Saint Helena", value: "SH" },
        { text: "Saint Kitts and Nevis", value: "KN" },
        { text: "Saint Lucia", value: "LC" },
        { text: "Saint Pierre and Miquelon", value: "PM" },
        { text: "Saint Vincent and the Grenadines", value: "VC" },
        { text: "Samoa", value: "WS" },
        { text: "San Marino", value: "SM" },
        { text: "Sao Tome and Principe", value: "ST" },
        { text: "Saudi Arabia", value: "SA" },
        { text: "Senegal", value: "SN" },
        { text: "Serbia and Montenegro", value: "CS" },
        { text: "Seychelles", value: "SC" },
        { text: "Sierra Leone", value: "SL" },
        { text: "Singapore", value: "SG" },
        { text: "Slovakia", value: "SK" },
        { text: "Slovenia", value: "SI" },
        { text: "Solomon Islands", value: "SB" },
        { text: "Somalia", value: "SO" },
        { text: "South Africa", value: "ZA" },
        { text: "South Georgia and the South Sandwich Islands", value: "GS" },
        { text: "Spain", value: "ES" },
        { text: "Sri Lanka", value: "LK" },
        { text: "Sudan", value: "SD" },
        { text: "Suriname", value: "SR" },
        { text: "Svalbard and Jan Mayen", value: "SJ" },
        { text: "Swaziland", value: "SZ" },
        { text: "Sweden", value: "SE" },
        { text: "Switzerland", value: "CH" },
        { text: "Syrian Arab Republic", value: "SY" },
        { text: "Taiwan, Province of China", value: "TW" },
        { text: "Tajikistan", value: "TJ" },
        { text: "Tanzania, United Republic of", value: "TZ" },
        { text: "Thailand", value: "TH" },
        { text: "Timor-Leste", value: "TL" },
        { text: "Togo", value: "TG" },
        { text: "Tokelau", value: "TK" },
        { text: "Tonga", value: "TO" },
        { text: "Trinidad and Tobago", value: "TT" },
        { text: "Tunisia", value: "TN" },
        { text: "Turkey", value: "TR" },
        { text: "Turkmenistan", value: "TM" },
        { text: "Turks and Caicos Islands", value: "TC" },
        { text: "Tuvalu", value: "TV" },
        { text: "Uganda", value: "UG" },
        { text: "Ukraine", value: "UA" },
        { text: "United Arab Emirates", value: "AE" },
        { text: "United Kingdom", value: "GB" },
        { text: "United States", value: "US" },
        { text: "United States Minor Outlying Islands", value: "UM" },
        { text: "Uruguay", value: "UY" },
        { text: "Uzbekistan", value: "UZ" },
        { text: "Vanuatu", value: "VU" },
        { text: "Venezuela", value: "VE" },
        { text: "Viet Nam", value: "VN" },
        { text: "Virgin Islands, British", value: "VG" },
        { text: "Virgin Islands, U.S.", value: "VI" },
        { text: "Wallis and Futuna", value: "WF" },
        { text: "Western Sahara", value: "EH" },
        { text: "Yemen", value: "YE" },
        { text: "Zambia", value: "ZM" },
        { text: "Zimbabwe", value: "ZW" },
      ],
      //Traducción
      traducciones: [
        {
          name: "spanish",
          es: "Español",
          en: "Spanish",
        },
        {
          name: "english",
          es: "Inglés",
          en: "English",
        },
        {
          name: "hi",
          es: "Hola",
          en: "Hi",
        },
        {
          name: "change image",
          es: "Editar Imagen",
          en: "Change Image",
        },
        {
          name: "min size",
          es: "Tamaño min",
          en: "Min size",
        },
        {
          name: "min weight",
          es: "Peso máx",
          en: "Max weight",
        },
        {
          name: "acces data",
          es: "Datos de acceso",
          en: "Acces data",
        },
        {
          name: "user",
          es: "Usuario",
          en: "User",
        },
        {
          name: "profile",
          es: "Mi perfil",
          en: "My profile",
        },
        {
          name: "password",
          es: "Contraseña",
          en: "Password",
        },
        {
          name: "change password",
          es: "Modificar contraseña",
          en: "Change password",
        },
        {
          name: "deactivationDate",
          es: "Fecha de baja",
          en: "Deactivation date",
        },
        {
          name: "notifications",
          es: "Avisos y notificaciones",
          en: "Notifications",
        },
        {
          name: "allow to get notifications",
          es: "Recibir avisos y notificaciones por email",
          en: "Allow to get notifications",
        },
        {
          name: "about you",
          es: "Sobre ti",
          en: "About you",
        },
        {
          name: "name",
          es: "Nombre",
          en: "Name",
        },
        {
          name: "surname",
          es: "Apellidos",
          en: "Surname",
        },
        {
          name: "professional development",
          es: "Trayectoria",
          en: "Profesional development",
        },
        {
          name: "company",
          es: "Empresa",
          en: "Company",
        },
        {
          name: "knowledge area",
          es: "Área de conocimiento",
          en: "Knowledge area",
        },
        {
          name: "expertise",
          es: "Especialidad",
          en: "Major",
        },
        {
          name: "calendar url",
          es: "URL calendario",
          en: "Calendar URL",
        },
        {
          name: "update",
          es: "Actualizar",
          en: "Update",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "current password",
          es: "Contraseña actual",
          en: "Current password",
        },
        {
          name: "new password",
          es: "Nueva contraseña",
          en: "New password",
        },
        {
          name: "repeat password",
          es: "Repite la contraseña",
          en: "Repeat password",
        },
        {
          name: "select file",
          es: "Seleccionar archivo",
          en: "Select file",
        },
        {
          name: "Changed!",
          es: "¡Modificada!",
          en: "Changed!",
        },
        {
          name: "language",
          es: "Idioma",
          en: "Language",
        },
        {
          name: "country",
          es: "País",
          en: "Country",
        },
        {
          name: "phone number",
          es: "Número de teléfono",
          en: "Phone number",
        },
        {
          name: "birth date",
          es: "Fecha de nacimiento",
          en: "Birth date",
        },
        {
          name: "vegan foods",
          es: "Alimentos veganos",
          en: "Vegan foods",
        },
        {
          name: "food",
          es: "Alimentación",
          en: "Food",
        },
        {
          name: "The new password has been changed succesfully",
          es: "La nueva contraseña ha sido modificada con éxito.",
          en: "The new password has been changed succesfully.",
        },
      ],
    };
  },
  computed: {
    formattedDeactivationDate() {
      return this.user_info.deactivated_at
        ? moment(this.user_info.deactivated_at).format("DD/MM/YYYY")
        : "";
    },
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/edit-profile/get_user`)
      .then((response) => {
        this.user_info = response.data;
      });
    $(".carga").hide();
    $(".edit-profile-MainContainer").show();
  },
  mounted() {
    setTimeout(() => {
      this.$parent.loading = false;
    }, 500);
  },
  methods: {
    getAvatar() {
      if (this.user_info.avatar.includes("http")) {
        // remove blank spaces
        this.user_info.avatar = this.user_info.avatar.replace(/ /g, "%20");
        return this.user_info.avatar;
      } else {
        return process.env.VUE_APP_API_STORAGE + this.user_info.avatar;
      }
    },
    openModalPassword() {
      $("#lastPasswordEditUser").val("");
      $("#passwordEditUser").val("");
      $("#passwordConfirmEditUser").val("");

      var myModalPasswordModel = Modal.getOrCreateInstance(
        document.getElementById("EditUser-PasswordModal")
      );
      myModalPasswordModel.toggle();
    },
    imgPreview() {
      var image = document.getElementById("ImageEditUser");
      var imagePreview = document.getElementById("UserEdit-UserImagePreview");
      var dataImage = document.getElementById("dataImage");

      const [file] = image.files;
      if (file) {
        imagePreview.src = URL.createObjectURL(file);

        dataImage.src = URL.createObjectURL(file);
      }
    },
    saveImgPreview() {
      var imagePreview = document.getElementById("dataImage");
      var imageTongue = document.getElementById("modal_img_tongue");

      var imageUser = document.getElementById("UserEdit-UserImage");
      var size = $("#ImageEditUser")[0].files[0].size / 1024000;
      var width = imagePreview.width;
      var height = imagePreview.height;

      if (size <= 5 && width >= 160 && height >= 160) {
        this.avatar = document.getElementById("ImageEditUser").files[0];
        imageUser.src = imagePreview.src;
        imageTongue.src = imagePreview.src;

        this.saveUserInfo();
        var myModalPasswordModel = Modal.getOrCreateInstance(
          document.getElementById("EditUser-ImageModal")
        );
        myModalPasswordModel.toggle();
      } else {
        this.$refs.alerts.title = "Ups... Something went wrong!";
        this.$refs.alerts.text =
          "Try with an image with a size lower than 5MB and a minimum of 160x160 pixels.";
        this.openErrorsAlert();
      }
    },
    validatePasswords() {
      var password = $("#lastPasswordEditUser").val();
      var newpassword = $("#passwordEditUser").val();
      var confirmpassword = $("#passwordConfirmEditUser").val();
      var succesful = false;
      $("#lastPasswordEditUser").removeClass("newProject-InputValidation");
      $("#passwordEditUser").removeClass("newProject-InputValidation");
      $("#confirmPasswordEditUser").removeClass("newProject-InputValidation");
      var password_match = false;
      axios
        .post(`${process.env.VUE_APP_API_URL}/edit-profile/password_match`, {
          new_pass: password,
          current_pass: this.user_info.password,
        })
        .then((response) => {
          if (response.data == 1) {
            password_match = true;
          }
        });

      if (
        newpassword == confirmpassword &&
        password_match &&
        password !== "" &&
        newpassword !== ""
      ) {
        succesful = true;

        var myModalSuccessfulPassword = Modal.getOrCreateInstance(
          document.getElementById("EditUser-SuccesfulPasswordModal")
        );
        myModalSuccessfulPassword.toggle();

        var myModalPasswordModel = Modal.getOrCreateInstance(
          document.getElementById("EditUser-PasswordModal")
        );
        myModalPasswordModel.toggle();
      }

      if (succesful == true) {
        succesful = false;
        this.user_info.password = newpassword;
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/edit-profile/save_pass`,
            this.user_info
          )
          .then((response) => {
            this.user_info.password = response.data;
            $("#lastPasswordEditUser").val("");
            $("#passwordEditUser").val("");
            $("#passwordConfirmEditUser").val("");
          });
      } else {
        if (password == "") {
          $("#lastPasswordEditUser").addClass("newProject-InputValidation");
          this.$refs.alerts.title = "¡Incompleted fields!";
          this.$refs.alerts.text = "To continue you must fill all the fields.";
          this.openErrorsAlert();
        } else if (!password_match) {
          $("#lastPasswordEditUser").addClass("newProject-InputValidation");

          this.$refs.alerts.title = "Ups... Something went wrong!";
          this.$refs.alerts.text = "The password you entered do not match.";
          this.openErrorsAlert();
        } else if (newpassword == "") {
          $("#passwordEditUser").addClass("newProject-InputValidation");
          $("#confirmPasswordEditUser").addClass("newProject-InputValidation");
          this.$refs.alerts.title = "¡Incompleted fields!";
          this.$refs.alerts.text = "To continue you must fill all the fields.";
          this.openErrorsAlert();
        } else if (newpassword !== confirmpassword) {
          $("#passwordEditUser").addClass("newProject-InputValidation");
          $("#confirmPasswordEditUser").addClass("newProject-InputValidation");
          this.$refs.alerts.title = "¡Incompleted fields!";
          this.$refs.alerts.text = "The passwords you entered do not match.";
          this.openErrorsAlert();
        }
      }
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
          this.$refs.alerts.succesful = false;
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.$refs.alerts.succesful = false;
      this.errors = [];
    },
    saveUserInfo() {
      var nickname = $("#Input-user_nickname").val();
      var firstname = $("#Input-user_firstname").val();
      var lastname = $("#Input-user_lastname").val();
      var succesful = true;
      if (firstname.length == 0) {
        succesful = false;

        if (nickname.length == 0) {
          succesful = false;
          $("#Input-user_nickname").addClass("newProject-InputValidation");
        } else {
          $("#Input-user_nickname").removeClass("newProject-InputValidation");
        }

        $("#Input-user_firstname").addClass("newProject-InputValidation");
      } else {
        $("#Input-user_firstname").removeClass("newProject-InputValidation");
      }
      if (lastname.length == 0) {
        succesful = false;

        $("#Input-user_lastname").addClass("newProject-InputValidation");
      } else {
        $("#Input-user_lastname").removeClass("newProject-InputValidation");
      }

      if (succesful == true) {
        succesful = false;
        var avatar = "";
        if (this.avatar != "") {
          avatar = this.avatar;
        } else {
          avatar = "noimg";
        }
        var formData = new FormData();
        formData.append("file", avatar);
        formData.append("user", JSON.stringify(this.user_info));
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/edit-profile/save_user`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then(() => {
            if (this.lang != this.user_info.language) {
              window.location.reload();
              this.newLang = this.user_info.language;
            }
            this.$refs.alerts.succesful = true;
            this.$refs.alerts.title = "¡Updated profile!";
            this.$refs.alerts.text = "The profile has been updated succesfully";
            this.openErrorsAlert();
          })
          .catch(function () {});
      } else {
        this.$refs.alerts.title = "¡Incompleted fields!";
        this.$refs.alerts.text = "To continue you must fill all the fields.";
        this.openErrorsAlert();
      }
    },
  },
};
</script>
