<template>
  <div style="position: relative; z-index: 100">
    <div class="tool-search" style="position: absolute; top: 0px; right: 130px">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="tool-image-search"
      >
        <circle cx="8.5" cy="8" r="7" stroke="#301889" stroke-width="2" />
        <line
          x1="13.9142"
          y1="14"
          x2="18.5"
          y2="18.5858"
          stroke="#301889"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
      <input
        class="tool-search-input"
        type="text"
        :placeholder="langFilter('Search')"
        @focus="search_bar(true)"
        @blur="search_bar(false)"
        @keyup="search($event.target.value)"
      />
    </div>
    <RouterLink v-if="isAdmin" :to="'/new-track'">
      <div
        id="post-modal-button"
        class="tool-add"
        style="position: absolute; top: 0px; right: 80px"
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="tool-image"
        >
          <line
            x1="8.5"
            y1="1"
            x2="8.5"
            y2="15"
            stroke="#301889"
            stroke-width="2"
            stroke-linecap="round"
          />
          <line
            x1="15.5"
            y1="8"
            x2="1.5"
            y2="8"
            stroke="#301889"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </RouterLink>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
export default {
  mixins: [translationMixin],
  props: {
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    estilos: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["searchQuery"],
  data() {
    return {
      searching: false,
      searched: "",
      // traduccion
      traducciones: [
        {
          name: "buscar",
          es: "Buscar",
          en: "Search",
        },
      ],
    };
  },
  methods: {
    search_bar(show) {
      if (show) {
        $(".tool-search").addClass("search-big");
      } else {
        $(".tool-search").removeClass("search-big");
      }
    },
    startSearch() {
      this.searching = true;
      $("#categories-tracks").css("left", "0%");
    },
    stopSearch(value) {
      if (value == "") {
        this.searching = false;
        $("#categories-tracks").css("left", "8%");
      }
    },
    newTrack() {
      this.searching = false;
      $("#btn-search").css("margin-left", "35%");
      $("#track-new-btn").attr("src", "/img/track/new-track-btn.svg");
    },
    leftTrack() {
      if (this.searched != "") {
        this.searching = true;
      }
      $("#track-new-btn").attr("src", "/img/track/mini-add-btn.svg");
      $("#btn-search").css("margin-left", "35%");
      $("#categories-tracks").css("left", "8%");
    },
    search(value) {
      if (this.searching == true) {
        $("#categories-tracks").css("left", "0%");
      } else {
        $("#categories-tracks").css("left", "8%");
      }
      this.searched = value;
      this.$emit("searchQuery", value);
    },
  },
};
</script>
