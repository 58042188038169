<template>
  <div class="solution-first-container">
    <div
      v-if="!cdti"
      :class="[
        { 'solution-answer-map-opened': mapOpened },
        'solution-header-div',
      ]"
    >
      <div class="row">
        <div class="col-12 align-left">
          <h4
            v-if="firstSolutionInStation"
            style="font-size: 26px; margin-bottom: 25px"
            class="title-station"
          >
            {{ stationName }}
          </h4>
          <p class="title-station">{{ solutionName }}</p>
        </div>
      </div>
    </div>
    <div v-if="answers.length > 0">
      <solution-write
        v-for="(answer, answerIndex) in answers"
        :key="answerIndex"
        :answers="answers"
        :answer="answer"
        :loadedPrev="loadedPrev"
        :role="role"
        :mapOpened="mapOpened"
        :edited="edited"
        :answerIndex="answerIndex"
        :answerIndex2="answerIndex2"
        :projectId="projectId"
        :solutionId="mySolution"
        :lang="lang"
        :cdti="cdti"
        :spaceId="spaceId"
        :assistants="assistants"
        @edited-solution="editedSolution($event)"
        @error-file-upload="errorFileUpload"
        @reload-percentage="reloadPercentage"
        @update-send-to-correct="updateSendToCorrect"
        @prepare-answers="prepareAnswers($event)"
        @reload-chat-gpt="reloadChatGpt()"
      />
    </div>
    <div v-else-if="!loading">
      <div v-if="!checkIfLengthIs0()" class="div-101">
        <h4
          class="solution-no-results"
          :class="{ 'solution-answer-map-opened': mapOpened }"
        >
          {{ langFilter("noResults") }}
        </h4>
      </div>
    </div>
    <div
      v-if="!$parent.initialLoadState"
      :class="[{ 'solution-answer-map-opened': mapOpened }, 'div-center']"
    >
      <div v-if="!cdti" class="solutions-loading-gif-div">
        <img
          class="solutions-loading-gif-container"
          :src="require('@/assets/img/general/loading.gif')"
        />
      </div>
    </div>
    <div class="solution-component-footer" />
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import SolutionWrite from "./SolutionWriteComponent.vue";
export default {
  components: {
    SolutionWrite,
  },
  mixins: [translationMixin],
  props: {
    answers: {
      type: Array,
      required: true,
    },
    mapOpened: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    loadingMoreSolutions: {
      type: Boolean,
      required: true,
    },
    answerIndex2: {
      type: Number,
      required: true,
    },
    solutionName: {
      type: String,
      required: true,
    },
    loadedPrev: {
      type: Boolean,
      required: true,
    },
    cdti: {
      type: Boolean,
      required: true,
    },
    spaceId: {
      type: Number,
      required: true,
    },
    allStations: {
      type: Array,
      required: true,
    },
    allSolutions: {
      type: Array,
      required: true,
    },
    currentSolutions: {
      type: Array,
      required: true,
    },
    stationId: {
      type: Number,
      required: true,
    },
    assistants: {
      type: Array,
      required: true,
    },
  },
  emits: [
    "repositionMap",
    "reloadPercentage",
    "updateSendToCorrect",
    "sendCorrection",
    "editedSolution",
    "errorFileUpload",
    "prepareAnswers",
    "reloadChatGpt",
  ],
  data() {
    return {
      edited: false,
      prevIndex: 0,
      currentSolution: "",
      loadingMore: false,
      stationName: "",
      firstSolutionInStation: false,
      traducciones: [
        {
          name: 1,
          es: "Pendiente",
          en: "Pending",
        },
        {
          name: "noResults",
          es: "No hay preguntas asociadas a este apartado",
          en: "There are no questions in this section",
        },
      ],
    };
  },
  created() {
    if (this.answers.length > 0) {
      this.currentSolution = this.answers[0].solutionId;
      this.mySolution = this.answers[0].solutionId;
    }
  },
  mounted() {
    // Check if allSolutions is defined and is an array before proceeding
    if (Array.isArray(this.allSolutions) && this.allSolutions.length > 0) {
      // Proceed with grouping solutions by station_id
      const agrupados = this.allSolutions.reduce((resultado, solution) => {
        if (!resultado[solution.station_id]) {
          resultado[solution.station_id] = [];
        }
        resultado[solution.station_id].push(solution);
        return resultado;
      }, {});

      const solutionByStation = Object.values(agrupados);

      if (this.currentSolution) {
        const subArreglo = solutionByStation.find((sub) =>
          sub.some((objeto) => objeto.id === this.currentSolution)
        );

        if (subArreglo) {
          const posicion = subArreglo.findIndex(
            (objeto) => objeto.id === this.currentSolution
          );
          this.firstSolutionInStation = posicion === 0;
          this.station_name =
            this.allStations.find(
              (station) => station.id === subArreglo[posicion].station_id
            )?.name || "";
        }
      }
    } else {
      // Handle the case where allSolutions is not available or empty
      console.error("allSolutions is not defined or is empty.");
    }

    // Perform additional actions
    if (!this.cdti) {
      this.scrollMap();
    }

    // Wait 1 second to restore scroll position
    setTimeout(() => {
      this.$parent.initialLoadState = true;
    }, 1000);

    if (this.$parent.checkIfScrollTop()) {
      this.restoreScrollPosition();
    }
  },
  methods: {
    reloadChatGpt() {
      this.$emit("reloadChatGpt");
    },
    restoreScrollPosition() {
      const height = document.getElementById("scrollContainer-0").offsetHeight;
      window.scrollTo({
        top: height,
        left: 0,
        behavior: "instant",
      });
    },
    checkIfLengthIs0() {
      if (this.answers != undefined) {
        if (this.answers.length > 0) {
          return true;
        }
      }
      return false;
    },
    scrollMap() {
      try {
        if (this.currentSolution && this.currentSolution.length > 0) {
          const element = document.getElementById(
            `solution-border${this.currentSolution[0]}`
          );

          if (!element) {
            return;
          }

          element.scrollIntoView(true);
        }
      } catch (e) {
        console.error(e);
      }
    },
    repositionMap(answerIndex) {
      this.currentSolution = [
        this.answers[0].solutionId,
        this.answers[answerIndex].question_id,
      ];
      this.scrollMap();
      this.$emit("repositionMap", this.currentSolution);
    },
    reloadPercentage() {
      this.$emit("reloadPercentage");
    },
    updateSendToCorrect() {
      this.$emit("updateSendToCorrect");
    },
    sendCorrection() {
      this.$emit("sendCorrection");
    },
    editedSolution(data) {
      this.$emit("editedSolution", data);
    },
    errorFileUpload() {
      this.$emit("errorFileUpload");
    },
    prepareAnswers(data) {
      this.$emit("prepareAnswers", data);
    },
  },
};
</script>
