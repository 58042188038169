<template>
  <div>
    <span class="newItinerary-user-title"
      ><b>{{ langFilter("title") }}</b></span
    >
    <div id="editor" style="margin: 0 30px">
      <div id="edit" style="margin-top: 30px">
        <div>{{ information }}</div>
      </div>
    </div>
  </div>
  <div class="newItinerary-savedInfo">
    {{ saved ? langFilter("saved") : langFilter("saving") }}
  </div>
</template>

<script>
import $ from "jquery";
import FroalaEditor from "../../../node_modules/froala-editor/js/froala_editor.pkgd.min.js";
import translationMixin from "@/mixins/translationMixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NewProjectInfo",
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    syllabus: {
      type: String,
      required: true,
    },
  },
  emits: ["updateSyllabus"],
  data() {
    return {
      information: "",
      savingInfo: "",
      proj_id: "",
      saved: true,
      froala: null,
      traducciones: [
        {
          name: "title",
          es: "INFORMACIÓN",
          en: "INFORMATION",
        },
        {
          name: "saved",
          es: "Guardado",
          en: "Saved",
        },
        {
          name: "saving",
          es: "Guardando",
          en: "Saving",
        },
        {
          name: "information",
          es: "Escribe la información aqui",
          en: "Write information here",
        },
        {
          name: "noInfo",
          es: "Este proyecto no tiene información disponible",
          en: "This project has no information available",
        },
      ],
    };
  },
  watch: {},
  created() {
    this.onCreated();
    if (this.syllabus) {
      this.information = this.syllabus;
    }
  },
  mounted() {
    this.initFroala();
  },
  methods: {
    saveInfo() {
      let information = $("#froala-info").text();
      information = information.split("ColorClear Formatting")[1];
      this.information = "<p>" + information + "</p>";
    },
    initFroala() {
      const _token = $('meta[name="csrf-token"]').attr("content");
      new FroalaEditor("#edit", {
        placeholderText: this.langFilter("information"),
        fontFamilyDefaultSelection: "Poppins",
        height: "calc(59vh - 60px)",
        attribution: false,
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting",
            ],
          },
          moreParagraph: {
            buttons: ["outdent", "indent"],
          },
          moreRich: {
            buttons: ["insertLink", "insertImage", "insertFile"],
          },
          moreMisc: {
            buttons: ["undo", "redo"],
            align: "right",
            buttonsVisible: 2,
          },
        },
        linkAlwaysBlank: true,
        wordPasteModal: false,
        quickInsertButtons: ["image", "link", "file"],
        key: process.env.VUE_APP_FROALA_KEY,
        imageUploadMethod: "POST",
        imageUploadParams: {
          _token: _token,
        },
        imageAllowedTypes: ["jpeg", "jpg", "png"],
        imageUploadURL: process.env.VUE_APP_API_URL + "/froala/upload/img",
        fileUploadMethod: "POST",
        fileUploadParams: {
          _token: _token,
        },
        fileUploadURL: process.env.VUE_APP_API_URL + "/froala/upload/files",
        videoUploadMethod: "POST",
        videoUploadParams: {
          _token: _token,
        },
        videoUploadURL: process.env.VUE_APP_API_URL + "/froala/upload/videos",
        saveInterval: 1000,
        saveParam: "content",
        saveURL: process.env.VUE_APP_API_URL + "/newProjectV2/autosave",
        saveMethod: "POST",
        saveParams: {
          _token: _token,
        },
        events: {
          "froala.initialized": function (e, editor) {
            editor.edit.off();
          }.bind(this),
          "save.before": function () {
            this.saved = false;
            this.savingInfo = this.information;
          }.bind(this),
          "save.after": function (request) {
            this.saved = true;
            this.$emit("updateSyllabus", request);
          }.bind(this),
          "save.error": function () {
            // console.log("error");
          },
        },
      });
    },
    onCreated() {},
  },
});
</script>

<style lang="scss">
@import "../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
</style>
