<template>
  <div class="modal-input-row">
    <div
      v-for="(value, index) in values"
      :key="index"
      class="modal-input-col"
      style="max-width: 1px"
    >
      <label class="modal-NumberInput">{{ value.number }}</label
      ><label class="modal-TextInput">{{ value.title }}</label>
      <p class="modal-input-info">{{ value.info }}</p>
      <modal-type-input
        style="margin-top: 28px"
        :lang="lang"
        :name="value.name"
        :type="value.type"
        :placeholder="value.placeholder"
        :options="value.options"
      ></modal-type-input>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      //Traducción
      traducciones: [],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
