<template>
  <div>
    <div
      :id="id"
      class="itinerary-eachRow row-itinerary question-border-1px no-selection-itinerary"
      style="margin-right: 0; padding-top: 8px"
      @mouseover="passmousequestion()"
      @mouseout="outmousequestion()"
    >
      <div class="first-question-row-itinerary">
        <div
          v-if="not"
          :id="'notification' + id"
          class="notification-itinerary"
        ></div>
        {{ name }}
      </div>
      <div class="second-question-row-itinerary">
        <img v-if="time != '00:00:00'" src="/img/itinerario/clock.svg" />
        <span v-if="time != '00:00:00'" class="text-minutes-itinerary">{{
          time
        }}</span>
      </div>
      <div
        class="question-row-itinerary text-enseña-datos-tabla-itinerary"
        style="display: flex; justify-content: center; align-items: center"
      >
        <div
          class="itinerary-advanced-by"
          style="margin-top: -10px"
          :style="
            isCompleted !== null
              ? 'background-color: #13C99E'
              : 'background-color: #B4BAC5'
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
export default {
  name: "ItineraryQuestionTableComponent",
  mixins: [translationMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    questionType: {
      type: Number,
      required: true,
    },
    notification: {
      type: Boolean,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    solutionId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      not: this.notification,
      traducciones: [
        {
          name: "archivo",
          es: "Respuesta de archivo",
          en: "File answer",
        },
        {
          name: "texto",
          es: "Respuesta de texto",
          en: "Text answer",
        },
        {
          name: "unica",
          es: "Respuesta única",
          en: "Unique answer",
        },
        {
          name: "multiple",
          es: "Respuesta múltiple",
          en: "Multiple answer",
        },
      ],
    };
  },
  created() {
    if (this.time == "00:00:00") {
      //    this.time = null;
    }
  },
  mounted() {},
  methods: {
    passmousequestion() {
      $("#" + this.id)
        .addClass("pass-mouse-in-div-itinerary")
        .removeClass("question-border-1px");
    },
    outmousequestion() {
      $("#" + this.id)
        .removeClass("pass-mouse-in-div-itinerary")
        .addClass("question-border-1px");
    },
    checkQuestionType() {
      switch (this.questionType) {
        case 0:
          return "/img/itinerario/logo_file.svg";
        case 1:
          return "/img/itinerario/logo_text.svg";
        case 5:
          return "/img/itinerario/logo_single.svg";
        case 10:
          return "/img/itinerario/logo_multiple.svg";
      }
    },
    showhover() {
      $("#hover_question" + this.id).show();
    },
    hidehover() {
      $("#hover_question" + this.id).hide();
    },
    showTextHover() {
      switch (this.questionType) {
        case 0:
          return this.langFilter("archivo");
        case 1:
          return this.langFilter("texto");
        case 5:
          return this.langFilter("unica");
        case 10:
          return this.langFilter("multiple");
      }
    },
  },
};
</script>
