<script setup>
import CdtiModal from "../components/cdti/CdtiModalComponent.vue";
</script>

<template>
  <CdtiModal :lang="lang" />
</template>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
@import "../../src/assets/css/cdti.css";
</style>
