<template>
  <div class="modal-general-mask" @click="closeModal">
    <div class="modal-general-wrapper">
      <div class="modal-send-correction-container div-center">
        <div class="modal-send-correction-header">
          <p class="modal-send-correction-close-btn">x</p>
        </div>
        <div class="modal-send-correction-body">
          <p>
            <span v-if="isTutor">
              {{ langFilter("sendCorrection") }}
            </span>
            <span v-else>
              {{ langFilter("sendToCorrect") }}
            </span>
          </p>
          <div class="send-correction-observations-container">
            <label class="send-correction-observations-title">
              {{ langFilter("observations") }}
            </label>
            <textarea
              v-model="observations"
              class="send-correction-observations-textarea"
              :placeholder="langFilter('observationsPlaceholder')"
            ></textarea>
          </div>
        </div>
        <div class="modal-send-correction-footer">
          <div class="modal-send-correction-footer-buttons">
            <button
              class="modal-send-correction-cancel-button"
              @click="closeModal"
            >
              {{ langFilter("cancel") }}
            </button>
            <button
              class="modal-send-correction-footer-button"
              @click="sendCorrection"
            >
              {{ langFilter("send") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
import { ROLES_IN_PROJECT } from "@/constants/index.js";
const { TUTOR } = ROLES_IN_PROJECT;

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    stationId: {
      type: Number,
      required: true,
    },
    solutionId: {
      type: Number,
      required: true,
    },
    solutionName: {
      type: String,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    spaceId: {
      type: Number,
      required: true,
    },
  },
  emits: ["closeModal"],
  data() {
    return {
      observations: "",
      traducciones: [
        {
          name: "observations",
          es: "Observaciones",
          en: "Observations",
        },
        {
          name: "observationsPlaceholder",
          es: "Añade tus observaciones aquí si lo deseas...",
          en: "Add your observations here if you wish...",
        },
        {
          name: "sendCorrection",
          es: "Los miembros del proyecto recibirán tus comentarios.",
          en: "Members of the project will receive your feedback.",
        },
        {
          name: "sendToCorrect",
          es: "El tutor recibirá la solicitud de revisión del proyecto",
          en: "The tutor will receive the request to review the project",
        },
        {
          name: "cancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "send",
          es: "Sí, continuar",
          en: "Yes, continue",
        },
      ],
    };
  },
  computed: {
    isTutor() {
      return this.role === TUTOR;
    },
  },
  methods: {
    closeModal(el) {
      if (
        el.target.className == "modal-general-wrapper" ||
        el.target.className == "modal-send-correction-cancel-button" ||
        el.target.className == "modal-send-correction-close-btn"
      ) {
        this.$emit("closeModal", false);
      }
    },
    sendCorrection() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/solution/sendCorrection`, {
          project_id: this.projectId,
          station_id: this.stationId,
          solution_id: this.solutionId,
          space_id: this.spaceId,
          observations: this.observations,
          role: this.role,
        })
        .catch((error) => {
          console.error("Error al enviar la corrección:", error);
        })
        .finally(() => {
          this.$emit("closeModal", true);
        });
    },
  },
};
</script>
