<template>
  <div class="padding-y-28">
    <div v-show="showMessageHover" class="chat-info-hover">
      {{ messageInfoHover }}
    </div>
    <span
      style="cursor: pointer"
      @mouseenter="setMessageHover(true)"
      @mouseleave="setMessageHover(false)"
    >
      <span v-html="messageHeader"></span>
      <a style="display: inline-block" @click="$emit('headerAction')">{{
        langFilter("linkConfigAssistant")
      }}</a>
    </span>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    messageHeader: {
      type: String,
      required: true,
    },
    messageInfoHover: {
      type: String,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  emits: ["headerAction"],
  data() {
    return {
      showMessageHover: false,
      traducciones: [
        {
          name: "linkConfigAssistant",
          es: "haz clic aquí",
          en: "click here",
        },
      ],
    };
  },
  methods: {
    setMessageHover(value) {
      this.showMessageHover = value;
    },
  },
};
</script>
