<template>
  <div>
    <!-- Modal -->
    <div
      id="modalConfirmation"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalConfirmation"
      aria-hidden="true"
      style="z-index: 9999999999999999999999999999999999999999"
    >
      <div
        class="modal-dialog"
        role="document"
        style="width: 373px; height: 212px; margin-top: calc(9%)"
      >
        <div class="modal-content confirmation-container z-index-top">
          <div>
            <div
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                :src="require('@/assets/img/projects/modal_X.png')"
                aria-hidden="true"
                class="confirmation-close"
              />
            </div>
            <div class="confirmation-texts">
              <p v-if="confirmationMessage2 !== ''">
                {{ langFilter(confirmationMessage) }}
                {{ variable }}
              </p>
              <p v-if="confirmationMessage2 !== ''">
                {{ langFilter(confirmationMessage2) }}
                {{ variable }}
              </p>
              <p
                v-if="confirmationMessage2 == ''"
                class="confirmation-unique-text"
              >
                {{ langFilter(confirmationMessage) }}
                {{ variable }}
              </p>
            </div>
            <div class="confirmation-buttons">
              <button
                v-if="confirmationButtonText2 !== ''"
                class="invisible-button"
                @click="$parent.confirmTrackCancel()"
              >
                {{ langFilter(confirmationButtonText) }}
              </button>
              <button
                v-if="confirmationButtonText2 !== ''"
                @click="$parent.confirmTrackContinue()"
              >
                {{ langFilter(confirmationButtonText2) }}
              </button>
              <button
                v-if="confirmationButtonText2 == ''"
                @click="$parent.confirmTrackNoOptions()"
              >
                {{ langFilter(confirmationButtonText) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmationMessage: "Al editar esta plantilla se creara un nuevo Track",
      confirmationMessage2: "¿Esta seguro de querer continuar?",
      confirmationButtonText: "Cancelar",
      confirmationButtonText2: "Continuar",
      variable: "",

      traducciones: [
        {
          name: "Al editar esta plantilla se creara un nuevo Track",
          es: "Al editar esta plantilla se creará un nuevo Track",
          en: "If you edit this template new Track will be created",
        },
        {
          name: "¿Esta seguro de querer continuar?",
          es: "¿Esta seguro de querer continuar?",
          en: "Are you sure you want continue?",
        },
        {
          name: "Cancelar",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "Progress",
          es: "Al salir de esta pantalla se perderá el progreso.",
          en: "Closing this screen will lose progress.",
        },
        {
          name: "Sure",
          es: "¿Está seguro de querer salir?",
          en: "Are you sure you want to exit?",
        },
        {
          name: "Continuar",
          es: "Continuar",
          en: "Continue",
        },

        // delete question

        {
          name: "¿Está seguro de querer eliminar la pregunta?",
          es: "¿Está seguro de querer eliminar la pregunta?",
          en: "Are you sure you want to delete the question?",
        },

        {
          name: "¿Está seguro de querer eliminar el apartado?",
          es: "¿Está seguro de querer eliminar el apartado?",
          en: "Are you sure you want to delete the section?",
        },

        {
          name: "¿Está seguro de querer eliminar el tema?",
          es: "¿Está seguro de querer eliminar el tema?",
          en: "Are you sure you want to delete the chapter?",
        },

        // track exist

        {
          name: "El nombre del Track ya existe, prueba a insertar otro nombre.",
          es: "El nombre del Track ya existe, prueba a insertar otro nombre.",
          en: "Track name exist, try to enter other name.",
        },
        {
          name: "Texto",
          es: "Continuar",
          en: "Continue",
        },
        {
          name: "question",
          es: "",
          en: "",
        },

        // back modal

        {
          name: "Atras",
          es: 'Al volver "Atras" se perderan todas las modificaciones aplicadas a este Track.',
          en: 'If you go "Back", you will lose all your modifications applyed to this Track.',
        },
        {
          name: "¿Está seguro de querer volver atrás?",
          es: "¿Está seguro de querer volver atrás?",
          en: "Are you sure you want to go back?",
        },
        // Translation cancel exist
        {
          name: "Si, atrás",
          es: "Si, atrás",
          en: "Yes, back",
        },

        {
          name: "Si, salir",
          es: "Si, salir",
          en: "Yes, exit",
        },

        {
          name: "Si, Eliminar",
          es: "Si, Eliminar",
          en: "Yes, Delete",
        },

        // Change tab

        {
          name: "Perderá todos los datos rellenados.",
          es: "Perderá todos los datos rellenados.",
          en: "You will lose all the entered info",
        },

        {
          name: "Si, cambiar",
          es: "Si, cambiar",
          en: "Yes, change",
        },
        {
          name: "yes, continue",
          es: "Si",
          en: "Yes",
        },
        {
          name: "Are you sure you want to delete this topic?",
          es: "¿Estás seguro de querer eliminar este tema?",
          en: "Are you sure you want to delete this topic?",
        },
        {
          name: "Are you sure you want to delete this subtopic?",
          es: "¿Estás seguro de querer eliminar este subtema?",
          en: "Are you sure you want to delete this subtopic?",
        },
        {
          name: "Are you sure you want to delete this message?",
          es: "¿Estás seguro de querer eliminar este mensaje?",
          en: "Are you sure you want to delete this message?",
        },

        //Delete user
        {
          name: "Are you sure you want to remove Name Surname?",
          es: "¿Estás seguro de querer eliminar a ",
          en: "Are you sure you want to remove the user ",
        },
        {
          name: "yes, delete",
          es: "Si, eliminar",
          en: "Yes, delete",
        },
        //Delete New track
        {
          name: "Are you sure you want to delete this question?",
          es: "¿Estás seguro de querer eliminar esta pregunta?",
          en: "Are you sure you want to delete this question?",
        },
        {
          name: "Are you sure you want to delete this part?",
          es: "¿Estás seguro de querer eliminar este apartado?",
          en: "Are you sure you want to delete this part?",
        },
        {
          name: "Are you sure you want to delete this theme?",
          es: "¿Estás seguro de querer eliminar este tema?",
          en: "Are you sure you want to delete this theme?",
        },
        {
          name: "Está seguro que desea eliminar la plantilla?",
          es: "Está seguro que desea eliminar la plantilla?",
          en: "Are you sure you want to delete the template?",
        },
        {
          name: "No saved changes will be lost",
          es: "Se perderán los datos no guardados. ¿Deseas continuar?",
          en: "No saved changes will be lost. Do you want to continue?",
        },
        //new-projectV2
        {
          name: "¿Está seguro que desea eliminar el tema?",
          es: "¿Está seguro que desea eliminar el tema?",
          en: "Are you sure you want to delete the chapter?",
        },
      ],
    };
  },
  created() {},

  methods: {},
};
</script>

<style lang="scss">
@import "../../src/assets/css/confirmation.css";
</style>
