<template>
  <div class="row margin-screens-ap">
    <div class="col-3"></div>
    <div class="col-9">
      <nav class="min-height-nav-ap">
        <ul class="ul-adminpanel">
          <li
            v-for="(screen, screenIndex) in screens"
            :key="screenIndex"
            :class="{
              'active-li-adminpanel': $parent.screen_id === screenIndex,
            }"
            class="li-adminpanel"
            @click="$parent.screen_id = screenIndex"
          >
            {{ langFilter(screen) }}
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {},
  data() {
    return {
      screens: [
        //"home",
        //"users",
        //"permissions",
        //"personalization",
        //"dashboards",
      ],
      traducciones: [
        {
          name: "home",
          es: "Home",
          en: "Home",
        },
        {
          name: "users",
          es: "Usuarios",
          en: "Users",
        },
        {
          name: "permissions",
          es: "Permisos",
          en: "Permissions",
        },
        {
          name: "personalization",
          es: "Personalización",
          en: "Personalization",
        },
        {
          name: "dashboards",
          es: "Dashboards",
          en: "Dashboards",
        },
      ],
    };
  },
};
</script>
