<template>
  <div>
    <header-general
      :title="langFilter('title_my_projects_screen')"
      src="/img/sidebar/icon–projects.svg"
    />
    <div class="myProjects-MainContainer">
      <div
        id="modal-copy-number"
        class="copyProjects-modals-container modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div
          class="modal-dialog modal-xl modal-dialog-centered copyProjects-modal-vertical"
        >
          <div
            id="modal-number-of-copies"
            class="modal-content copyProjects-modal-number-projects"
            style="width: 423px; height: 251px"
          >
            <svg
              class="close-button"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <path
                d="M9.5 9.22922C9.20761 9.50643 8.73356 9.50643 8.44118 9.22922L5.03529 6.00005L1.63824 9.22085C1.32148 9.52117 0.807928 9.52117 0.491177 9.22085C0.174426 8.92053 0.174425 8.43363 0.491177 8.13331L3.88824 4.91251L0.499999 1.70008C0.207613 1.42287 0.207614 0.973412 0.5 0.696197C0.792386 0.418982 1.26644 0.418982 1.55882 0.696197L4.94706 3.90863L8.34412 0.687831C8.66087 0.387515 9.17443 0.387516 9.49118 0.687832C9.80793 0.988148 9.80793 1.47506 9.49118 1.77537L6.09412 4.99617L9.5 8.22533C9.79239 8.50255 9.79239 8.952 9.5 9.22922Z"
                fill="#B7AED6"
              />
            </svg>

            <div class="confirmation-texts">
              <p>{{ langFilter("URL Gestor de tareas") }}:</p>
              <input
                id="url-gestor-tareas"
                v-model="urlTaskManager"
                style="
                  position: relative;
                  bottom: 23px;
                  width: 100%;
                  text-align: left;
                  padding: 0 10px;
                "
                type="text"
              />
              <span
                v-if="errorUrl"
                style="color: red; bottom: 10px; position: relative"
                >{{ langFilter("validUrl") }}</span
              >
              <br />
            </div>
            <div class="confirmation-buttons">
              <button
                id="cancel-copy-button"
                class="invisible-button"
                data-bs-toggle="modal"
                data-bs-target="#modal-copy-number"
                @click="cancelCopyModal()"
              >
                {{ langFilter("cancel") }}
              </button>
              <button id="confirm-copy-button" @click="setTaskManager()">
                {{ langFilter("continue") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="modal-copy-projects"
        class="copyProjects-modals-container modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog modal-xl copyProjects-modal-vertical">
          <div
            id="modal-copy-main"
            class="modal-content copyProjects-modal-copy"
            style="width: calc(100vw - 128px); height: 507px"
          >
            <svg
              class="close-button"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="openModalConfirmation()"
            >
              <path
                d="M9.5 9.22922C9.20761 9.50643 8.73356 9.50643 8.44118 9.22922L5.03529 6.00005L1.63824 9.22085C1.32148 9.52117 0.807928 9.52117 0.491177 9.22085C0.174426 8.92053 0.174425 8.43363 0.491177 8.13331L3.88824 4.91251L0.499999 1.70008C0.207613 1.42287 0.207614 0.973412 0.5 0.696197C0.792386 0.418982 1.26644 0.418982 1.55882 0.696197L4.94706 3.90863L8.34412 0.687831C8.66087 0.387515 9.17443 0.387516 9.49118 0.687832C9.80793 0.988148 9.80793 1.47506 9.49118 1.77537L6.09412 4.99617L9.5 8.22533C9.79239 8.50255 9.79239 8.952 9.5 9.22922Z"
                fill="#B7AED6"
              />
            </svg>

            <p id="original-copy-name" class="copyProjects-modal-copy-header">
              {{ langFilter("copies of") }}:
              <strong>“{{ copyProject.project_name }}”</strong>
            </p>

            <div class="copyProjects-modal-project-main-container">
              <div class="row copyProjects-modal-project-header-container">
                <div class="col-4 copyProjects-table-header">
                  <p>{{ langFilter("projects name") }}</p>
                </div>
                <div class="col-2 copyProjects-table-header">
                  <p>{{ langFilter("template") }}</p>
                </div>
                <div class="col-2 copyProjects-table-header">
                  <p>{{ langFilter("program") }}</p>
                </div>
                <div class="col-2 copyProjects-table-header">
                  <p>{{ langFilter("tutor") }}</p>
                </div>
                <div class="col-2 copyProjects-table-header">
                  <p>{{ langFilter("members") }}</p>
                </div>
              </div>
              <div
                v-for="(copy, index) in copyProjects"
                :id="'copy-container-' + index"
                :key="index"
                class="copyProjects-modal-project-container row"
              >
                <div
                  class="col-4 copyProjects-modal-copy-project-name"
                  style="height: 38px"
                >
                  <input
                    :id="'copy-name-' + index"
                    v-model="copy.project"
                    class="copyProjects-invisible-input"
                    :placeholder="langFilter('Insert the project name')"
                  />
                </div>
                <div
                  :id="'copy-template-' + index"
                  class="col-2 copyProjects-modal-copy-text"
                  style="height: 38px"
                >
                  <p>{{ copy.typology }}</p>
                </div>
                <div
                  :id="'copy-program-' + index"
                  class="col-2 copyProjects-modal-copy-text"
                  style="height: 38px"
                >
                  <p>{{ copy.program }}</p>
                </div>
                <div
                  :id="'copy-tutor-' + index"
                  class="col-2 copyProjects-modal-copy-text"
                  style="height: 38px"
                >
                  <p style="display: inline-block">
                    <img :src="myUser.avatar" style="display: inline-block" />{{
                      myUser.name
                    }}
                  </p>
                </div>
                <div
                  v-if="copy.users.length > 0"
                  :id="'copy-members-' + index"
                  class="col-2 copyProjects-modal-copy-members-text"
                >
                  <div
                    v-for="(user, index_user) in copy.users"
                    :key="index_user"
                    class="copyProjects-modal-user-image-container"
                    :style="'z-index:' + (5 - index_user) + '!important;'"
                    @click="usersCopyModal(index)"
                  >
                    <img
                      v-if="index_user < 4"
                      :src="user.avatar.replace('https://www.', 'https://')"
                      :style="
                        (index_user == 0
                          ? 'margin-left:0px;'
                          : 'margin-left:-9px;') +
                        (copy.users.length - 4 <= 0
                          ? 'margin-top:8px;'
                          : 'margin-top:0px;') +
                        'z-index:' +
                        (5 - index_user) +
                        '!important;'
                      "
                    />
                  </div>
                  <p v-if="copy.users.length - 4 > 0">
                    +{{ copy.users.length - 4 }}
                  </p>
                </div>
                <div
                  v-if="copy.users.length == 0"
                  :id="'copy-members-' + index"
                  class="col-2 copyProjects-modal-copy-members-text"
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="usersCopyModal(index)"
                  >
                    <circle cx="8.5" cy="8.5" r="8" stroke="#301889" />
                    <path
                      d="M8.5 4.5V12.5"
                      stroke="#301889"
                      stroke-linecap="round"
                    />
                    <path
                      d="M12.5 8.5L4.5 8.5"
                      stroke="#301889"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              id="copy-modal-create-button"
              class="copyProjects-modal-copy-footer"
            >
              <div
                class="copyProjects-modal-copy-button"
                @click="submitCopyModal()"
              >
                <p>{{ langFilter("create") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="modal-copy-users"
        class="copyProjects-modals-container modal fade"
        tabindex="-1"
        aria-labelledby="modal-copy-users"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog modal-xl copyProjects-modal-vertical">
          <div
            id="modal-users-of-copy"
            class="modal-content copyProjects-modal-users"
            style="width: 620px; height: 397px"
          >
            <svg
              class="close-button"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="returnCopyModal()"
            >
              <path
                d="M9.5 9.22922C9.20761 9.50643 8.73356 9.50643 8.44118 9.22922L5.03529 6.00005L1.63824 9.22085C1.32148 9.52117 0.807928 9.52117 0.491177 9.22085C0.174426 8.92053 0.174425 8.43363 0.491177 8.13331L3.88824 4.91251L0.499999 1.70008C0.207613 1.42287 0.207614 0.973412 0.5 0.696197C0.792386 0.418982 1.26644 0.418982 1.55882 0.696197L4.94706 3.90863L8.34412 0.687831C8.66087 0.387515 9.17443 0.387516 9.49118 0.687832C9.80793 0.988148 9.80793 1.47506 9.49118 1.77537L6.09412 4.99617L9.5 8.22533C9.79239 8.50255 9.79239 8.952 9.5 9.22922Z"
                fill="#B7AED6"
              />
            </svg>
            <h2>{{ langFilter("INVITE USER TO THE PROJECT") }}</h2>
            <div class="newProjects-list-users-dropdown">
              <div class="newProject-SearchUserContainer">
                <input
                  id="input-search-users"
                  v-model="searchUsers"
                  type="text"
                  name="search-users"
                  :placeholder="
                    langFilter('Invitar usuario existente o añadir email')
                  "
                  @focus="openPopUpFilter"
                  @blur="closePopUpFilter"
                  @keyup.enter="addEmailToUsers"
                />
                <div
                  class="newProjects-list-users-dropdown-content"
                  @mousedown="noLoseFocusOnClickUser"
                >
                  <ul id="list-of-all-users">
                    <li
                      v-for="(filter, indexFilter) in filteredUserList"
                      :key="indexFilter"
                      @click="addUser(filter, indexFilter)"
                    >
                      <label>{{ filter.name }}</label>
                    </li>
                    <label
                      v-if="filteredUserList.length == 0"
                      class="newProject-NoUsers"
                      >{{ langFilter("no users") }}</label
                    >
                  </ul>
                </div>
              </div>
            </div>
            <!-- <input type="text"> -->
            <div id="list-of-copy-users" class="copyProjects-modal-users-body">
              <div v-if="copyProjects[users_copy]" class="row row-cols-3">
                <div
                  v-for="(user, index) in copyProjects[users_copy].users"
                  :key="index"
                  class="col"
                >
                  <div class="users-container">
                    {{ user.name }}
                    <svg
                      class="delete-user"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      @click="removeUser(index)"
                    >
                      <path
                        d="M9.5 9.22922C9.20761 9.50643 8.73356 9.50643 8.44118 9.22922L5.03529 6.00005L1.63824 9.22085C1.32148 9.52117 0.807928 9.52117 0.491177 9.22085C0.174426 8.92053 0.174425 8.43363 0.491177 8.13331L3.88824 4.91251L0.499999 1.70008C0.207613 1.42287 0.207614 0.973412 0.5 0.696197C0.792386 0.418982 1.26644 0.418982 1.55882 0.696197L4.94706 3.90863L8.34412 0.687831C8.66087 0.387515 9.17443 0.387516 9.49118 0.687832C9.80793 0.988148 9.80793 1.47506 9.49118 1.77537L6.09412 4.99617L9.5 8.22533C9.79239 8.50255 9.79239 8.952 9.5 9.22922Z"
                        fill="#B7AED6"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="copyProjects-modal-users-footer">
              <div
                id="invite-users-button"
                class="copyProjects-modal-copy-users-button"
                @click="returnCopyModal()"
              >
                <p>{{ langFilter("invite") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="modal-copy-final"
        class="copyProjects-modals-container modal fade"
        tabindex="-1"
        aria-labelledby="modal-copy-final"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div
          class="modal-dialog modal-xl modal-dialog-centered copyProjects-modal-vertical"
        >
          <div
            id="final-copy-modal"
            class="modal-content copyProjects-modal-finish"
            style="width: calc(100vw - 128px); height: 507px"
          >
            <img :src="require('@/assets/img/general/loading.gif')" />
            <h1>¡Estupendo!</h1>
            <p v-if="copyLoading">
              {{ langFilter("copies are")
              }}<span>{{ langFilter("being generated") }}</span
              >,{{ langFilter("this process will take a few seconds") }}.
            </p>
            <p v-if="!copyLoading">
              {{ langFilter("generated copies") }}
            </p>
            <div
              v-if="!copyLoading"
              id="button-finish-copies"
              class="copyProjects-modal-copy-users-button"
              @click="closeLastCopyModal()"
            >
              <p>{{ langFilter("accept") }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="modal-delete-project"
        class="copyProjects-modals-container modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div
          class="modal-dialog modal-xl modal-dialog-centered copyProjects-modal-vertical"
        >
          <div
            class="modal-content deleteProjects-modal"
            style="height: auto; width: 373px"
          >
            <svg
              class="close-button"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-bs-toggle="modal"
              data-bs-target="#modal-delete-project"
            >
              <path
                d="M9.5 9.22922C9.20761 9.50643 8.73356 9.50643 8.44118 9.22922L5.03529 6.00005L1.63824 9.22085C1.32148 9.52117 0.807928 9.52117 0.491177 9.22085C0.174426 8.92053 0.174425 8.43363 0.491177 8.13331L3.88824 4.91251L0.499999 1.70008C0.207613 1.42287 0.207614 0.973412 0.5 0.696197C0.792386 0.418982 1.26644 0.418982 1.55882 0.696197L4.94706 3.90863L8.34412 0.687831C8.66087 0.387515 9.17443 0.387516 9.49118 0.687832C9.80793 0.988148 9.80793 1.47506 9.49118 1.77537L6.09412 4.99617L9.5 8.22533C9.79239 8.50255 9.79239 8.952 9.5 9.22922Z"
                fill="#B7AED6"
              />
            </svg>
            <div class="confirmation-texts-deleteProject">
              <p>
                {{ langFilter("are-you-sure-delete") }}
                {{ deleteProjectName }}?
              </p>
            </div>
            <div class="confirmation-buttons">
              <button
                class="invisible-button"
                data-bs-toggle="modal"
                data-bs-target="#modal-delete-project"
              >
                {{ langFilter("cancel") }}
              </button>
              <button
                class="deleteProjects-modal-confirm"
                @click="deleteProject(projectIdDelete)"
              >
                {{ langFilter("yes-delete") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <smart-printing
        :smartPrintings="smartPrintings"
        :project="project"
        :lang="lang"
      />

      <div v-if="no_results == false">
        <div class="myProjects-SecondTitleContainerNew">
          <div class="myProjects-list-assignedTo-dropdown">
            <div
              class="myProjects-AssignedToButton"
              :class="{
                active:
                  filteredByAssignedTo ||
                  (filters.users.length > 0 && !filteredByMe),
              }"
              @click="openAssignedTo"
            >
              <p class="myProjects-AssignedToText">
                {{ langFilter("Assigned To") }}
              </p>
            </div>
            <div
              v-show="filteredByAssignedTo"
              id="container-of-users-filter"
              class="myProjects-list-AssignedTo-dropdown-content"
            >
              <div class="closeAssign">
                <img
                  class="close_image"
                  :src="require('@/assets/img/projects/close_assigned.svg')"
                  @click="filteredByAssignedTo = false"
                />
              </div>
              <div class="assignedToTop">
                <div class="assignedPeople">
                  <span class="assignedPeopleText">{{
                    langFilter("Assigned People")
                  }}</span>
                  <span
                    v-if="filters.users.length != usersList.length"
                    id="button-select-all-users"
                    ref="selectAllButton"
                    class="assignedPeopleButton"
                    @click="selectAllPeople"
                    >{{
                      langFilter(`Select
                    all`)
                    }}</span
                  >
                  <span
                    v-else
                    id="button-remove-all-users"
                    ref="selectAllButton"
                    class="assignedPeopleButton"
                    @click="selectAllPeople"
                    >{{ langFilter("Delete all") }}</span
                  >
                </div>
              </div>

              <div class="usersList">
                <li
                  v-for="(user, usersIndex2) in usersList"
                  :id="'filter-user-' + usersIndex2"
                  :key="usersIndex2"
                >
                  <label class="usersRow">
                    <span class="user_data"
                      ><img
                        class="avatar_user"
                        :src="getAvatar(user.avatar)"
                      />{{ user.name }}</span
                    >
                    <input
                      :id="'user_' + user.user_id"
                      v-model="filters.users"
                      :value="user.user_id"
                      type="checkbox"
                      class="assignedToCheckbox"
                      @change="projectsFiltered"
                    />
                    <span
                      id="input-assignedTo"
                      class="assignedTo-checkmark"
                      :class="
                        filters.users.includes(user.user_id)
                          ? 'assignedTo-checkmark-check'
                          : 'assignedTo-checkmark'
                      "
                    >
                      <img
                        class="myProjects-tick_image"
                        :src="require('@/assets/img/projects/tick.svg')"
                      />
                    </span>
                  </label>
                </li>
              </div>
            </div>
          </div>

          <div
            id="button-filter-me"
            class="myProjects-FilterMeButton"
            :class="{
              active:
                filters.users.includes(parseInt(currentUser)) &&
                filterByMeActive,
            }"
            @click="filterByMe"
          >
            <p class="myProjects-FilterMeText">
              {{ langFilter("Filter Me") }}
            </p>
          </div>

          <div class="myProjects-list-programs-dropdown">
            <div
              id="myProjects-filter"
              class="myProjects-FilterButtonIcon"
              :class="{
                active: filtered || filters.programs.length > 0,
              }"
              @click="openPopUp"
            >
              <!-- <p class="myProjects-NewProjectText">
                                {{ langFilter("Filter") }}
                            </p> -->
              <img
                v-show="!filtered && filters.programs.length == 0"
                class="myProjects-FilterIcon"
                :src="require('@/assets/img/projects/filter.svg')"
              />
              <img
                v-show="filtered || filters.programs.length > 0"
                class="myProjects-FilterIcon"
                :src="require('@/assets/img/projects/filter_clicked.svg')"
              />
            </div>

            <div
              id="filter-programs-container"
              class="myProjects-list-programs-dropdown-content"
            >
              <input
                v-model="searchProgram"
                class="myProjects-list-programs-title"
                :placeholder="langFilter('Search program')"
              />
              <!-- <p class="myProjects-list-programs-title">{{langFilter('Search program')}}</p> -->
              <ul>
                <li
                  v-for="(program, programsIndex2) in filteredProgramsList"
                  :id="'program-filter-' + programsIndex2"
                  :key="programsIndex2"
                >
                  <label
                    ><span>{{ program.name }}</span
                    ><input
                      :id="'prog_' + program.id"
                      v-model="filters.programs"
                      :value="program.id"
                      type="checkbox"
                      @change="projectsFiltered"
                  /></label>
                </li>
              </ul>
            </div>
            <div
              id="backdrop-filter-by-programs"
              class="myProjects-list-programs-dropdown-content-container"
              @click="clickOutsideDiv($event)"
            ></div>
          </div>
          <router-link
            v-if="role == 1"
            :to="'/new-project'"
            @click="toNewProject()"
          >
            <div
              class="myProjects-NewProjectButtonIcon"
              @click="toNewProject()"
            >
              <img
                class="myProjects-NewProjectIcon"
                :src="require('@/assets/img/projects/new_project.svg')"
              />
            </div>
          </router-link>
          <div
            v-if="view !== 5"
            id="searchbar-projects"
            class="tool-search-myProjects"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image-search-myProjects"
              @click="focusSearchBar"
            >
              <circle cx="8.5" cy="8" r="7" stroke="#301889" stroke-width="2" />
              <line
                x1="13.9142"
                y1="14"
                x2="18.5"
                y2="18.5858"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>

            <input
              id="project-search"
              v-model="filters.name"
              class="tool-search-myProjects-input"
              name="searchbar-projects"
              type="text"
              :placeholder="langFilter('Search')"
              @focus="search_bar(true)"
              @blur="search_bar(false)"
              @input="projectsFiltered"
            />
          </div>
        </div>
        <div
          v-if="paginatedSelected.length !== 0"
          class="myProjects-ProjectCardsContainerNew"
        >
          <div
            v-for="(program, programsIndex) in programsSelectedFilter"
            :id="'projects-container-by-program-' + programsIndex"
            :key="programsIndex"
            :style="
              selectedProgramsListValidation.length == programsIndex + 1
                ? { 'border-bottom': '0px' }
                : { '': '' }
            "
            class="myProjects-ProgramContainer"
          >
            <div class="myProjects-ProgramLabel editProgamLabel">
              <input
                v-if="editProgram.includes(program.id)"
                type="text"
                :value="program.name"
                @input="editProgramName($event, programsIndex)"
              />
              <p v-else>{{ program.name }}</p>
              <button
                v-if="editProgram.includes(program.id) && role == 1"
                class="itinerary-save-btn"
                @click="newProgramName(program.id, program.name)"
              >
                <img
                  :src="require(`@/assets/img/newitinerary/save.svg`)"
                  alt="save"
                />
              </button>
              <img
                v-else-if="role == 1"
                :src="require(`@/assets/img/newitinerary/edit.svg`)"
                alt="edit"
                @click="editingProgramName(program.id)"
              />
            </div>

            <div
              v-for="(myProject, myProjectsIndex) in paginatedSelected"
              :id="'project-card-' + myProjectsIndex"
              :key="myProjectsIndex"
            >
              <router-link :to="`/itinerary/${myProject.id}`">
                <div
                  v-if="myProject.program_id == program.id"
                  class="myProjects-ProjectCardNew"
                >
                  <img
                    v-show="cargaGif == myProject.id"
                    :id="'options_button' + myProject.id"
                    v-outside="hidehover"
                    class="option_button_myprojects_carga"
                    :src="require('@/assets/img/general/carga.gif')"
                    @click.stop="showhover($event, myProject.id)"
                  />

                  <img
                    v-show="cargaGif == 0 && role == 1"
                    :id="'options_button' + myProject.id"
                    v-outside="hidehover"
                    class="option_button_myprojects"
                    :src="require('@/assets/img/itinerario/options.svg')"
                    @click.stop="showhover($event, myProject.id)"
                  />

                  <div
                    v-if="hoverVisible == myProject.id"
                    :id="'hover_header' + myProject.id"
                    class="box-project-myprojects delete-button-myprojects"
                  >
                    <p
                      id="text_options"
                      class="box-project-myprojects-option m-1"
                      @click.stop="startProjectManager($event, myProject.id)"
                    >
                      <img
                        class="box-project-myprojects-image"
                        :src="require('@/assets/img/projects/edit.svg')"
                      />
                      {{ langFilter("config") }}
                    </p>
                    <p
                      id="text_options"
                      class="box-project-myprojects-option m-1"
                      @click.stop="
                        modalDeleteProject($event, myProject.id, myProject.name)
                      "
                    >
                      <img
                        class="box-project-myprojects-image"
                        :src="require('@/assets/img/projects/delete.svg')"
                      />
                      {{ langFilter("delProject") }}
                    </p>
                  </div>
                  <div
                    v-show="myProject.interaction > 0"
                    class="myProjects-ProjectCardNotification"
                  ></div>

                  <img
                    class="myProjects-ProjectCardLogo"
                    :src="require('@/assets/img/flow/Flow_roadmap_Icon-20.png')"
                  />

                  <p
                    :id="'project-name-' + myProjectsIndex"
                    class="myProjects-ProjectCardTitle"
                    @mouseover="changeProjectHover(myProject.id)"
                    @mousemove="moveTitleCoordinates($event)"
                    @mouseout="projectTitleHovered = ''"
                  >
                    {{ myProject.name }}
                  </p>
                  <div class="myProjects-ProjectCardSeparator"></div>

                  <ul
                    :id="'project-info-' + myProjectsIndex"
                    class="myProjects-ProjectCardInfo"
                  >
                    <li :id="'project-resources-' + myProjectsIndex">
                      {{ langFilter("Resources") }}:
                      <b> {{ getNumResources(myProject.id) }}</b>
                    </li>
                    <li :id="'project-complete-' + myProjectsIndex">
                      {{ langFilter("Progress") }}:
                      <b class="hide"> {{ myProject.percentage }}%</b>
                      <b> {{ myProject.percentage }}%</b>
                    </li>
                  </ul>
                  <div
                    :id="'project-users-' + myProjectsIndex"
                    class="myProjects-ProjectCardUsers"
                  >
                    {{ langFilter("Users") }}:
                    <b>{{ myProject.users.length }}</b
                    ><br />
                    <div
                      v-for="(
                        users, usersWithImageIndex
                      ) in myProject.usersWithImage.slice(0, 7)"
                      :key="usersWithImageIndex"
                      class="myProjects-ProjectCardUsersImageRow"
                    >
                      <img
                        class="avatar_user_card"
                        :src="getAvatar(users.avatar)"
                        @mouseover="changeUserHover(users.user_id)"
                        @mousemove="moveAvatarCoordinates($event)"
                        @mouseout="userAvatarHovered = ''"
                      />
                    </div>
                    <span
                      v-if="myProject.users.length > 7"
                      class="myProjects-ProjectCardUsersRemaining"
                    >
                      <b>+{{ myProject.users.length - 7 }}</b>
                    </span>
                  </div>
                  <div class="myProjects-ProjectUrlLinksContainer">
                    <br />
                    <p
                      id="smart_printing"
                      class="myProjects-ProjectCardSmartPrinting"
                      @click.stop="
                        openSmartPrintingModal(
                          myProject.id,
                          myProject.name,
                          $event
                        )
                      "
                    >
                      Smart Printing
                    </p>
                  </div>
                </div>
              </router-link>
              <div
                v-show="projectTitleHovered == myProject.id"
                class="myProjects-ProjectTitleHover"
              >
                {{ myProject.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="!loading"
          id="info-no-projects"
          style="
            font-family: Poppins-Regular;
            font-size: 20;
            text-align: center;
            width: 100%;
            margin: 200px 0px 200px 0px;
          "
        >
          {{ langFilter("Noprojects") }}
        </div>
        <div v-else>
          <img
            class="loading-rocket"
            :src="require('@/assets/img/general/loading.gif')"
          />
        </div>
        <div
          v-show="userAvatarHovered != ''"
          class="myProjects-userAvatarHover"
        >
          {{ userAvatarHovered }}
        </div>
        <div
          v-show="projectTitleHovered != ''"
          class="myProjects-ProjectTitleHover"
        >
          {{ projectTitleHovered }}
        </div>
        <pagination
          v-show="myProjectsPagination.length > 25"
          id="paginacion"
          :key="paginationNumberDelete"
          ref="position"
          :countLength="selectedProgramsListValidation.length"
          @update:option="paginated"
        />
      </div>
      <div v-else>
        <Error
          title="permiso-title"
          body="no-programs"
          link="link-redirect"
          image='<img class="cohete-espacio" id="cohete" src="/img/errors/no_autorizado.svg">'
          vector="/img/errors/vector_158.svg"
          vamos="permiso-volver"
          header="true"
          :lang="lang"
        ></Error>
      </div>
    </div>
    <confirmation ref="modalConfirmation" :lang="lang"></confirmation>
    <smart-printing-modal
      v-if="showOptionsPopup"
      :project-id="project_id"
      @close="closeOptionsPopup"
    />
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
import pagination from "./../PaginationComponent.vue";
import { Modal } from "bootstrap";
import $ from "jquery";
import axios from "axios";
export default {
  components: { pagination },
  props: ["lang", "role", "space", "view"],
  data() {
    return {
      //Modal copy
      allResources: [],
      loading: true,
      number_copies: 1,
      users_copy: 0,
      copyProjects: [
        {
          project_name: "",
          program_name: "",
          typology_name: "",
          listUsersCustomer: [],
          users: [],
        },
      ],
      copyProject: {
        project_name: "",
        program_name: "",
        typology_name: "",
        listUsersCustomer: [],
        users: [],
      },
      editProgram: [],
      searchUsers: "",
      listUsersCustomer: [],
      completeListUsersCustomer: [],
      myUser: {},
      errorUrl: false,
      selectedCopyProject: 0,
      copyLoading: true,
      currentUser: 0,
      programsSelected: [],
      myProjectsPagination: [],
      myProjects: [],
      myProjectsOrdered: [],
      programsList: [],
      selectedProgramsList: [],
      searchProgram: "",
      paginatedNum: 0,
      paginatedSelected: [],
      no_results: false,
      smartPrintings: [],
      project: [],
      newProject: false,
      hoverVisible: 0,
      cargaGif: 0,
      currentPage: 0,
      filtered: false,
      filteredByMe: false,
      filteredByAssignedTo: false,
      usersList: [],
      usersAssignedTo: [],
      allPeopleSelected: false,
      projectTitleHovered: "",
      userAvatarHovered: "",
      projectTitleHovered: 0,
      projectIdDelete: 0,
      allPercentages: [],
      resourcesProjectCount: [],
      deleteProjectName: "",
      filters: {
        name: [],
        programs: [],
        users: [],
      },
      filterByMeActive: false,
      showOptionsPopup: false,
      project_id: null,
      urlTaskManager: "",
      selectedProject_id: 0,
      paginationNumberDelete: 1,
      traducciones: [
        {
          name: "My projects",
          es: "Mis proyectos",
          en: "My projects",
        },
        {
          name: "config",
          es: "Configurar",
          en: "Configure",
        },
        {
          name: "Filter",
          es: "Filtro",
          en: "Filter",
        },
        {
          name: "Search program",
          es: "Buscar programa...",
          en: "Search program...",
        },
        {
          name: "New project",
          es: "Nuevo proyecto",
          en: "New project",
        },
        {
          name: "Solutions",
          es: "Apartados",
          en: "Parts",
        },
        {
          name: "URL Gestor de tareas",
          es: "URL Gestor de tareas",
          en: "URL Task Manager",
        },
        {
          name: "Pending modifications",
          es: "Pendientes de modificación",
          en: "Pending modifications",
        },
        {
          name: "Completed",
          es: "Completados",
          en: "Completed",
        },
        {
          name: "Progress",
          es: "Progreso",
          en: "Progress",
        },
        {
          name: "Resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "title_my_projects_screen",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "new_project",
          es: "Nuevo proyecto",
          en: "New project",
        },
        {
          name: "delProject",
          es: "Eliminar",
          en: "Delete",
        },
        {
          name: "Filter Me",
          es: "Yo",
          en: "Me",
        },
        {
          name: "Assigned To",
          es: "Asignado a ",
          en: "Assigned to ",
        },
        {
          name: "Assigned People",
          es: "Personas asignadas",
          en: "Assigned people ",
        },
        {
          name: "Select all",
          es: "Selec. todo ",
          en: "Select all ",
        },
        {
          name: "Delete all",
          es: "Borrar todo",
          en: "Delete all",
        },
        {
          name: "Noprojects",
          es: "No tienes proyectos",
          en: "You don't have projects",
        },
        {
          name: "Users",
          es: "Usuarios",
          en: "Users",
        },
        {
          name: "number of copies",
          es: "Número de copias",
          en: "Number of copies",
        },
        {
          name: "cancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "continue",
          es: "Continuar",
          en: "Continue",
        },
        {
          name: "copies of",
          es: "Copias de",
          en: "Copies of",
        },
        {
          name: "projects name",
          es: "Nombre del proyecto",
          en: "Project's name",
        },
        {
          name: "template",
          es: "Plantilla",
          en: "Template",
        },
        {
          name: "program",
          es: "Programa",
          en: "Program",
        },
        {
          name: "tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "members",
          es: "Miembros",
          en: "Members",
        },
        {
          name: "validUrl",
          es: "Introduce una URL válida ej: https://acceleralia.com",
          en: "Enter a valid URL eg: https://acceleralia.com",
        },
        {
          name: "create",
          es: "Crear",
          en: "Create",
        },
        {
          name: "INVITE USER TO THE PROJECT",
          es: "INVITAR USUARIO AL PROYECTO",
          en: "INVITE USER TO THE PROJECT",
        },
        {
          name: "invite",
          es: "Invitar",
          en: "Invite",
        },
        {
          name: "copies are",
          es: "Se están ",
          en: "Copies are ",
        },
        {
          name: "being generated",
          es: "generando las copias",
          en: "being generated",
        },
        {
          name: "this process will take a few seconds",
          es: " este proceso tomará unos segundos",
          en: " this process will take a few seconds",
        },
        {
          name: "Insert the project name",
          es: "Introduce el nombre del projecto",
          en: "Insert the project name",
        },
        {
          name: "copy",
          es: "Copiar",
          en: "Copy",
        },
        {
          name: "accept",
          es: "Aceptar",
          en: "Accept",
        },
        {
          name: "generated copies",
          es: "Ya se han generando las copias, dale a Aceptar para contiuar.",
          en: "The copies have already been generated, click accept to continue.",
        },
        {
          name: "Search",
          es: "Buscar...",
          en: "Search...",
        },
        {
          name: "yes-delete",
          es: "Sí, eliminar",
          en: "Yes, delete",
        },
        {
          name: "are-you-sure-delete",
          es: "Estás seguro de querer eliminar el Proyecto",
          en: "Are you sure you want to delete the Project",
        },
      ],
    };
  },
  created() {
    $(function () {
      $(".my-element").on("click contextmenu", function (e) {
        // If the right mouse button is used
        if (e.which === 3) {
          // Grab data-link value from <div>
          var link = $(this).data("link");
          // Open new tab
          window.open(link, "_blank");
        }

        // Cancel default right click behavior
        return false;
      });
    });

    // consigue el id del usuario loggeado
    axios
      .get(`${process.env.VUE_APP_API_URL}/general/getLogedUser`)
      .then((response) => {
        this.currentUser = response.data;
        // consigue el número de recursos por proyecto
        axios
          .get(
            `${process.env.VUE_APP_API_URL}/getResourcesByUser/${response.data}`
          )
          .then((res) => {
            const newResources = [];
            res.data[0].forEach((el) => {
              if (
                !newResources.find(
                  (item) =>
                    item.resource_id == el.resource_id &&
                    item.solution_id == el.solution_id
                )
              ) {
                newResources.push({
                  resource_id: el.resource_id,
                  station_id: el.station_id,
                  project_id: el.project_id,
                });
              }
            });
            res.data[1].forEach((el) => {
              if (
                !newResources.find(
                  (item) =>
                    item.resource_id == el.resource_id &&
                    item.solution_id == el.solution_id
                )
              ) {
                newResources.push({
                  resource_id: el.resource_id,
                  station_id: el.station_id,
                  project_id: el.project_id,
                });
              }
            });
            res.data[2].forEach((el) => {
              if (
                !newResources.find(
                  (item) =>
                    item.resource_id == el.resource_id &&
                    item.solution_id == el.solution_id
                )
              ) {
                newResources.push({
                  resource_id: el.resource_id,
                  station_id: el.station_id,
                  project_id: el.project_id,
                });
              }
            });
            res.data[3].forEach((el) => {
              if (
                !newResources.find(
                  (item) =>
                    item.resource_id == el.resource_id &&
                    item.solution_id == el.solution_id
                )
              ) {
                newResources.push({
                  resource_id: el.resource_id,
                  station_id: el.station_id,
                  project_id: el.project_id,
                });
              }
            });
            this.allResources = newResources;
          });
      });

    // consigue todos los proyectos
    axios
      .post(`${process.env.VUE_APP_API_URL}/myProjects/GetProjects`, {
        role: this.role,
      })
      .then((response) => {
        this.myProjects = response.data;

        axios
          .post(`${process.env.VUE_APP_API_URL}/getResourcesCountByProject`, {
            project_id: response.data,
          })
          .then((response) => {
            this.resourcesProjectCount = response.data;
          })
          .catch((error) => {
            this.resourcesProjectCount = [];
          });

        this.myProjects.sort((a, b) =>
          a.id < b.id ? 1 : b.id < a.id ? -1 : 0
        );

        // consigue todos los programas asociados al usuario
        axios
          .post(`${process.env.VUE_APP_API_URL}/getPrograms`)
          .then((response) => {
            this.programsList = response.data;

            // consigue todos los usuarios del espacio
            // si el porcentaje es mayor que 100, lo pone a 100
            // recorre los usuarios del proyecto y les añade la imagen
            // filtra los proyectos que tienen programas asociados al usuario y los añade a myProjectsPagination
            //divide los proyectos en páginas de 25
            axios
              .post(`${process.env.VUE_APP_API_URL}/myProjects/getUsers`, {
                newProject: false,
                space_id: this.space.id,
              })
              .then((response) => {
                this.usersList = response.data;
                this.myProjects.forEach((project) => {
                  if (project.percentage > 100) {
                    project.percentage = 100;
                  }
                  project.usersWithImage = [];
                  project.users.forEach((user) => {
                    const avatar = this.usersList.find(
                      (user2) => JSON.stringify(user2.user_id) == user
                    );
                    project.usersWithImage.push({
                      user_id: user,
                      avatar: avatar.avatar,
                    });
                  });
                });
                this.programsList.forEach((program) => {
                  this.myProjects.forEach((project) => {
                    if (project !== null) {
                      if (project.program_id == program.id) {
                        this.myProjectsPagination.push(project);
                      }
                    }
                  });
                });
                this.myProjectsOrdered = this.myProjectsPagination;
                this.paginatedSelected = this.myProjectsPagination.slice(0, 25);
                this.getUsersFromCustomer();
                this.getUserInfo();

                if (window.location.href.includes("?")) {
                  const id = window.location.href.split("=")[1];
                  if ($("#prog_" + id).length) {
                    $("#prog_" + id).trigger("click");
                  } else {
                    this.no_results = true;
                  }
                }
                this.loading = false;
                // this.$refs.position.paginationInit();
              });
          });
      });
  },
  computed: {
    // lista de filtros de usuarios
    filteredUserList() {
      return this.copyProjects[this.users_copy].listUsersCustomer.filter(
        (post) => {
          if (post.role == 2) {
            return post.name
              .toLowerCase()
              .includes(this.searchUsers.toLowerCase());
          }
        }
      );
    },

    // lista de filtros de programas
    programsSelectedFilter() {
      return this.programsSelected.filter((program) => {
        return this.paginatedSelected.find((obj) => {
          if (obj.program_id == program.id) {
            return true;
          }
        });
      });
    },

    // validacion de la lista de programas seleccionados
    selectedProgramsListValidation() {
      if (this.selectedProgramsList.length > 0) {
        this.programsSelected = this.selectedProgramsList;
        return this.selectedProgramsList;
      } else {
        this.programsSelected = this.programsList;
        return this.programsList.filter((program) => {
          return this.myProjects.find((obj) => {
            if (obj.program_id == program.id) {
              return true;
            }
          });
        });
      }
    },

    // lista de filtros de programas 2, por alguna razón hay 2
    filteredProgramsList() {
      return this.programsList.filter((post) => {
        var validation = this.myProjects.find((obj) => {
          if (obj.program_id == post.id) {
            return true;
          }
        });
        var validation2 = post.name
          .toLowerCase()
          .includes(this.searchProgram.toLowerCase());
        if (validation && validation2) {
          return true;
        }
      });
    },
  },
  methods: {
    openOptionsPopup(project_id) {
      this.project_id = project_id;
      this.showOptionsPopup = true;
    },

    closeOptionsPopup() {
      this.showOptionsPopup = false;
    },

    editingProgramName(id) {
      if (this.editProgram.includes(id)) {
        this.editProgram.splice(this.editProgram.indexOf(id), 1);
      } else {
        this.editProgram.push(id);
      }
    },
    editProgramName(event, i) {
      event.preventDefault();
      event.stopPropagation();
      this.programsSelectedFilter[i].name = event.target.value;
    },
    newProgramName(id, name) {
      axios
        .patch(`${process.env.VUE_APP_API_URL}/myProjects/editProgramName`, {
          id: id,
          name: name,
        })
        .then(() => {
          this.editingProgramName(id);
        });
    },
    getProjectPercentage(id) {
      if (this.allPercentages[id] != undefined) {
        return this.allPercentages[id];
      }
      axios
        .get(`${process.env.VUE_APP_API_URL}/getPercentageByProject/${id}`)
        .then((response) => {
          this.allPercentages[id] = response.data;
        })
        .catch((error) => {
          this.allPercentages[id] = 0;
        });
    },
    getNumResources(id) {
      const resources = this.resourcesProjectCount.filter(
        (project) => project.project_id === id
      );
      return resources.length > 0 ? resources[0].count : 0;
    },
    // confirmación en el modal
    confirmTrackContinue() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
      var myModal2 = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-projects")
      );
      myModal2.hide();
    },

    isValidUrl(string) {
      try {
        const url = new URL(string);
        const hostnameValidation = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
        if (hostnameValidation.test(url.hostname)) {
          return true;
        }
        return false;
      } catch (_) {
        return false;
      }
    },

    // cancelación en el modal
    confirmTrackCancel() {
      event.preventDefault();
      this.errorUrl = false;
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
    },

    setTaskManager() {
      if (this.urlTaskManager !== "") {
        if (!this.isValidUrl(this.urlTaskManager)) {
          this.errorUrl = true;
          return;
        }
      } else {
        this.urlTaskManager = null;
      }
      this.errorUrl = false;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/myProjects/addProjectTaskManager`,
          {
            project_id: this.selectedProject_id,
            task_manager: this.urlTaskManager,
          }
        )
        .then(() => {
          this.urlTaskManager = "";
          this.selectedProject_id = 0;
          this.cancelCopyModal();
        })
        .catch((error) => {
          console.error(error);
          alert("Error al guardar la URL del gestor de tareas");
        });
    },

    // abre el modal de confirmación
    openModalConfirmation() {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.$refs.modalConfirmation.confirmationMessage =
        "Perderas toda la información rellenada";
      this.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$refs.modalConfirmation.confirmationButtonText = "cancel";
      this.$refs.modalConfirmation.confirmationButtonText2 = "confirm";
      myModal.toggle();
    },

    // saca la información del usuario y cambia la url del avatar
    getUserInfo() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/myProjects/getUserInfo`)
        .then((response) => {
          this.myUser = response.data;
          this.myUser.avatar.replace("https://www/.", "https://");
        });
    },

    // añade un usuario al proyecto para copiar
    addEmailToUsers() {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.searchUsers)
      ) {
        $("#newProject-ResourceFileContainer").removeClass(
          "newProject-InputValidation"
        );

        var obj = {
          id: 0,
          name: this.searchUsers,
          email: this.searchUsers,
          avatar: "/img/users/Avatar4.png",
          is_new: true,
          type: 2,
        };
        this.copyProjects[this.users_copy].users.push(obj);
        this.searchUsers = "";
      } else {
        $("#newProject-ResourceFileContainer").addClass(
          "newProject-InputValidation"
        );
      }
    },

    // Consigue los usuarios del espacio
    getUsersFromCustomer() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/myProjects/getCustomerUsers`, {
          params: {
            space: this.space.id,
          },
        })
        .then((response) => {
          this.completeListUsersCustomer = response.data;
          this.listUsersCustomer = response.data;
        });
    },

    // añade un usuario al proyecto para copiar
    addUser(filter) {
      var indexOfObject_user_customer_list = this.copyProjects[
        this.users_copy
      ].listUsersCustomer.findIndex((object) => {
        return object.id === filter.id;
      });

      filter.type = 2;

      this.copyProjects[this.users_copy].users.push(filter);
      this.copyProjects[this.users_copy].listUsersCustomer.splice(
        indexOfObject_user_customer_list,
        1
      );

      this.searchUsers = "";
    },

    // quita al usuario del proyecto para copiar
    removeUser(index) {
      this.copyProjects[this.users_copy].listUsersCustomer.splice(
        this.copyProjects[this.users_copy].users[index].position,
        0,
        this.copyProjects[this.users_copy].users[index]
      );
      this.copyProjects[this.users_copy].users.splice(index, 1);
    },
    noLoseFocusOnClickUser(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    closePopUpFilter(el) {
      var blur = el.currentTarget;

      $(blur).next().hide();
    },
    openPopUpFilter(el) {
      $(el.currentTarget).next().show();
    },

    // devuelve el proyecto copiado
    getCopyProject() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/myProjects/getCopyProject`, {
          project: this.selectedCopyProject,
        })
        .then((response) => {
          this.copyProject = response.data;
        });
    },

    // guarda la copia del proyecto
    submitCopyModal() {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-projects")
      );
      myModal.hide();
      var myModal2 = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-final")
      );
      myModal2.show();

      var json_projects = JSON.stringify(this.copyProjects);

      axios
        .post(`${process.env.VUE_APP_API_URL}/myProjects/submitCopyProject`, {
          projects: json_projects,
        })
        .then(() => {
          setTimeout(() => {
            setTimeout(() => {
              this.copyLoading = false;
            }, 1000);
          }, 1000);
        });
    },

    // abre el modal de seleccionar cuantas copias se quieren hacer
    startCopyProject(e, id) {
      e.preventDefault();
      this.filteredByAssignedTo = false;
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-number")
      );
      myModal.show();
      this.selectedCopyProject = id;
    },

    startProjectManager(e, id) {
      e.preventDefault();
      this.$router.replace({
        name: "ConfigProject",
        params: { project_id: id },
      });
    },
    cancelCopyModal() {
      this.errorUrl = false;
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-number")
      );
      myModal.hide();
    },

    getProjectTaskManager(id) {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/myProjects/getProjectTaskManager/${id}`
        )
        .then((response) => {
          const { task_manager_url } = response.data;
          this.urlTaskManager = task_manager_url;
        });
    },

    // abre el modal de edición de proyecto copiado
    returnCopyModal() {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-users")
      );
      myModal.hide();
      var myModal2 = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-projects")
      );
      myModal2.show();
    },

    // abre el modal para seleccionar los usuarios asociados a la copia
    usersCopyModal(index) {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-projects")
      );
      myModal.hide();
      this.users_copy = index;
      var myModal2 = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-users")
      );
      myModal2.show();
    },

    // pasa del modal de seleccionar cuantas copias se quieren hacer al modal de edición de proyecto copiado
    nextCopyModal() {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-number")
      );
      myModal.hide();
      this.getCopyProject();
      this.copyProjects = [];

      for (let index = 0; index < this.number_copies; index++) {
        var obj = {
          company: this.copyProject.company,
          project: "Copy of " + this.copyProject.project_name,
          project_id: this.copyProject.project_id,
          program: this.copyProject.program_name,
          program_id: this.copyProject.program_id,
          typology: this.copyProject.typology_name,
          typology_id: this.copyProject.typology_id,
          cascade: this.copyProject.cascade,
          time: this.copyProject.time,
          duration: this.copyProject.duration,
          solutions_status: this.copyProject.solutions_status,
          users: [],
          listUsersCustomer: [],
        };
        this.completeListUsersCustomer.forEach((element) => {
          obj.listUsersCustomer.push(element);
        });
        this.copyProjects.push(obj);
      }

      var myModal2 = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-projects")
      );
      myModal2.show();
    },

    // cierra el modal de copia de proyecto
    closeLastCopyModal() {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-final")
      );
      myModal.hide();
      window.location.reload(true);
    },

    nullhref(event) {
      event.preventDefault();
    },

    // redirige a new-project
    toNewProject() {
      this.newProject = true;
    },

    // abre el smart printing, dependiendo del espacio usará unos colores u otros
    openSmartPrintingModal(project_id, name, event) {
      event.preventDefault();
      this.project = [project_id, name];
      if (this.space.id == 22680) {
        //camara de comercio
        window.open(
          `${process.env.VUE_APP_API_STORAGE}/SmartPrinting/template/${project_id}`,
          "_blank"
        );
      } else {
        // axios
        //   .get(
        //     `${process.env.VUE_APP_API_STORAGE}/getSmartPrintingTemplate/${project_id}`
        //   )
        //   .then((response) => {
        //     const doctype = response.data.doctype;
        //     if (doctype == "docx") {
        this.openOptionsPopup(project_id);
        //     } else {
        //       window.open(
        //         `${process.env.VUE_APP_API_STORAGE}/SmartPrinting/template/${project_id}`,
        //         "_blank"
        //       );
        //     }
        //   });
      }
      /* window.open(
        `${process.env.VUE_APP_API_STORAGE}/SmartPrinting/${project_id}`
      );*/
      //get date hours, minutes and seconds
      /*
      var today = new Date();
      var time =
        today.getFullYear() +
        today.getMonth() +
        today.getDay() +
        today.getHours() +
        today.getMinutes() +
        today.getSeconds();
      switch (this.space.id) {
        case 12238:
          window.open(
            `${process.env.VUE_APP_API_STORAGE}/my-projects/smart-printing.pdf?project_id=` +
              project_id +
              "&type=0&color1=0c0c120&color2=115c237c255&color3=255c255c255&time=" +
              time,
            "_blank"
          );
          break;
        case 36924:
          window.open(
            `${process.env.VUE_APP_API_STORAGE}/my-projects/smart-printing.pdf?project_id=` +
              project_id +
              "&type=1&color1=77c195c184&color2=156c215c210&color3=17c44c44&time=" +
              time,
            "_blank"
          );
          break;
        case 36923:
          window.open(
            `${process.env.VUE_APP_API_STORAGE}/my-projects/smart-printing.pdf?project_id=` +
              project_id +
              "&type=0&color1=71c97c78&color2=101c114c123&color3=253c185c9&time=" +
              time,
            "_blank"
          );
          break;
        case 36913:
          window.open(
            `${process.env.VUE_APP_API_STORAGE}/my-projects/smart-printing.pdf?project_id=` +
              project_id +
              "&type=0&color1=159c223c217&color2=205c210c210&color3=168c223c216&time=" +
              time,
            "_blank"
          );
          break;
        case 16655:
          window.open(
            `${process.env.VUE_APP_API_STORAGE}/my-projects/smart-printing.pdf?project_id=` +
              project_id +
              "&type=0&color1=159c223c217&color2=205c210c210&color3=168c223c216&time=" +
              time,
            "_blank"
          );
          break;
        case 36912:
          window.open(
            `${process.env.VUE_APP_API_STORAGE}/my-projects/smart-printing.pdf?project_id=` +
              project_id +
              "&type=0&color1=246c74c106&color2=255c165c191&color3=255c255c255&time=" +
              time,
            "_blank"
          );
          break;
        case 22680:
          this.project = [project_id, name];
          window.open(
            `${process.env.VUE_APP_API_STORAGE}/SmartPrinting/${project_id}`
          );
          break;
        default:
          window.open(
            `${process.env.VUE_APP_API_STORAGE}/my-projects/smart-printing.pdf?project_id=` +
              project_id +
              "&type=0&color1=126c234c181&color2=141c116c235&color3=255c255c255&time=" +
              time,
            "_blank"
          );
          break;
      }
      */
    },

    // filtra los proyectos por programa
    myProjectsInProgram(id) {
      return this.myProjectsPagination.filter((project) => {
        return project.program_id == id;
      });
    },

    // devuelve true o false dependiendo de si el programa tiene proyectos o no
    programWithoutProjects(program) {
      var validation = this.myProjects.find((element) => {
        return element.program_id == program;
      });
      if (validation !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    // abre o cierra el filtro de programas
    openPopUp: function (el) {
      if ($(el.currentTarget).next().is(":visible")) {
        $(el.currentTarget).next().hide();
        $(el.currentTarget).next().next().hide();
        this.filtered = false;
      } else {
        $(el.currentTarget).next().next().show();
        $(el.currentTarget).next().show();
        this.filtered = true;
      }
    },

    // desaparece el filtro de programas
    clickOutsideDiv(el) {
      $(el.currentTarget).hide();
      $(el.currentTarget).prev().hide();
      this.filtered = false;
    },

    // cambia a la página seleccionada
    paginated: function (value) {
      this.paginatedNum = value;
      if (value == 0) {
        this.paginatedSelected = this.myProjectsPagination.slice(0, 25);
      } else {
        this.paginatedSelected = this.myProjectsPagination.slice(
          value * 25,
          (value + 1) * 25
        );
      }
    },

    // abre el modal de borrado de proyecto
    modalDeleteProject(event, project_id, name) {
      this.projectIdDelete = project_id;
      this.deleteProjectName = name;

      event.preventDefault();
      this.filteredByAssignedTo = false;

      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-delete-project")
      );
      myModal.show();
    },

    // borra el proyecto, muestra el gif de carga y lo elimina de la base de datos
    deleteProject(project_id) {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-delete-project")
      );
      myModal.hide();

      // this.showhover(event, project_id);
      this.cargaGif = project_id;

      setTimeout(() => {
        this.cargaGif = 0;
        this.update_myprojects(project_id);
      }, 300);

      axios.post(`${process.env.VUE_APP_API_URL}/myProjects/deleteProject`, {
        project_id: project_id,
        user_id: this.currentUser,
      });
    },

    // muestra la etiqueta de nombre de proyecto
    showhover(event, id) {
      if (this.hoverVisible == id) {
        this.hoverVisible = 0;
        $("#options_button" + id)
          .parent()
          .css("cursor", "pointer");
      } else {
        this.hoverVisible = id;
        $("#options_button" + id)
          .parent()
          .css("cursor", "default");
      }
      event.preventDefault();
    },

    // desaparece la etiqueta de nombre de proyecto
    hidehover(event) {
      if (!event.target.classList.contains("option_button_itinerary")) {
        this.hoverVisible = 0;
        $(".myProjects-ProjectCardNew").css("cursor", "pointer");
      }
    },

    // actualiza la lista de proyectos y vuelve a calcular la paginación
    update_myprojects(project_id) {
      this.paginationNumberDelete = Math.ceil(
        this.myProjectsPagination.length / 25
      );

      let pos = this.myProjects.findIndex((i) => i.id == project_id);
      if (pos != -1) {
        this.myProjects.splice(pos, 1);
      }

      this.myProjectsPagination = this.myProjects;

      this.paginationNumberDelete = Math.ceil(
        this.myProjectsPagination.length / 25
      );

      this.paginated(this.currentPage);
      this.$refs.position.position = this.currentPage;

      if (this.filters.programs.length > 0) {
        this.projectsFiltered();
      }
    },

    // filtrado de proyectos - esto es una putisima mierda y hay que refactorizarlo
    projectsFiltered(param) {
      let projects = this.myProjects;

      switch (param) {
        case "selectAll":
          this.filteredByMe = false;
          this.filters.users = [];
          this.usersList.forEach((user) => {
            this.filters.users.push(user.user_id);
          });
          break;
        case "removeAll":
          this.filters.users = [];
          break;
        case "selectMe":
          this.filteredByAssignedTo = false;
          this.filters.users = [];
          this.filters.users.push(parseInt(this.currentUser));
          break;
        case "removeMe":
          this.filters.users = [];
          break;
      }
      if (this.filters.users.length > 0) {
        projects = projects.filter((project) => {
          return this.filters.users.find((user) => {
            if (project.users.includes(JSON.stringify(user))) {
              return true;
            }
          });
        });
      }

      if (this.filters.programs.length > 0) {
        projects = projects.filter((project) => {
          return this.filters.programs.find((program) => {
            if (project.program_id === program) {
              return true;
            }
          });
        });
      }

      if (this.filters.name.length > 0) {
        projects = projects.filter((project) => {
          return project.name
            .toLowerCase()
            .includes(this.filters.name.toLowerCase());
        });
      }

      // group projects again by program
      this.myProjectsPagination = [];
      this.programsList.forEach((program) => {
        projects.forEach((project) => {
          if (project.program_id == program.id) {
            this.myProjectsPagination.push(project);
          }
        });
      });

      this.paginatedSelected = this.myProjectsPagination.slice(0, 25);
      this.paginationNumberDelete = Math.ceil(
        this.myProjectsPagination.length / 25
      );
      this.$refs.position.position = 0;
    },

    // filtra los proyectos por el usuario loggeado
    filterByMe() {
      var checkboxes = $(".assignedToCheckbox").hasClass(
        "assignedTo-checkmark-check"
      );

      if (!checkboxes) {
        this.filterByMeActive = true;
      } else {
        this.filterByMeActive = false;
      }

      if (!this.filteredByMe) {
        this.filteredByMe = true;
        this.projectsFiltered("selectMe");
      } else {
        this.filteredByMe = false;
        this.projectsFiltered("removeMe");
      }
    },

    // selecciona a todos los usuarios
    selectAllPeople() {
      if (this.filters.users.length != this.usersList.length) {
        this.allPeopleSelected = true;
        this.projectsFiltered("selectAll");
      } else {
        this.allPeopleSelected = false;
        this.projectsFiltered("removeAll");
      }
    },
    closePopUp(el) {
      $(el.currentTarget).parent().parent().hide();
      $(el.currentTarget).parent().parent().next().hide();
    },

    //abre el menú de filtrado por usuario
    openAssignedTo() {
      if (!this.filteredByAssignedTo) {
        this.filteredByAssignedTo = true;
        if (this.filteredByMe) {
          this.filters.users = [];
        }
      } else {
        this.filteredByAssignedTo = false;
      }
      this.filteredByMe = false;
      this.filterByMeActive = false;
    },

    // mueve la etiqueta de nombre de proyecto
    moveTitleCoordinates(e) {
      if ($(".div-general-menu-lateral").hasClass("container-close-sidebar")) {
        $(".myProjects-ProjectTitleHover").css({
          top: e.clientY + window.pageYOffset - 20 + "px",
          left: e.clientX - 56 + "px",
        });
      } else {
        $(".myProjects-ProjectTitleHover").css({
          top: e.clientY + window.pageYOffset - 20 + "px",
          left: e.clientX - 236 + "px",
        });
      }
    },

    // cambia el nombre que aperece en la etiqueta de nombre de proyecto
    changeProjectHover(id) {
      this.myProjects.forEach((project) => {
        if (project.id == id) {
          this.projectTitleHovered = project.name;
        }
      });
    },

    // mueve la etiqueta de usuarios asignados al proyecto
    moveAvatarCoordinates(e) {
      if ($(".div-general-menu-lateral").hasClass("container-close-sidebar")) {
        $(".myProjects-userAvatarHover").css({
          left: e.clientX - 56 + "px",
          top: e.clientY + window.pageYOffset - 20 + "px",
        });
      } else {
        $(".myProjects-userAvatarHover").css({
          left: e.clientX - 236 + "px",
          top: e.clientY + window.pageYOffset - 20 + "px",
        });
      }
    },

    // cambia el nombre que aperece en la etiqueta de usuario asignado al proyecto
    changeUserHover(id) {
      this.usersList.forEach((user) => {
        if (user.user_id == id) {
          this.userAvatarHovered = user.name;
        }
      });
    },

    // consigue el avatar del usuario, y lo sustituye por uno por defecto si no tiene
    getAvatar(avatar) {
      if (avatar != undefined && avatar != "" && avatar != "undefined") {
        if (avatar.includes("http")) {
          return avatar;
        }
        return process.env.VUE_APP_API_STORAGE + avatar;
      } else {
        return "/img/login_register/Avatar4.png";
      }
    },

    // muestra la barra de búsqueda
    search_bar(show) {
      if (show) {
        $(".tool-search-myProjects").addClass("search-big-newProject");
      } else {
        $(".tool-search-myProjects").removeClass("search-big-newProject");
      }
    },

    // hace focus sobre la barra de búsqueda
    focusSearchBar() {
      $("#project-search").focus();
    },
  },
  mixins: [translationMixin],
};
</script>
<style lang="scss">
@import "../../../src/assets/css/projects/myProjects2831.css";
@import "../../../src/assets/css/projects/myProjects.css";
@import "../../../src/assets/css/projects/copyProjects.css";
@import "../../../src/assets/css/projects/newProject.css";
@import "../../../src/assets/css/confirmation.css";
@import "../../../src/assets/css/smartPrinting.css";
</style>
