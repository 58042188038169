<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="modal-container-general">
    <div class="modal-content modal-content-resources">
      <div class="modal-header-resources">
        <h5 class="modal-title-resources">
          {{ langFilter("resources") }}
        </h5>
        <button class="confirmation-close-btn" @click="checkChanges" />
      </div>
      <div class="modal-body modal-body-resources">
        <div v-if="itinerary" class="modal-right-direction">
          <button class="modal-filter-button" @click="showFilter = !showFilter">
            <img
              style="margin-right: 8px"
              :src="require('@/assets/img/projects/filter.svg')"
            />
            <span class="modal-filter-text">{{ langFilter("filter") }}</span>
          </button>
          <button class="modal-lupa-button">
            <img :src="require('@/assets/img/general/lupa-purple.svg')" />
            <input
              v-model="filterSearch"
              :placeholder="langFilter('search')"
              class="modal-input-search"
              type="text"
              @keyup="filterResources"
            />
          </button>
          <div v-if="showFilter" class="modal-filter-modal">
            <div
              style="
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
              "
              @click="clickSelectAll"
            >
              <div
                class="modal-filter-tick"
                :style="
                  selectAll ? 'background: #2A3B5A;' : 'background: #D9D9D9;'
                "
                style="margin-left: 10px"
              >
                <img
                  :src="require('@/assets/img/projects/tick.svg')"
                  alt="tick"
                />
              </div>
              <span class="modal-filter-text-modal" style="width: auto">{{
                langFilter("selectAll")
              }}</span>
            </div>
            <div
              v-for="(chapters, chapterIndex) in updatedThemes"
              :key="'filter-' + chapterIndex"
              class="modal-each-modal-filter"
              @click="checkFilter(chapterIndex)"
            >
              <div class="modal-filter-text-modal">
                {{
                  langFilter("theme") +
                  " " +
                  (chapterIndex + 1) +
                  ". " +
                  chapters.title
                }}
              </div>
              <div
                class="modal-filter-tick"
                :style="
                  chapters.tick
                    ? 'background: #2A3B5A;'
                    : 'background: #D9D9D9;'
                "
              >
                <img
                  :src="require('@/assets/img/projects/tick.svg')"
                  alt="tick"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-for="(theme, themeIndex) in theme" :key="themeIndex">
          <div class="modal-div-resources">
            <button
              v-if="edit"
              class="new-btn-purple-add"
              @click="newResourceModal(themeIndex, null)"
            />
            <p>
              {{ langFilter("theme") }} {{ themeIndex + 1 }}.
              <span class="resource-bold-name">{{ theme.title }}</span>
            </p>
          </div>
          <draggable
            v-model="updatedThemes[themeIndex].resources"
            draggable=".resource"
            handle=".dragg-me-task"
            animation="500"
            ghostClass="ghost"
            chosenClass="chosen"
            :options="{
              animation: 500,
              handle: '.dragg-me-task',
              ghostClass: 'ghost',
              chosenClass: 'chosen',
              group: 'resource',
              itemKey: 'id',
            }"
          >
            <div
              v-for="(myresource, resourceIndex) in theme.resources"
              :key="resourceIndex"
              class="modal-div-resources-link resource"
            >
              <div class="content-resource-div">
                <div :class="getResourceIcon(myresource.link)" />
                <a :href="myresource.link" target="_blank">
                  <span class="resource-name">
                    {{ myresource.name }}
                  </span></a
                >
              </div>
              <new-template-creation-options
                v-if="edit"
                class="new-template-padding-question"
                :edit="true"
                :lang="lang"
                :themeIndex="themeIndex"
                :resourceIndex="resourceIndex"
                :type="3"
                @click="selectResource(themeIndex, null, resourceIndex)"
                @openModalConfirmation="openModalConfirmation($event)"
              />
            </div>
          </draggable>
          <div v-for="(apart, apartIndex) in theme.parts" :key="apartIndex">
            <div class="modal-div-resources resources-apart-title">
              <button
                v-if="edit"
                class="new-btn-purple-add"
                @click="newResourceModal(themeIndex, apartIndex)"
              />
              <p>
                {{ langFilter("part") }}
                {{ apartIndex + 1 }}.
                <span class="resource-bold-name">{{ apart.title }}</span>
              </p>
            </div>

            <draggable
              v-model="updatedThemes[themeIndex].parts[apartIndex].resources"
              draggable=".resource2"
              handle=".dragg-me-task"
              animation="500"
              ghostClass="ghost"
              chosenClass="chosen"
              :options="{
                animation: 500,
                handle: '.dragg-me-task',
                ghostClass: 'ghost',
                chosenClass: 'chosen',
                group: 'resource2',
                itemKey: 'id',
              }"
            >
              <div
                v-for="(myresource, resourceIndex) in apart.resources"
                :key="resourceIndex"
                class="modal-div-resources-link resources-apart-resource resource2"
              >
                <div class="content-resource-div">
                  <div class="link-icon-div" />
                  <a :href="getLink(myresource.link)" target="_blank">
                    <span class="resource-name">
                      {{ myresource.name }}
                    </span></a
                  >
                </div>

                <new-template-creation-options
                  v-if="edit"
                  class="new-template-padding-question"
                  :edit="true"
                  :lang="lang"
                  :themeIndex="themeIndex"
                  :apartIndex="apartIndex"
                  :resourceIndex="resourceIndex"
                  :type="3"
                  @click="selectResource(themeIndex, apartIndex, resourceIndex)"
                  @openModalConfirmation="openModalConfirmation($event)"
                />
              </div>
            </draggable>
          </div>
          <hr
            v-if="themeIndex + 1 !== updatedThemes.length"
            class="separator-resources"
          />
        </div>
      </div>
      <div class="modal-div-footer">
        <button
          v-if="edit"
          :class="[
            { 'modal-div-footer-btn-enabled': btnEnabled },
            'modal-div-footer-btn',
          ]"
          @click="confirmUpdate()"
        >
          {{ langFilter("save") }}
        </button>
      </div>
      <new-resource-modal
        v-if="showNewResourceModal"
        :editing="editing"
        :lang="lang"
        @newResourceModal="openCloseNewResourceModal"
        @addResource="addResource($event)"
      />
      <confirmation-modal
        v-if="confirmationModal"
        :lang="lang"
        body="noSave"
        :fixed="true"
        @closeConfirmation="showConfirmationModal"
        @confirm="confirmationConfirm"
        @cancel="confirmationCancel"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "themes", "title", "resource", "edit", "itinerary"],
  data() {
    return {
      showNewResourceModal: false,
      indexesSelected: [],
      confirmationModal: false,
      editing: null,
      confirmationData: "",
      closeResources: false,
      showFilter: false,
      filterSearch: "",
      selectAll: true,
      editIndex: null,
      updatedThemes: [],
      newResources: [],
      resourcesDeleted: [],
      btnEnabled: false,
      editedCount: 0,
      traducciones: [
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "part",
          es: "Apartado",
          en: "Part",
        },
        {
          name: "theme",
          es: "Tema",
          en: "Theme",
        },
        {
          name: "filter",
          es: "Filtrar",
          en: "Filter",
        },
        {
          name: "selectAll",
          es: "Seleccionar todo",
          en: "Select all",
        },
        {
          name: "search",
          es: "Buscar...",
          en: "Search...",
        },
      ],
    };
  },
  created() {
    this.updatedThemes = JSON.parse(JSON.stringify(this.themes));
    this.theme = this.updatedThemes;
  },
  methods: {
    getResourceIcon(link) {
      if (link.includes("api.acceleralia.com")) return "link-file-div";
      return "link-icon-div";
    },
    getLink(link) {
      if (link.includes("http://") || link.includes("https://")) return link;
      else return "https://api.acceleralia.com" + link;
    },
    checkChanges() {
      if (
        this.resourcesDeleted.length === 0 &&
        this.newResources.length === 0 &&
        this.editedCount === 0
      )
        this.$emit("closeResources");
      else this.confirmationClose();
    },
    clickSelectAll() {
      !this.selectAll
        ? this.updatedThemes.map((theme) => (theme.tick = true))
        : this.updatedThemes.map((theme) => (theme.tick = false));
      this.theme = this.updatedThemes.filter((theme) => theme.tick);
      this.selectAll = !this.selectAll;
    },
    filterResources() {
      this.theme = this.updatedThemes.filter((theme) =>
        theme.title.toLowerCase().includes(this.filterSearch.toLowerCase())
      );
    },
    checkFilter(index) {
      this.updatedThemes[index];
      if (!this.updatedThemes[index]["tick"])
        this.updatedThemes[index]["tick"] = true;
      else this.updatedThemes[index]["tick"] = false;
      this.theme = this.updatedThemes.filter((theme) => theme.tick);
      this.selectAll = false;
    },
    confirmUpdate() {
      if (this.btnEnabled) {
        //     // this.resourcesDeleted.forEach((resource) => {
        //     //   this.deleteResourceDb(resource);
        //     // });
        //     this.$emit("updateResources", this.updatedThemes);
        //     // this.$emit(
        //     //   "resourcesChanged",
        //     //   this.newResources,
        //     //   this.resourcesDeleted
        //     // );
        //     this.updatedThemes = [];
        //     this.resourcesDeleted = [];
        //     this.newResources = [];
        //   }
        this.$emit("updateResources", this.updatedThemes);
      }
    },
    selectResource(themeIndex, apartIndex, resourceIndex) {
      this.editIndex = [themeIndex, apartIndex, resourceIndex];
      if (apartIndex !== null) {
        this.editing =
          this.updatedThemes[themeIndex].parts[apartIndex].resources[
            resourceIndex
          ];
      } else {
        this.editing = this.updatedThemes[themeIndex].resources[resourceIndex];
      }
    },
    showConfirmationModal() {
      this.confirmationModal = !this.confirmationModal;
    },
    confirmationClose() {
      if (this.edit) {
        this.confirmationModal = true;
        this.closeResources = true;
      } else {
        this.$emit("closeResources");
      }
    },
    confirmationCancel() {
      this.closeResources = false;
      this.confirmationData = "";
      this.showConfirmationModal();
    },
    confirmationConfirm() {
      this.showConfirmationModal();
      if (this.closeResources) {
        this.newResources.forEach((resource) => {
          this.deleteResourceDb(resource);
        });
        this.$emit("closeResources");
      } else {
        this.deleteResource();
      }
      this.closeResources = false;
    },
    checkIfResourcesNamesAreEmpty() {
      let empty = false;
      this.updatedThemes.forEach((theme) => {
        theme.resources.forEach((resource) => {
          if (resource.name === "") {
            empty = true;
          }
        });
        theme.parts.forEach((apart) => {
          apart.resources.forEach((resource) => {
            if (resource.name === "") {
              empty = true;
            }
          });
        });
      });
      return empty;
    },
    checkIfResourceNameIsEmpty(themeIndex, apartIndex, resourceIndex) {
      if (apartIndex == null) {
        return this.updatedThemes[themeIndex].resources[resourceIndex].name ===
          ""
          ? true
          : false;
      } else {
        return this.updatedThemes[themeIndex].parts[apartIndex].resources[
          resourceIndex
        ].name === ""
          ? true
          : false;
      }
    },
    updateResourceName(event, themeIndex, apartIndex, resourceIndex) {
      const value = event.target.innerText;
      if (apartIndex == null) {
        this.updatedThemes[themeIndex].resources[resourceIndex].name = value;
      } else {
        this.updatedThemes[themeIndex].parts[apartIndex].resources[
          resourceIndex
        ].name = value;
      }
    },
    addResource(resource) {
      if (!this.newResources.includes(resource.id) && this.editIndex === null) {
        this.newResources.push(resource.id);
      }
      this.openCloseNewResourceModal();
      if (this.editIndex !== null) {
        this.editedCount++;
        this.btnEnabled = true;
        if (this.editIndex[1] === null) {
          this.updatedThemes[this.editIndex[0]].resources[this.editIndex[2]] =
            resource;
          this.editIndex = null;
          this.editing = null;
          return;
        }
        this.updatedThemes[this.editIndex[0]].parts[
          this.editIndex[1]
        ].resources[this.editIndex[2]] = resource;
        this.editIndex = null;
        this.editing = null;
        return;
      }
      if (this.indexesSelected[1] === null) {
        this.updatedThemes[this.indexesSelected[0]].resources.push(resource);
      } else {
        this.updatedThemes[this.indexesSelected[0]].parts[
          this.indexesSelected[1]
        ].resources.push(resource);
      }
      this.indexesSelected = [];
      this.btnEnabled = true;
    },
    openCloseNewResourceModal() {
      this.showNewResourceModal = !this.showNewResourceModal;
    },
    newResourceModal(themeIndex, apartIndex) {
      this.indexesSelected = [themeIndex, apartIndex];
      this.editing = null;
      this.openCloseNewResourceModal();
    },
    openModalConfirmation(data) {
      if (data[1] !== 3) {
        this.newResourceModal(0, null);
      } else {
        this.confirmationData = data;
        this.showConfirmationModal();
      }
    },
    deleteResourceDb(id) {
      axios.delete(
        `${process.env.VUE_APP_API_URL}/resources/deleteResource/${id}`
      );
    },
    deleteResource() {
      const data = this.confirmationData;
      const index = data[2];
      if (index[1] == null) {
        this.resourcesDeleted.push(
          this.updatedThemes[index[0]].resources[index[3]].id
        );
        this.updatedThemes[index[0]].resources.splice(index[3], 1);
      } else {
        this.resourcesDeleted.push(
          this.updatedThemes[index[0]].parts[index[1]].resources[index[3]].id
        );
        this.updatedThemes[index[0]].parts[index[1]].resources.splice(
          index[3],
          1
        );
      }
      this.btnEnabled = true;
    },
  },
  mixins: [translationMixin],
};
</script>

<style lang="scss">
@import "../../../src/assets/css/resources/resources.css";
</style>
