<script setup>
import Parts from "../components/solution/SolutionComponent.vue";
</script>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <Parts :space="spaces[0]" :lang="lang" :role="role" />
</template>

<style>
@import "../../src/assets/css/solution/solution.css";
</style>
