<template>
  <div class="chat-avatar" :class="cssClass">{{ label }}</div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    fontColor: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    cssClass: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.chat-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 20px;
  height: 42px;
  width: 42px;
  font-family: "Poppins-Medium";
  font-size: 18px;
}
</style>
