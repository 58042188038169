<template>
  <div>
    <tongue-container
      ref="tongueContainer"
      :newNotifications="countNotifications"
      :lang="lang"
    />
    <notifications
      v-show="showNotificationsModal"
      id="notification_modal"
      ref="notifications"
      v-outside="closeNotifications"
      :lang="lang"
      :banner="true"
      :space="space[0]"
      @close-notifications="closeNotificationsModal"
      @view="changeView($event)"
      @reload-home="reloadHome"
    />
    <tongue-modal
      v-if="showTongueModal"
      v-outside="closeTongueModal"
      :lang="lang"
      @close-tongue="closeTongue"
      @view="changeView($event)"
    />
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: {
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Array,
      required: true,
    },
  },
  emits: ["view", "reloadHome"],
  data() {
    return {
      showNotificationsModal: false,
      showTongueModal: false,
      countNotifications: "",
    };
  },
  watch: {
    showNotificationsModal: function (val) {
      if (val === true) {
        this.showTongueModal = false;
      }
    },
    showTongueModal: function (val) {
      if (val === true) {
        this.showNotificationsModal = false;
      }
    },
  },
  mounted() {
    setTimeout(
      function () {
        this.setnotifications();
      }.bind(this),
      1
    );
  },
  methods: {
    changeView(id) {
      this.showTongueModal = false;
      this.$emit("view", id);
    },
    closeTongueModal(el) {
      if (el.target.id !== "modal_img_tongue") {
        this.showTongueModal = false;
      }
    },
    closeTongue() {
      this.showTongueModal = false;
    },
    closeNotifications(el) {
      if (el.target.id !== "img_bell") {
        this.showNotificationsModal = false;
      }
    },
    closeNotificationsModal() {
      this.showNotificationsModal = false;
    },
    verifymodals() {
      $("#invisible_modal").toggleClass("hide");
    },
    setnotifications() {
      this.$refs.notifications.notificationCount();
    },
    reloadHome() {
      this.$emit("reloadHome");
    },
  },
};
</script>
