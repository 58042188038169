<template>
  <div class="inputs">
    <label class="consultaHeader"> {{ langFilter("nombre") }}</label>
    <input
      id="nombreApellido"
      :value="name"
      :placeholder="langFilter('nombre-placeholder')"
      readonly
    />
    <label class="consultaHeader">Email</label>
    <input
      id="correo"
      :value="email"
      :placeholder="langFilter('email-placeholder')"
      readonly
    />
    <label class="consultaHeader"> {{ langFilter("consulta") }}</label>
    <textarea
      id="areaConsulta"
      v-model="message"
      :placeholder="langFilter('consulta-placeholder')"
    ></textarea>
  </div>
  <button
    id="enviar"
    :class="{ enviarClickable: isFormValid && !sendMessage }"
    :disabled="!isFormValid || sendMessage"
    @click="handleSubmit"
  >
    {{ langFilter("enviar") }}
  </button>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: ["submit"],
  data() {
    return {
      sendMessage: false,
      message: "",
      traducciones: [
        {
          name: "consulta",
          es: "Consulta",
          en: "Query",
        },
        {
          name: "nombre-placeholder",
          es: "Escribe aquí tu nombre...",
          en: "Write here your name...",
        },
        {
          name: "email-placeholder",
          es: "Escribe aquí tu email...",
          en: "Write here your email...",
        },
        {
          name: "consulta-placeholder",
          es: "Escribe aquí tu mensaje...",
          en: "Write here your message...",
        },
        { name: "enviar", es: "Enviar", en: "Send" },
        { name: "nombre", es: "Nombre", en: "Name" },
        { name: "contacto", es: "Contacto", en: "Contact" },
      ],
    };
  },
  computed: {
    isFormValid() {
      return (
        this.name.trim() !== "" &&
        this.email.trim() !== "" &&
        this.message.trim() !== ""
      );
    },
  },
  methods: {
    handleSubmit() {
      if (this.isFormValid) {
        this.sendMessage = true;
        this.$emit("submit", {
          message: this.message,
          name: this.name,
          email: this.email,
        });
      }
    },
  },
};
</script>
