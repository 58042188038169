<template>
  <div class="confirmation-backdrop">
    <div
      :class="[
        {
          'confirmation-modal-fixed': fixed,
          'confirmation-container': !fixed,
          'full-width': fullWidth,
        },
        'modal-content',
      ]"
    >
      <button class="confirmation-close-btn" @click="closeConfirmation" />
      <div class="confirmation-body">
        <p class="confirmation-txt">
          {{ langFilter(body) }}
        </p>
        <p v-if="warning">
          <span class="confirmation-warning">
            {{ langFilter(warning) }}
          </span>
        </p>
      </div>
      <div class="confirmation-footer">
        <button
          class="confirmation-btn confirmation-btn-cancel"
          @click="closeConfirmation"
        >
          {{ langFilter(info ? "close" : "cancel") }}
        </button>
        <button v-if="!info" class="confirmation-btn" @click="confirm">
          {{ langFilter("continue") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    fixed: {
      type: Boolean,
      required: true,
    },
    warning: {
      type: String,
      required: false,
      default: "",
    },
    info: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeConfirmation", "confirm"],
  data() {
    return {
      traducciones: [
        {
          name: "cancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "close",
          es: "Cerrar",
          en: "Close",
        },
        {
          name: "continue",
          es: "Continuar",
          en: "Continue",
        },
        {
          name: "noSave",
          es: "Se perderán los datos no guardados. ¿Deseas continuar?",
          en: "Unsaved data will be lost. Do you want to continue?",
        },
        {
          name: "deleteSure",
          es: "¿Estás seguro que lo quieres eliminar?",
          en: "Are you sure you want to delete?",
        },
        {
          name: "areYouSureToExitFromConfig",
          es: "Estás seguro de que desea salir de la configuración del proyecto? Se perderán todos los cambios",
          en: "Are you sure you want to exit the project settings? All changes will be lost",
        },
        {
          name: "areYouSureToExit",
          es: "Está seguro de que desea salir? Se perderán todos los cambios",
          en: "Are you sure you want to exit the project? All changes will be lost",
        },
      ],
    };
  },
  created() {},
  methods: {
    closeConfirmation() {
      this.$emit("closeConfirmation");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss">
@import "../../../src/assets/css/modals/confirmation.css";
</style>
