<template>
  <div>
    <div v-if="error" class="error-margin-top">
      <Error
        title="permiso-title"
        body="permiso-body"
        link="link-redirect"
        image="`<img class='cohete-espacio' id='cohete' src='/img/errors/no_autorizado.svg'>`"
        vector="/img/errors/vector_158.svg"
        vamos="permiso-volver"
        header="true"
        :lang="lang"
      />
    </div>
    <div v-else>
      <cdti-modal :project_id="project_id" :lang="lang" />
      <header-general
        :title="langFilter('itinerario')"
        src="/img/icons/itinerary.svg"
      />
      <div class="margen-general-itinerary">
        <itinerary-usermodal
          ref="user_modal"
          :lang="lang"
          :projectId="project_id"
          :projectName="project_name"
          :web="web"
          :modalSources="modalSources"
          :space="space"
        />
        <resources ref="resource" newRec="2"></resources>
        <ResourcesStation ref="resourcestation" newRec="2"></ResourcesStation>
        <carrousel
          class="allign-center-projects"
          :projectId="project_id"
          :web="web"
          :lang="lang"
          :userRole="user_role"
          :loading="$parent.loading"
          :space="space"
          page="itinerary"
          showHeader="true"
          @change-project="changeProject($event)"
          @loading="newLoading($event)"
        />
        <div v-if="loading" class="loading-gif-div">
          <img :src="require('@/assets/img/general/carga.gif')" />
        </div>
        <div v-else id="body_itinerario" class="allign-center-projects">
          <div>
            <itinerary-headertable
              id="table_header_itinerary"
              :lang="lang"
              :cascade="is_cascade"
            />
            <itinerary-themetable
              v-for="(station, stationIndex) in stations_id"
              :id="'table_theme_' + stations_id[stationIndex].station_id"
              ref="itineraryStation"
              :key="stationIndex"
              :lang="lang"
              :percentage="calculatePercentage(stationIndex)"
              :projectId="project_id"
              :cascade="is_cascade"
              :stationId="stations_id[stationIndex].station_id"
              :resources="stations_id[stationIndex].resources"
              :name="stations_id[stationIndex].name"
              :description="stations_id[stationIndex].description"
              :state="stations_id[stationIndex].solutions_status"
              :stateCascade="
                stations_id[cascade_project_id(stationIndex)].solutions_status +
                '/' +
                stationIndex
              "
              :filter="filter"
              :user_role="user_role"
            />
            <div id="not_Found" class="hide no-hay-estado-itinerary">
              <span>{{ langFilter("noHayEstado") }}</span>
            </div>
          </div>
          <p class="text-team-itinerary team-text-itinerary">
            {{ langFilter("equipo") }}
          </p>
          <div
            class="wrapper-itinerary"
            :style="
              'margin-left: 35px; margin-right: 35px; ' +
              (user_role == 'Tutor' ? '' : 'margin-bottom: 75px;')
            "
          >
            <itinerary-profile
              v-for="(profile, profilesIndex) in profiles"
              :id="profilesIndex"
              ref="profile"
              :key="profilesIndex"
              :lang="lang"
              :projectIid="project_id"
              :userRole="user_role"
              :name="profiles[profilesIndex].name"
              :role="profiles[profilesIndex].role"
              :email="profiles[profilesIndex].email"
              :userId="profiles[profilesIndex].id"
              :avatar="profiles[profilesIndex].meta_value"
              :web="web"
              :spaceId="space.id"
            ></itinerary-profile>
          </div>
          <div v-if="user_role == 'Tutor'" style="text-align: center">
            <button
              data-bs-toggle="modal"
              data-bs-target="#usersModal"
              class="add-button-itinerary"
              @click="addUserProject()"
            >
              {{ langFilter("add") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      usersSelected: [],
      filterStations: [],
      hover: false,
      web: "",
      project_id: "",
      project_name: "",
      user_id: "",
      stations_id: [],
      profiles: [],
      selectedStation_id: "",
      solutionsResources_id: [],
      is_cascade: false,
      filter: "",
      user_role: "",
      modalSources: [],
      error: false,
      pdfFile: null,

      //Traducción
      traducciones: [
        {
          name: "itinerario",
          es: "Proyecto",
          en: "Project",
        },
        {
          name: "pendiente",
          es: "Pendiente",
          en: "Pending",
        },
        {
          name: "revision",
          es: "Pendiente de revisión",
          en: "Pending review",
        },
        {
          name: "modificacion",
          es: "Pendiente de modificación",
          en: "Pending modification",
        },
        {
          name: "edit_name",
          es: "Editar nombre",
          en: "Edit name",
        },
        {
          name: "guardar",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "completado",
          es: "Completado",
          en: "Completed",
        },
        {
          name: "cascada",
          es: "Proyecto en cascada",
          en: "Cascade Project",
        },
        {
          name: "tiene",
          es: "El usuario tiene un ",
          en: "The user have a ",
        },
        {
          name: "progreso",
          es: "progreso escalonado ",
          en: "staggered progress ",
        },
        {
          name: "ver",
          es: "Ver más",
          en: "View more",
        },
        {
          name: "recursos",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "estado",
          es: "Estado",
          en: "State",
        },
        {
          name: "fichero",
          es: "Subida ficheros",
          en: "Files upload",
        },
        {
          name: "libre",
          es: "Respuesta libre",
          en: "Free answer",
        },
        {
          name: "simple",
          es: "Respuesta simple",
          en: "Simple answer",
        },
        {
          name: "multiple",
          es: "Respuesta multiple",
          en: "Multiple answer",
        },
        {
          name: "estados",
          es: "Estados",
          en: "States",
        },
        {
          name: "opcional",
          es: "Opcional",
          en: "Optional",
        },
        {
          name: "casc_proj",
          es: "El Proyecto en cascada se trata de un progreso escalonado.",
          en: "The cascade Project is about stepwise progress.",
        },
        {
          name: "casc_desc",
          es: "(El usuario no podrá seguir adelante en su progreso hasta ",
          en: "(The user will not be able to continue their progress ",
        },
        {
          name: "casc_desc2",
          es: "completar la tarea que tenga pendiente.)",
          en: "until the pending task is completed.)",
        },
        {
          name: "apartados",
          es: "Apartados",
          en: "Sections",
        },
        {
          name: "buscar",
          es: "Buscar",
          en: "Search",
        },
        {
          name: "user_proj",
          es: "AÑADIR USUARIO AL PROYECTO",
          en: "ADD USER TO PROJECT",
        },
        {
          name: "pregunta",
          es: "P*=Pregunta",
          en: "Q*=Question",
        },
        {
          name: "atras",
          es: "Atrás",
          en: "Back",
        },
        {
          name: "si",
          es: "Si",
          en: "Yes",
        },
        {
          name: "seguro",
          es: "¿Estas seguro que quieres eliminar a ",
          en: "Are you sure you want to delete ",
        },
        {
          name: "del_proj",
          es: " del proyecto",
          en: " from the project",
        },
        {
          name: "delete_user",
          es: "Eliminar usuario",
          en: "Delete user",
        },
        {
          name: "Tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "Emprendedor",
          es: "Emprendedor",
          en: "Entrepreneur",
        },
        {
          name: "equipo",
          es: "Equipo",
          en: "Team",
        },
        {
          name: "add",
          es: "Añadir Miembros",
          en: "Add Members",
        },
        {
          name: "name_vacio",
          es: "El nombre del proyecto no puede estar vacío.",
          en: "The project name cannot be empty.",
        },
        {
          name: "error",
          es: "Error, por favor contacta con support@acceleralia.com.",
          en: "Error, please contact support@acceleralia.com.",
        },
        {
          name: "error_usr",
          es: "Añade al menos un usuario",
          en: "Add at least one user",
        },
        {
          name: "noHay",
          es: "No hay estaciones asociadas a este proyecto",
          en: "There are no station associated to this project",
        },
        {
          name: "noHayEstado",
          es: "No hay apartados con este estado",
          en: "There are no parts with this state",
        },
      ],
    };
  },
  created() {
    $("#carga").attr("style", "left: 60% !important");
    this.create_itinerary();
  },

  mounted() {
    this.appcarga();
    this.bodycarga();
  },

  methods: {
    newLoading(e) {
      this.loading = e;
    },
    changeProject(project) {
      this.loading = true;
      history.replaceState(null, null, `/itinerary/${project}`);
      this.create_itinerary();
    },
    reloadRoles(user_id, role) {
      this.$nextTick(() => {
        this.$refs.profile.reloadRole(user_id, role);
      });
    },
    filterResource() {
      this.filter = this.$refs.filter.filter;
    },
    setfilter(filt) {
      filt = this.filter;
      return filt;
    },
    getResourcesStation(station) {
      this.$refs.resourcestation.getResources(station);
    },
    getResources(solution) {
      this.$refs.resource.getResources(solution);
    },
    addUserProject() {
      this.$refs.user_modal.getAllUsers();
      this.$refs.user_modal.deleteEverything();
    },
    showModal() {
      this.$refs.modalComponent.showInfo();
    },
    hideSolutions() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/hideSolutions`, {
          filter: this.filter,
          project_id: this.project_id,
        })
        .then((response) => {
          this.filterStations = response.data;
          let cont = 0;
          this.filterStations.forEach(function (element) {
            if (element[1] != 0) {
              $("#table_theme_" + element[0]).show();
              cont++;
            } else {
              $("#table_theme_" + element[0]).hide();
            }
          });
          if (cont == 0) {
            $("#not_Found").show();
            $("#table_header_itinerary").hide();
          } else {
            $("#not_Found").hide();
            $("#table_header_itinerary").show();
          }
          this.$nextTick(() => {
            this.$refs.itineraryStation.forEach((element) =>
              element.hideEverySolution()
            );
          });
        });
    },

    calculatePercentage(i) {
      if (this.stations_id[i].countQuestions == 0) {
        return 100;
      }
      return Math.round(
        (this.stations_id[i].countAnswers * 100) /
          this.stations_id[i].countQuestions
      );
    },
    create_itinerary() {
      if (this.role == 1) {
        this.user_role = "Tutor";
      } else {
        this.user_role = "Emprendedor";
      }
      this.loading = true;
      this.web = window.location.pathname;
      let ids = this.web.split("/");
      this.project_id = ids[2];
      this.stations_id = [];
      this.authConfirm();

      axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/getStation`, {
          project_id: this.project_id,
        })
        .then((response) => {
          this.stations_id = response.data;
          if (this.stations_id[0].cascade_structure != 0) {
            this.is_cascade = true;
          }
          this.$nextTick(() => {
            this.$refs.itineraryStation.forEach((element) => {
              element.hideEverySolution();
              element.onCreated_Stations();
            });
          });
        });
      axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/getProfile`, {
          project_id: this.project_id,
          space_id: this.space.id,
        })
        .then((response) => {
          this.profiles = response.data;
          this.profiles.forEach((element) => {
            if (element.role == 1) {
              element.role = "Tutor";
            } else {
              element.role = "Emprendedor";
            }
          });
          this.loading = false;
        });
    },
    authConfirm() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/confirmLoggedUser`, {
          project_id: this.project_id,
        })
        .then((response) => {
          this.error = response.data;
        });
    },
    cascade_project_id(index) {
      if (index == 0) {
        return 0;
      } else {
        return index - 1;
      }
    },
    appcarga() {
      $("#app").show();
    },
    bodycarga() {
      $("#carga").hide();
      $("#body_itinerario").show();
    },
    update_profiles() {
      this.profiles = [];
      axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/getProfile`, {
          project_id: this.project_id,
          space_id: this.space.id,
        })
        .then((response) => {
          this.profiles = response.data;
          this.profiles.forEach((element) => {
            if (element.role == 1) {
              element.role = "Tutor";
            } else {
              element.role = "Emprendedor";
            }
          });
        });
    },
  },
};
</script>
