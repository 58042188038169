<template>
  <div style="display: flex; justify-content: center">
    <div
      v-if="showTitleHover === true"
      id="hover_carrousel"
      :style="styles"
      class="carrousel-title-hover"
    >
      {{ hover_ProjectName }}
    </div>
    <div
      v-show="showHeader"
      class="itineraryCarr-arrow"
      @click="backCarrousel()"
    >
      <svg
        width="9"
        height="20"
        viewBox="0 0 9 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="itineraryCarr-arrow2"
          d="M8.06964 18.4784C8.47706 19.1447 7.99751 20 7.21648 20L6.66153 20C6.31324 20 5.99004 19.8188 5.80836 19.5216L0.318247 10.5423C0.122831 10.2227 0.122441 9.82066 0.317237 9.50067L5.80863 0.480015C5.99009 0.181926 6.31383 -2.34833e-07 6.6628 -2.04324e-07L7.22052 -1.55567e-07C8.00069 -8.73629e-08 8.48037 0.85359 8.0747 1.51999L3.2164 9.50067C3.0216 9.82065 3.02199 10.2227 3.21741 10.5423L8.06964 18.4784Z"
          fill="#818A9A"
        />
      </svg>
    </div>
    <div v-if="loading" class="loading-carrousel">
      <img :src="require('@/assets/img/general/carga.gif')" />
    </div>
    <div v-else class="itinerary-carrouselContainer">
      <div
        v-for="(proj, projindex) in all_projects"
        :id="'carr_project_' + projindex"
        :key="projindex"
        class="itineraryEachProject"
        :class="
          projindex === indexfocus
            ? 'itineraryCarr-focus'
            : 'itineraryCarr-graytext'
        "
        :style="
          projindex === 0
            ? marginfirst()
            : projindex > index - 2
              ? ''
              : 'margin: 0 0 0 -33.33%'
        "
        @mouseenter="carrouselHover($event)"
        @mouseleave="carrouselHoverOut()"
        @click="changeProj($event, page, projindex)"
      >
        <div class="itinerary-textCarrousel">
          <span
            :id="'project_name_' + projindex"
            style="
              text-align: center;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            "
            >{{ proj.name }}</span
          >
          <span
            v-if="
              all_projects[indexfocus].projectId ===
                all_projects[projindex].projectId && page === 'itinerary'
            "
            id="input_name"
            class="text-input-acceleralia-itinerarios hide"
            role="textbox"
            contenteditable
            >{{ proj.name }}</span
          >
          <div v-show="showHeader" class="row itineraryCarr-perinput">
            <div class="col-7">
              <input
                :style="
                  proj.percentage >= 100
                    ? '--color: var(--green);'
                    : '--color: var(--purple);'
                "
                class="itineraryCarr-percentage"
                type="range"
                :value="proj.percentage ? 100 - proj.percentage : 100"
                disabled
              />
            </div>
            <span style="white-space: nowrap; margin-left: -15px" class="col-2"
              >{{ proj.percentage > 100 ? 100 : proj.percentage }}%</span
            >
          </div>
          <div
            v-if="
              all_projects[indexfocus].projectId ===
                all_projects[projindex].projectId && page === 'itinerary'
            "
          >
            <span
              id="edit_name"
              class="text-edit-save-name"
              style="cursor: pointer"
              @click="editName()"
              @mouseover="passmouse()"
              @mouseout="outmouse()"
            >
              {{ langFilter("editar") }}</span
            >
            <span
              id="save_project_name"
              class="text-edit-save-name hide"
              style="cursor: pointer"
              @click="saveName()"
              @mouseover="passmouse()"
              @mouseout="outmouse()"
            >
              {{ langFilter("guardar") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="showHeader"
      class="itineraryCarr-arrow"
      @click="nextCarrousel()"
    >
      <svg
        width="9"
        height="20"
        viewBox="0 0 9 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="itineraryCarr-arrow2"
          d="M0.930356 1.52164C0.522941 0.855294 1.00249 0 1.78352 0L2.33847 0C2.68676 0 3.00996 0.181214 3.19164 0.478361L8.68175 9.45771C8.87717 9.77732 8.87756 10.1793 8.68276 10.4993L3.19137 19.52C3.00991 19.8181 2.68617 20 2.3372 20H1.77948C0.999315 20 0.51963 19.1464 0.925303 18.48L5.7836 10.4993C5.9784 10.1793 5.97801 9.77732 5.7826 9.45771L0.930356 1.52164Z"
          fill="#818A9A"
        />
      </svg>
    </div>
    <alertMessage ref="alerts" :lang="lang"></alertMessage>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  name: "ItineraryCarrouselComponent",
  mixins: [translationMixin],

  props: {
    projectId: {
      type: [String, Number],
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    showHeader: {
      type: Boolean,
      required: true,
    },
    percentage: {
      type: Number,
      required: true,
    },
  },
  emits: ["changeProject"],
  a() {
    return {
      loading: true,
      index: 0,
      indexfocus: 0,
      project: "",
      all_projects: [],
      shown_projects: [],
      errors: [],
      succesful: [],
      hover_ProjectName: "",
      x: 0,
      y: 0,
      showTitleHover: false,
      timer: null,
      traducciones: [
        {
          name: "guardar",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "editar",
          es: "Editar Nombre",
          en: "Edit Name",
        },
        {
          name: "alerttitle",
          es: "El nombre del proyecto vacío",
          en: "Project name empty",
        },
        {
          name: "alerttext",
          es: "Por favor, introduzca un nombre para el proyecto",
          en: "Please, enter a name for the project",
        },
      ],
    };
  },

  computed: {
    styles() {
      return {
        left: percent(this.x - 180),
        top: percent(this.y - 60),
      };

      function percent(value) {
        return value + "px";
      }
    },
  },
  watch: {
    percentage: function (newVal) {
      this.all_projects[this.indexfocus].percentage = newVal;
    },
  },
  created() {
    this.project = this.projectId;
    this.on_created_carrousel();
  },
  mounted() {
    document.addEventListener("mousemove", this.onMouseMove);
  },

  unmounted() {
    document.removeEventListener("mousemove", this.onMouseMove);
  },
  methods: {
    onMouseMove(ev) {
      this.x = ev.clientX;
      this.y = ev.clientY;
    },
    carrouselHover(event) {
      if ($("#input_name").is(":visible")) {
        return;
      }
      this.hover_ProjectName = event.target.innerText.split(/\r?\n/)[0];
      this.hover_X = event.clientX;
      this.hover_Y = event.clientY;
      this.timer = setTimeout(() => {
        this.showTitleHover = true;
      }, 1000);
    },
    carrouselHoverOut() {
      this.showTitleHover = false;
      clearTimeout(this.timer);
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "6s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 6000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);

      this.errors = [];
    },
    marginfirst() {
      if (this.index === 0) {
        return "margin: 0 0 0 33.33%;";
      } else if (this.index === 1) {
        return "margin: 0 0 0 0;";
      }
      return "margin: 0 0 0 -33.33%;";
    },
    showHeaderTitle() {
      if (
        this.page === "solution" &&
        this.all_projects[this.indexfocus].projectId === this.projectId
      ) {
        return true;
      }
      return false;
    },
    changeProj(event, page, index) {
      if (index === this.indexfocus) return;
      this.indexfocus = index;
      if (this.indexfocus < this.index) {
        this.backCarrousel();
      } else if (this.indexfocus > this.index) {
        this.nextCarrousel();
      }
      this.index = this.indexfocus;
      if (page === "itinerary") {
        this.$emit(
          "changeProject",
          this.all_projects[this.indexfocus].project_id
        );
      } else if (page === "solution") {
        this.$emit(
          "changeProject",
          this.all_projects[this.indexfocus].project_id
        );
      }
    },
    nextCarrousel() {
      if (this.index < this.all_projects.length - 1 && this.index !== 0) {
        document.getElementById("carr_project_" + (this.index - 1)).style =
          "margin: 0 0 0 -33.33%";
        this.index++;
      } else if (this.index === 0) {
        document.getElementById("carr_project_" + this.index).style =
          "margin: 0 0 0 0";
        this.index++;
      }
    },
    backCarrousel() {
      if (this.index > 1) {
        document.getElementById("carr_project_" + (this.index - 2)).style =
          "margin: 0 0 0 0;";
        this.index--;
      } else if (this.index === 1) {
        document.getElementById("carr_project_" + (this.index - 1)).style =
          "margin: 0 0 0 33.33%;";
        this.index--;
      }
    },
    passmouse() {
      $("#edit_name").removeClass("text-edit-save-name");
      $("#edit_name").addClass("text-edit-save-name-hover");
      $("#save_project_name").removeClass("text-edit-save-name");
      $("#save_project_name").addClass("text-edit-save-name-hover");
    },
    outmouse() {
      $("#edit_name").addClass("text-edit-save-name");
      $("#edit_name").removeClass("text-edit-save-name-hover");
      $("#save_project_name").addClass("text-edit-save-name");
      $("#save_project_name").removeClass("text-edit-save-name-hover");
    },
    saveName() {
      if ($("#input_name").text() !== "") {
        let name = $("#input_name").text();
        axios
          .post(`${process.env.VUE_APP_API_URL}/itinerary/setProjectName`, {
            name: name,
            projectId: this.projectId,
          })
          .then(() => {
            $("#project_name_" + this.indexfocus).show();
            $("#edit_name").show();
            $("#input_name").hide();
            $("#save_project_name").hide();
            $("#project_name_" + this.indexfocus).text(name);
          });
      } else {
        this.$refs.alerts.title = this.langFilter("alerttitle");
        this.$refs.alerts.text = this.langFilter("alerttext");
        this.openErrorsAlert();
      }
    },
    editName() {
      $("#project_name_" + this.indexfocus).hide();
      $("#edit_name").hide();
      this.showTitleHover = false;
      $("#input_name").show();
      $("#save_project_name").show();
    },
    checkProjectName(index) {
      if (this.shown_projects[index] != null) {
        return this.shown_projects[index].name;
      } else {
        return null;
      }
    },
    on_created_carrousel() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/getProjects`, {
          space_id: this.space.id,
        })
        .then((response) => {
          if (response.data == "") {
            this.$parent.error = true;
          } else {
            this.all_projects = response.data;
            this.index = response.data.findIndex(
              (item) => item.project_id == this.project
            );
            this.indexfocus = this.index;
            this.shown_projects = [
              this.all_projects[this.index - 1],
              this.all_projects[this.index],
              this.all_projects[this.index + 1],
            ];
            this.$parent.project_name = this.shown_projects[1].name;
            this.$parent.modalSources = this.shown_projects[1];
            this.project = this.shown_projects[1].projectId;
            this.$parent.space_id = this.all_projects[this.index].space_id;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
