<template>
  <div>
    <p>Search Space</p>
    <select @change="changeSpace($event)">
      <option
        v-for="(space, spaceIndex) in spaces"
        :key="spaceIndex"
        :value="spaceIndex"
      >
        {{ space.name }}
      </option>
    </select>
    <br /><br />
    <p>Name</p>
    <input v-model="spaceSelectedName" type="text" />
    <br /><br />
    <p>Img</p>
    <input
      :key="fileInputKey"
      accept="image/x-png,image/gif,image/jpeg"
      multiple
      type="file"
      @change="getFile"
    />
    <br />
    <br />
    <div v-if="editingUsers">
      <user-table-space
        :space_id="spaceSelected.id"
        @add-user="addUser($event)"
        @remove-user="removeUser($event)"
      />
    </div>
    <button class="btn btn-secondary" @click="setEditingUsers">
      {{ editingUsers ? "Cancel Users" : "Edit Users" }}
    </button>
    <br /><br />
    <button class="btn btn-primary" @click="updateSpace">Update</button>
    <br />
    <br />
  </div>
</template>

<script>
import axios from "axios";
export default {
  emits: ["msg"],
  data() {
    return {
      spaces: [],
      users: [],
      spaceSelected: "",
      spaceSelectedName: "",
      file: null,
      fileInputKey: 0,
      editingUsers: false,
      prevUsers: [],
      addedUsers: [],
    };
  },
  created() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/manageSpaces-acc/getSpaces`)
      .then((response) => {
        this.spaces = response.data;
        this.spaceSelected = this.spaces[0];
        this.spaceSelectedName = this.spaceSelected.name;
      });
  },
  methods: {
    changeSpace(event) {
      this.spaceSelected = this.spaces[event.target.value];
      this.spaceSelectedName = this.spaceSelected.name;
      this.file = null;
      this.editingUsers = false;
      this.fileInputKey++;
      this.prevUsers = [];
    },
    getFile(event) {
      this.file = event.target.files[0];
    },
    addUser(user) {
      this.users = user;
      // this.addedUsers.push(user[user.length - 1]);
      if (this.prevUsers.length == 0) {
        this.prevUsers = this.users;
      }
    },
    removeUser(user) {
      this.users = this.users.filter((u) => u.id != user);
      this.addedUsers = this.addedUsers.filter((u) => u.id != user);
    },
    setEditingUsers() {
      this.prevUsers = [];
      this.addedUsers = [];
      this.users = [];
      this.editingUsers = !this.editingUsers;
    },
    updateSpace() {
      const formData = new FormData();
      formData.append("id", this.spaceSelected.id);
      formData.append("name", this.spaceSelectedName);
      formData.append("file", this.file);
      formData.append("users", JSON.stringify(this.users));

      //Get the users that were removed
      const deleted_users = this.prevUsers.filter(
        (u) => !this.users.includes(u),
      );

      // formData.append("add_users", JSON.stringify(this.addedUsers));
      formData.append("deleted_users", JSON.stringify(deleted_users));

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/manageSpaces-acc/updateSpace`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        )
        .then((response) => {
          if (response.status == 200) {
            this.$emit("msg", "Space updated");
            //get index of space selected
            const index = this.spaces.findIndex(
              (space) => space.id == this.spaceSelected.id,
            );
            this.spaces[index].name = this.spaceSelectedName;
            this.spaceName = "";
            this.file = null;
            this.fileInputKey++;
            // this.addedUsers = [];
            this.prevUsers = this.users;
            // this.users = [];
            this.editingUsers = false;
          } else {
            this.$emit("msg", "Error updating space");
          }
        })
        .catch(function () {
          this.$emit("msg", "Error updating space");
        });
    },
  },
};
</script>
