<template>
  <div class="container template-step-container">
    <div v-for="(step, stepIndex) in steps" :key="stepIndex">
      <p
        :class="[
          { 'step-selected': stepNum === stepIndex },
          { 'step-not-selected': stepNum < stepIndex },
          { 'step-completed': stepNum > stepIndex },
          'template-step',
        ]"
        @click="selectStep(stepIndex)"
      >
        {{ langFilter(step) }}
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../../mixins/translationMixin.js";
export default {
  props: ["lang", "space", "stepNum"],
  data() {
    return {
      steps: ["title", "assistants", "image", "category", "template"],
      traducciones: [
        {
          name: "title",
          es: "Título",
          en: "Title",
        },
        {
          name: "assistants",
          es: "Asistentes",
          en: "Attendants",
        },
        {
          name: "image",
          es: "Imagen de portada",
          en: "Front page image",
        },
        {
          name: "category",
          es: "Categoría",
          en: "Category",
        },
        {
          name: "template",
          es: "Plantilla",
          en: "Template",
        },
      ],
    };
  },
  methods: {
    selectStep(stepIndex) {
      this.$emit("selectStep", stepIndex);
    },
  },
  mixins: [translationMixin],
};
</script>
