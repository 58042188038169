<template>
  <div v-if="alerts.length" class="alerts-container">
    <div
      v-for="{ id, title, text, successful, purple, alertTime } in alerts"
      :key="id"
      class="newProject-ErrorWindowContainer"
      :class="[{ succesful: successful }, { purple: purple }]"
    >
      <img
        :src="purple || successful ? closeErrosGreenIcon : closeErrosIcon"
        style="width: 16px"
        class="newProject-ErrorClose"
        alt="close"
        @click="closeAlertsWindow(id)"
      />
      <img
        v-if="!successful"
        class="info-alert"
        alt="warning"
        :src="purple ? infoAlertIcon : infoAlertWhiteIcon"
      />
      <p class="alert-margin">
        <strong>{{ langFilter(title) }}</strong
        ><br />{{ langFilter(text) }} <strong>{{ variable1 }}</strong>
        {{ text2 !== "" ? langFilter(text2) : "" }}
        <strong>{{ variable2 }}</strong>
      </p>
      <div class="newProject-ErrorTimeBar" :style="alertTime"></div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../mixins/translationMixin.js";
import CloseErrosIcon from "@/assets/img/projects/close_errors.png";
import CloseErrosGreenIcon from "@/assets/img/projects/close_errors_green.png";
import InfoAlertIcon from "@/assets/img/general/info-alert.svg";
import InfoAlertWhiteIcon from "@/assets/img/general/info-alert-white.svg";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      variable1: "",
      text2: "",
      variable2: "",
      alerts: [],
      timeouts: [],
      closeErrosIcon: CloseErrosIcon,
      closeErrosGreenIcon: CloseErrosGreenIcon,
      infoAlertIcon: InfoAlertIcon,
      infoAlertWhiteIcon: InfoAlertWhiteIcon,
      traducciones: [
        {
          name: "To continue you must fill all the fields.",
          es: "Para continuar debes rellenar todos los campos.",
          en: "To continue you must fill all the fields.",
        },
        {
          name: "To continue there must be at least one Task in the Part.",
          es: "To continue there must be at least one Task in the Part.",
          en: "To continue there must be at least one Task in the Part.",
        },
        {
          name: "¡Incompleted fields!",
          es: "¡Campos incompletos!",
          en: "¡Incompleted fields!",
        },
        {
          name: "Incorrect data entered!",
          es: "¡Datos introducidos incorrectos!",
          en: "Incorrect data entered!",
        },
        {
          name: "the email and/or password do not match.",
          es: "Cuidado, el usuario y/o la contraseña no coinciden.",
          en: "the email and/or password do not match.",
        },
        {
          name: "The password is to weak.",
          es: "La contraseña es demasiado débil.",
          en: "The password is to weak.",
        },
        {
          name: "The password is to weak.",
          es: "La contraseña es demasiado débil.",
          en: "The password is to weak.",
        },
        {
          name: "Fill the password",
          es: "Rellena la contraseña.",
          en: "Fill the password.",
        },
        {
          name: "Field required",
          es: "Campo requerido.",
          en: "Field required.",
        },
        {
          name: "Use at least 5 characters for your username",
          es: "Utiliza un mínimo de 5 caracteres para tu usuario.",
          en: "Use at least 5 characters for your username.",
        },
        {
          name: "Username too short",
          es: "Usuario demasiado corto.",
          en: "Username too short",
        },
        {
          name: "Please read and accept terms and conditions and privacy policy.",
          es: "Por favor lee y acepta los terminos y condiciones y la politica de privacidad.",
          en: "Please read and accept terms and conditions and privacy policy.",
        },
        {
          name: "Ups... Something went wrong!",
          es: "¡Ups... Algo ha salido mal!",
          en: "Ups... Something went wrong!",
        },
        {
          name: "The passwords you entered do not match.",
          es: "Las contraseñas introducidas no coinciden.",
          en: "The passwords you entered do not match.",
        },
        {
          name: "The password you entered do not match.",
          es: "La contraseña introducida no coincide.",
          en: "The password you entered do not match.",
        },
        {
          name: "¡Updated profile!",
          es: "¡Perfil actualizado!",
          en: "¡Updated profile!",
        },
        {
          name: "The profile has been updated succesfully",
          es: "El perfil ha sido actualizado con éxito.",
          en: "The profile has been updated succesfully",
        },
        {
          name: "The email field must be an email.",
          es: "El campo email debe ser un email.",
          en: "The email field must be an email.",
        },
        {
          name: "Be careful, the email introduced does not exist.",
          es: "Cuidado, el email introducido no existe.",
          en: "Be careful, the email introduced does not exist.",
        },
        {
          name: "To INVITE a new user you have to insert an email.",
          es: "Para INVITAR un nuevo usuario debes introducir un email.",
          en: "To INVITE a new user you have to insert an email.",
        },
        {
          name: "Incompleted field!",
          es: "¡Campo incompleto!",
          en: "Incompleted field!",
        },
        {
          name: "Incorrect field!",
          es: "¡Campo incorrecto!",
          en: "Incorrect field!",
        },
        {
          name: "Email sent!",
          es: "¡Email enviado!",
          en: "Email sent!",
        },
        {
          name: "An invitation email has been sent",
          es: "Se ha enviado un email de invitación al espacio",
          en: "An invitation email to the space has been sent",
        },
        {
          name: "Please insert a valid email",
          es: "Por favor inserta un email válido",
          en: "Please insert a valid email",
        },
        {
          name: "The user is already in the space",
          es: "El usuario ya esta en el espacio",
          en: "The user is already in the space",
        },

        {
          name: "This Username already exists. Please try with another one.",
          es: "El Nombre de usuario ya existe. Por favor inténtalo con otro.",
          en: "This Username already exists. Please try with another one.",
        },
        //Solutions screen
        {
          name: "warning",
          es: "Aviso",
          en: "Warning",
        },
        {
          name: "The status has been updated from",
          es: "Se ha actualizado el estado de",
          en: "The status has been updated from",
        },
        {
          name: "to",
          es: "a",
          en: "to",
        },
        {
          name: "The file can't be more than 5MB",
          es: "El archivo no puede ser mayor de 5MB",
          en: "The file can't be more than 5MB",
        },
        {
          name: "Error uploading the file",
          es: "Error subiendo el archivo",
          en: "Error uploading the file",
        },
        {
          name: "Try with an image with a size lower than 5MB and a minimum of 160x160 pixels.",
          es: "Prueba con una imagen que pese menos de 5MB y con una dimensión mínima de 160x160 pixeles.",
          en: "Try with an image with a size lower than 5MB and a minimum of 160x160 pixels.",
        },
        //templates
        {
          name: "templateSaved",
          es: 'La plantilla se ha guardado en la sección "Borradores" de Plantillas.',
          en: 'The template has been saved in the "Drafts" section of Templates.',
        },
        {
          name: "templateSavedAlert",
          es: "La plantilla se ha guardado correctamente, la puedes visualizar en su apartado correspondiente.",
          en: "The template has been saved correctly, you can view it in its corresponding section.",
        },
        {
          name: "deletePart",
          es: "No se puede eliminar el último apartado",
          en: "You cannot delete the last section",
        },
        {
          name: "deletePart",
          es: "No se puede eliminar el último apartado",
          en: "You cannot delete the last section",
        },
        {
          name: "deleteTheme",
          es: "No se puede eliminar el último tema",
          en: "You cannot delete the last theme",
        },
        {
          name: "projectSaved",
          es: "El proyecto se ha guardado con exito",
          en: "The project has been saved successfully",
        },
        {
          name: "error",
          es: "Error",
          en: "Error",
        },
        {
          name: "errorDeletingFile",
          es: "Error al eliminar el archivo",
          en: "Error deleting the file",
        },
        {
          name: "errorGettingFiles",
          es: "Error al obtener los archivos",
          en: "Error getting files",
        },
        {
          name: "errorLoadingAssistant",
          es: "Error al obtener el asistente",
          en: "Error loading assistant",
        },
      ],
    };
  },
  methods: {
    closeAlertsWindow(id) {
      this.alerts = this.alerts.filter((alert, index) =>
        alert.id === id ? (clearTimeout(this.timeouts[index]), false) : true
      );

      this.timeouts = this.timeouts.filter((_, index) => this.alerts[index]);
    },
    openErrorsAlert({ title, text, successful, purple }) {
      const alert = {
        id: Date.now(),
        title,
        text,
        successful,
        purple,
        alertTime: {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        },
      };

      this.alerts.push(alert);

      const timeoutId = setTimeout(() => {
        this.closeAlertsWindow(alert.id);
      }, 12000);

      this.timeouts.push(timeoutId);
    },
  },
};
</script>

<style>
@import "../../src/assets/css/projects/newProject.css";
</style>
