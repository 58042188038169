<template>
  <div class="col-md-4 col-sm-5 justify-right">
    <button v-if="editing" class="itinerary-add-btn" @click="addQuestion(i)">
      +
    </button>
    <div>{{ langFilter("question") }} {{ i + 1 }}.</div>
  </div>
  <div class="col-md-6 col-sm-5">
    <div class="itinerary-question-content">
      <input
        v-if="editing"
        :class="`itinerary-input-theme ${
          showError && question.name === '' ? 'itinerary-user-error-input' : ''
        }`"
        :value="question.name"
        type="text"
        @input="editName(i, $event.target.value)"
      />
      <h5 v-else>{{ question.name }}</h5>
    </div>
  </div>
  <div class="col-2 itinerary-questions-options">
    <template v-if="editing">
      <img
        :src="require(`@/assets/img/newitinerary/edit.svg`)"
        alt="edit"
        @click="openEditQuestion(i)"
      />
      <img
        :src="require(`@/assets/img/newitinerary/x.svg`)"
        alt="delete"
        @click="removeQuestion(i)"
      />
    </template>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    i: {
      type: Number,
      required: true,
    },
    editing: {
      type: Boolean,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    showError: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["addQuestion", "removeQuestion", "editQuestionName", "editQuestion"],
  data() {
    return {
      traducciones: [
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
      ],
    };
  },
  methods: {
    editName(i, name) {
      this.$emit("editQuestionName", { i, name });
    },
    addQuestion(i) {
      this.$emit("addQuestion", i);
    },
    removeQuestion(i) {
      this.$emit("removeQuestion", i);
    },
    openEditQuestion(i) {
      this.$emit("editQuestion", i);
    },
    editQuestion(question) {
      this.$emit("editQuestion", question);
    },
  },
};
</script>
