<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div id="modalEditQuestion" class="modal" tabindex="-1">
    <div class="modal-dialog" style="z-index: 99">
      <div class="modal-content modal-content-editQuestion">
        <confirmation-modal
          v-if="confirmationModal"
          :lang="lang"
          body="noSave"
          :fixed="true"
          @closeConfirmation="confirmationModal = false"
          @confirm="backToOldChoices()"
        />
        <div class="modal-div-editQuestion">
          <h5 class="modal-title-EditQuestion">
            {{ langFilter("editQuestion") }}
          </h5>
          <button
            class="btn-close delete-cross-editQuestion"
            @click="openModalConfirmation"
          />
        </div>
        <div class="modal-body div-editQuestion-body">
          <p class="modal-body-EditQuestion">{{ langFilter("question") }}*</p>
          <textarea
            class="edit-question-textarea"
            cols="30"
            rows="10"
            :value="itinerary.title"
            :placeholder="langFilter('writeQuestion')"
            @input="setQuestionName($event)"
          />
          <p class="modal-body-EditQuestion">Prompt</p>
          <textarea
            class="edit-question-textarea"
            cols="30"
            rows="10"
            :value="itinerary.prompt"
            :placeholder="langFilter('writePrompt')"
            @input="setQuestionPrompt($event)"
          />
          <p class="modal-body-EditQuestion">
            {{ langFilter("typology") }}
            <span
              v-if="itinerary.type == 5"
              class="editQuestion-typology-subtitle"
              >({{ langFilter("subtitleSingle") }})</span
            >
            <span
              v-else-if="itinerary.type == 10"
              class="editQuestion-typology-subtitle"
              >({{ langFilter("subtitleMultiple") }})</span
            >
          </p>
          <select
            class="form-select-editQuestion"
            aria-label="Select typology"
            :value="itinerary.type"
            @change="selectChanged($event)"
          >
            <option :value="1">
              {{ langFilter("text") }}
            </option>
            <option :value="10">
              {{ langFilter("multiple") }}
            </option>
            <option :value="5">{{ langFilter("single") }}</option>
          </select>
          <div v-if="itinerary.type >= 5">
            <div
              v-for="(choice, choiceIndex) in itinerary.choices"
              :key="choiceIndex"
              class="selected-option-div"
            >
              <div
                :class="[
                  {
                    'selected-option-correct': choice.selected,
                  },
                  { 'selected-option': !choice.selected },
                ]"
                @click="selectChoice(choiceIndex)"
              />
              <input
                class="selected-option-input"
                type="text"
                :value="choice.name"
                :placeholder="langFilter('addResponse') + '...'"
                @input="setChoiceName($event, choiceIndex)"
              />
              <button
                v-if="choices.length > 1"
                class="btn-close selected-option-btn"
                @click="deleteChoice(choiceIndex)"
              />
            </div>
            <p class="editQuestion-add-choice" @click="addChoice()">
              + {{ langFilter("add") }}
            </p>
          </div>
          <p class="modal-body-EditQuestion">
            {{ langFilter("description") }}
          </p>
          <textarea
            class="edit-question-textarea"
            cols="30"
            rows="10"
            :value="itinerary.description"
            :placeholder="langFilter('description') + '...'"
            @input="setQuestionDesc($event)"
          />
        </div>
        <div class="modal-div-editQuestion">
          <button
            :class="[
              { 'modal-div-editQuestion-btn-enabled': completed },
              'modal-div-editQuestion-btn',
            ]"
            @click="confirmUpdate()"
          >
            {{ langFilter("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    myChoices: {
      type: Array,
      default: () => [],
    },
    itinerary: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        type: 1,
        choices: [],
        prompt: "",
      }),
    },
  },
  emits: [
    "backToOldCoices",
    "changeType",
    "editChoices",
    "editName",
    "editPrompt",
    "editDescription",
    "editQuestion",
    "editChoiceSelected",
    "deleteChoice",
    "addChoice",
    "setChoiceName",
  ],
  data() {
    return {
      title: "",
      completed: true,
      option: 1,
      description: "",
      prompt: "",
      choices: [{ name: "", selected: true }],
      confirmationModal: false,
      traducciones: [
        {
          name: "editQuestion",
          es: "Editar Pregunta",
          en: "Edit Question",
        },
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
        {
          name: "typology",
          es: "Tipología",
          en: "Typology",
        },
        {
          name: "description",
          es: "Descripción",
          en: "Description",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "writeQuestion",
          es: "Escribir pregunta...",
          en: "Write a question...",
        },
        {
          name: "writePrompt",
          es: "Escribir Prompt...",
          en: "Write a prompt...",
        },
        {
          name: "text",
          es: "Texto",
          en: "Text",
        },
        {
          name: "multiple",
          es: "Opción Múltiple",
          en: "Multiple Choice",
        },
        {
          name: "single",
          es: "Opción Única",
          en: "Single Choice",
        },
        {
          name: "file",
          es: "Archivo",
          en: "File",
        },
        {
          name: "subtitleSingle",
          es: "marca la respuesta correcta",
          en: "mark the correct answer",
        },
        {
          name: "subtitleMultiple",
          es: "marca la/s respuesta correcta/s",
          en: "mark the correct answer/s",
        },
        {
          name: "addResponse",
          es: "Introducir respuesta",
          en: "Add response",
        },
        {
          name: "add",
          es: "Añadir",
          en: "Add",
        },
      ],
    };
  },
  watch: {
    itinerary: function () {},
  },

  methods: {
    editItinerary() {
      if (this.itinerary) {
        this.title = this.itinerary.title;
        this.description = this.itinerary.description;
        this.type = this.itinerary.type;
        this.options = this.itinerary.types;
        this.prompt = this.itinerary.prompt;
      }
    },
    backToOldChoices() {
      this.$emit("backToOldCoices", this.choices);
      this.confirmationModal = false;
    },
    openModalConfirmation() {
      this.confirmationModal = true;
    },
    selectChanged(event) {
      this.$emit("changeType", event.target.value);
      this.option = event.target.value;
      if (this.itinerary.type == 5) {
        let numSelected = 0;
        this.itinerary.choices.forEach((choice) => {
          if (choice.selected) {
            numSelected++;
            if (numSelected > 1) {
              choice.selected = false;
            }
          }
        });
      }
      this.checkCompleted();
    },
    reset() {
      this.option = 1;
      this.title = "";
      this.description = "";
      this.prompt = "";
      this.$emit("editChoices", [{ name: "", selected: true }]);
    },
    setQuestionName(event) {
      this.$emit("editName", event.target.value);
      this.checkCompleted();
    },
    setQuestionPrompt(event) {
      this.$emit("editPrompt", event.target.value);
      this.checkCompleted();
    },
    setQuestionDesc(event) {
      this.$emit("editDescription", event.target.value);
    },
    checkCompleted() {
      //   if (this.itinerary.title != "" && this.itinerary.type < 5) {
      //     this.completed = true;
      //   } else if (
      //     this.itinerary.title != "" &&
      //     this.itinerary.option >= 5 &&
      //     this.itinerary.choices.length > 0
      //   ) {
      //     let completed = true;
      //     this.itinerary.choices.forEach((choice) => {
      //       if (choice.name == "") {
      //         completed = false;
      //       }
      //     });
      //     this.completed = completed;
      //   } else {
      //     this.completed = false;
      //   }
      if (this.itinerary.title == "") this.completed = false;
      else this.completed = true;
    },
    confirmUpdate() {
      if (this.completed) {
        this.$emit("editQuestion", {
          title: this.itinerary.title,
          description: this.itinerary.description,
          type: this.itinerary.type,
          choices: this.itinerary.choices,
          prompt: this.itinerary.prompt,
        });
        Modal.getOrCreateInstance(
          document.getElementById("modalEditQuestion")
        ).toggle();
        this.reset();
      }
    },
    selectChoice(choiceIndex) {
      if (this.itinerary.type == 5) {
        this.itinerary.choices.forEach((choice) => {
          choice.selected = false;
        });
      } else {
        let numSelected = 0;
        this.itinerary.choices.forEach((choice) => {
          choice.selected == true ? numSelected++ : null;
        });
        if (numSelected == 1) {
          this.$emit("editChoiceSelected", [choiceIndex, true]);
          return;
        }
      }
      this.$emit("editChoiceSelected", [
        choiceIndex,
        !this.itinerary.choices[choiceIndex].selected,
      ]);
    },
    deleteChoice(choiceIndex) {
      this.$emit("deleteChoice", choiceIndex);
      let numSelected = 0;
      this.itinerary.choices.forEach((choice) => {
        choice.selected == true ? numSelected++ : null;
      });
      if (numSelected == 0) {
        this.$emit("editChoiceSelected", [0, true]);
      }
      this.checkCompleted();
    },
    addChoice() {
      this.$emit("addChoice");
      this.completed = false;
      this.$emit("editChoices", this.itinerary.choices);
    },
    setChoiceName(event, choiceIndex) {
      this.$emit("setChoiceName", [event.target.value, choiceIndex]);
      this.checkCompleted();
    },
  },
};
</script>
