<template>
  <div id="header" class="no-selection-itinerary" style="padding-left: 67px">
    <td nowrap>
      <img src="/img/icons/itinerary.svg" />
      <h1 class="css-titulo-itinerary margin-itinerary-header">
        {{ langFilter("itinerario") }}
      </h1>
    </td>
    <atras style="margin-top: 42px" :lang="lang"></atras>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  name: "ItineraryHeader",
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      traducciones: [
        {
          name: "itinerario",
          es: "itinerario",
          en: "itinerary",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
