<template>
  <chat-with-gpt v-if="chatOpened" :lang="lang" />
  <div class="gpt-chatbot-container">
    <div @click="chatOpened = !chatOpened">
      <img
        class="alex-logo-chatbot"
        :src="chatOpened ? CloseGPT : Alex"
        alt="Alex"
      />
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import Alex from "./images/alex.webp";
import CloseGPT from "../solution/images/close-gpt.png";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    spaceId: {
      type: Number,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CloseGPT: CloseGPT,
      Alex: Alex,
      chatOpened: false,
    };
  },
};
</script>
