<template>
  <div
    :class="[
      { 'solution-answer-map-opened': mapOpened },
      'solution-div-footer',
    ]"
  >
    <button
      :disabled="!edited"
      :class="[
        { 'solution-btn-disabled': !edited },
        { 'solution-btn-enabled': edited },
        'solution-send-corrections',
      ]"
      @click="sendCorrection"
    >
      {{ langFilter("edited") }}
    </button>
    <br />
    <button class="solution-validate-btn" @click="validateSolution">
      {{ langFilter("validateSolutions") }}
    </button>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    mapOpened: {
      type: Boolean,
      required: true,
    },
    edited: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["sendCorrection", "validateSolution"],
  data() {
    return {
      traducciones: [
        {
          name: "edited",
          es: "Enviar Correcciones",
          en: "Send Corrections",
        },
        {
          name: "validateSolutions",
          es: "Validar Soluciones",
          en: "Validate Solutions",
        },
      ],
    };
  },
  methods: {
    sendCorrection() {
      this.$emit("sendCorrection");
    },
    validateSolution() {
      this.$emit("validateSolution", 4);
    },
  },
};
</script>
