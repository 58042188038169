<template>
  <div class="confirmation-backdrop generic-modal">
    <div
      class="confirmation-modal-fixed modal-content"
      :class="{ 'full-width': fullWidth }"
    >
      <button class="confirmation-close-btn" @click="closeConfirmation" />
      <div class="confirmation-body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeConfirmation"],
  methods: {
    closeConfirmation() {
      this.$emit("closeConfirmation");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/css/modals/generic_modal.css";
</style>
