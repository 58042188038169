<template>
  <div>
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="notification-div-container"
      @mouseover="notification_sel = notification.id"
      @mouseleave="notification_sel = ''"
    >
      <button
        v-if="notification_sel === notification.id"
        class="notifications-option-body"
        @click="
          locateModal($event, banner, newer);
          openModalNotifications(notification.id);
        "
      >
        <img
          class="padding-dot-notifications"
          :src="require('@/assets/img/notifications/mini_points.svg')"
          alt="Options"
        />
      </button>
      <a :href="getUrl(notification.url)" @click="markAsRead(notification)">
        <div class="pointer">
          <div class="img-notification-div">
            <img
              class="center not-circle-img"
              :src="require('@/assets/img/notifications/rocket.svg')"
              alt="Notification image"
            />
          </div>
          <div class="notification-body-div">
            <span class="notification-body-txt" v-html="notification.message" />
            <br />
            <span
              class="notification-body-time"
              :class="{
                'notification-new-time': notification.new == 1,
              }"
              >{{ notification.notification_date }}</span
            >
            <span
              v-if="
                notification.new == 1 && notification_sel !== notification.id
              "
              class="purple-dot"
            ></span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import notificationsMixin from "../../mixins/notificationsMixin.js";
export default {
  mixins: [notificationsMixin],
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    newer: {
      type: Boolean,
      required: true,
    },
    token: {
      type: String,
      required: false,
      default: "",
    },
    banner: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["closeNotifications"],
  data() {
    return {
      notification_sel: "",
    };
  },
  methods: {
    markAsRead(notification) {
      event.preventDefault();
      this.$emit("closeNotifications");
      axios
        .patch(`${process.env.VUE_APP_API_URL}/home/changeNotificationNew`, {
          notification_id: notification.id,
        })
        .then(() => {
          window.location.href = this.getUrl(notification.url);
        });
    },
    getUrl(url) {
      return url.replace(process.env.VUE_APP_FRONTEND_URL, "");
    },
  },
};
</script>
