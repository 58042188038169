<template>
  <div style="display: flex; justify-content: space-evenly">
    <div
      :id="id"
      class="div-team-itinerary"
      @mouseover="passmouseprofile()"
      @mouseout="outmouseprofile()"
    >
      <img
        v-if="role !== 'Tutor' && userRole == 'Tutor'"
        :id="'options_button' + id"
        class="option_button_itinerary"
        src="/img/itinerario/options.svg"
        @click="showhover()"
        @clickout="hidehover()"
      />
      <span :id="'hover_header' + id" class="hide-options-itinerary">
        <div class="text-deleteuser-itinerary delete-button-profiles-itinerary">
          <p
            id="text_options"
            class="text-options-itinerary color-text-options-itinerary"
            style="
              text-align: center;
              width: 97px;
              height: 27px;
              padding: 3px 0 0 0;
              cursor: pointer;
            "
            @mouseover="passmouseapart()"
            @mouseout="outmouseapart()"
            @click="deleteUser()"
          >
            {{ langFilter("delUser") }}
          </p>
        </div>
      </span>
      <br />
      <div>
        <img
          :id="'avatar_' + id"
          style="float: left; margin-left: 21px"
          class="circular--square-itinerary"
          :src="getAvatar()"
        />
        <div style="display: grid; padding-left: 14px; padding-right: 43px">
          <span class="name-profiles-itinerary">{{ name }}</span>
          <span class="role-profiles-itinerary">{{ setRole() }}</span>
        </div>
      </div>
      <br />
      <div class="email-profilescontainer-itinerary">
        <span class="email-profiles-itinerary">{{ email }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  name: "ItineraryProfileComponent",
  mixins: [translationMixin],
  props: {
    role: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    logUser: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    spaceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      traducciones: [
        {
          name: "Emprendedor",
          es: "Emprendedor",
          en: "Entrepreneur",
        },
        {
          name: "verperfil",
          es: "Ver perfil",
          en: "See profile",
        },
        {
          name: "delUser",
          es: "Eliminar Usuario",
          en: "Delete User",
        },
      ],
    };
  },
  created() {},
  methods: {
    passmouseprofile() {
      $("#" + this.id).removeClass("div-team-itinerary");
      $("#" + this.id).addClass("div-team-hover-itinerary");
      $("#avatar_" + this.id).removeClass("circular--square-itinerary");
      $("#avatar_" + this.id).addClass("circular--square-hover-itinerary");
    },
    outmouseprofile() {
      $("#" + this.id).addClass("div-team-itinerary");
      $("#" + this.id).removeClass("div-team-hover-itinerary");
      $("#avatar_" + this.id).addClass("circular--square-itinerary");
      $("#avatar_" + this.id).removeClass("circular--square-hover-itinerary");
    },
    setRole() {
      if (this.role == "Tutor") {
        return "Tutor";
      } else {
        return this.langFilter("Emprendedor");
      }
    },
    deleteUser() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/deleteUser`, {
          url: window.location.pathname,
          projectId: this.projectId,
          logUser: this.logUser,
          userId: this.userId,
          spaceId: this.spaceId,
        })
        .then(() => {
          this.$parent.update_profiles();
          $(".hide-options-itinerary").hide();
        });
    },
    getAvatar() {
      if (this.avatar != undefined && this.avatar != "") {
        if (this.avatar.includes("http")) {
          return this.avatar;
        }
        return process.env.VUE_APP_API_STORAGE + this.avatar;
      } else {
        return "/img/general/avatar.jpg";
      }
    },
    showhover() {
      setTimeout(
        function () {
          if ($("#hover_header" + this.id).is(":visible")) {
            $("#hover_header" + this.id).hide();
          } else {
            $("#hover_header" + this.id).show();
          }
        }.bind(this),
        1
      );
    },
    hidehover() {
      $("#hover_header" + this.id).hide();
    },
    passmouseapart() {
      $("#text_options")
        .addClass("color-options-itinerary")
        .removeClass("color-text-options-itinerary");
    },
    outmouseapart() {
      $("#text_options")
        .removeClass("color-options-itinerary")
        .addClass("color-text-options-itinerary");
    },
  },
};
</script>

<style scoped></style>
