<template>
  <div>
    <div v-for="(value, index) in values" :key="index" class="modal-input-row">
      <div class="modal-input-col">
        <label class="modal-NumberInput">{{ value.number }}</label
        ><label class="modal-TextInput">{{ value.title }}</label>
        <p class="modal-input-info">{{ value.info }}</p>
        <modal-type-input
          :lang="lang"
          :type="value.type"
          :name="value.name"
          :placeholder="value.placeholder"
          :options="value.options"
        ></modal-type-input>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],

  props: {
    lang: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      //Traducción
      traducciones: [],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
