<template>
  <div>
    <span class="newItinerary-user-title"
      ><b>{{ langFilter("title") }}</b></span
    >
    <div class="newItinerary-user-container">
      <span class="newItinerary-user-subtitle">{{
        langFilter("subtitle")
      }}</span>
      <div class="newItinerary-add-container">
        <div class="newItinerary-add-user" @click="functionModal(1)">
          <img
            class="newItinerary-add-image"
            :src="require('@/assets/img/newitinerary/add.svg')"
            alt="Add"
          />
        </div>
      </div>
      <div style="display: flex; flex-direction: column">
        <new-itinerary-eachUser
          :users="tutor"
          :role="1"
          @delete-user="deleteUser"
        />
      </div>
      <span style="margin-top: 26px" class="newItinerary-user-subtitle">{{
        langFilter("subtitle2")
      }}</span>
      <div class="newItinerary-add-container">
        <div class="newItinerary-add-user" @click="functionModal(2)">
          <img
            class="newItinerary-add-image"
            :src="require('@/assets/img/newitinerary/add.svg')"
            alt="Add"
          />
        </div>
      </div>
      <div style="display: flex; flex-direction: column">
        <new-itinerary-eachUser
          :users="member"
          :role="1"
          @delete-user="deleteUser"
        />
      </div>
      <new-itinerary-user-modal
        v-if="showModalUser"
        :users="users"
        :projectUser="projectUser"
        @hide-modal="showModalUser = false"
        @send-invite="sendInvite"
      />
      <div v-if="showModalUser" class="newItinerary-Backdrop"></div>
    </div>
  </div>

  <div
    class="newProjectV2-NextButton"
    :class="{ 'nextButton-clickable': tutor.length > 0 || member.length > 0 }"
    @click="createProject()"
  >
    <p class="newProject-ButtonText">
      {{ langFilter("create-project") }}
    </p>
  </div>
</template>
<script>
import axios from "axios";
import translationMixin from "@/mixins/translationMixin";
import { defineComponent } from "vue";
export default defineComponent({
  name: "NewItineraryUser",
  mixins: [translationMixin],
  props: {
    space: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  emits: ["setUsers", "inviteUsers", "stepsFunction"],
  data() {
    return {
      // scre : this.screen,
      users: [],
      showModalUser: false,
      modal_role: 0,
      projectUser: [],
      tutor: [],
      member: [],
      emails: [],
      traducciones: [
        {
          name: "create-project",
          es: "¡Crear proyecto!",
          en: "Create project!",
        },
        {
          name: "title",
          es: "MIEMBROS DEL PROYECTO",
          en: "PROJECT MEMBERS",
        },
        {
          name: "subtitle",
          es: "Tutor/es",
          en: "Tutor/s",
        },
        {
          name: "subtitle2",
          es: "Miembros",
          en: "Members",
        },
      ],
    };
  },
  watch: {
    tutor: function (newVal) {
      this.users = this.users.filter(
        (user) => !newVal.some((user2) => user2.id === user.id)
      );
      this.tutor.forEach((user) => {
        user.role = 1;
      });
    },
    member: function (newVal) {
      this.users = this.users.filter(
        (user) => !newVal.some((user2) => user2.id === user.id)
      );
      this.member.forEach((user) => {
        user.role = 2;
      });
    },
  },
  created() {
    this.onCreated();
  },
  methods: {
    sendInvite(users) {
      this.showModalUser = false;
      if (this.modal_role == 1) {
        this.tutor = this.tutor.concat(users);
      }
      if (this.modal_role == 2) {
        this.member = this.member.concat(users);
      }
      this.projectUser = this.projectUser.concat(users);
      this.$emit("setUsers", this.users, this.projectUser);
    },
    functionModal(role) {
      this.modal_role = role;
      this.showModalUser = true;
    },
    onCreated() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/myProjects/getUsers`, {
          newProject: true,
          space_id: this.space.id,
        })
        .then((response) => {
          this.users = response.data;
          this.users.forEach((user) => {
            user.avatar = user.avatar.replace("https://www.", "https://");
            if (
              user.avatar != null &&
              user.avatar != undefined &&
              user.avatar != ""
            ) {
              if (!user.avatar.includes("http")) {
                user.avatar = process.env.VUE_APP_API_STORAGE + user.avatar;
              }
            } else {
              user.avatar = "/img/login_register/Avatar4.png";
            }
          });
        });
    },
    createProject() {
      let users = [];
      if (this.tutor.length > 0 || this.member.length > 0) {
        this.tutor.forEach((user) => {
          users.push({
            id: user.id,
            email: user.email,
            role: 1,
            pendant: user.pendant,
          });
        });
        this.member.forEach((user) => {
          users.push({
            id: user.id,
            email: user.email,
            role: 2,
            pendant: user.pendant,
          });
        });
        this.$emit("inviteUsers", users);
        this.$emit("stepsFunction", this.$parent.steps + 1);
      }
    },
    deleteUser(user) {
      this.tutor = this.tutor.filter((user2) => user2.id !== user.id);
      this.member = this.member.filter((user2) => user2.id !== user.id);
      this.projectUser = this.projectUser.filter(
        (user2) => user2.id !== user.id
      );
      this.users.push(user);
    },
  },
});
</script>
