<template>
  <div>
    <div class="sidebar-corporation-name-container active-up">
      <h4
        v-if="space != undefined"
        id="sidebar-ap-arrow"
        class="sidebar-corporation-name"
      >
        {{ space.name }}
      </h4>
    </div>

    <sidebar-option
      v-for="(option, optionIndex) in $parent.options"
      :key="optionIndex"
      :option="option"
      :optionIndex="optionIndex"
      :lang="lang"
      :optionSelected="getOptionSelected(optionIndex)"
      :class="[
        {
          'sidebar-background-selected': getOptionSelected(optionIndex),
        },
        'sidebar-container-options',
      ]"
    />

    <!-- Admin Panel Options (Index (section_number) start in 5) -->
    <div v-if="role == 1">
      <sidebar-option
        v-for="option in $parent.optionsAdmin"
        :key="option.section_number"
        :option="option"
        :optionIndex="option.section_number"
        :lang="lang"
        :optionSelected="getOptionSelected(option.section_number)"
        :class="[
          {
            'sidebar-background-selected': getOptionSelected(
              option.section_number
            ),
          },
          'sidebar-container-options',
        ]"
      />
    </div>

    <div v-show="!$parent.closed" class="sidebar-footer">
      <div class="sidebar-background-container">
        <div
          :class="{
            'sidebar-background-selected': 50 == page,
          }"
          class="sidebar-container-options"
          @mouseover="sidebarHover(50)"
          @mouseleave="leaveSidebarHover(50)"
        >
          <div v-show="50 == page" class="sidebar-option-content">
            <router-link to="/faqs">
              <img
                :src="require('@/assets/img/sidebar/hovers/icon-help.svg')"
                class="sidebar-option-image"
              />
              <p class="sidebar-option-text sidebar-option-selected">
                {{ langFilter("help") }}
              </p>
            </router-link>
          </div>
          <div v-show="50 !== page" class="sidebar-option-content">
            <router-link to="/faqs">
              <img
                :src="require('@/assets/img/sidebar/logos/icon-help.svg')"
                class="sidebar-option-image"
              />
              <p class="sidebar-option-text">
                {{ langFilter("help") }}
              </p>
            </router-link>
            <div
              id="sidebar-mouse-over-5"
              style="right: -5px"
              class="sidebar-mouse-over"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
export default {
  mixins: [translationMixin],
  props: {
    space: {
      type: Object,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    old: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAdminPanel: false,

      traducciones: [
        {
          name: "help",
          es: "¿Ayuda?",
          en: "Need help?",
        },
      ],
    };
  },
  methods: {
    sidebarHover(id) {
      $("#sidebar-mouse-over-" + id).css("background-color", "#4bedb1");
    },
    leaveSidebarHover(id) {
      $("#sidebar-mouse-over-" + id).css("background-color", "");
    },
    getOptionSelected(optionIndex) {
      if (
        optionIndex == 2 &&
        (this.page == 102 || this.page == 101 || this.page == 105)
      ) {
        return true;
      }
      return optionIndex == this.page;
    },
  },
};
</script>
