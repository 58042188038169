<template>
  <div class="search-project-container">
    <div class="row">
      <div class="col-1 justify-left">{{ langFilter("search") }}:</div>
      <div class="col-11">
        <input
          type="text"
          :value="search"
          :placeholder="langFilter('search') + '...'"
          @input="searching($event.target.value)"
        />
      </div>
      <div class="col-1" />
      <div class="col-11">
        <div v-if="projectsSearch.length !== 0" class="project-search-div">
          <p
            v-for="(project, i) in projectsSearch"
            :key="i"
            @click="selectProject(project.id)"
          >
            {{ project.name }}
          </p>
        </div>
        <div v-else class="no-result-project-search">
          {{ langFilter("noProjects") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "projects"],
  data() {
    return {
      search: "",
      projectsSearch: this.projects,
      traducciones: [
        {
          name: "search",
          es: "Buscar",
          en: "Search",
        },
        {
          name: "noProjects",
          es: "No hay proyectos",
          en: "No projects",
        },
      ],
    };
  },
  methods: {
    searching(search) {
      this.search = search;
      if (search === "") this.projectsSearch = this.projects;
      else
        this.projectsSearch = this.projects.filter((project) =>
          project.name.toLowerCase().includes(search.toLowerCase())
        );
    },
    selectProject(id) {
      this.$emit("selectProject", id);
    },
    setOpened() {
      this.$emit("setOpened");
    },
  },
  mixins: [translationMixin],
};
</script>
