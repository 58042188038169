<template>
  <div id="usersModal" class="modal fade users-modal" role="dialog">
    <div class="modal-dialog" role="document" style="margin-top: 8%">
      <div
        class="modal-content add-users-div-width"
        style="width: 440px; height: 450px"
      >
        <div class="modal-body">
          <div class="container div-add-users">
            <div class="row margin-top-add-user">
              <div class="close-button-userModal">
                <img
                  id="dismissUserModal"
                  style="height: 30px"
                  data-bs-dismiss="modal"
                  :src="require('@/assets/img/itinerario/x.svg')"
                />
              </div>
              <div class="col-12 align-center">
                <span class="add-user-title">{{
                  langFilter("user_proj")
                }}</span>
              </div>
              <div class="col-12 add-email-container">
                <select id="role" class="select-emailRol" name="select">
                  <option value="Tutor">Tutor</option>
                  <option value="Member" selected>Member</option>
                </select>
                <input
                  class="input-add-email-itinerary"
                  style="width: 100%"
                  :placeholder="langFilter('buscar')"
                  type="text"
                  @keyup="searchUsers($event.target.value)"
                />
                <img
                  class="img-lupa"
                  :src="require('@/assets/img/itinerario/lupa.svg')"
                />
              </div>
              <div class="col-12">
                <div
                  v-if="users != ''"
                  class="div-select-user"
                  style="width: 94.33%"
                >
                  <div
                    v-for="(user, usersIndex) in users2"
                    :key="usersIndex"
                    class="users-selector"
                    @click="
                      selectUser(user.id, user.name, user.email, user.role)
                    "
                  >
                    {{ user.name }}
                  </div>
                </div>
              </div>
              <div class="col-12 users-selected-div">
                <div
                  v-for="(userSelected, usersSelectedIndex) in usersSelected"
                  :key="usersSelectedIndex"
                  class="users-selected"
                  @mouseover="showDeleteUser(usersSelectedIndex)"
                  @mouseout="hideDeleteUser(usersSelectedIndex)"
                >
                  {{ userSelected.name }}
                  <img
                    :id="`x-${usersSelectedIndex}`"
                    class="delete-user-x"
                    :src="require('@/assets/img/itinerario/x.svg')"
                    @click="diselectUser(usersSelectedIndex)"
                  />
                </div>
                <div>
                  <div id="user_email" class="hide add-email-container">
                    <select
                      id="role_email"
                      class="select-emailRol"
                      name="select"
                    >
                      <option value="Tutor">Tutor</option>
                      <option value="Member" selected>Member</option>
                    </select>
                    <input
                      id="input_add_email"
                      class="input-add-email-itinerary"
                      :placeholder="writeEmail()"
                      type="email"
                      value=""
                      @keyup.enter="submitEmail()"
                    />
                  </div>
                  <button
                    id="button_add_email"
                    style="width: 100%"
                    class="button-add-email-itinerary"
                    @click="addEmailclick()"
                  >
                    <i>+ {{ langFilter("addEmail") }}</i>
                  </button>
                </div>
              </div>
            </div>

            <button
              v-if="cargaboton === false"
              class="add-btn-user-modal"
              @click="addUserProject()"
            >
              {{ langFilter("add") }}
            </button>
            <img
              v-if="cargaboton === true"
              id="carga_boton"
              class="carga-boton-modal-itinerary"
              :src="require('@/assets/img/general/carga.gif')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin";
import { Modal } from "bootstrap";
import $ from "jquery";
import axios from "axios";

export default {
  mixins: [translationMixin],
  props: {
    vista: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
    modalSources: {
      type: Array,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      cargaboton: false,
      myUsers: [],
      myUsersAvatar: [],
      myProjects: [],
      users: [],
      users2: [],
      usersSelected: [],
      selectedProject: "",
      hover: false,
      email: "",
      emailChecked: false,
      thisProject: [],

      //Traducción
      traducciones: [
        {
          name: "itinerario",
          es: "Itinerario",
          en: "Itinerary",
        },
        {
          name: "pendiente",
          es: "Pendiente",
          en: "Pending",
        },
        {
          name: "revision",
          es: "Pendiente de revisión",
          en: "Pending review",
        },
        {
          name: "modificacion",
          es: "Pendiente de modificación",
          en: "Pending modification",
        },
        {
          name: "edit_name",
          es: "Editar nombre",
          en: "Edit name",
        },
        {
          name: "guardar",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "completado",
          es: "Completado",
          en: "Completed",
        },
        {
          name: "cascada",
          es: "Proyecto en cascada",
          en: "Cascade Project",
        },
        {
          name: "tiene",
          es: "El usuario tiene un ",
          en: "The user have a ",
        },
        {
          name: "progreso",
          es: "progreso escalonado ",
          en: "staggered progress ",
        },
        {
          name: "ver",
          es: "Ver más",
          en: "View more",
        },
        {
          name: "recursos",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "estado",
          es: "Estado",
          en: "State",
        },
        {
          name: "fichero",
          es: "Subida ficheros",
          en: "Files upload",
        },
        {
          name: "libre",
          es: "Respuesta libre",
          en: "Free answer",
        },
        {
          name: "simple",
          es: "Respuesta simple",
          en: "Simple answer",
        },
        {
          name: "multiple",
          es: "Respuesta multiple",
          en: "Multiple answer",
        },
        {
          name: "estados",
          es: "Estados",
          en: "States",
        },
        {
          name: "opcional",
          es: "Opcional",
          en: "Optional",
        },
        {
          name: "casc_proj",
          es: "El Proyecto en cascada se trata de un progreso escalonado.",
          en: "The cascade Project is about stepwise progress.",
        },
        {
          name: "casc_desc",
          es: "(El usuario no podrá seguir adelante en su progreso hasta ",
          en: "(The user will not be able to continue their progress ",
        },
        {
          name: "casc_desc2",
          es: "completar la tarea que tenga pendiente.)",
          en: "until the pending task is completed.)",
        },
        {
          name: "apartados",
          es: "Apartados",
          en: "Sections",
        },
        {
          name: "buscar",
          es: "Buscar",
          en: "Search",
        },
        {
          name: "user_proj",
          es: "AÑADIR USUARIO AL PROYECTO",
          en: "ADD USER TO PROJECT",
        },
        {
          name: "pregunta",
          es: "P*=Pregunta",
          en: "Q*=Question",
        },
        {
          name: "atras",
          es: "Atrás",
          en: "Back",
        },
        {
          name: "si",
          es: "Si",
          en: "Yes",
        },
        {
          name: "seguro",
          es: "¿Estas seguro que quieres eliminar a ",
          en: "Are you sure you want to delete ",
        },
        {
          name: "del_proj",
          es: " del proyecto",
          en: " from the project",
        },
        {
          name: "delete_user",
          es: "Eliminar usuario",
          en: "Delete user",
        },
        {
          name: "Tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "Emprendedor",
          es: "Emprendedor",
          en: "Entrepreneur",
        },
        {
          name: "equipo",
          es: "Equipo",
          en: "Team",
        },
        {
          name: "add",
          es: "Añadir",
          en: "Add",
        },
        {
          name: "name_vacio",
          es: "El nombre del proyecto no puede estar vacío.",
          en: "The project name cannot be empty.",
        },
        {
          name: "error",
          es: "Error, por favor contacta con support@acceleralia.com.",
          en: "Error, please contact support@acceleralia.com.",
        },
        {
          name: "error_usr",
          es: "Añade al menos un usuario",
          en: "Add at least one user",
        },
        {
          name: "noHay",
          es: "No hay estaciones asociadas a este proyecto",
          en: "There are no station associated to this project",
        },
        {
          name: "noHayEstado",
          es: "No hay apartados con este estado",
          en: "There are no parts with this state",
        },
        {
          name: "addEmail",
          es: "Añadir email",
          en: "Add email",
        },
        {
          name: "writeEmail",
          es: "Escribir email...",
          en: "Write email...",
        },
      ],
    };
  },

  methods: {
    deleteEverything() {
      $("#input_add_email").val("");
      $("#user_email").attr("style", "display: none");
      $(".input-add-user").val("");
      this.usersSelected = [];
      this.users = [];
    },
    submitEmail() {
      this.email = $("#input_add_email").val();
      let role = $("#role_email").val();
      if (role == "Tutor") {
        role = 1;
      } else {
        role = 2;
      }
      this.selectUser(0, this.email, this.email, role);
      $("#user_email").hide();
      this.emailChecked = false;
    },
    saveEmailinVariable() {
      this.email = $("#input_add_email").value();
    },
    writeEmail() {
      return this.langFilter("writeEmail");
    },
    addEmailclick() {
      if (!this.emailChecked) {
        $("#input_add_email").val("");
        $("#button_add_email").css("margin-top", "4px");
        $("#user_email").attr("style", "display: inline-flex");
        this.emailChecked = true;
      } else {
        $("#input_add_email").val("");
        $("#user_email").hide();
        this.emailChecked = false;
      }
    },
    addUserProject() {
      let userSel = "";
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("usersModal")
      );
      //Añadir usuarios al proyecto
      if (this.usersSelected.length != 0) {
        this.cargaboton = true;
        axios
          .post(`${process.env.VUE_APP_API_URL}/itinerary/addusersproject`, {
            url: window.location.pathname,
            space_id: this.space.id,
            users: this.usersSelected,
            project_id: this.projectId, // Changed to camelCase
          })
          .then(() => {
            axios
              .post(`${process.env.VUE_APP_API_URL}/itinerary/getUsersAvatar`, {
                project_id: this.projectId, // Changed to camelCase
              })
              .then((response) => {
                this.myUsersAvatar = response.data;
                axios
                  .post(`${process.env.VUE_APP_API_URL}/itinerary/getUsers`, {
                    project_id: this.projectId, // Changed to camelCase
                    space_id: this.space.id,
                  })
                  .then((response) => {
                    this.myUsers = response.data;
                    this.$parent.update_profiles();
                    $(".div-select-user").hide();
                    $(".input-add-user").val("");
                    $(".modal-backdrop").remove();
                    this.cargaboton = false;
                    userSel = this.usersSelected;
                    this.usersSelected = [];
                    myModal.hide();
                  });
              });
          });

        // Envío de email a los 5 segundos para no interferir con lo anterior
        setTimeout(
          function () {
            axios.post(
              `${process.env.VUE_APP_API_URL}/send/email/emailNewUser`,
              {
                addedUser: 2,
                project_name: this.projectName, // Changed to camelCase
                project_id: this.projectId, // Changed to camelCase
                lang: this.lang,
                users: userSel,
                space_id: this.space.id,
              }
            );
          }.bind(this),
          5000
        );
      }
    },
    getAllUsers() {
      //Usuarios por proyecto
      axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/getUsers`, {
          project_id: this.projectId, // Changed to camelCase
          space_id: this.space.id,
        })
        .then((response) => {
          this.myUsers = response.data;
          this.searchAllUsers();
        });
    },
    searchAllUsers() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/general/searchusersspace`, {
          project_id: this.projectId, // Changed to camelCase
          users: this.myUsers,
          space: this.space.id,
        })
        .then((response) => {
          this.users = response.data;
        });
    },
    searchUsers(user_name) {
      if (user_name == "") {
        $(".div-select-user").hide();
      } else {
        this.users2 = this.users.filter(
          (user) => user.name.toLowerCase().search(user_name.toLowerCase()) > -1
        );
        $(".div-select-user").show();
      }
    },
    selectUser(id, name, email, role) {
      $(".div-select-user").hide();
      $(".input-add-user").val("");

      let obj = {};
      obj["id"] = id;
      obj["name"] = name;
      obj["email"] = email;
      obj["role"] = role;
      this.usersSelected.push(obj);

      //Evitar usuarios duplicados
      this.usersSelected = [
        ...new Map(
          this.usersSelected.map((email) => [email["email"], email])
        ).values(),
      ];
    },

    showDeleteUser(index) {
      $("#x-" + index).show();
    },

    hideDeleteUser(index) {
      $("#x-" + index).hide();
    },

    diselectUser(index) {
      this.usersSelected.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
