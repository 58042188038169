<script setup>
import EditProfile from "../components/users/EditProfileComponent.vue";
</script>

<template>
  <EditProfile :lang="lang" />
</template>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
@import "../../src/assets/css/users/edit-profile.css";
</style>
