<template>
  <ul class="list-group mt-3 mx-3">
    <li
      v-for="(file, index) in files"
      :key="index"
      :class="[
        'd-flex justify-content-between align-items-center px-5 py-2 my-1 ',
        {
          'bg-hover-file shadow-sm': hoverFile === index,
        },
      ]"
      @mouseover="hoverFile = index"
      @mouseleave="hoverFile = null"
    >
      <div>
        <img src="../../assets/img/track/file-assistant.svg" class="pb-1" />
        {{ file.file_name }}
      </div>
      <img
        width="20"
        class="pointer download-icon"
        src="/img/itinerario/download.svg"
        @click="downloadFile(index)"
      />
    </li>
  </ul>

  <alertMessage ref="alerts" :lang="lang"></alertMessage>
</template>

<script>
import axios from "axios";

export default {
  name: "AssistantFilesList",
  props: {
    files: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      default: "en",
    },
  },
  data() {
    return {
      hoverFile: null,
      errors: [],
      errorTime: {}
    };
  },
  methods: {
    async downloadFile(index) {
      try {
        const file = this.files[index];
        const fileUrl = `${process.env.VUE_APP_API_URL}/assistants/${file.gpt_assistant_id}/files/${file.id}`;

        const response = await axios.get(fileUrl, {
          responseType: "blob",
        });

        const fileName = file.file_name;

        const url = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(url);
      } catch (error) {
        this.openErrorsAlert("downloadErrorTitle", "Please try again later");
        console.error("Error downloading file:", error);
      }
    },
    openErrorsAlert(title, text) {
     this.$refs.alerts.title = title;
      this.$refs.alerts.text = text;

      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      this.errors = [];
    },
  },
};
</script>
