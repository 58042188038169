<template>
  <div>
    <img
      v-if="loading"
      class="loading-center"
      :src="require('@/assets/img/general/loading.gif')"
    />
    <new-template
      v-show="!loading"
      ref="newtemplate"
      :space="spaces[0]"
      :lang="lang"
      @open-alert="openAlert($event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  emits: ["open-alert"],
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    window.addEventListener("popstate", this.handlePopstate);
  },
  beforeUnmounted() {
    window.removeEventListener("popstate", this.handlePopstate);
  },
  methods: {
    handlePopstate(event) {
      event.preventDefault();
    },
    openAlert(message) {
      this.$emit("open-alert", message);
    },
    confirmation(from, to, next) {
      if (
        confirm(
          "Do you really want to leave? All unsaved changes will be lost.",
        )
      ) {
        // User confirmed, proceed with navigation
        next();
      } else {
        // User canceled, prevent navigation
        next(false);
      }
    },
  },
  //   beforeRouteLeave(to, from, next) {
  //     // this.confirmation(from, to, next);
  //   },
};
</script>

<style>
@import "../../src/assets/css/template/new-template.css";
</style>
