<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="itinerary-theme-container">
    <div class="row">
      <div class="col-md-2 col-sm-3">
        <div class="itinerary-theme-content justify-right theme-gap no-wrap-it">
          <open-btn :opened="opened" @setOpened="opened = !opened" />
          <button v-if="editing" class="itinerary-add-btn" @click="addTheme(i)">
            +
          </button>
          {{ langFilter("theme") }} {{ i + 1 }}.
        </div>
      </div>
      <div class="col-md-8 col-sm-7">
        <div class="itinerary-theme-content theme-gap">
          <input
            v-if="editing"
            :class="`itinerary-input-theme ${
              showError && theme.title === ''
                ? 'itinerary-user-error-input'
                : ''
            }`"
            :value="theme.title"
            type="text"
            @input="editThemeName($event.target.value)"
          />
          <h5 v-else>{{ theme.title }}</h5>
        </div>
      </div>
      <div class="col-2 itinerary-theme-content theme-gap2">
        <div v-if="!editing" class="justify-center gap5">
          <div class="solution-percentage">
            <div :style="{ width: theme.percentage + '%' }" />
          </div>
          <span :class="theme.percentage >= 100 ? 'color-completed' : ''"
            >{{ theme.percentage }}%</span
          >
        </div>
        <div class="justify-center gap7 pointer">
          <img
            v-if="editing"
            :src="require(`@/assets/img/newitinerary/x.svg`)"
            alt="delete"
            @click="removeTheme()"
          />
          <div
            v-else
            class="justify-center gap7 pointer"
            @click="showResourcesFunc()"
          >
            <img
              :src="require(`@/assets/img/newitinerary/resource.svg`)"
              alt="resources"
            />
            <span>{{ resourceCount }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-sm-3" />
      <div class="col-md-8 col-sm-7">
        <textarea
          v-if="editing"
          class="theme-desc-edit"
          :value="theme.description"
          @input="editThemeDescription($event.target.value)"
        />
        <p v-else class="theme-desc">
          {{ themeDescription }}
        </p>
      </div>
      <div class="col-md-2 col-sm-3" />
      <template v-if="opened">
        <itinerary-part
          v-for="(part, j) in theme.parts"
          :key="j"
          :i="j"
          :lang="lang"
          :part="part"
          :editing="editing"
          :showError="showError"
          :themeId="theme.id"
          :projectId="projectId"
          @addPart="addPart($event)"
          @removePart="removePart($event)"
          @editPartName="editPartName($event)"
          @removeQuestion="removeQuestion($event)"
          @addQuestion="addQuestion($event)"
          @editQuestionName="editQuestionName($event)"
          @editQuestion="editQuestion($event)"
        />
        <div
          v-if="theme.parts.length === 0 && editing"
          class="col-12 justify-center"
        >
          <button
            class="itinerary-add-btn itinerary-add-btn-margin"
            @click="addPart(0)"
          >
            +
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    theme: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        percentage: 0,
        parts: [],
        id: null,
      }),
    },
    // eslint-disable-next-line vue/require-default-prop
    i: Number,
    editing: Boolean,
    showError: Boolean,
    // eslint-disable-next-line vue/prop-name-casing
    projectId: [String, Number],
  },
  emits: [
    "add-theme",
    "edit-theme-description",
    "remove-theme",
    "edit-theme-name",
    "add-part",
    "remove-part",
    "edit-part-name",
    "remove-question",
    "add-question",
    "edit-question-name",
    "edit-question",
    "show-resources",
  ],
  data() {
    return {
      opened: true,
      resourceCount: 0,
      traducciones: [
        {
          name: "theme",
          es: "Tema",
          en: "Theme",
        },
      ],
    };
  },
  created() {
    this.getResourcesByTheme(this.theme.id);
  },
  computed: {
    themeDescription() {
      return this.theme.description?.replace(/<[^>]*>/g, "")
    },
  },
  methods: {
    showResourcesFunc() {
      this.$emit("show-resources");
    },
    addTheme() {
      this.$emit("add-theme", this.i);
    },
    removeTheme() {
      this.$emit("remove-theme", this.i);
    },
    editThemeName(name) {
      this.$emit("edit-theme-name", [this.i, name]);
    },
    editThemeDescription(desc) {
      this.$emit("edit-theme-description", [this.i, desc]);
    },
    addPart(i) {
      this.$emit("add-part", { partIndex: i, themeIndex: this.i });
    },
    removePart(i) {
      this.$emit("remove-part", { partIndex: i, themeIndex: this.i });
    },
    editPartName({ i, name }) {
      this.$emit("edit-part-name", {
        partIndex: i,
        themeIndex: this.i,
        name,
      });
    },
    removeQuestion({ questionIndex, partIndex }) {
      this.$emit("remove-question", {
        questionIndex,
        partIndex,
        themeIndex: this.i,
      });
    },
    addQuestion({ questionIndex, partIndex }) {
      this.$emit("add-question", {
        questionIndex,
        partIndex,
        themeIndex: this.i,
      });
    },
    editQuestionName({ questionIndex, partIndex, name }) {
      this.$emit("edit-question-name", {
        questionIndex,
        partIndex,
        themeIndex: this.i,
        name,
      });
    },
    editQuestion({ questionIndex, partIndex }) {
      this.$emit("edit-question", {
        questionIndex,
        partIndex,
        themeIndex: this.i,
      });
    },
    getResourcesByTheme(themeId) {
      let url = window.location.href;
      let id = 0;
      const newUrl = url.split("/");
      id = newUrl[newUrl.length - 1];
      url = `/getResourcesByProject/${id}`;
      axios
        .get(process.env.VUE_APP_API_URL + url)
        .then((response) => {
          let count = 0;
          let inserted = [];
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].forEach((el) => {
              if (el.station_id == themeId) {
                if (!inserted.includes(el.resource_id)) {
                  inserted.push(el.resource_id);
                  count++;
                }
              }
            });
          }
          this.resourceCount = count;
        })
        .catch((error) => {
          console.log(error);
          this.resourceCount = 0;
        });
    },
  },
};
</script>
