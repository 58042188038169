<template>
  <div>
    <div
      v-if="
        type !== 'select' && type !== 'textarea' && type !== 'search_select'
      "
    >
      <input :id="name" :name="name" :type="type" :placeholder="placeholder" />
    </div>
    <div v-else-if="type == 'textarea'" :id="'froala-' + name">
      <textarea :id="name" :name="name" :placeholder="placeholder"></textarea>
    </div>
    <div v-else-if="type == 'select'">
      <select :id="name" :name="name">
        <option value="0">{{ placeholder }}</option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
    </div>
    <div v-else-if="type == 'search_select'">
      <div
        v-outside="disableSelectSearch"
        class="select-search-container"
        @click="openSelectSearch"
      >
        <input
          :id="name"
          class="modal-input-no-hover"
          type="text"
          :name="name"
          :placeholder="placeholder"
          readonly
        />
        <input
          v-show="false"
          :id="name + '-value'"
          type="text"
          :name="name + '-value'"
          :placeholder="placeholder"
          disabled
        />
        <div v-show="showSelectSearch" class="select-search">
          <input
            v-model="searchOptions"
            type="text"
            :placeholder="langFilter('Search...')"
          />
          <ul>
            <li
              v-for="(option, index) in search_options"
              :key="index"
              @click="
                hideSelectSearch(),
                  $parent.$parent.$parent.selectedValueModal(name, option)
              "
            >
              {{ option.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    functionIdentifier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showSelectSearch: false,
      searchOptions: "",
      //Traducción
      traducciones: [
        {
          name: "Search...",
          es: "Buscar...",
          en: "Search...",
        },
      ],
    };
  },
  computed: {
    search_options() {
      return this.options.filter((option) => {
        return option.name
          .toLowerCase()
          .includes(this.searchOptions.toLowerCase());
      });
    },
  },
  created() {},
  mounted() {},
  methods: {
    openSelectSearch(el) {
      if (el.target.tagName == "LI") {
        this.showSelectSearch = false;
      } else {
        this.showSelectSearch = true;
      }
    },
    disableSelectSearch(el) {
      if (el.target.className == "new-project-select-search-container") {
        this.showSelectSearch = true;
      } else {
        this.showSelectSearch = false;
      }
    },
    hideSelectSearch() {
      this.showSelectSearch = false;
    },
  },
};
</script>
