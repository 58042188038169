<template>
  <div>
    <img src="/img/home/bulletin.svg" alt="bulletin" class="img-home-banner" />
    <span class="title-home-banner">{{ langFilter("bulletin") }}</span>

    <div class="bulletin-div">
      <div class="bulletin-title">
        <span>Title</span>
      </div>
      <div class="bulletin-content">
        <span>
          Lorem ipsum dolor sit amet, consecter adipiscing elit. In at lacinia
          elit string is...
        </span>
      </div>
    </div>
    <div class="bulletin-div">
      <div class="bulletin-title">
        <span>Title</span>
      </div>
      <div class="bulletin-content">
        <span>
          Lorem ipsum dolor sit amet, consecter adipiscing elit. In at lacinia
          elit string is...
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    web: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allTracks: [],
      token: "",

      traducciones: [
        {
          name: "bulletin",
          es: "Tablón de anuncios",
          en: "Bulletin board",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>
