<script setup>
import Recover from "../components/RecoverPasswordComponent.vue";
</script>

<script>
export default {
  data() {
    return {
      email: "",
      token: "",
    };
  },
  created() {
    this.email = this.$route.params.email;
    this.token = this.$route.params.token;
  },
};
</script>

<template>
  <Recover :email="email" :token="token" />
</template>

<style>
@import "../../src/assets/css/auth/signup.css";
@import "../../src/assets/css/auth/login.css";
</style>
