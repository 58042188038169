<template>
  <div
    id="newProjectModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-new-program">
        <div class="modal-body">
          <div class="container">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="newProject-close-modals"
              @click="closeModalClickingX"
            >
              <path
                d="M16.3248 16.6752C15.9342 17.0658 15.3011 17.0658 14.9105 16.6752L9.05882 10.8235L3.14828 16.7341C2.75776 17.1246 2.12459 17.1246 1.73407 16.7341L1.23652 16.2365C0.845994 15.846 0.845995 15.2128 1.23652 14.8223L7.14706 8.91177L1.32476 3.08946C0.934231 2.69894 0.93423 2.06577 1.32475 1.67525L1.67525 1.32475C2.06577 0.934229 2.69894 0.934229 3.08946 1.32475L8.91177 7.14706L14.8223 1.23652C15.2128 0.845995 15.846 0.845995 16.2365 1.23652L16.7341 1.73407C17.1246 2.12459 17.1246 2.75776 16.7341 3.14828L10.8235 9.05882L16.6752 14.9105C17.0658 15.3011 17.0658 15.9342 16.6752 16.3248L16.3248 16.6752Z"
                fill="#B7AED6"
              />
            </svg>
            <div class="row align-center">
              <div class="col-12">
                <p class="new-prog-tit">
                  {{ langFilter("newProg") }}
                </p>
              </div>
              <div class="col-6 align-left">
                <p class="label-new-prog">
                  {{ langFilter("cliente") }}
                </p>
                <select id="user_sel" class="input-new-prog select-inpt">
                  <option
                    v-for="(customer, customersIndex) in customers"
                    :key="customersIndex"
                    :value="customer.id"
                  >
                    {{ customer.name }}
                  </option>
                </select>
              </div>
              <div class="col-6 align-left">
                <p class="label-new-prog">
                  {{ langFilter("programa") }}
                </p>
                <input
                  id="prog_name"
                  :placeholder="langFilter('nameProg')"
                  class="input-new-prog"
                  type="text"
                />
              </div>
              <div class="col-6 align-left">
                <p class="label-new-prog">
                  {{ langFilter("inicio") }}
                </p>
                <input
                  id="start_date"
                  class="input-new-prog date-prog"
                  type="date"
                />
              </div>
              <div class="col-6 align-left">
                <p class="label-new-prog">
                  {{ langFilter("fin") }}
                </p>
                <input
                  id="end_date"
                  class="input-new-prog date-prog"
                  type="date"
                />
              </div>
              <div class="col-12">
                <button
                  id="new_program_button"
                  class="btn-new-prog"
                  @click="newProgram()"
                >
                  {{ langFilter("crear") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import { Modal } from "bootstrap";
import $ from "jquery";
import axios from "axios";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    programs: {
      type: Array,
      required: true,
    },
  },
  emits:["openAlert"],
  data() {
    return {
      customers: [],
      timer: false,
      // traduccion
      traducciones: [
        {
          name: "newProg",
          es: "NUEVO PROGRAMA",
          en: "NEW PROGRAM",
        },
        {
          name: "cliente",
          es: "Cliente",
          en: "Client",
        },
        {
          name: "programa",
          es: "Nombre de programa",
          en: "Program name",
        },
        {
          name: "inicio",
          es: "Fecha de inicio",
          en: "Start date",
        },
        {
          name: "fin",
          es: "Fecha de finalización",
          en: "End date",
        },
        {
          name: "crear",
          es: "Crear",
          en: "Create",
        },
        {
          name: "selCli",
          es: "Selecciona el cliente",
          en: "Select client",
        },
        {
          name: "nameProg",
          es: "Inserta el nombre del Programa...",
          en: "Insert program name...",
        },
        {
          name: "date",
          es: "dd/mm/aaaa",
          en: "dd/mm/yyyy",
        },
        {
          name: "dates",
          es: "La fecha de finalización no puede ser anterior a la de inicio.",
          en: "The end date cannot be earlier than the start date.",
        },
        {
          name: "exist",
          es: "Este programa ya existe.",
          en: "The program already exists.",
        },
      ],
    };
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/general/getCustomers`)
      .then((response) => {
        this.customers = response.data;
      });
  },
  methods: {
    closeModalClickingX() {
      const myModal = Modal.getInstance(
        document.getElementById("newProjectModal")
      );
      myModal.hide();
    },
    newProgram() {
      if (!this.timer) {
        this.timer = true;
        // timer to false after 4 seconds
        setTimeout(() => {
          this.timer = false;
        }, 2000);
        var myModal = Modal.getInstance(
          document.getElementById("newProjectModal")
        );
        let name = $("#prog_name").val();
        let start = $("#start_date").val();
        let end = $("#end_date").val();
        let user = $("#user_sel").val();

        if (new Date(start) > new Date(end)) {
          $("#start_date").addClass("input-void");
          $("#end_date").addClass("input-void");
          if (name == "") {
            $("#prog_name").addClass("input-void");
          } else {
            $("#prog_name").removeClass("input-void");
          }
          if (user == "") {
            $("#user_sel").addClass("input-void");
          } else {
            $("#user_sel").removeClass("input-void");
          }
        } else if (name != "" && start != "" && end != "" && user != "") {
          // check if program already exists
          let exists = false;
          this.programs.forEach((program) => {
            if (
              program.name == name &&
              program.inicio == start &&
              program.final == end
            ) {
              exists = true;
            }
          });

          if (!exists) {
            axios
              .post(`${process.env.VUE_APP_API_URL}/programs/newProgram`, {
                name: name,
                start: start,
                end: end,
                user: user,
              })
              .then(() => {
                this.$parent.getAllPrograms();
                $("#prog_name").removeClass("input-void");
                $("#start_date").removeClass("input-void");
                $("#end_date").removeClass("input-void");
                $("#user_sel").removeClass("input-void");
                document.getElementById("prog_name").value = "";
                document.getElementById("start_date").value = "";
                document.getElementById("end_date").value = "";
                $(".modal-backdrop").remove();
                myModal.hide();
                this.$emit("openAlert");
                document
                  .getElementById("newProjectModal")
                  .addEventListener("hidden.bs.modal", () => {
                    this.$parent.getAllPrograms();
                  });
              });
          } else {
            alert(this.langFilter("exist"));
          }
        } else {
          if (name == "") {
            $("#prog_name").addClass("input-void");
          } else {
            $("#prog_name").removeClass("input-void");
          }
          if (start == "") {
            $("#start_date").addClass("input-void");
          } else {
            $("#start_date").removeClass("input-void");
          }
          if (end == "") {
            $("#end_date").addClass("input-void");
          } else {
            $("#end_date").removeClass("input-void");
          }
          if (user == "") {
            $("#user_sel").addClass("input-void");
          } else {
            $("#user_sel").removeClass("input-void");
          }
        }
      }
    },
  },
};
</script>
