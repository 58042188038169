<template>
  <div class="terms-sidebar">
    <div class="top-of-sidebar">
      <img
        :src="require('@/assets/img/sidebar/acc_logo.png')"
        class="acceleralia-sidebar-logo"
      />
    </div>
    <div class="right-of-sidebar-terms">
      <RouterLink to="/terms-conditions">
        <div class="text-option">
          <p id="policy" :class="{ 'hover-hidden': policy }">
            {{ langFilter("terms_conditions") }}
          </p>
          <img
            v-if="terms"
            id="policy"
            :src="require('@/assets/img/sidebar/hovers/option-hover.png')"
          />
        </div>
      </RouterLink>
      <RouterLink to="/privacy-policy">
        <div class="text-option">
          <p id="terms" :class="{ 'hover-hidden': terms }">
            {{ langFilter("privacy_policy") }}
          </p>
          <img
            v-if="!terms"
            id="terms"
            :src="require('@/assets/img/sidebar/hovers/option-hover.png')"
          />
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import translationMixin from "../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    terms: {
      type: Boolean,
      required: true,
    },
    policy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      traducciones: [
        {
          name: "terms_conditions",
          es: "Términos y condiciones",
          en: "Terms and conditions",
        },
        {
          name: "privacy_policy",
          es: "Política de privacidad",
          en: "Privacy policy",
        },
      ],
    };
  },
};
</script>

<style>
@import "../../src/assets/css/auth/terms_conditions.css";
@import "../../src/assets/css/sidebar/sidebar.css";
</style>
