<template>
  <div>
    <p>Search Space</p>
    <select @change="changeSpace($event)">
      <option
        v-for="(space, spaceIndex) in spaces"
        :key="spaceIndex"
        :value="space.id"
      >
        {{ space.name }}
      </option>
    </select>
    <br /><br />
    <div v-if="sure">
      <p>Are you sure you want to delete this space?</p>
      <button type="submit" class="btn btn-info" @click="deleteSpace">
        Yes
      </button>
      <button type="button" class="btn btn-info" @click="sure = false">
        No
      </button>
    </div>
    <button class="btn btn-danger" @click="sure = true">Delete</button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  emits: ["msg"],
  data() {
    return {
      spaces: [],
      spaceSelected: "",
      sure: false,
    };
  },
  created() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/manageSpaces-acc/getSpaces`)
      .then((response) => {
        this.spaces = response.data;
        this.spaceSelected = this.spaces[0].id;
      });
  },
  methods: {
    changeSpace(event) {
      this.spaceSelected = event.target.value;
    },
    deleteSpace() {
      axios
        .delete(`${process.env.VUE_APP_API_URL}/manageSpaces-acc/deleteSpace`, {
          params: {
            id: this.spaceSelected,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$emit("msg", `Space deleted`);
            this.sure = false;
            //delete space from spaces array
            this.spaces = this.spaces.filter(
              (space) => space.id != this.spaceSelected,
            );
            //set spaceSelected to first space in array
            this.spaceSelected = this.spaces[0].id;
          } else {
            this.$emit("msg", `Error deleting space`);
          }
        })
        .catch(function () {
          this.$emit("msg", `Error deleting space`);
        });
      this.sure = false;
    },
  },
};
</script>
