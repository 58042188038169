<template>
  <div>
    <div v-for="(value, index) in values" :key="index" class="modal-input-row">
      <div
        class="modal-input-col"
        style="width: 50%; padding-right: 9px; float: left"
      >
        <label class="modal-NumberInput">{{ values[0].number }}</label>
        <label class="modal-TextInput">{{ values[0].title }}</label>
        <p class="modal-input-info">{{ values[0].info }}</p>
        <modal-type-input
          :lang="lang"
          :name="values[0].name"
          :type="values[0].type"
          :placeholder="values[0].placeholder"
          :options="values[0].options"
        ></modal-type-input>
      </div>

      <div
        class="modal-input-col"
        style="width: 50%; padding-left: 9px; float: left"
      >
        <label class="modal-NumberInput">{{ values[1].number }}</label>
        <label class="modal-TextInput">{{ values[1].title }}</label>
        <p class="modal-input-info">{{ values[1].info }}</p>
        <modal-type-input
          :lang="lang"
          :name="values[1].name"
          :type="values[1].type"
          :placeholder="values[1].placeholder"
          :options="values[1].options"
        ></modal-type-input>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      //Traducción
      traducciones: [],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
