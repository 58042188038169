<template>
  <div>
    <div id="trackModal" class="modal" tabindex="-1" role="dialog">
      <div
        class="modal-dialog"
        role="document"
        style="min-height: 100%; max-height: 100%"
      >
        <div
          class="modal-track-template modal-content"
          style="height: 94%; width: 794px; margin-top: 2%"
        >
          <div class="modal-template-header banner-track-img">
            <img
              class="template-close-button"
              :src="require('@/assets/img/track/close-modal.png')"
              alt="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <span class="track-name-modal-template">{{ track.name }}</span>
            <!-- <div class="select-track-btn">
                            {{ langFilter("select") }}
                        </div> -->
          </div>
          <div class="modal-body box-shadow-modal-template">
            <div class="desc-track-modal">
              {{ track.description }}
            </div>
            <div class="data-track-modal-template">
              <span
                v-if="
                  track.created_by != '' &&
                  track.created_by != undefined &&
                  track.created_by != null
                "
              >
                <span class="data-track-name-template"
                  >{{ langFilter("created by") }}:</span
                >
                <span>{{ track.creatorName }}</span>
                <br /><br
              /></span>
              <span>
                <span class="data-track-name-template"
                  >{{ langFilter("categoria") }}:</span
                >
                <span v-if="selTrackCat == '' || selTrackCat == null">{{
                  langFilter("sin_cat")
                }}</span>
                <span v-else>{{ selTrackCat[0].name }}</span> <br /><br />
              </span>
              <span>
                <span class="data-track-name-template">
                  {{ langFilter("expert") }}:
                </span>
                <span>{{ track?.assistant_name }}</span>
                <br /><br />
              </span>
              <span>
                <span class="data-track-name-template">
                  {{ langFilter("evaluator") }}:
                </span>
                <span>{{ track?.evaluator_name }}</span>
                <br /><br />
              </span>
              <span
                v-if="
                  track.template_assistant_name != '' &&
                  track.template_assistant_name != undefined &&
                  track.template_assistant_name != null
                "
              >
                <span class="data-track-name-template"
                  >{{ langFilter("templateName") }}:</span
                >
                <span>{{ track.template_assistant_name }}</span>
                <br /><br
              /></span>
              <!-- <span class="data-track-name"
                                >{{ langFilter("duracion") }}:</span
                            >
                            <span>24 semanas</span> <br />
                            <span class="data-track-name"
                                >{{ langFilter("creacion") }}:</span
                            >
                            <span>23/04/2019</span> -->
            </div>
            <div
              v-if="selectableProject"
              class="newProjectV2-selectTrackButton"
              @click="selectTemplateNewProject(track)"
            >
              <p class="newProjectV2-selectTrackButtonText">
                {{ langFilter("selectProject") }}
              </p>
            </div>
          </div>
          <div class="index-select-modal">
            <span
              v-if="$parent.selectorModal == 0"
              class="index-modal-track-template"
              >{{ langFilter("indice") }}</span
            >
            <span v-else class="index-modal-track-template">{{
              langFilter("recursos")
            }}</span>
            <select
              id="select-modal-track"
              class="select-modal-track-template"
              @change="selectorTrack($event)"
            >
              <option value="0">
                {{ langFilter("indice") }}
              </option>
              <option value="1">
                {{ langFilter("recursos") }}
              </option>
            </select>
          </div>
          <div class="modal-final-template">
            <div class="track-rec-it-div">
              <templateItinerary
                v-show="$parent.selectorModal == 0"
                :trackStatSol="trackStatSol"
                :track="track"
                :itinerary="itinerary"
                :questions="selQuestions"
                :lang="lang"
              />
              <templateResources
                v-show="$parent.selectorModal != 0"
                :trackStatSol="trackStatSol"
                :track="track"
                :itinerary="itinerary"
                :questions="selQuestions"
                :resources="selResources"
                :resourcesStat="selResourcesStat"
                :lang="lang"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import templateResources from "./TemplateResourcesComponent.vue";
import templateItinerary from "./TemplateItineraryComponent.vue";
import axios from "axios";
import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  components: {
    templateResources,
    templateItinerary,
  },
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
    track: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    selectableProject: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["showTemplates"],
  data() {
    return {
      selTrackCat: "",
      selResources: "",
      selResourcesStat: "",
      selQuestions: "",
      itinerary: [],
      resources: [],
      resourcesStat: [],
      questions: [],
      trackStatSol: [],
      queryCounter: 0,
      trackAssistants: [],
      // traduccion
      traducciones: [
        {
          name: "indice",
          es: "Índice",
          en: "Index",
        },
        {
          name: "sin_cat",
          es: "Sin categoría",
          en: "No category",
        },
        {
          name: "itinerario",
          es: "Plantilla",
          en: "Template",
        },
        {
          name: "preguntas",
          es: "Preguntas",
          en: "Questions",
        },
        {
          name: "recursos",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "categoria",
          es: "Categoría",
          en: "Category",
        },
        {
          name: "expert",
          es: "Experto",
          en: "Expert",
        },
        {
          name: "evaluator",
          es: "Evaluador",
          en: "Evaluator",
        },
        {
          name: "duracion",
          es: "Duración",
          en: "Duration",
        },
        {
          name: "creacion",
          es: "Creación",
          en: "Creation",
        },
        {
          name: "select",
          es: "Seleccionar Track",
          en: "Select Track",
        },
        {
          name: "created by",
          es: "Creado por",
          en: "Created by",
        },
        {
          name: "templateName",
          es: "Plantilla de asistente",
          en: "Assistant template",
        },
        {
          name: "selectProject",
          es: "Seleccionar",
          en: "Select",
        },
      ],
    };
  },
  watch: {
    // cuando se terminan de cargar las soluciones, preguntas y recursos, se emite un evento para que el componente padre muestre las plantillas
    queryCounter: function (val) {
      if (val == 4) {
        this.$emit("showTemplates");
      }
    },

    track: function (val) {
      this.selTrackCat = this.categories.filter(
        (item) => val.category_id == item.id
      );

      this.itinerary = this.trackStatSol.filter(
        (item) => item.track_id == val.id
      );

      this.itinerary = this.itinerary.reduce((r, a) => {
        r[a.station_id] = [...(r[a.station_id] || []), a];
        return r;
      }, {});

      let new_itinerary = [];

      $.each(this.itinerary, function (index, item) {
        new_itinerary.push(item);
      });

      this.itinerary = new_itinerary;

      let reorder = [];

      // foreach itinerary console.log first element
      this.itinerary.forEach((element, index) => {
        //create object array with element[0].solution_order and index
        reorder.push({
          solution_order:
            element[0].station_position == null
              ? element[0].solution_order
              : element[0].station_position,
          index: index,
        });
      });

      //reorder by solution_order
      reorder.sort(function (a, b) {
        return a.solution_order - b.solution_order;
      });

      new_itinerary = [];

      reorder.forEach((element) => {
        new_itinerary.push(this.itinerary[element.index]);
      });

      this.itinerary = new_itinerary;

      reorder = [];

      new_itinerary.forEach((element, index) => {
        element.forEach((item, i) => {
          reorder.push({
            solution_order:
              item.solution_position == null
                ? item.solution_order
                : item.solution_position,
            index: i,
          });
        });

        reorder.sort(function (a, b) {
          return a.solution_order - b.solution_order;
        });

        let new_element = [];

        reorder.forEach((el) => {
          new_element.push(element[el.index]);
        });

        reorder = [];

        new_itinerary[index] = new_element;
      });

      this.selQuestions = this.questions.filter(
        (item) => item.track_id == val.id
      );

      this.selQuestions = this.selQuestions.reduce((r, a) => {
        r[a.solution_id] = [...(r[a.solution_id] || []), a];
        return r;
      }, {});

      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getResourcesByTemplate/${this.track.id}`
        )
        .then((response) => {
          const newResources = [];

          for (let i = 0; i < 4; i++) {
            response.data[i].forEach((el) => {
              // check if resource is already in array
              if (
                !newResources.find(
                  (item) =>
                    item.resource_id == el.resource_id &&
                    item.solution_id == el.solution_id
                )
              )
                newResources.push({
                  resource_id: el.resource_id,
                  resource: el.resource,
                  link: el.link,
                  solution_id: el.solution_id,
                  track_id: this.track.id,
                  station_id: el.station_id,
                });
            });
          }

          this.selResources = newResources;
        });

      //   this.selResources = this.resources.filter(
      //     (item) => item.track_id == this.track.id
      //   );

      //     this.selResourcesStat = this.resourcesStat.filter(
      //       (item) => item.track_id == this.track.id
      //     );

      $(".track-it-apt-div ").hide();
      $(".template-it-quest-div").hide();
      $(".arrow-modal-template").attr(
        "src",
        "/img/track/template-arrow-right-modal.svg"
      );
    },
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/tracks/getTrackStatSol`, {})
      .then((response) => {
        const arrayData = [];
        response.data.forEach((element) => {
          if (
            !arrayData.find(
              (item) =>
                item.track_id == element.track_id &&
                item.station_id == element.station_id &&
                item.solution_id == element.solution_id
            )
          )
            arrayData.push(element);
        });

        this.trackStatSol = arrayData;
        this.queryCounter += 1;
      });
    axios
      .post(`${process.env.VUE_APP_API_URL}/tracks/getTrackQuestions`, {})
      .then((response) => {
        this.questions = response.data;
        this.queryCounter += 1;
      });
    axios
      .post(
        `${process.env.VUE_APP_API_URL}/tracks/getTrackResourcesBySolutions`,
        {}
      )
      .then((response) => {
        this.resources = response.data;
        this.queryCounter += 1;
      });
    axios
      .post(
        `${process.env.VUE_APP_API_URL}/tracks/getTrackResourcesByStations`,
        {}
      )
      .then((response) => {
        this.resourcesStat = response.data;
        this.queryCounter += 1;
      });
  },
  methods: {
    // devuelve la imagen de la plantilla
    getTrackHeader(track) {
      return `${process.env.VUE_APP_API_STORAGE}/img/track_headers/${track}`;
    },

    // selecciona si se quiere ver el componente de itinerario o el de recursos
    selectorTrack($event) {
      this.$parent.selectorModal = $event.target.value;
    },

    // selecciona la plantilla para crear un nuevo proyecto, solo disponible desde new-project
    selectTemplateNewProject(track) {
      this.$parent.selected_template = track;

      const { assistant_id, evaluator_id } = track;

      this.$parent.assistant_id = assistant_id ?? "";
      this.$parent.evaluator_id = evaluator_id ?? "";
      this.$parent.assistantTemplate =
        assistant_id !== null || evaluator_id !== null;

      if (!this.$parent.template_assistant_id) {
        this.$parent.selected_assistant = track.template_assistant_name;
        this.$parent.selected_assistant_id = track.template_assistant_id;
      }

      var myModal = Modal.getOrCreateInstance(
        document.getElementById("trackModal")
      );
      myModal.toggle();
      this.$parent.stepsFunction(this.$parent.steps + 1);
    },
  },
};
</script>
