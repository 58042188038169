<template>
  <div>
    <div v-if="spaceIndex === 1" class="space-separator">
      <img
        class="space-separator-img"
        :src="require('@/assets/img/sidebar/separator.svg')"
        alt="separator"
      />
    </div>
    <div
      :style="'background-image: url(' + checkImg(space.img) + ') !important'"
      class="space-image sidebar-customer-image"
      :class="{
        'selected-space': spaceIndex === 0,
      }"
      @click="selectSpace(spaceIndex)"
    >
      <div class="space-label">
        <p>{{ space.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    space: {
      type: Object,
      required: true,
    },
    spaceSelecteds: {
      type: Object,
      required: true,
    },
    spaceIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["space"],
  data() {
    return {
      spaceSelected: this.spaceSelecteds,
    };
  },
  methods: {
    checkImg(img) {
      if (img === "") {
        return "/img/spaces/default.png";
      } else {
        return img;
      }
    },
    selectSpace(i) {
      if (i !== 0) {
        this.$emit("space", i);
      }
    },
  },
};
</script>
