<template>
  <header-general
    :title="langFilter('assistants')"
    src="/img/icons/icon-robot-wink.svg"
  />
  <div class="d-flex flex-column mx-5 px-5">
    <div
      class="ms-auto py-1 px-2 assistant-newAssistant"
      @click="goToNewAssistant"
    >
      <img :src="require('@/assets/img/projects/new_project.svg')" />
    </div>

    <div v-if="loading">
      <img
        class="loading-rocket"
        :src="require('@/assets/img/general/loading.gif')"
      />
    </div>
    <div v-else-if="assistantTypes.length === 0" class="col-12">
      <p>{{ langFilter("assistant_not_created") }}</p>
    </div>
    <div v-for="type in assistantTypes" v-else :key="type.id" class="mb-4">
      <div
        class="px-5 py-2 w-25 text-start text-capitalize fs-5 fw-bold assistant-type"
      >
        {{ langFilter(type.name) }}
      </div>
      <AssistantsList
        :lang="lang"
        :assistList="filteredAssistants[type.id]"
        :onFetchAssistants="fetchAssistants"
      />
    </div>
  </div>
</template>

<script>
import AssistantsList from "@/components/assistants/AssistantsList.vue";
import axios from "axios";
import translationMixin from "@/mixins/translationMixin";
import translations from "../components/assistants/translate/AssistantTranslate.json";

export default {
  components: {
    AssistantsList,
  },
  mixins: [translationMixin],
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    homeNotifications: {
      type: Number,
      required: true,
    },
  },
  emits: ["openAlert"],
  data() {
    return {
      loading: false,
      assistList: [],
      traducciones: translations,
    };
  },
  computed: {
    assistantTypes() {
      const types = {};
      this.assistList.forEach((assistant) => {
        const { id, name } = assistant.assistant_type;
        if (!types[id]) {
          types[id] = { id, name };
        }
      });
      return Object.values(types);
    },
    filteredAssistants() {
      const result = {};
      this.assistList.forEach((assistant) => {
        const typeId = assistant.assistant_type.id;
        if (!result[typeId]) {
          result[typeId] = [];
        }
        result[typeId].push(assistant);
      });
      return result;
    },
  },
  mounted() {
    this.fetchAssistants();
  },
  methods: {
    fetchAssistants() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}/assistants`)
        .then((response) => {
          this.assistList = response.data;
        })
        .catch((error) => {
          console.error("Error trayendo asistentes:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToNewAssistant() {
      this.$router.push("/new-assistant");
    },
  },
};
</script>
