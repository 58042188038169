var moment = require("moment");
import axios from "axios";

export default {
  data() {
    return {
      notifications: [],
      prevNotifications: [],
      newNotifications: [],
      userDoAction: [],
      objectName: [],
      initialLoad: true,
      loading: true,
    };
  },
  methods: {
    getAllNotifications(response) {
      if (response.length > 0) {
        this.notifications = response;
        this.startUserDoAction();
      }
    },
    getObjectUserDoAction() {
      moment.locale(this.lang);
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.userDoAction[i] == undefined) {
          this.userDoAction[i] = this.lang == "es" ? "Alguien" : "Someone";
        }
        if (this.objectName[i] == undefined) {
          this.objectName[i] = this.lang == "es" ? "el objeto" : "the object";
        }
        if (this.notifications[i] != undefined) {
          let finalMsg = this.notifications[i].message.replace(
            "$user_do_action",
            "<span class='bold-not-home'>" + this.userDoAction[i] + "</span>"
          );
          finalMsg = finalMsg.replace(
            "$object_name",
            "<span class='bold-not-home'>" + this.objectName[i] + "</span>"
          );
          this.notifications[i].message = finalMsg;
          this.notifications[i].notification_date = moment(
            this.notifications[i].notification_date
          ).fromNow();
          this.notifications[i].notification_date =
            this.notifications[i].notification_date.charAt(0).toUpperCase() +
            this.notifications[i].notification_date.slice(1);
        }
      }
      this.initialLoad = false;
      this.loading = false;
    },
    startUserDoAction() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/home/getUserDoAction`, {
          notifications: this.notifications,
        })
        .then((response) => {
          this.userDoAction = response.data;
          axios
            .post(`${process.env.VUE_APP_API_URL}/home/getObjectName`, {
              notifications: this.notifications,
            })
            .then((response) => {
              this.objectName = response.data;
              this.getObjectUserDoAction();
            });
        });
    },
    getHoursNotification(hours) {
      let datetime = "";
      if (hours) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/home/getDateTime`, {
            notification_date: hours,
          })
          .then((response) => {
            datetime = response.data;
          });
      }
      return datetime;
    },
    locateModal(event, banner, newer) {
      const mousex = event.pageX;
      const mousey = event.pageY;
      if (!banner) {
        this.$emit("locateModal", [mousey + 30, mousex - 100, newer]);
      } else {
        this.$emit("locateModal", [mousey + 15, mousex - 260, newer]);
      }
    },
    reorderNotifications() {
      this.prevNotifications = this.notifications.filter(function (el) {
        return el.new == 0;
      });
      this.newNotifications = this.notifications.filter(function (el) {
        return el.new == 1;
      });
    },
    openModalNotifications(id) {
      setTimeout(
        function () {
          this.$emit("openModal", id);
        }.bind(this),
        5
      );
    },
    getNotifications(skip, take) {
      axios
        .post(`${process.env.VUE_APP_API_URL}/notifications/getNotifications`, {
          skip: skip,
          take: take,
          lang: this.lang,
          space_id: this.spaceId,
        })
        .then((response) => {
          this.getAllNotifications(response.data);
          if (response.data.length > 0) {
            this.reorderNotifications();
          }
          if (this.notifications.length === 0) this.loading = false;
        });
    },
    goToNotification(url) {
      //pasar notificacion a leída
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/notifications/markAsReadVisited`,
          {
            url: url,
          }
        )
        .then(() => {
          window.location.href;
        });
    },
  },
};
