<script setup>
import NewTrack from "../components/track/newTrack/NewTrackComponent.vue";
</script>

<template>
  <NewTrack :space="spaces[0]" :lang="lang" />
</template>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
@import "../../src/assets/css/track/new_track.css";
@import "../../src/assets/css/home/home.css";
</style>
