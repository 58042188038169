<template>
  <div
    :id="id"
    class="apart-background-itinerary no-selection-itinerary"
    @mouseover="passmouseapart()"
    @mouseout="outmouseapart()"
  >
    <div class="itinerary-eachRow row-itinerary">
      <div
        v-if="notification"
        :id="'notification' + id"
        class="notification-apart-itinerary"
      ></div>
      <div
        id="primer_row"
        class="first-apart-row-itinerary"
        @click="openQuestion = !openQuestion"
      >
        <router-link
          :to="'/parts/' + projectId + '/' + stationId + '/' + solutionId"
          onclick="event.preventDefault()"
          ><span
            id="click_solution"
            style="color: #2a3b5a; cursor: pointer"
            @click="goToSolution()"
            >{{ name }}</span
          ></router-link
        >
        <img
          :id="'imagen_flecha2' + id"
          style="padding-left: 12px"
          :src="
            openQuestion
              ? '/img/itinerario/arrow_down.svg'
              : '/img/itinerario/arrow_right.svg'
          "
        />
      </div>
      <div class="apart-row-itinerary text-enseña-datos-tabla-itinerary">
        <p
          class=""
          data-bs-toggle="modal"
          data-bs-target="#recursosModal"
          @click="getResources(solutionId)"
        >
          {{ resources }} {{ langFilter("recursos") }}
        </p>
      </div>
      <div class="apart-row-itinerary text-enseña-datos-tabla-itinerary">
        <div
          class="itinerary-advanced-by"
          :style="
            isCompleted
              ? 'background-color: #13C99E'
              : 'background-color: #B4BAC5'
          "
        ></div>
      </div>
    </div>
    <div v-if="openQuestion" style="margin-left: 10%">
      <itinerary-questiontable
        v-for="(question, questionIndex) in questionId"
        :id="'question_table_' + questionIndex + '_' + solutionId"
        :key="questionIndex"
        :is_completed="questionId[questionIndex].answer"
        :question_id="questionId[questionIndex].question_id"
        :class="'question-class-' + id"
        :name="questionId[questionIndex].name"
        :time="questionId[questionIndex].time"
        :question_type="questionId[questionIndex].question_type"
        :notification="
          userRole == 'Emprendedor'
            ? questionId[questionIndex].tutor === 1 ||
              questionId[questionIndex].notTutor === 1
              ? true
              : false
            : questionId[questionIndex].entrepreneur === 1 ||
                questionId[questionIndex].notEntrepreneur === 1
              ? true
              : false
        "
        :solution_id="solutionId"
        :project_id="projectId"
        :user_role="userRole"
        :lang="lang"
      ></itinerary-questiontable>
    </div>
    <div
      v-if="isVisible === 1 && questionId[0].question_id === 0"
      id="no_question"
    >
      No hay preguntas para esta solución
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  name: "ItineraryApartTableComponent",
  mixins: [translationMixin],
  props: {
    solutionId: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    resources: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    stationId: {
      type: Number,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    changeView: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      web: "",
      questionId: [],
      isOptional: this.optional,
      countFilterSolutions: "",
      notificationTutor: 0,
      notificationEntrepreneur: 0,
      isVisible: "",
      notQuestions: [],
      notification: false,
      openQuestion: false,
      traducciones: [
        {
          name: "recursos",
          es: "recursos",
          en: "resources",
        },
        {
          name: "apartados",
          es: "Apartados",
          en: "Parts",
        },
        {
          name: "pendiente",
          es: "Pendiente",
          en: "Pending",
        },
        {
          name: "revision",
          es: "Pendiente Revisión",
          en: "Pending Review",
        },
        {
          name: "modificacion",
          es: "Pendiente Modificación",
          en: "Pending Modification",
        },
        {
          name: "completado",
          es: "Completado",
          en: "Complete",
        },
        {
          name: "si",
          es: "Sí",
          en: "Yes",
        },
      ],
    };
  },
  created() {
    this.onCreated_Solution();
  },
  methods: {
    getResources(solution) {
      this.$parent.getResources(solution);
    },
    passmouseapart() {
      $("#" + this.id).addClass("apart-border-1px");
    },
    outmouseapart() {
      $("#" + this.id).removeClass("apart-border-1px");
    },
    openSolutions(el) {
      if (el.target.id !== "click_solution") {
        if (this.isVisible == 1) {
          $("#imagen_flecha2" + this.id).attr(
            "src",
            "/img/itinerario/arrow_right.svg"
          );
          $(".question-class-" + this.id).hide();
          this.isVisible = 0;
        } else {
          axios
            .post(`${process.env.VUE_APP_API_URL}/itinerary/getQuestion`, {
              project_id: this.projectId,
              solution_id: this.solutionId,
            })
            .then((response) => {
              if (response.data.length > 0) {
                this.questionId = response.data;
                this.$nextTick(() => {
                  $(".question-class-" + this.id).show();
                  $("#imagen_flecha2" + this.id).attr(
                    "src",
                    "/img/itinerario/arrow_down.svg"
                  );
                });
              } else {
                let object = {
                  question_id: 0,
                  name: "No hay preguntas",
                  time: 0,
                  question_type: 0,
                  tutor: 0,
                  entrepreneur: 0,
                  notTutor: 0,
                  notEntrepreneur: 0,
                };
                this.questionId.push(object);
                this.$nextTick(() => {
                  $(".question-class-" + this.id).show();
                  $("#imagen_flecha2" + this.id).attr(
                    "src",
                    "/img/itinerario/arrow_down.svg"
                  );
                });
                this.$nextTick(() => {
                  this.isVisible = 1;
                });
              }
            });
        }
      }
    },
    clickSiNo() {
      if (this.lang == "es") {
        if (this.isOptional == true) {
          $("#si_no" + this.id).attr("src", "/img/itinerario/si-no.svg");
          this.isOptional = false;
        } else {
          $("#si_no" + this.id).attr("src", "/img/itinerario/no-si.svg");
          this.isOptional = true;
        }
      } else {
        if (this.isOptional == true) {
          $("#si_no" + this.id).attr("src", "/img/itinerario/yes-no.svg");
          this.isOptional = false;
        } else {
          $("#si_no" + this.id).attr("src", "/img/itinerario/no-yes.svg");
          this.isOptional = true;
        }
      }
      axios.post("/itinerary/setOptional", {
        project_id: this.projectId,
        station_id: this.stationId,
        solution_id: this.solutionId,
        optional: this.isOptional,
      });
    },
    checkState() {
      switch (this.state) {
        case 1:
          return this.langFilter("pendiente");
        case 2:
          return this.langFilter("revision");
        case 3:
          return this.langFilter("modificacion");
        case 4:
          return this.langFilter("completado");
      }
    },
    filterSolutions(filter) {
      if (this.state == filter || filter == 0) {
        $("#" + this.id).attr(
          "src",
          "margin-right: 43px; margin-left: 10%; padding-top: 8px; padding-bottom: 2px; margin-bottom: 15px; cursor: pointer; display: flex; flex-direction: column"
        );
      } else {
        $("#" + this.id).attr("src", "");
      }
    },
    checkOptional() {
      if (this.lang == "es") {
        if (this.optional == true) {
          return "/img/itinerario/no-si.svg";
        } else {
          return "/img/itinerario/si-no.svg";
        }
      } else {
        if (this.optional == true) {
          return "/img/itinerario/no-yes.svg";
        } else {
          return "/img/itinerario/yes-no.svg";
        }
      }
    },
    showOptionalEntrepreneur() {
      if (this.optional == true) {
        return this.langFilter("si");
      } else {
        return "No";
      }
    },
    goToSolution() {
      this.changeView(105, this.projectId, this.stationId, this.solutionId);
    },
    onCreated_Solution() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/itinerary/setNotificationSolution`,
          {
            project_id: this.projectId,
            solution_id: this.solutionId,
          }
        )
        .then((response) => {
          let not = "";
          if (this.userRole === "Emprendedor")
            not = response.data.filter(
              (element) =>
                element.interaction_tutor == 1 ||
                element.ready_correct_tutor == 1
            );
          else
            not = response.data.filter(
              (element) =>
                element.interaction_entrepreneur == 1 ||
                element.ready_correct_entrepreneur == 1
            );

          if (not.length > 0) {
            this.notification = true;
          }
        });
      $("itineraryquestiontable").next().hide();
      this.web = window.location.pathname;

      this.web = this.web.split("/");
      this.isVisible = 0;
      $("#imagen_flecha2" + this.id).attr(
        "src",
        "/img/itinerario/arrow_right.svg"
      );
      $(".question-class-" + this.id).hide();
      if (this.state == this.filter || this.filter == 0) {
        $("#" + this.id).addClass("hide-show-Solution");
        this.countFilterSolutions = 1;
      } else {
        $("#" + this.id).removeClass("hide-show-Solution");
        this.countFilterSolutions = 0;
      }
      if (this.questionId.length === 0) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/itinerary/getQuestion`, {
            project_id: this.projectId,
            solution_id: this.solutionId,
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.questionId = response.data;
            } else {
              let object = {
                question_id: 0,
                name: "No hay preguntas",
                time: 0,
                question_type: 0,
                tutor: 0,
                entrepreneur: 0,
                notTutor: 0,
                notEntrepreneur: 0,
              };
              this.questionId.push(object);
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>
