<template>
  <div>
    <head>
      <link rel="stylesheet" type="text/css" href="/css/version.css" />
      <title>Version - Acceleralia</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/img/general/favicon.png" />
    </head>
    <header-general
      :src="require('@/assets/img/sidebar/gears.svg')"
      :title="langFilter('versiones')"
    ></header-general>
    <div class="vistaVersion">
      <div v-if="view == 1">
        <div class="consulta">
          <div class="textoConsulta">
            {{ langFilter("front") }}:
            <strong>{{ frontend_build }}</strong>
          </div>
          <div class="textoConsulta">
            {{ langFilter("back") }}:
            <strong>{{ backend_build }}</strong>
          </div>
        </div>
      </div>

      <div v-if="view == 0">
        <img class="carga2" :src="require('@/assets/img/general/carga.gif')" />
      </div>

      <div v-if="view == 3">
        <errorComponent
          title="404-title"
          body="404-body"
          vamos="404-vamos"
          header="true"
        ></errorComponent>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import headerGeneral from "../general/HeaderComponent.vue";
import errorComponent from "../ErrorComponent.vue";
import axios from "axios";

export default {
  components: {
    headerGeneral,
    errorComponent,
  },
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    ruta: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      view: 0,
      backend_build: "",
      frontend_build: "",
      traducciones: [
        {
          name: "front",
          es: "Versión Frontend",
          en: "Frontend Build Version",
        },
        {
          name: "back",
          es: "Versión Backend",
          en: "Backend Build Version",
        },
        {
          name: "versiones",
          es: "Versión de compilación",
          en: "Build Version",
        },
      ],
    };
  },
  created() {
    this.view = this.ruta;
    setTimeout(
      function () {
        this.popStateReload();
      }.bind(this),
      1
    );
    this.view = 0;
    this.getBackendVersion();
  },
  methods: {
    popStateReload() {
      this.view = 0;

      setTimeout(() => {
        this.$parent.loading = false;
      }, 1000);
    },

    // carga la version del backend
    getBackendVersion() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/version`, {
          lang: this.lang,
        })
        .then((response) => {
          this.view = 1;
          this.backend_build = response.data.Compilation;
          this.frontend_build = `${process.env.VUE_APP_GIT}`;
        })
        .catch(() => {
          this.view = 3;
        });
    },
  },
};
</script>
