<script setup>
import Tracks from "../components/track/TrackRootComponent.vue";
</script>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <Tracks :lang="lang" :role="role" :spaces="spaces" />
</template>

<style>
@import "../../src/assets/css/track/track.css";
@import "../../src/assets/css/track/new_track.css";
</style>
