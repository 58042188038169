<template>
  <!-- Card container -->
  <div v-if="post.active" class="forum-card thread" style="cursor: default">
    <!-- User info -->
    <div class="published-by thread-card">
      <img :src="replaced_avatar" />
      <h1>{{ post.user_first_name }}<br />{{ post.user_last_name }}</h1>
      <p>{{ langFilter(post.role) }}</p>
      <p>{{ date_format() }}</p>
    </div>
    <div class="topic-thread-card" style="max-width: 190px">
      <p
        v-if="
          $parent.main_post.program_name == null &&
          $parent.main_post.project_name == null
        "
        style="height: 0px"
      ></p>

      <p v-if="$parent.main_post.program_name" class="thread-card-p">
        {{ langFilter("Program") }}:
        <span class="thread-card-span">{{
          $parent.main_post.program_name
        }}</span>
      </p>
      <p v-if="$parent.main_post.project_name" class="thread-card-p">
        {{ langFilter("Project") }}:
        <span class="thread-card-span">{{
          $parent.main_post.project_name
        }}</span>
      </p>
      <!-- Quote info -->
      <p v-if="post.quote_message" class="quote-p">Cita:</p>
      <div
        v-if="post.quote_message"
        class="thread-quote-message"
        style="margin-bottom: 22px"
      >
        {{ get_quote() }}
      </div>
      <!-- Title only for the post card -->
      <h1
        v-if="
          (index == 0 && !$parent.filterFav) ||
          (post.post_id == $parent.normal_posts[0].post_id && $parent.filterFav)
        "
        class="thread-card-h1"
      >
        {{ $parent.main_post.title }}
      </h1>
      <!-- Message -->
      <div class="thread-card-div">{{ post.description }}</div>
      <!-- Likes -->
      <div class="likes-count">
        <label>{{ post.likes }}</label>
        <span aria-hidden="true">
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="cursor: default"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.41892 0.31074C6.50416 0.121778 6.69435 0 6.90424 0C7.60851 0 8.28394 0.275641 8.78194 0.766286C9.27994 1.25693 9.55971 1.92239 9.55971 2.61626V4.88369H13.0339C13.315 4.88102 13.5935 4.93837 13.85 5.05181C14.1076 5.16569 14.3369 5.33338 14.5221 5.54326C14.7074 5.75313 14.8441 6.00017 14.9228 6.26727C15.0015 6.53437 15.0203 6.81513 14.9779 7.09011L14.0007 13.3691C14.0007 13.3691 14.0007 13.369 14.0007 13.3691C13.9303 13.8265 13.6944 14.2436 13.3366 14.5433C12.9796 14.8423 12.5251 15.0044 12.0566 14.9999H1.94735C1.43088 14.9999 0.935564 14.7978 0.570365 14.438C0.205166 14.0782 0 13.5902 0 13.0813V8.19763C0 7.68878 0.205166 7.20078 0.570365 6.84098C0.935564 6.48117 1.43088 6.27903 1.94735 6.27903H3.72658L6.41892 0.31074ZM4.60282 6.91333L7.23405 1.0805C7.5336 1.14295 7.81104 1.2897 8.03086 1.50628C8.32966 1.80066 8.49752 2.19994 8.49752 2.61626V5.40694C8.49752 5.69593 8.7353 5.9302 9.02862 5.9302H13.0366L13.0426 5.93016C13.1709 5.92873 13.298 5.9548 13.4151 6.00657C13.5322 6.05833 13.6364 6.13455 13.7206 6.22995C13.8048 6.32535 13.8669 6.43764 13.9027 6.55905C13.9385 6.68038 13.947 6.80791 13.9278 6.93282C13.9278 6.93274 13.9278 6.9329 13.9278 6.93282L12.9506 13.2122C12.9186 13.4201 12.8113 13.6097 12.6487 13.7459C12.486 13.8821 12.2789 13.9558 12.0654 13.9534L4.60282 13.9534V6.91333ZM3.54063 13.9534V7.32554H1.94735C1.71259 7.32554 1.48745 7.41742 1.32145 7.58097C1.15545 7.74451 1.06219 7.96633 1.06219 8.19763V13.0813C1.06219 13.3126 1.15545 13.5344 1.32145 13.698C1.48745 13.8615 1.71259 13.9534 1.94735 13.9534H3.54063Z"
              fill="#ACA3D0"
            />
          </svg>
        </span>
      </div>
    </div>

    <div class="options thread-card">
      <!-- Fav button -->
      <div>
        <svg
          v-if="$parent.is_fav(post)"
          width="16"
          height="16"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click.stop.prevent="$parent.remove_post_fav(post)"
        >
          <path
            class="filled"
            d="M7.52772 0.881555C7.68337 0.433772 8.31663 0.433775 8.47228 0.881552L9.65039 4.27081C9.85638 4.86342 10.4094 5.26522 11.0367 5.27801L14.6241 5.35111C15.0981 5.36077 15.2938 5.96305 14.916 6.24944L12.0567 8.41723C11.5567 8.79626 11.3455 9.4464 11.5272 10.0469L12.5662 13.4813C12.7035 13.9351 12.1912 14.3073 11.802 14.0365L8.85677 11.9871C8.3418 11.6287 7.6582 11.6287 7.14323 11.987L4.19797 14.0365C3.80884 14.3073 3.29652 13.9351 3.4338 13.4813L4.47285 10.0469C4.65452 9.4464 4.44328 8.79626 3.94333 8.41723L1.08401 6.24945C0.706245 5.96304 0.901938 5.36077 1.3759 5.35111L4.96333 5.27801C5.59058 5.26522 6.14362 4.86342 6.34961 4.27081L7.52772 0.881555Z"
            fill="#301889"
            stroke="#301889"
          />
        </svg>
        <svg
          v-if="!$parent.is_fav(post)"
          width="16"
          height="16"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="$parent.add_post_fav(post)"
        >
          <path
            d="M7.52772 0.881555C7.68337 0.433772 8.31663 0.433775 8.47228 0.881552L9.65039 4.27081C9.85638 4.86342 10.4094 5.26522 11.0367 5.27801L14.6241 5.35111C15.0981 5.36077 15.2938 5.96305 14.916 6.24944L12.0567 8.41723C11.5567 8.79626 11.3455 9.4464 11.5272 10.0469L12.5662 13.4813C12.7035 13.9351 12.1912 14.3073 11.802 14.0365L8.85677 11.9871C8.3418 11.6287 7.6582 11.6287 7.14323 11.987L4.19797 14.0365C3.80884 14.3073 3.29652 13.9351 3.4338 13.4813L4.47285 10.0469C4.65452 9.4464 4.44328 8.79626 3.94333 8.41723L1.08401 6.24945C0.706245 5.96304 0.901938 5.36077 1.3759 5.35111L4.96333 5.27801C5.59058 5.26522 6.14362 4.86342 6.34961 4.27081L7.52772 0.881555Z"
            stroke="#ACA3D0"
          />
        </svg>
      </div>
      <!-- Like button -->
      <span v-if="!is_like()" aria-hidden="true" @click="add_like()">
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 7H3.5V15H2C1.17157 15 0.5 14.3284 0.5 13.5V8.5C0.5 7.67157 1.17157 7 2 7Z"
            stroke="#ACA3D0"
          />
          <path
            d="M12.19 15H3.5V6.62606L6.77421 0.54539C6.78926 0.517434 6.81845 0.5 6.8502 0.5C7.76136 0.5 8.5 1.23864 8.5 2.1498V5.5C8.5 5.77614 8.72386 6 9 6H12.79C13.6775 6 14.3709 6.76623 14.2826 7.64926L13.6826 13.6493C13.6059 14.4161 12.9607 15 12.19 15Z"
            stroke="#ACA3D0"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <span v-if="is_like()" aria-hidden="true" @click="remove_like()">
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 7H3.5V15H2C1.17157 15 0.5 14.3284 0.5 13.5V8.5C0.5 7.67157 1.17157 7 2 7Z"
            stroke="#301889"
            fill="#301889"
          />
          <path
            d="M12.19 15H3.5V6.62606L6.77421 0.54539C6.78926 0.517434 6.81845 0.5 6.8502 0.5C7.76136 0.5 8.5 1.23864 8.5 2.1498V5.5C8.5 5.77614 8.72386 6 9 6H12.79C13.6775 6 14.3709 6.76623 14.2826 7.64926L13.6826 13.6493C13.6059 14.4161 12.9607 15 12.19 15Z"
            stroke-linejoin="round"
            stroke="#ffffff"
            fill="#301889"
          />
        </svg>
      </span>
      <!-- <span aria-hidden="true" @click="scrollResponse('')">
                        <svg width="18" height="12" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.1034 9.18964L15.6483 11.5054C15.7154 11.7906 15.333 11.9518 15.1757 11.7046L14.8619 11.2116C13.3686 8.86487 10.8921 7.32134 8.12749 7.01416C8.05948 7.00661 8 7.05984 8 7.12827V9.08417C8 9.47975 7.56238 9.71867 7.22962 9.50476L1.65425 5.92059C1.34809 5.72377 1.34809 5.27623 1.65425 5.07941L7.22962 1.49524C7.56238 1.28133 8 1.52025 8 1.91583V3.64143C8 3.8441 8.15536 4.01295 8.35734 4.02978L9.19893 4.09991C12.0618 4.33848 14.4455 6.39324 15.1034 9.18964Z" stroke="#ACA3D0" stroke-linejoin="round"/>

                        </svg>

                    </span> -->
      <!-- Delete button -->
      <span
        v-if="
          post.user_id == $parent.user_id &&
          post.post_id !== $parent.normal_posts[0].post_id
        "
        aria-hidden="true"
      >
        <svg
          data-bs-toggle="modal"
          data-bs-target="#modalConfirmation"
          width="14"
          height="16"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click.stop.prevent="delete_thread_message(post)"
        >
          <path
            d="M1 3.89062H12.5556"
            stroke="#ACA3D0"
            stroke-linecap="round"
          />
          <path
            d="M3.89062 2C3.89062 1.44772 4.33834 1 4.89062 1H8.6684C9.22069 1 9.6684 1.44772 9.6684 2V3.88889H3.89062V2Z"
            stroke="#ACA3D0"
          />
          <path
            d="M2.44531 3.89062H11.112V13.0017C11.112 13.554 10.6643 14.0017 10.112 14.0017H3.44531C2.89303 14.0017 2.44531 13.554 2.44531 13.0017V3.89062Z"
            stroke="#ACA3D0"
          />
          <path
            d="M8.22266 6.77734V11.1107"
            stroke="#ACA3D0"
            stroke-linecap="round"
          />
          <path
            d="M5.33203 6.77734V11.1107"
            stroke="#ACA3D0"
            stroke-linecap="round"
          />
        </svg>
      </span>
    </div>
    <div class="quote-button" @click="scrollResponse(post)">
      <svg
        width="13"
        height="11"
        viewBox="0 0 13 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.29865 1.87582C4.96201 1.3305 4.46431 0.913575 3.88035 0.687694C3.29639 0.461812 2.65773 0.439182 2.06035 0.623206C1.46297 0.80723 0.939149 1.18796 0.567616 1.70819C0.196082 2.22841 -0.00308926 2.86001 3.62304e-05 3.50806C0.000346103 4.04471 0.139197 4.57143 0.402085 5.03319C0.664972 5.49495 1.04225 5.87481 1.4945 6.13307C1.94675 6.39133 2.45737 6.51853 2.97303 6.50137C3.48869 6.48421 3.99046 6.32332 4.42591 6.03552C4.19952 6.7348 3.77784 7.48081 3.08312 8.22862C2.9502 8.37165 2.87734 8.56375 2.88058 8.76265C2.88383 8.96155 2.96289 9.15096 3.1004 9.28922C3.2379 9.42748 3.42257 9.50326 3.61379 9.49989C3.80501 9.49652 3.98711 9.41427 4.12002 9.27125C6.6881 6.5029 6.35457 3.49368 5.29865 1.87941V1.87582ZM12.2114 1.87582C11.8747 1.3305 11.377 0.913575 10.7931 0.687694C10.2091 0.461812 9.57046 0.439182 8.97308 0.623206C8.3757 0.80723 7.85188 1.18796 7.48035 1.70819C7.10882 2.22841 6.90964 2.86001 6.91277 3.50806C6.91308 4.04471 7.05193 4.57143 7.31482 5.03319C7.57771 5.49495 7.95498 5.87481 8.40723 6.13307C8.85948 6.39133 9.37011 6.51853 9.88577 6.50137C10.4014 6.48421 10.9032 6.32332 11.3386 6.03552C11.1123 6.7348 10.6906 7.48081 9.99585 8.22862C9.86293 8.37165 9.79008 8.56375 9.79332 8.76265C9.79656 8.96155 9.87563 9.15096 10.0131 9.28922C10.1506 9.42748 10.3353 9.50326 10.5265 9.49989C10.7177 9.49652 10.8998 9.41427 11.0328 9.27125C13.6008 6.5029 13.2673 3.49368 12.2114 1.87941V1.87582Z"
          fill="#C5C1E4"
        />
      </svg>
      <p>{{ langFilter("Quote") }}</p>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";

import $ from "jquery";
import axios from "axios";

export default {
  props: ["lang", "index", "post"],

  data() {
    return {
      //Traducción
      traducciones: [
        {
          name: "Program",
          es: "Programa",
          en: "Program",
        },
        {
          name: "Project",
          es: "Proyecto",
          en: "Project",
        },
        {
          name: "Entrepreneur",
          es: "Emprendedor",
          en: "Entrepreneur",
        },
        {
          name: "Tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "Mentor",
          es: "Mentor",
          en: "Mentor",
        },
        {
          name: "Quote",
          es: "Citar",
          en: "Quote",
        },
      ],
    };
  },
  computed: {
    // Load avatar
    replaced_avatar() {
      if (this.post.user_avatar != null) {
        let avatar = this.post.user_avatar.replace("https://www/.", "https://");
        // remove blank spaces
        avatar = avatar.replace(/ /g, "%20");
        if (avatar.includes("http")) {
          return avatar;
        } else {
          return process.env.VUE_APP_API_STORAGE + avatar;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    // Get quote onclick quote button to response the message
    get_quote() {
      var quote = "";
      quote = this.$parent.normal_posts.find((quote) => {
        return this.post.quote_message == quote.post_id;
      });
      if (quote) {
        return quote.description;
      }
    },
    // Check likes
    is_like() {
      if (this.index == 0) {
        return this.$parent.like_subpost;
      } else {
        return this.$parent.like_threads.find((like) => {
          return this.post.post_id === like.thread_message_id;
        });
      }
    },
    // Add like function to all the variables
    add_like() {
      if (this.index == 0) {
        this.$parent.like_subpost = "true";
        this.post.likes = this.post.likes + 1;
        axios
          .post(`${process.env.VUE_APP_API_URL}/forum-add-subpost-like`, {
            post_id: this.post.post_id,
            user_id: this.$parent.user_id,
          })
          .then(() => {})
          .catch(() => {
            // console.log("error");
          });
      } else {
        var like = { thread_message_id: this.post.post_id };
        this.$parent.like_threads.push(like);
        this.post.likes = this.post.likes + 1;
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/forum-add-thread-message-like`,
            {
              post_id: this.post.post_id,
              user_id: this.$parent.user_id,
            }
          )
          .then(() => {})
          .catch(() => {
            // console.log("error");
          });
      }
      if (this.$parent.is_fav(this.post)) {
        if (this.$parent.filterFav) {
          const indexOfObject = this.$parent.normal_posts.findIndex(
            (object) => {
              return object.post_id === this.post.post_id;
            }
          );
          if (indexOfObject >= 0) {
            this.$parent.normal_posts[indexOfObject].likes += 1;
          }
        } else {
          const indexOfObject = this.$parent.favourite_posts.findIndex(
            (object) => {
              return object.post_id === this.post.post_id;
            }
          );
          if (indexOfObject >= 0) {
            this.$parent.favourite_posts[indexOfObject].likes += 1;
          }
        }
      }
    },
    // Remove like function to all the variables
    remove_like() {
      if (this.index == 0) {
        this.$parent.like_subpost = null;
        this.post.likes = this.post.likes - 1;
        axios
          .post(`${process.env.VUE_APP_API_URL}/forum-remove-subpost-like`, {
            post_id: this.post.post_id,
            user_id: this.$parent.user_id,
          })
          .then((response) => {})
          .catch(() => {
            // console.log("error");
          });
      } else {
        const indexOfObject = this.$parent.like_threads.findIndex((object) => {
          return object.thread_message_id === this.post.post_id;
        });

        this.$parent.like_threads.splice(indexOfObject, 1);
        this.post.likes = this.post.likes - 1;
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/forum-remove-thread-message-like`,
            {
              post_id: this.post.post_id,
              user_id: this.$parent.user_id,
            }
          )
          .then((response) => {})
          .catch(() => {
            // console.log("error");
          });
      }
      if (this.$parent.is_fav(this.post)) {
        if (this.$parent.filterFav) {
          const indexOfObject = this.$parent.normal_posts.findIndex(
            (object) => {
              return object.post_id === this.post.post_id;
            }
          );
          if (indexOfObject >= 0) {
            this.$parent.normal_posts[indexOfObject].likes -= 1;
          }
        } else {
          const indexOfObject = this.$parent.favourite_posts.findIndex(
            (object) => {
              return object.post_id === this.post.post_id;
            }
          );
          if (indexOfObject >= 0) {
            this.$parent.favourite_posts[indexOfObject].likes -= 1;
          }
        }
      }
    },
    // Load date format
    date_format() {
      var value = Date.parse(this.post.created_at);

      const date = new Date(value);

      return (
        "" +
        date.getDate() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getFullYear() +
        ", " +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes()
      );
    },
    // Open delete confirmation modal
    delete_thread_message(post) {
      this.$parent.$refs.modalConfirmation.confirmationMessage =
        "Are you sure you want to delete this message?";
      this.$parent.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$parent.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$parent.$refs.modalConfirmation.confirmationButtonText2 =
        "Si, Eliminar";
      this.$parent.confirmModal = 2;
      this.$parent.delete_post = post;
      // this.$parent.openModalConfirmation();
    },
    // On quote do scroll to the new message card
    scrollResponse(post) {
      this.$parent.quoteMessage = post;
      $("html, body").animate(
        {
          scrollTop: $("#response-div").offset().top,
        },
        500
      );
    },
  },
  mixins: [translationMixin],
};
</script>
