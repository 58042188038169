export const ROLES_IN_PROJECT = {
  MEMBER: 0,
  TUTOR: 1,
};

export const TABS_TYPE = {
  ITINERARY: 0,
  EXPERT: 1,
  EVALUATOR: 2,
};

export const ASSISTANTS_TYPE = {
  EXPERT: 1,
  EVALUATOR: 2,
};
