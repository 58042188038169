<script setup>
import newItinerary from "../components/newitinerary/newItineraryComponent.vue";
</script>

<template>
  <newItinerary
    :role="role"
    :space="spaces[0]"
    :lang="lang"
    @alert-message="alertMessage"
  />
</template>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  emits: ["openAlert"],
  methods: {
    alertMessage() {
      this.$emit("openAlert", { message: "projectSaved", colour: 2 });
    },
  },
};
</script>

<style>
@import "../../src/assets/css/newitinerary/newitinerary.css";
</style>
