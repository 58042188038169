<template>
  <div id="notifications_div_header" class="notifications-div-header">
    <img
      v-if="notifications.length != 0"
      id="notImg"
      class="notifications-points padding-dot-notifications"
      :src="require('@/assets/img/notifications/points.svg')"
      alt="Options"
      @click="
        locateModal($event, banner);
        openModalNotifications(0);
      "
    />
    <span class="notifications-title">{{ langFilter("notifications") }}</span>
    <br />
    <button
      style="margin-right: 5px"
      class="notifications-btn notifications-btn-new"
      :class="[isSelectedAllTab ? 'selected-tab' : 'unselected-tab']"
      @click="showNotification(0)"
    >
      {{ langFilter("all") }}
    </button>
    <button
      class="notifications-btn notification-btn-unread"
      :class="[
        { disabled: !newNotifications?.length },
        isSelectedUnreadTab ? 'selected-tab' : 'unselected-tab',
      ]"
      :disabled="!newNotifications?.length"
      @click="showNotification(1)"
    >
      {{ langFilter("unread") }}
    </button>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import notificationsMixin from "../../mixins/notificationsMixin.js";

const NOTIFICATIONS_TABS = {
  ALL: 0,
  UNREAD: 1,
};

export default {
  mixins: [translationMixin, notificationsMixin],
  props: {
    newNotifications: {
      type: Array,
      required: true,
    },
    token: {
      type: String,
      required: false,
      default: "",
    },
    banner: {
      type: Boolean,
      required: true,
    },
    spaceId: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    changeNotification: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  emits: ["changeNot"],
  data() {
    return {
      notifications: [],
      traducciones: [
        {
          name: "notifications",
          es: "Notificaciones",
          en: "Notifications",
        },
        {
          name: "all",
          es: "Todo",
          en: "All",
        },
        {
          name: "unread",
          es: "No leídos",
          en: "Unread",
        },
      ],
    };
  },
  computed: {
    isSelectedAllTab() {
      return this.changeNotification === NOTIFICATIONS_TABS.ALL;
    },
    isSelectedUnreadTab() {
      return this.changeNotification === NOTIFICATIONS_TABS.UNREAD;
    },
  },
  created() {
    this.getNotifications(0, 1);
  },
  methods: {
    showNotification(type) {  
      if (this.newNotifications.length > 0) {
        this.$emit("changeNot", type);
      }
    },
  },
};
</script>
