<template>
  <div class="modal-head">
    <h5 class="modal-main-title">{{ headText.title }}</h5>
    <p class="modal-info">{{ headText.info }}</p>
    <div type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3248 16.6752C15.9342 17.0658 15.3011 17.0658 14.9105 16.6752L9.05882 10.8235L3.14828 16.7341C2.75776 17.1246 2.12459 17.1246 1.73407 16.7341L1.23652 16.2365C0.845994 15.846 0.845995 15.2128 1.23652 14.8223L7.14706 8.91177L1.32476 3.08946C0.934231 2.69894 0.93423 2.06577 1.32475 1.67525L1.67525 1.32475C2.06577 0.934229 2.69894 0.934229 3.08946 1.32475L8.91177 7.14706L14.8223 1.23652C15.2128 0.845995 15.846 0.845995 16.2365 1.23652L16.7341 1.73407C17.1246 2.12459 17.1246 2.75776 16.7341 3.14828L10.8235 9.05882L16.6752 14.9105C17.0658 15.3011 17.0658 15.9342 16.6752 16.3248L16.3248 16.6752Z"
            fill="#B7AED6"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],

  props: {
    lang: {
      type: String,
      required: true,
    },
    headText: {
      type: Object,
      required: true,
      validator(value) {
        return "title" in value && "info" in value;
      },
    },
  },

  data() {
    return {
      //Traducción
      traducciones: [],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
