<template>
  <div class="chat-with-gpt-container">
    <img
      v-if="initLoading"
      class="initLoading-gpt"
      :src="require('@/assets/img/general/carga.gif')"
    />
    <template v-else>
      <img
        class="gpt-reload-logo"
        :src="Reload"
        alt="ReloadGpt"
        @click="showConfirmation = true"
      />
      <div
        v-if="showConfirmation"
        class="gpt-confirmation-fade"
        @click="cancelConfirmation"
      />
      <div v-if="showConfirmation" class="gpt-confirmation-container">
        {{ langFilter("confirmationMsg") }}
        <div>
          <button @click="cancelConfirmation">
            {{ langFilter("cancel") }}
          </button>
          <button @click="acceptConfirmation">
            {{ langFilter("accept") }}
          </button>
        </div>
      </div>
      <div
        ref="chatContainer"
        :style="`height: ${chatHeight}px;`"
        class="chat-texts-div"
      >
        <div class="gpt-user-container">
          <div class="gpt-dialoge gpt-user-dialoge no-left row">
            <div class="col-2">
              <img :src="Alex" alt="Alex" />
            </div>
            <div class="col-10">
              <span
                >{{ langFilter("welcome") }} {{ user_info.first_name }},
                {{ langFilter("howHelp") }}</span
              >
            </div>
          </div>
        </div>
        <div
          v-for="(msg, messageIndex) in messages"
          :key="messageIndex"
          class="gpt-user-container"
        >
          <div class="gpt-dialoge gpt-chat-dialoge row" @copy="stripHtml">
            <div class="col-11">
              <span>{{ formatMessage(msg.message) }}</span>
            </div>
            <div class="col-1">
              <img :src="getAvatar()" alt="User avatar" class="gpt-user-logo" />
            </div>
          </div>
          <div
            v-if="filesPreUploaded.some((file) => file.message_id === msg.id)"
            style="margin-bottom: 0"
            class="file-uploaded-gpt-div file-uploaded-gpt-user"
          >
            <img :src="FileGptIcon" alt="FileGptIcon" />
            <a :href="getMessagesFileUrl(msg.id)" target="_blank">{{
              getMessagesFile(msg.id)
            }}</a>
          </div>
          <div class="gpt-user-container">
            <div class="gpt-dialoge gpt-user-dialoge row" @copy="stripHtml">
              <div class="col-1">
                <img
                  class="alex-logo-chatbot gpt-init-logo"
                  :src="Alex"
                  alt="gptBot"
                />
              </div>
              <div class="col-10">
                <img
                  v-if="msg.response == undefined"
                  class="loading-gpt"
                  :src="require('@/assets/img/general/carga.gif')"
                />
                <span
                  v-else-if="msg.response == 'Error'"
                  class="gpt-error-color"
                  >{{ langFilter("gptError") }}
                  <br />
                  <button class="chaterrorbtn" @click="showConfirmation = true">
                    {{ langFilter("newChat") }}
                  </button>
                  <!-- <a href="mailto:support@acceleralia.com"
                    >support@acceleralia.com</a
                  > -->
                </span>
                <span v-else>
                  {{ formatMessage(msg.response) }}
                </span>
              </div>
              <div class="col-1">
                <img
                  class="gpt-copy-logo pointer"
                  :src="Copy"
                  alt="copy"
                  @click="copyToClipboard(messageIndex)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row gpt-files-div">
        <div
          v-for="(fileUploaded, filesUploadedIndex) in filesUploaded"
          :key="filesUploadedIndex"
          class="col-12"
        >
          <div class="gpt-files-div-item">
            <a :href="filesUrl[filesUploadedIndex]" target="_blank">
              {{ fileUploaded.name }}
            </a>
            <button @click="removeFileGpt(filesUploadedIndex, event)">x</button>
          </div>
        </div>
      </div>
      <div class="form-chatbot">
        <input
          ref="fileInput"
          class="hide"
          type="file"
          accept=".c,.cs,.cpp,.csv,.doc,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.rb,.tex,.txt,.css,.jpeg,.jpg,.js,.gif,.png,.sh,.ts,.xlsx"
          @change="uploadedFileGpt($event)"
        />
        <div class="row">
          <div class="col-1 flex-center">
            <button class="gpt-send-btn" @click="openFileInput">
              <img class="gpt-send-logo" :src="FileIcon" alt="file" />
            </button>
          </div>
          <div class="col-10 padding-0">
            <form @submit="sendMessage($event)">
              <input
                class="chat-with-gpt-input"
                :value="message"
                type="text"
                maxlength="4096"
                @input="message = $event.target.value"
              />
            </form>
          </div>
          <div class="col-1 gpt-send-div">
            <button class="gpt-send-btn" @click="sendMessage">
              <img class="gpt-send-logo" :src="Send" alt="send" />
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
import Alex from "./images/alex.webp";
import CloseGPT from "../solution/images/close-gpt.png";
import FileGptIcon from "./images/file-gpt-icon.png";
import Send from "../solution/images/send.png";
import Copy from "./images/copy.png";
import FileIcon from "./images/file.svg";
import Reload from "../solution/images/reload.png";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CloseGPT: CloseGPT,
      Alex: Alex,
      Copy: Copy,
      FileIcon: FileIcon,
      chatOpened: false,
      Send: Send,
      Reload: Reload,
      message: "",
      user_info: {},
      messages: [],
      showConfirmation: false,
      initLoading: true,
      filesUploaded: [],
      filesUrl: [],
      chatHeight: 590,
      FileGptIcon: FileGptIcon,
      messageFiles: [],
      filesPreUploaded: [],
      traducciones: [
        {
          name: "gptError",
          es: "Has llegado al límite de consultas, por favor inicie un nuevo chat.",
          en: "You have reached the limit of queries, please start a new chat.",
        },
        {
          name: "gptError2",
          es: "Ha ocurrido un error al conectar con el Asistente, por favor inténtelo de nuevo de más tarde o contacte con:",
          en: "An error has occurred while connecting to the Assistant, please try again later or contact with:",
        },
        {
          name: "newChat",
          es: "Nuevo Chat",
          en: "New Chat",
        },
        {
          name: "confirmationMsg",
          es: "¿Estás seguro de que quieres reiniciar la conversación?",
          en: "Are you sure you want to restart the conversation?",
        },
        {
          name: "accept",
          es: "Aceptar",
          en: "Accept",
        },
        {
          name: "cancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "welcome",
          es: "Bienvenido",
          en: "Welcome",
        },
        {
          name: "howHelp",
          es: "Hola, soy tu asistente de IA entrenado para este proyecto, ¿en qué puedo ayudarte?",
          en: "Hello, I'm your AI assistant trained for this project, how can I help you?",
        },
      ],
    };
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/edit-profile/get_user`)
      .then((response) => {
        this.user_info = response.data;
      });
    this.getUserChatGpt();
  },
  methods: {
    getMessagesFile(id) {
      const file = this.filesPreUploaded.find((file) => file.message_id === id);
      if (file) {
        return file.file_name;
      } else {
        return "";
      }
    },
    getMessagesFileUrl(id) {
      const file = this.filesPreUploaded.find((file) => file.message_id === id);
      if (file) {
        return process.env.VUE_APP_API_STORAGE + "/storage/" + file.file_path;
      } else {
        return undefined;
      }
    },
    getUserChatGpt() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/getUserChatGpt`)
        .then((response) => {
          this.initLoading = false;
          this.messages = [];
          response.data.messages.forEach((message) => {
            if (message.files.length > 0) {
              message.files.forEach((file) => {
                this.filesPreUploaded.push(file);
              });
            }
            this.messages = response.data.messages;
            setTimeout(() => {
              const chatContainer = this.$refs.chatContainer;
              chatContainer.scrollTop = chatContainer.scrollHeight;
            }, 200);
          });
        });
    },
    removeFileGpt(i) {
      this.chatHeight = this.chatHeight + 50;
      this.$nextTick(() => {
        this.$refs.chatContainer.scrollTop =
          this.$refs.chatContainer.scrollHeight;
      });
      this.filesUploaded.splice(i, 1);
      this.filesUrl.splice(i, 1);
    },
    uploadedFileGpt() {
      const file = this.$refs.fileInput.files[0];
      const allowedExtensions = [
        ".c",
        ".cs",
        ".cpp",
        ".csv",
        ".doc",
        ".docx",
        ".html",
        ".java",
        ".json",
        ".md",
        ".pdf",
        ".php",
        ".pptx",
        ".py",
        ".rb",
        ".tex",
        ".txt",
        ".css",
        ".jpeg",
        ".jpg",
        ".js",
        ".gif",
        ".png",
        ".sh",
        ".ts",
        ".xlsx",
      ];
      const fileExtension = "." + file.name.split(".").pop();

      if (!allowedExtensions.includes(fileExtension)) {
        alert(
          "Invalid file type. Only .c, .cs, .cpp, .csv, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .sh, .ts files are allowed."
        );
        return;
      }

      this.chatHeight = this.chatHeight - 50;
      this.$nextTick(() => {
        this.$refs.chatContainer.scrollTop =
          this.$refs.chatContainer.scrollHeight;
      });
      this.filesUploaded.push(file);
      this.filesUrl.push(URL.createObjectURL(file));
    },
    openFileInput(event) {
      event.preventDefault();
      this.$refs.fileInput.click();
    },
    stripHtml(e) {
      e.preventDefault();
      const text = window.getSelection().toString();
      e.clipboardData.setData("text/plain", text);
    },
    acceptConfirmation() {
      axios
        .delete(`${process.env.VUE_APP_API_URL}/removeUserChatGpt`)
        .then(() => {
          this.messages = [];
          this.showConfirmation = false;
        });
    },
    cancelConfirmation() {
      this.showConfirmation = false;
    },
    formatMessage(text) {
      return text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    },
    copyToClipboard(i) {
      const textArea = document.createElement("textarea");
      textArea.value = this.messages[i].response;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    },
    sendMessage(e) {
      e.preventDefault();
      const maxFiles = 5;
      const maxSize = 8388608;
      let totalSize = 0;

      this.filesUploaded.forEach((file) => {
        totalSize += file.size;
      });

      if (this.filesUploaded.length > maxFiles) {
        alert(`You can only upload a maximum of ${maxFiles} files.`);
        return;
      }

      if (totalSize > maxSize) {
        alert(
          `Total file size should not exceed ${maxSize / (1024 * 1024)} MB.`
        );
        return;
      }
      if (this.filesUploaded.length === 0 && this.prompt === "") return;
      if (this.filesUploaded.length > 0) {
        const forbiddenTypes = [
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ];

        for (let file of this.filesUploaded) {
          if (forbiddenTypes.includes(file.type)) {
            alert("PowerPoint files are not allowed");
            return;
          }
        }

        let formData = new FormData();
        const message =
          this.message !== "" ? this.message : "Analiza los archivos adjuntos";
        formData.append("message", message);
        if (this.filesUploaded) {
          for (let i = 0; i < this.filesUploaded.length; i++) {
            formData.append("files[]", this.filesUploaded[i]);
          }
        }
        this.filesUploaded = [];
        this.messages.push({ message: message });
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.chatContainer.scrollTop =
              this.$refs.chatContainer.scrollHeight;
          });
        }, 200);
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/assistant/uploadUserFile`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            this.filesUploaded = [];
            //this.filesUrl = [];
            this.messages.push(res.data);
            this.getUserChatGpt();
          });
      } else {
        if (this.message !== "") {
          this.messages.push({ message: this.message });
          const message = this.message;
          this.message = "";
          setTimeout(() => {
            const chatContainer = this.$refs.chatContainer;
            chatContainer.scrollTop = chatContainer.scrollHeight;
            this.chatHeight = 590;
            this.$nextTick(() => {
              this.$refs.chatContainer.scrollTop =
                this.$refs.chatContainer.scrollHeight;
            });
            this.filesUploaded = [];
          }, 200);

          axios
            .post(`${process.env.VUE_APP_API_URL}/chatgptUser`, {
              message: message,
            })
            .then(() => {
              // const data = res.data.message;
              // this.previousBotMessages.push(data);
              this.getUserChatGpt();
            })
            .catch(() => {
              //   this.previousBotMessages.push("Error generando la respuesta");
              alert("Error generando la respuesta");
            })
            .finally(() => {
              const chatContainer = this.$refs.chatContainer;
              chatContainer.scrollTop = chatContainer.scrollHeight;
            });
        }
      }
    },
    getAvatar() {
      if (this.user_info.avatar.includes("http")) {
        // remove blank spaces
        this.user_info.avatar = this.user_info.avatar.replace(/ /g, "%20");
        return this.user_info.avatar;
      } else {
        return process.env.VUE_APP_API_STORAGE + this.user_info.avatar;
      }
    },
  },
};
</script>
