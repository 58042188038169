<template>
  <div
    v-if="
      $parent.posts.length / $parent.maxPosts > 1 &&
      $parent.view !== 5 &&
      $parent.view !== 0
    "
    class="pagination-container"
  >
    <head>
      <link
        rel="stylesheet"
        type="text/css"
        href="/css/components/pagination2.css"
      />
    </head>
    <div class="pagination-numbers">
      <div>
        <p @click="paginationFirst($event)">{{ langFilter("First") }}</p>
      </div>
      <div
        v-for="index in paginationTotalNumbers"
        :id="
          (index == Math.ceil(length / max) ? 'pagination-last' : '') +
          (index == 1 ? 'pagination-first' : '')
        "
        :key="index"
        :class="index == 1 ? 'pagination-active' : ''"
      >
        <p
          v-if="
            index >= position + 1 - 2 &&
            index <= position + 1 + 2 &&
            position + 1 - 2 > 0 &&
            position + 1 + 2 < length
          "
          @click="changePaginationPosition(index - 1, $event)"
        >
          {{ index }}
        </p>
        <p
          v-else-if="position + 1 - 2 <= 0 && index <= 5"
          @click="changePaginationPosition(index - 1, $event)"
        >
          {{ index }}
        </p>
        <p
          v-else-if="position + 1 + 2 >= length && index > length - 5"
          @click="changePaginationPosition(index - 1, $event)"
        >
          {{ index }}
        </p>
      </div>
      <div>
        <p @click="paginationLast($event)">{{ langFilter("Last") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../mixins/translationMixin.js";
import $ from "jquery";

export default {
  mixins: [translationMixin],
  props: {
    length: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      position: 0,

      //Traducción
      traducciones: [
        {
          name: "First",
          es: "Primera",
          en: "First",
        },
        {
          name: "Last",
          es: "última",
          en: "Last",
        },
      ],
    };
  },
  computed: {
    paginationTotalNumbers() {
      const result = this.length / this.max;

      return Math.ceil(result);
    },
  },
  created() {},
  methods: {
    changePaginationPosition(index, el) {
      $(".pagination-active").removeClass("pagination-active");
      $(el.target).addClass("pagination-active");
      this.position = index;
      this.operationSlice();
    },
    paginationFirst() {
      this.position = 0;
      this.operationSlice();
      $(".pagination-active").removeClass("pagination-active");
      $("#pagination-first").addClass("pagination-active");
    },
    paginationLast() {
      this.position = this.length / this.max - 1;
      if (this.position % 1 != 0) {
        this.position = Math.ceil(this.position);
      }
      this.operationSlice();

      $(".pagination-active").removeClass("pagination-active");
      $("#pagination-last").addClass("pagination-active");
    },
    operationSlice() {
      this.$parent.pagination1 = this.position * this.max;
      this.$parent.pagination2 = this.position * this.max + this.max;
    },
  },
};
</script>
