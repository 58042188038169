<template>
  <div id="modalEditQuestion" class="modal" tabindex="-1">
    <div class="modal-dialog" style="z-index: 99">
      <div class="modal-content modal-content-editQuestion">
        <div class="modal-div-editQuestion">
          <h5 class="modal-title-EditQuestion">
            {{ langFilter("editQuestion") }}
          </h5>
          <button
            class="btn-close delete-cross-editQuestion"
            @click="openModalConfirmation"
          />
        </div>
        <div class="modal-body div-editQuestion-body">
          <p class="modal-body-EditQuestion">{{ langFilter("question") }}*</p>
          <textarea
            class="edit-question-textarea"
            cols="30"
            rows="10"
            :value="title"
            :placeholder="langFilter('writeQuestion') + '...'"
            @input="setQuestionName($event)"
          />
          <p class="modal-body-EditQuestion">
            {{ langFilter("ChatGPT Prompt") }}
          </p>
          <textarea
            class="edit-question-textarea"
            :value="prompt"
            :placeholder="langFilter('ChatGPT Prompt') + '...'"
            cols="30"
            rows="10"
            @input="setQuestionPrompt($event)"
          />
          <p class="modal-body-EditQuestion">
            {{ langFilter("typology") }}*
            <span
              v-if="optionSelected == 5"
              class="editQuestion-typology-subtitle"
              >({{ langFilter("subtitleSingle") }})</span
            >
            <span
              v-else-if="optionSelected == 10"
              class="editQuestion-typology-subtitle"
              >({{ langFilter("subtitleMultiple") }})</span
            >
          </p>
          <select
            class="form-select-editQuestion"
            aria-label="Select typology"
            :value="optionSelected"
            @change="selectChanged($event)"
          >
            <option :value="1">
              {{ langFilter("text") }}
            </option>
            <option :value="10">
              {{ langFilter("multiple") }}
            </option>
            <option :value="5">{{ langFilter("single") }}</option>
          </select>
          <div v-if="optionSelected >= 5">
            <div
              v-for="(choice, choiceIndex) in choices"
              :key="choiceIndex"
              class="selected-option-div"
            >
              <div
                :class="[
                  {
                    'selected-option-correct': choice.selected,
                  },
                  { 'selected-option': !choice.selected },
                ]"
                @click="selectChoice(choiceIndex)"
              />
              <input
                class="selected-option-input"
                type="text"
                :value="choice.name"
                :placeholder="langFilter('addResponse') + '...'"
                @input="setChoiceName($event, choiceIndex)"
              />
              <button
                v-if="choices.length > 1"
                class="btn-close selected-option-btn"
                @click="deleteChoice(choiceIndex)"
              />
            </div>
            <p class="editQuestion-add-choice" @click="addChoice()">
              + {{ langFilter("add") }}
            </p>
          </div>
          <p class="modal-body-EditQuestion">
            {{ langFilter("description") }}
          </p>
          <textarea
            class="edit-question-textarea"
            cols="30"
            rows="10"
            :value="description"
            :placeholder="langFilter('description') + '...'"
            @input="setQuestionDesc($event)"
          />
        </div>
        <div class="modal-div-editQuestion">
          <button
            :class="[
              { 'modal-div-editQuestion-btn-enabled': completed },
              'modal-div-editQuestion-btn',
            ]"
            @click="confirmUpdate()"
          >
            {{ langFilter("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Modal } from "bootstrap";
import translationMixin from "../../../mixins/translationMixin.js";
export default {
  props: ["lang", "myChoices"],
  data() {
    return {
      title: "",
      completed: true,
      optionSelected: 1,
      description: "",
      prompt: "",
      choices: [{ name: "", selected: true }],
      traducciones: [
        {
          name: "editQuestion",
          es: "Editar Pregunta",
          en: "Edit Question",
        },
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
        {
          name: "typology",
          es: "Tipología",
          en: "Typology",
        },
        {
          name: "description",
          es: "Descripción",
          en: "Description",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "writeQuestion",
          es: "Escribir pregunta",
          en: "Write the question",
        },
        {
          name: "text",
          es: "Texto",
          en: "Text",
        },
        {
          name: "multiple",
          es: "Opción Múltiple",
          en: "Multiple Choice",
        },
        {
          name: "single",
          es: "Opción Única",
          en: "Single Choice",
        },
        {
          name: "file",
          es: "Archivo",
          en: "File",
        },
        {
          name: "subtitleSingle",
          es: "marca la respuesta correcta",
          en: "mark the correct answer",
        },
        {
          name: "subtitleMultiple",
          es: "marca la/s respuesta correcta/s",
          en: "mark the correct answer/s",
        },
        {
          name: "addResponse",
          es: "Introducir respuesta",
          en: "Add response",
        },
        {
          name: "add",
          es: "Añadir",
          en: "Add",
        },
      ],
    };
  },
  created() {
    if (this.myChoices.length > 0) {
      this.choices = this.myChoices;
    }
  },
  methods: {
    openModalConfirmation() {
      this.$emit("openEditQuestionModal");
      Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      ).toggle();
    },
    selectChanged(event) {
      this.optionSelected = event.target.value;
      if (this.optionSelected == 5) {
        let numSelected = 0;
        this.choices.forEach((choice) => {
          if (choice.selected) {
            numSelected++;
            if (numSelected > 1) {
              choice.selected = false;
            }
          }
        });
      }
      this.checkCompleted();
    },
    reset() {
      this.completed = false;
      this.optionSelected = 1;
      this.title = "";
      this.description = "";
      this.prompt = "";
      this.choices = [{ name: "", selected: true }];
    },
    setQuestionName(event) {
      this.title = event.target.value;
      this.checkCompleted();
    },
    setQuestionPrompt(event) {
      this.prompt = event.target.value;
      this.checkCompleted();
    },
    setQuestionDesc(event) {
      this.description = event.target.value;
    },
    checkCompleted() {
      if (this.title != "" && this.optionSelected < 5) {
        this.completed = true;
      } else if (
        this.title != "" &&
        this.optionSelected >= 5 &&
        this.choices.length > 0
      ) {
        let completed = true;
        this.choices.forEach((choice) => {
          if (choice.name == "") {
            completed = false;
          }
        });
        this.completed = completed;
      } else {
        this.completed = false;
      }
    },
    confirmUpdate() {
      if (this.completed) {
        this.$emit("editQuestion", {
          title: this.title,
          description: this.description,
          type: this.optionSelected,
          choices: this.choices,
          prompt: this.prompt,
        });
        Modal.getOrCreateInstance(
          document.getElementById("modalEditQuestion")
        ).toggle();
        this.reset();
      }
    },
    selectChoice(choiceIndex) {
      if (this.optionSelected == 5) {
        this.choices.forEach((choice) => {
          choice.selected = false;
        });
      } else {
        let numSelected = 0;
        this.choices.forEach((choice) => {
          choice.selected == true ? numSelected++ : null;
        });
        if (numSelected == 1) {
          this.choices[choiceIndex].selected = true;
          return;
        }
      }
      this.choices[choiceIndex].selected = !this.choices[choiceIndex].selected;
    },
    deleteChoice(choiceIndex) {
      this.choices.splice(choiceIndex, 1);
      let numSelected = 0;
      this.choices.forEach((choice) => {
        choice.selected == true ? numSelected++ : null;
      });
      if (numSelected == 0) {
        this.choices[0].selected = true;
      }
      this.checkCompleted();
    },
    addChoice() {
      this.choices.push({ name: "", selected: false });
      this.completed = false;
    },
    setChoiceName(event, choiceIndex) {
      this.choices[choiceIndex].name = event.target.value;
      this.checkCompleted();
    },
  },
  mixins: [translationMixin],
};
</script>
