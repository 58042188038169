<template>
  <div class="notifications-new-div-container">
    <div
      id="notifications_new_title"
      style="display: inline-block; width: 100%"
    >
      <span class="notifications-new-title">{{ langFilter("new") }}</span>
      <a href="/notifications">
        <span v-if="banner" id="viewallnew" class="view-all-notification">{{
          langFilter("view")
        }}</span>
      </a>
    </div>

    <notifications-table-notification
      :notifications="notifications"
      :lang="lang"
      :banner="banner"
      :newer="true"
      @open-modal="openModal($event)"
      @locate-modal="locateModal($event)"
    />
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    banner: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["openModal", "locateModal"],
  data() {
    return {
      traducciones: [
        {
          name: "new",
          es: "Nuevas",
          en: "New",
        },
        {
          name: "view",
          es: "Ver todo",
          en: "View all",
        },
      ],
    };
  },
  methods: {
    openModal(event) {
      let arr = [];
      arr.push(event);
      arr.push(1);
      this.$emit("openModal", arr);
    },
    locateModal(event) {
      this.$emit("locateModal", event);
    },
    getNotificationURL() {
      let page = window.location.pathname;
      let pagename = page.split("/");
      return pagename[0] + "/notifications";
    },
  },
};
</script>
