<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div>
    <!-- <confirmation :lang="lang" ref="modalConfirmation2" /> -->
    <header-general
      :title="langFilter('title_template_screen')"
      :src="require('@/assets/img/header/template_logo.svg')"
    />
    <img
      v-if="loading"
      class="loading-center"
      :src="require('@/assets/img/general/loading.gif')"
    />
    <div v-show="!loading">
      <div
        style="width: 10px"
        class="back-arrow-solution back-arrow-templates"
        @click="clickBackArrow"
      >
        <img :src="require('@/assets/img/general/new_back.svg')" alt="back" />
      </div>
      <template-steps
        :stepNum="stepNum"
        :lang="lang"
        @selectStep="selectStep($event)"
      />
      <div v-show="stepNum < 4">
        <template-container
          :stepNum="stepNum"
          :lang="lang"
          :space="space"
          :title="title"
          :template_assistant_id="template_assistant_id"
          :template_assistant_name="template_assistant_name"
          :assistant_id="assistant_id"
          :assistant_evaluator_id="assistant_evaluator_id"
          :categories="categories"
          :image="image"
          @setTitle="setTitle($event)"
          @setTemplateAssistant="setTemplateAssistant($event)"
          @setTemplateAssistantName="setTemplateAssistantName($event)"
          @setAssistant="setAssistant($event)"
          @setAssistantEvaluator="setAssistantEvaluator($event)"
          @enableNext="enableNext($event)"
          @setTemplateImage="setTemplateImage($event)"
          @setCategories="setCategories($event)"
          @errorFileUpload="errorFileUpload()"
        />
        <new-template-footer
          :stepNum="stepNum"
          :lang="lang"
          :nextStep="nextStep"
          @previousStep="previousStep"
          @setNextStep="setNextStep"
        />
      </div>
      <new-template-creation
        v-show="stepNum === 4"
        ref="templateCreation"
        :lang="lang"
        :title="title"
        :template_assistant_id="template_assistant_id"
        :template_assistant_name="template_assistant_name"
        :assistant_id="assistant_id"
        :assistant_evaluator_id="assistant_evaluator_id"
        :image="image"
        :categories="categories"
        :space="space"
        :draft="draft"
        :relations="relations"
        :draftResources="draftResources"
        @updateTitle="setTitle($event)"
        @updateTemplateAssistant="setTemplateAssistant($event)"
        @updateTemplateAssistantName="setTemplateAssistantName($event)"
        @setLoading="setLoading($event)"
        @openAlert="openAlert($event)"
      />
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import translationMixin from "../../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
  },
  emits: { openAlert: null },
  data() {
    return {
      stepNum: 0,
      nextStep: false,
      title: "",
      template_assistant_id: "",
      template_assistant_name: "",
      assistant_id: "",
      assistant_evaluator_id: "",
      categories: [],
      tracks: [],
      draft: false,
      image: "/img/track/predefined.png",
      relations: [],
      draftResources: [],
      loading: true,
      traducciones: [
        {
          name: "title_template_screen",
          es: "Plantillas",
          en: "Templates",
        },
      ],
    };
  },

  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTracks`, {
        lang: this.lang,
        space_id: this.space.id,
      })
      .then((response) => {
        console.log(response.data);
        this.tracks = response.data;
        console.log("getAllTracks: " + this.tracks);

        //console.log(response.data)
      });
    // Carga template si existe el parámetro template_id
    if (this.$route.params.template_id) {
      const template_id = this.$route.params.template_id;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getTemplate/${this.space.id}/${template_id}/${this.lang}`
        )
        .then((response) => {
          console.log(response);
          const template = response.data.template;
          const categories = response.data.categories;
          const questions = response.data.questions;
          this.title = template.name;
          this.image = template.img;
          this.categories = categories;
          this.stepNum = 4;
          this.draft = template.draft === 1 ? true : false;
          let resources = response.data.resources;
          resources.forEach((resource) => {
            if (!resource.link.includes("http"))
              resource.link = `${process.env.VUE_APP_API_STORAGE}${resource.link}`;
          });

          this.draftResources = resources;
          this.relations = [response.data.relations, questions, resources];
          this.template_assistant_id = template.template_assistant_id;
          this.template_assistant_name = template.template_assistant_name;
          setTimeout(() => {
            this.assistant_id = this.filterTrackType(
              template_id,
              1
            )?.assistant_id;
            this.assistant_evaluator_id = this.filterTrackType(
              template_id,
              2
            )?.evaluator_id;
          }, 1000);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push({ name: "NewTemplate" });
          } else if (error.response.status === 401) {
            alert("No tienes permisos para acceder a esta página");
          } else {
            alert("Error al cargar el template");
          }
          this.$router.replace({ name: "Templates" });
        });

      this.assistant_id = "";
      this.assistant_evaluator_id = "";
    } else {
      this.loading = false;
    }
  },
  methods: {
    confirmTrackContinue() {
      this.confirmTrackCancel();
      this.$router.push({ path: "/templates" });
    },
    confirmTrackCancel() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
    },
    clickBackArrow() {
      if (
        window.confirm(
          "¿Estas seguro que quieres salir? Se perderán los datos no guardados."
        )
      ) {
        this.$router.push({ path: "/templates" });
      }
    },
    exitScreen() {
      //   const state = this.$refs.templateCreation.draftCompleted;
      //   if (state) this.$refs.templateCreation.saveTemplate(true, true);
      //   return state;
    },
    openAlert(event) {
      this.$emit("openAlert", event);
    },
    setLoading(event) {
      this.loading = event;
    },
    checkIfStepCompleted(stepNum) {
      if (stepNum === 2 || stepNum === 1 || stepNum === 0 || stepNum == "-1") {
        return this.title !== "";
      } else if (stepNum === 3) {
        return this.categories.length !== 0 && this.title !== "";
      }
    },
    selectStep(stepIndex) {
      if (stepIndex < 4 && this.title === "") {
        this.nextStep = false;
      } else {
        if (this.stepNum <= stepIndex) {
          if (this.checkIfStepCompleted(stepIndex - 1)) {
            this.stepNum = stepIndex;
            this.nextStep = this.checkIfStepCompleted(stepIndex);
          }
        } else {
          this.stepNum = stepIndex;
          this.nextStep = true;
        }
      }
    },
    setTitle(event) {
      this.title = event;
    },
    setTemplateAssistant(event) {
      this.template_assistant_id = event;
    },
    setTemplateAssistantName(event) {
      this.template_assistant_name = event;
    },
    setAssistant(event) {
      this.assistant_id = event;
    },
    setAssistantEvaluator(event) {
      this.assistant_evaluator_id = event;
    },
    previousStep() {
      this.stepNum--;
      this.nextStep = true;
    },
    setNextStep() {
      if (this.checkIfStepCompleted(this.stepNum)) {
        this.stepNum++;
        this.checkIfNextStepCompleted();
      } else {
        if (this.checkIfNextStepCompleted()) {
          this.nextStep = false;
        }
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.errors = [];
    },
    enableNext(event) {
      this.nextStep = event;
    },
    checkIfNextStepCompleted() {
      if (this.stepNum === 0 || this.stepNum === 1 || this.stepNum === 2) {
        this.nextStep = this.title !== "";
      } else if (this.stepNum === 3) {
        this.nextStep = this.categories.length > 0;
      } else {
        this.nextStep = false;
      }
    },
    setTemplateImage(event) {
      this.image = event;
    },
    setCategories(event) {
      this.categories = event;
      this.nextStep = this.categories.length > 0;
    },
    errorFileUpload() {
      this.$refs.alerts.title = "Incorrect data entered!";
      this.$refs.alerts.text = "the email and/or password do not match.";
      console.log("error file upload");
      this.openErrorsAlert();
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    filterTrackType(id, type) {
      const result = this.tracks.find((item) => {
        if (item.id == id && type == 1) {
          return item?.assistant_id;
        }

        if (item.id == id && type == 2) {
          return item?.evaluator_id;
        }
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../src/assets/css/template/new-template.css";
</style>
